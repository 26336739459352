import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BsCardChecklist } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi";
import { IoIosClose, IoIosMore } from "react-icons/io";

import {
  MdAccountCircle,
  MdBlockFlipped,
  MdFlag,
  MdOutlineCancelPresentation,
  MdOutlinePendingActions,
} from "react-icons/md";
import { Transfers } from "../transfers/Transfers";
import { Users } from "../users/Users";
import style from "./Dashboard.css";
import { getCurrentFormattedDate } from "./DashboardUtils";
import { getTransfersDashboard } from "src/redux/actions/actions";

const Body = style;

export const Dashboard = () => {
  const users = useSelector((state: any) => state.users.users);
  const transfers = useSelector(
    (state: any) => state.transferDasboard.transfers
  );
  const user = useSelector((state: any) => state.auth.user);

  const [activetab, setActiveTab] = useState(0);
  const [dropdownTabs, setDropdownTabs] = useState(false);
  const [filterBoxactivestate, setFilterBoxactivestate] = useState("ALL");

  useEffect(() => {
    getTransfersDashboard();
  }, []);

  const [filterBox, setFilterBox] = useState([
    {
      name: "New users",
      icon: <HiOutlineUserGroup size={24} color="#424242" />,
      count: 10,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "ALL",
    },
    {
      name: "Pending documentation",
      icon: <MdOutlinePendingActions size={24} color="#EEC503" />,
      count: 10,
      icon_bg_color: "rgba(252, 210, 15, 0.1)",
      format: "PENDING_DOCUMENTATION",
    },
    {
      name: "Payment completed",
      icon: <BsCardChecklist size={24} color="#007B5D" />,
      count: 10,
      icon_bg_color: "rgba(0, 123, 93, 0.1)",
      format: "PAYMENT_COMPLETED",
    },
    {
      name: "Cancelled Transactions",
      icon: <MdOutlineCancelPresentation size={24} color="#CF0921" />,
      count: 5,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "CANCELLED",
    },
    {
      name: "Flagged Accounts",
      icon: <MdFlag size={24} color="#CF0921" />,
      count: 2,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "WATCHLIST_HIT",
    },
    {
      name: "Pending Payments",
      icon: <MdOutlinePendingActions size={24} color="#EEC503" />,
      count: 4,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "PENDING_PAYMENT",
    },
    {
      name: "Active Accounts",
      icon: <MdAccountCircle size={24} color="#007B5D" />,
      count: 2,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "ACTIVE",
    },
    {
      name: "Pending Verifications",
      icon: <MdOutlinePendingActions size={24} color="#EEC503" />,
      count: 4,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "PENDING_VERIFICATION",
    },
    {
      name: "Blocked Transcations",
      icon: <MdBlockFlipped size={24} color="#CF0921" />,
      count: 4,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "BLOCKED",
    },
  ]);

  const dropdownFilterBox = [
    {
      name: "New users",
      icon: <HiOutlineUserGroup size={24} color="#424242" />,
      count: 10,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "ALL",
    },
    {
      name: "Pending documentation",
      icon: <MdOutlinePendingActions size={24} color="#EEC503" />,
      count: 10,
      icon_bg_color: "rgba(252, 210, 15, 0.1)",
      format: "PENDING_DOCUMENTATION",
    },
    {
      name: "Payment completed",
      icon: <BsCardChecklist size={24} color="#007B5D" />,
      count: 10,
      icon_bg_color: "rgba(0, 123, 93, 0.1)",
      format: "PAYMENT_COMPLETED",
    },
    {
      name: "Cancelled Transactions",
      icon: <MdOutlineCancelPresentation size={24} color="#CF0921" />,
      count: 5,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "CANCELLED",
    },
    {
      name: "Flagged Accounts",
      icon: <MdFlag size={24} color="#CF0921" />,
      count: 2,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "WATCHLIST_HIT",
    },
    {
      name: "Pending Payments",
      icon: <MdOutlinePendingActions size={24} color="#EEC503" />,
      count: 4,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "PENDING_PAYMENT",
    },
    {
      name: "Active Accounts",
      icon: <MdAccountCircle size={24} color="#007B5D" />,
      count: 2,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "ACTIVE",
    },
    {
      name: "Pending Verifications",
      icon: <MdOutlinePendingActions size={24} color="#EEC503" />,
      count: 4,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "PENDING_VERIFICATION",
    },
    {
      name: "Blocked Transcations",
      icon: <MdBlockFlipped size={24} color="#CF0921" />,
      count: 4,
      icon_bg_color: "rgba(66, 66, 66, 0.1)",
      format: "BLOCKED",
    },
  ];

  const updateFilterBox = (index: number, newValue: any) => {
    const updatedTabs = [...filterBox];
    updatedTabs[index] = newValue;
    setFilterBox(updatedTabs);
  };

  const handleFilterBoxClick = (index: number, tab: any) => {
    setFilterBoxactivestate(tab.format);
    setActiveTab(index);
    setDropdownTabs((prev) => !prev);

    if (index === 3) {
      updateFilterBox(3, tab);
    }
    if (index > 3) {
      updateFilterBox(3, tab);
    }
  };

  const formattedDate: string = getCurrentFormattedDate();

  function filterTableByTab(users: any, tab: string) {
    if (users?.length > 0) {
      const filtered = users?.filter((user: any) => {
        if (tab.toUpperCase() === "ALL") {
          return true;
        }
        return user.status === tab;
      });
      return filtered?.length;
    }
  }

  return (
    <Body>
      <div className="dashboard">
        <div className="dashboard_section_1">
          <div className="welcome">
            <h1>Welcome back, {user.profile.firstName}</h1>
            <p>{formattedDate}.</p>
          </div>
        </div>
        <div className="dashboard_section_2">
          <div className="tabs">
            {filterBox.map((tab, index) => (
              <div
                key={"dasboard_tabs_" + index}
                className={
                  activetab === index || (activetab > 3 && index === 3)
                    ? "active_tab"
                    : "tab_container"
                }
                onClick={() => {
                  setActiveTab(index);
                  setFilterBoxactivestate(tab.format);
                }}
                style={{
                  display: index <= 3 ? "block" : "none",
                }}
              >
                <div className="tab">
                  <div className="tab_details">
                    <div className="tab_name">
                      <span>{tab.name}</span>
                      <br />
                      <span>Since 1 day ago</span>
                    </div>
                    <div
                      className="tab_icon"
                      style={{ background: tab.icon_bg_color }}
                    >
                      {tab.icon}
                    </div>
                  </div>
                  <div className="count">
                    {tab.format === "ALL" || tab.format === "ACTIVE" ? (
                      <p>{filterTableByTab(users, tab.format) || 0}</p>
                    ) : (
                      <p>{filterTableByTab(transfers, tab.format) || 0} </p>
                    )}
                  </div>
                </div>
              </div>
            ))}

            <div
              className="more"
              onClick={() => {
                setDropdownTabs((prev) => !prev);
              }}
            >
              <p>More</p>
              {dropdownTabs ? (
                <IoIosClose size={24} color="#007B5D" />
              ) : (
                <IoIosMore size={24} color="#007B5D" />
              )}
            </div>
          </div>

          {dropdownTabs && (
            <div className="dropdown_tab">
              <div className="options">
                {dropdownFilterBox.map((tab, index) => (
                  <div
                    className="option"
                    key={"dropdown_tab_" + index}
                    onClick={() => {
                      handleFilterBoxClick(index, tab);
                    }}
                  >
                    <div
                      className={
                        activetab === index
                          ? "active_checkbox"
                          : "inactive_checkbox"
                      }
                    >
                      <div className="indicator"></div>
                    </div>
                    <span>{tab.name}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="dashboard_user_table">
          <div
            className={`${
              filterBoxactivestate === "ALL" ||
              filterBoxactivestate === "ACTIVE"
                ? "show_table_componets"
                : "hide_table_componets"
            } `}
          >
            <Users dashboard={false} activeFilterBox={filterBoxactivestate} />
          </div>
          <div
            className={`${
              filterBoxactivestate === "ALL" ||
              filterBoxactivestate === "ACTIVE"
                ? "hide_table_componets"
                : "show_table_componets"
            } `}
          >
            <Transfers
              dashboard={false}
              activeFilterBox={filterBoxactivestate}
            />
          </div>
        </div>
      </div>
    </Body>
  );
};
