const endpoints = {
  SIGN_UP: "/users/registration",
  BUSINESS_SIGN_UP: "/business/registration",
  SESSION: "/admin/session",
  SIGN_OUT: "/sign-out",
  VALUES: "/values",
  VALUE: "/value",
  USER: "/user/$_1",
  USER_LOGS: "/user/$_1/logs",
  PASSWORD_REQUEST: "/user/password/request",
  PASSWORD_RESET: "/user/password/reset",
  CREATE_TRANSFER: "/user/$_1/transfer",
  TRANSFER: "/transfer/$_1",
  PREORDER: "/fx/preorder/$_1",
  TRANSFERS: "/transfers",
  PREORDERS: "/fx/preorders",
  QUOTE_SERVICE: "/exchange/$_1/$_2",
  GET_QUOTE: "/quote/$_1",
  GET_SERVICES: "/transfer/services",
  GET_SERVICE: "/transfer/service/$_1",
  INITIATE_PAYMENT: "/user/$_1/payment",
  USERS: "/users",
  RECIPIENTS: "/user/$_1/recipients",
  PROMOS: "/promos",
  PROMO: "/promo",
  PROMOS_USAGE: "/users/bonus",
  UPDATE_TRANSFER: "/user/transfer/$_1",
  PIVOT_PAYMENT: "/pivot/payment",

  CREATE_RECIPIENT: "/user/$_1/recipient",
  GET_TRANSFER: "/user/$_1/transfer/$_2",
  GET_TRANSFERS: "/user/$_1/transfers",

  FX_PREORDER: "/fx/preorder",
  EXCHANGE: "/exchange/$_1/$_2",
  EXCHANGES: "/exchanges",

  NOTIFICATIONS: "/user/$_1/notifications",
  DOCUMENTATION: "/user/documentation",
  VERIFICATION: "/verification/documents",
  VERIFICATION_DATA: "/verification/data/$_1",
  VERIFICATION_DATA_PDF_EXPORT: "/verification/data/export/pdf",
  USER_VERIFICATION: "/user/$_1/verification",
  DELETE_USER: "/user/$_1",
  UPDATE_VERIFICATION: "/update-verification",
  UPDATE_USER_VERIFICATION: "/update-verification",

  USER_ACTIVATION: "/user/activation",
  REFERRALS_CONFIGS: "/values/11",
  COMPETITOR_RATES: "/competitors/rates",
  POST_COMPETITOR_RATES: "/competitors/rates/$_1/$_2",
  GET_COMPETITOR_RATES_FORMATTED: "/competitors/rates/$_1/$_2/$_3",
  EXCHANGE_RATE_SPREADS: "/rate/spreads",
  USER_STATUS: "/user/status",
  USER_FILE_UPLOAD: "/user/$_1/file/upload",
  USER_FILE_UPLOAD_ON_TRANSFER: "/user/$_1/file/upload/$_2",

  HEALTH_CHECK: "/health-check",
  ADMIN_UPDATE: "/update/admin",
  USER_ACTIVITY: "/user/activity",
  AML: "/aml",

  FIXED_RATE: "/admin/rates/fixed",
};

export default endpoints;
