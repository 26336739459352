import React, { useState } from "react";

import dayjs from "dayjs";

import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Tag,
  Tooltip,
} from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import { createUpdatePromo, getPromos } from "src/redux/actions/actions";
import { userAppValues } from "src/util/constants";
import { getAllUniqueCurrencies } from "src/util/utility";

const { RangePicker } = DatePicker;

interface NewPromoModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  data?: any;
}

const { Option } = Select;

let initialValues: any = {
  name: "",
  code: "",
  type: "",
  startDate: "",
  endDate: "",
  //baseCurrency: "Cameroon",
  //targetCurrency: "",
  rate: "",
  minimumSpend: "",
  maximumSpend: "",
  discountAmount: "",
  percentage: "",
  usageLimit: "",
  currenciesValid: "ALL",
};

function NewPromoModal({ open, setOpen, data }: NewPromoModalProps) {
  const [form] = Form.useForm();
  const [users, setUsers] = useState<any[]>([]);
  const [date, setDate] = useState<any>();
  const [userInputValue, setUserInputValue] = useState("");

  const [submittable, setSubmittable] = React.useState(false);
  const [PromoType, setPromoType] = useState("FIXED_RATE");
  const [currrenciesValid, setCurrenciesValid] = useState("ALL");

  const { PayoutCountries: targetCurrency, PayinCountries } = userAppValues();

  const isUpdate = !!data?.name && !!data?.code;

  const baseCurrency = getAllUniqueCurrencies(PayinCountries);

  React.useEffect(() => {
    resetAndInitializeForm();
    if (data) {
      setUsers(Object?.values(data?.users));
    }
  }, [data]);

  const values = Form.useWatch([], form);
  const dateFormat = "YYYY-MM-DD";

  const resetAndInitializeForm = () => {
    form.resetFields();
    setPromoType("");
    delete data?.settings;
    data && setPromoType(data?.type);
    form.setFieldsValue(data);
    form.setFieldValue("users", "");
    data && form.setFieldValue("date", dateRangeInitialValues);
  };

  const dateRangeInitialValues = [
    dayjs(data?.endDate, dateFormat),
    dayjs(data?.startDate, dateFormat),
  ];

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  function handleCancel() {
    setOpen(false);
    setUsers([]);
    form.resetFields();
    setUsers([]);
  }

  function handleOk() {
    form.validateFields().then((values) => {
      const combinedFormData = { ...initialValues, ...values };

      if (combinedFormData) {
        // Remove unnecessary properties
        // (Assuming 'date' and 'email' are not needed in combinedFormData)
        delete combinedFormData.date;
        delete combinedFormData.email;

        // Assign start and end dates based on 'date' and 'data'
        if (data) {
          // If 'date' is provided, use its values; otherwise, use 'data' values
          combinedFormData.startDate = date?.[0] || data?.startDate;
          combinedFormData.endDate = date?.[1] || data?.endDate;
        } else {
          combinedFormData.startDate = date?.[0];
          combinedFormData.endDate = date?.[1];
        }

        // Set 'currenciesValid' and convert 'users' array to an object
        combinedFormData.currenciesValid = currrenciesValid;
        combinedFormData.users = users.reduce((a, v) => ({ ...a, [v]: v }), {});

        // Call 'createUpdatePromo' based on 'data' presence
        if (data) {
          // Update existing promo
          createUpdatePromo(combinedFormData, true, () =>
            onFormSubmitSuccess()
          );
        } else {
          // Create new promo
          createUpdatePromo(combinedFormData, false, () =>
            onFormSubmitSuccess()
          );
        }
      }
    });
  }

  const onFormSubmitSuccess = () => {
    // Reset form fields, clear users array, and close the modal
    form.resetFields();
    setUsers([]);
    setOpen(false);
    getPromos("promos");
  };

  const handleCloseTag = (removedTag: string) => {
    const newusers = users.filter((tag) => tag !== removedTag);
    //console.log(newusers);
    setUsers(newusers);
  };

  const forMap = (tag: string) => {
    const tagElem = (
      <Tag
        color="green"
        closable
        onClose={(e) => {
          e.preventDefault();
          handleCloseTag(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block", marginTop: "8px" }}>
        {tagElem}
      </span>
    );
  };

  const onDatePickerChange: RangePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    setDate(dateString);
  };

  const tagChild = users.map(forMap);

  const handleUserConfirm = () => {
    if (userInputValue && users.indexOf(userInputValue) === -1) {
      setUsers([...users, userInputValue]);
    }
    setUserInputValue("");
    form.setFieldsValue({ users: "" });
  };

  const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(e.target.value);
  };

  const onChangeRadio = (e: RadioChangeEvent) => {
    setCurrenciesValid(e.target.value);
  };

  const handleOnSelcetChange = (value: string) => {
    setPromoType(value);
    form.resetFields([
      "baseCurrency",
      "targetCurrency",
      "rate",
      "discountAmount",
      "percentage",
    ]);
  };

  return (
    <Modal
      open={open}
      title={data ? "Update promo" : "Create promo"}
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      //confirmLoading={true}
      footer={[
        <Button key="cancel" size="large" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="ok"
          type="primary"
          size="large"
          onClick={handleOk}
          // disabled={!submittable}
        >
          {data ? "Update" : "Create"}
        </Button>,
      ]}
      width={550}
      // style={{ top: "15%" }}
    >
      <Divider style={{ marginTop: "12px" }} />
      <div style={{ marginTop: "32px" }}>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          //initialValues={formValues}
        >
          <div>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input promo name!",
                },
              ]}
            >
              <Input
                size="large"
                style={{ width: "100%" }}
                placeholder="Name"
                disabled={isUpdate}
              />
            </Form.Item>

            <Form.Item
              name="code"
              label="Code"
              rules={[
                {
                  required: true,
                  message: "Please input promo code!",
                },
              ]}
            >
              <Input
                size="large"
                style={{ width: "100%" }}
                placeholder="Enter Code"
                disabled={isUpdate}
              />
            </Form.Item>

            <Form.Item
              name="type"
              label="Promo type"
              rules={[
                {
                  required: true,
                  message: "Please select promo type",
                },
              ]}
            >
              <Select
                placeholder="Type"
                value={PromoType}
                size="large"
                allowClear
                onChange={handleOnSelcetChange}
                disabled={isUpdate}
              >
                <Option value="FIXED_RATE">Fixed Rate</Option>
                <Option value="FIXED_AMOUNT">Fixed amount</Option>
                <Option value="PERCENTAGE">Percentage</Option>
                <Option value="FREE_OPERATOR_FEE">Free operator fee</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="users"
              label="Users"
              extra="Tap “Enter Key” to add more users"
              initialValue={users}
            >
              <Input
                size="large"
                style={{ width: "100%" }}
                placeholder="Add users"
                value={userInputValue}
                onPressEnter={handleUserConfirm}
                onChange={handleUserInputChange}
              />
            </Form.Item>

            <div style={{ display: "flex", marginTop: -15 }}>
              <div>{tagChild}</div>
            </div>
          </div>

          <div>
            <div style={{ marginTop: 24 }}>
              <div id="dynamic type">
                {PromoType === "FIXED_RATE" &&
                  fixedRateFormItems(baseCurrency, targetCurrency)}
                {PromoType === "FIXED_AMOUNT" && FixedAmountFormItems}
                {PromoType === "PERCENTAGE" && PercentageFormItems}
              </div>
              <div
                id="max and minimum spend"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Form.Item
                  style={{ width: "50%" }}
                  name="minimumSpend"
                  label="Min Spend"
                  rules={[
                    {
                      required: true,
                      message: "Please input min spend!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Minimum spend"
                    type="number"
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: "50%" }}
                  name="maximumSpend"
                  label="Max spend"
                  rules={[
                    {
                      required: true,
                      message: "Please input max spend!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Maximum spend"
                  />
                </Form.Item>
              </div>
              <Form.Item name="usageLimit" label="Usage Limit">
                <Input
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Usage Limit"
                />
              </Form.Item>

              <div id="currency valid for">
                <p style={{ fontWeight: 400 }}>Currency valid for</p>
                <Form.Item name="currenciesValid">
                  <Radio.Group defaultValue="ALL" onChange={onChangeRadio}>
                    <Space direction="horizontal">
                      <Radio value="ALL">All </Radio>
                      <Radio value="GBP">GBP</Radio>
                      <Radio value="CAD">CAD</Radio>
                      <Radio value="EUR">EUR</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div
                id="date range"
                style={{ marginTop: "24px", marginBottom: "24px" }}
              >
                <Form.Item
                  label="Date Range"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Please select start and end date!",
                    },
                  ]}
                >
                  <RangePicker
                    format={dateFormat}
                    size="large"
                    style={{ width: "100%" }}
                    onChange={onDatePickerChange}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <Divider style={{ marginTop: "32px" }} />
    </Modal>
  );
}

export default NewPromoModal;

const PercentageFormItems = (
  <div>
    <Form.Item
      name="percentage"
      label="Percentage"
      rules={[
        {
          required: true,
          message: "Please input percentage!",
        },
      ]}
    >
      <Input size="large" style={{ width: "100%" }} placeholder="Percentage" />
    </Form.Item>
  </div>
);

const FixedAmountFormItems = (
  <div>
    <Form.Item
      name="discountAmount"
      label="Discount Amount"
      rules={[
        {
          required: true,
          message: "Please input discount amount!",
        },
      ]}
    >
      <Input
        size="large"
        style={{ width: "100%" }}
        placeholder="Discount amount"
      />
    </Form.Item>
  </div>
);

const fixedRateFormItems = (baseCurrency: any[], targetCurrency: any[]) => (
  <Space
    wrap
    id="fixed rate"
    style={{
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
    }}
  >
    <Form.Item
      name="baseCurrency"
      label="Base Currency"
      rules={[
        {
          required: true,
          message: "Please input base currency!",
        },
      ]}
    >
      <Select style={{ width: 125 }} size="large" placeholder="Base currency">
        {baseCurrency?.map((item, index) => (
          <Option value={item.currency} key={index + item.name}>
            <Tooltip title={`(${item.name})`}>{item.currency}</Tooltip>
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item
      name="targetCurrency"
      label="Target currency"
      rules={[
        {
          required: true,
          message: "Please input traget currency!",
        },
      ]}
    >
      <Select style={{ width: 125 }} size="large" placeholder="Target currency">
        {targetCurrency?.map((item, index) => (
          <Option value={item.currency} key={index + item.name}>
            <Tooltip title={`(${item.name})`}>{item.currency}</Tooltip>
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item
      name="rate"
      label="Rate"
      rules={[
        {
          required: true,
          message: "Please input rate!",
        },
      ]}
    >
      <Input size="large" style={{ width: "100%" }} placeholder="Rate" />
    </Form.Item>
  </Space>
);
