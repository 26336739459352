/* eslint-disable jsx-a11y/anchor-is-valid */
import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import { IoIosMore } from "react-icons/io";

import { Badge, Button, Dropdown, Space, Table, Tooltip, theme } from "antd";
import type { ColumnsType } from "antd/es/table";

import {
  convertTimestampToRegularDate,
  replaceUnderscores,
} from "src/util/util";
import NewPromoModal from "../../new-promo-modal/NewPromoModal";
import DownloadData from "../../parts/DownloadData";

const { useToken } = theme;

interface TransactionTableProps {
  tableBody?: any[];
  tableDataType: string;
  promoCodeUsagecolumns: ColumnsType<any>;
  referralsUsagecolumns: ColumnsType<any>;
  vouchersUsagecolumns: ColumnsType<any>;
}

interface DataType {
  key: React.Key;
  name: string;
  code: string;
  type: string;
  value: string;
  date: string;
  status: string;
  action: string;
}

const getColumnKeys = (tableDataType: string): string[] => {
  const columnKeysMap: Record<string, string[]> = {
    promoCodes: [
      "promoCode",
      "customerId",
      "minSpend",
      "usageCount",
      "value",
      "lastDateUsed",
      "expiryDate",
      "status",
    ],
    vouchers: [
      "customerId",
      "totalPoints",
      "dateActivated",
      "dateUsed",
      "expiryDate",
      "status",
    ],
    referrals: [
      "refferalCode",
      "customerId",
      "progress",
      "referralBonus",
      "activationDate",
      "expiryDate",
      "status",
    ],
    promos: ["name", "code", "type", "value", "date", "status"],
  };

  return columnKeysMap[tableDataType];
};

export default function PromosTable({
  tableBody,
  tableDataType,
  promoCodeUsagecolumns,
  referralsUsagecolumns,
  vouchersUsagecolumns,
}: TransactionTableProps) {
  const [openDownload, setOpenDownload] = React.useState(false);

  // csv - column and row data
  const [header, setHeader] = React.useState<any>([]);
  const [csvData, setCSVData] = React.useState<any[]>([]);

  // row selection and relod indicator state
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [updatePromo, setUpdatePromo] = React.useState(false);
  const [promoData, setPromoData] = React.useState<any>();

  // customized action dropdown style
  const { token } = useToken();

  const dropdownStyle = {
    container: {
      backgroundColor: token.colorBgElevated,
      borderRadius: token.borderRadiusLG,
      boxShadow: token.boxShadowSecondary,
      padding: "18px",
      width: "auto",
    },
  };

  // set reloding to false after 1s
  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  // handle on row selected change
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataType[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // custom filed to be printed on csv or pdf
    const columKeys: string[] | undefined = getColumnKeys(tableDataType);

    splitSlectedData(columKeys, selectedRows);
  };

  // on row selected
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // handles numbwe of selected rowa
  const hasSelected = selectedRowKeys.length > 0;

  // column data @ant-design table
  const getTableColumns = (tableDataType?: string): ColumnsType<any> => {
    const columns: ColumnsType<DataType> = [
      {
        title: "NAME",
        dataIndex: "name",
        width: 250,
        ellipsis: {
          showTitle: false,
        },
        render: (name) => (
          <Tooltip placement="topLeft" title={name}>
            {name}
          </Tooltip>
        ),
      },
      {
        title: "CODE",
        dataIndex: "code",
      },
      {
        title: "Type",
        dataIndex: "type",
        render: (type) => (
          <div style={{ textTransform: "capitalize" }}>
            {replaceUnderscores(type)?.toLowerCase()}
          </div>
        ),
      },
      {
        title: "VALUE",
        dataIndex: "value",
      },
      {
        title: "END DATE",
        dataIndex: "date",
      },
      {
        title: "STATUS",
        dataIndex: "status",
        render: (status) => (
          <Badge
            status={
              (status === "active" && "success") ||
              (status === "expired" && "error") ||
              "default"
            }
            text={
              (status === "active" && "Active") ||
              (status === "expired" && "Expired") ||
              status
            }
          />
        ),
      },
      {
        title: "ACTION",
        dataIndex: "action",
        fixed: "right",
        width: 100,
        render: (action) => (
          <Space size="middle" onClick={(e) => e.stopPropagation()}>
            <Dropdown
              dropdownRender={() => (
                <div style={dropdownStyle.container}>
                  <a
                    onClick={() => {
                      setUpdatePromo(true);
                    }}
                  >
                    <Space>
                      <UploadOutlined />
                      Update promo
                    </Space>
                  </a>
                </div>
              )}
            >
              <a
                onMouseEnter={() =>
                  setPromoData({ ...action, ...action?.settings })
                }
              >
                <IoIosMore size={24} color="#CCCCCC" />
              </a>
            </Dropdown>
          </Space>
        ),
      },
    ];

    if (tableDataType === "promoCodes") {
      return promoCodeUsagecolumns;
    } else if (tableDataType === "vouchers") {
      return vouchersUsagecolumns;
    } else if (tableDataType === "referrals") {
      return referralsUsagecolumns;
    } else {
      return columns;
    }
  };

  const columns = getTableColumns(tableDataType);

  // separete the head and datasource into header and csv for easy download
  const splitSlectedData = (keys: string[], data: any[]) => {
    const newHeader: any = keys.map((key: string) => {
      // @ts-ignore
      const column = columns.find((col) => col.dataIndex === key);
      return column ? column.title : `Column with key '${key}' not found.`;
    });

    const newCSVData: any[] = data.map((item: any) => {
      const newRow: any = {};
      keys.forEach((key) => {
        // @ts-ignore
        const column = columns.find((col) => col.dataIndex === key);
        if (column) {
          // @ts-ignore
          newRow[column.title] = item[key];
        }
      });
      return newRow;
    });

    setHeader(newHeader);
    setCSVData(newCSVData);
  };

  return (
    <div>
      <NewPromoModal
        open={updatePromo}
        setOpen={setUpdatePromo}
        data={promoData}
      />
      {openDownload && (
        <DownloadData
          header={header}
          data={csvData}
          open={openDownload}
          setOpen={setOpenDownload}
          type="Promo"
        />
      )}
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableBody}
        pagination={{
          position: ["bottomCenter"],
          pageSizeOptions: [10, 20, 50, 100, 200],
        }}
        footer={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tooltip title="Click to reload selected user(s)">
                <Button
                  onClick={start}
                  disabled={!hasSelected}
                  loading={loading}
                >
                  Reload
                </Button>
              </Tooltip>
              <span style={{ marginLeft: 8 }}>
                {hasSelected
                  ? ` ${selectedRowKeys.length} user(s) selected`
                  : ""}
              </span>
            </div>
            <Tooltip title="Click to dwonload selected user(s)">
              <Button
                disabled={!hasSelected}
                onClick={() => setOpenDownload(true)}
              >
                Download
              </Button>
            </Tooltip>
          </div>
        )}
        scroll={{ x: 1300 }}
      />
    </div>
  );
}
