import { Divider, Modal, Tabs, TabsProps } from "antd";
import React from "react";
import styled from "styled-components";

interface VerificationsErrorsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  verifications: any;
}

export default function VerificationsErrors({
  open,
  setOpen,
  verifications,
}: VerificationsErrorsProps) {
  const onChange = (key: string) => {
    // console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Primary Errors",
      children: (
        <Body
          dangerouslySetInnerHTML={{
            __html: formatString(verifications?.submittedData?.primaryErrors),
          }}
        />
      ),
    },
    {
      key: "2",
      label: "Secondary Errors",
      children: (
        <Body
          dangerouslySetInnerHTML={{
            __html: formatString(verifications?.submittedData?.secondaryErrors),
          }}
        />
      ),
    },
  ];

  function formatString(input: string) {
    input = input?.replace(/\*\*\* (.*?) \*\*\*/g, "<p>*** $1 ***</p>");
    if (input === undefined) {
      return "<span>No errors found!</span>";
    } else {
      return input;
    }
  }

  return (
    <Modal
      open={open}
      title="Verification Errors"
      okText="Update"
      onCancel={() => setOpen(false)}
      width={600}
      style={{ top: "15%" }}
      footer={null}
    >
      <Divider style={{ marginTop: "12px" }} />

      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </Modal>
  );
}

const Body = styled.div`
  padding: 20px 16px;
  border: 1px solid #d9d9d9d9;
  font-size: 16px;
  max-height: 600px;
  overflow: auto;
  span {
    font-size: 16px;
  }
`;
