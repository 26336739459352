import { IAction } from ".";
import {
  SUBMITTING,
  TOAST,
  REDIRECT,
  APP_VALUES,
  LOADING,
  PERIOD,
  DATE,
  FILTER,
  CONFIRM,
  HORIZONTAL_LOADING,
  NOTIFICATIONS,
  UPLOAD_DOC,
  MAINTENANCE,
} from "../actionTypes";

const initialSubmittingState = "";
const initialToastState = {};
const initialRedirectState = {
  to: "/sign-in",
};
const initialAppValues = {
  values: {},
  countries: {},
  services: {},
};

const initialFilterState = {
  period: "all-time",
  date: "",
  search: "",
};
const initialLoadingState = false;
const initialHorizontalLoaderState = false;

const initialConfirmDialogState = {
  message: "Are you sure?",
  isPositive: false,
  callback: () => {},
  open: false,
};

const initialUploadDocState = {
  open: false,
  user: {},
  transfer: {},
  callback: () => {},
};

const initialMaintenanceData = {
  webMaintenance: 0,
  redirectToMobile: 0,
  redirectToMobileMessage: "We are working on the web for now",
  mobileMaintenance: 0,
  redirectToWeb: 0,
  redirectToWebMessage: "We are currently working on the mobile for now",
  maintenanceCarouselMessage: "Please bear with us while we set things back up",
  termsAndCondition: "<p>Some terms and conditions information here...</p>",
};

export const submitting = (
  state: any = initialSubmittingState,
  { type, payload }: IAction
) => {
  switch (type) {
    case SUBMITTING: {
      return payload;
    }
    default:
      return state;
  }
};

export const toast = (
  state: any = initialToastState,
  { type, payload }: IAction
) => {
  switch (type) {
    case TOAST: {
      return { ...payload };
    }
    default:
      return state;
  }
};

export const redirect = (
  state: any = initialRedirectState,
  { type, payload }: IAction
) => {
  switch (type) {
    case REDIRECT: {
      return payload;
    }
    default:
      return state;
  }
};

export const appValues = (
  state: any = initialAppValues,
  { type, payload }: IAction
) => {
  switch (type) {
    case APP_VALUES: {
      return payload;
    }
    default:
      return state;
  }
};

export const loading = (
  state: any = initialLoadingState,
  { type, payload }: IAction
) => {
  switch (type) {
    case LOADING: {
      return payload;
    }
    default:
      return state;
  }
};

export const horizontalLoader = (
  state: any = initialHorizontalLoaderState,
  { type, payload }: IAction
) => {
  switch (type) {
    case HORIZONTAL_LOADING: {
      return payload;
    }
    default:
      return state;
  }
};

export const filters = (
  state: any = initialFilterState,
  { type, payload }: IAction
) => {
  switch (type) {
    case PERIOD: {
      return {
        ...payload,
      };
    }
    case DATE: {
      return {
        ...payload,
      };
    }
    case FILTER: {
      return {
        ...payload,
      };
    }
    default:
      return state;
  }
};

export const confirmDialog = (
  state: any = initialConfirmDialogState,
  { type, payload }: IAction
) => {
  switch (type) {
    case CONFIRM: {
      return payload;
    }
    default:
      return state;
  }
};

export const uploadDoc = (
  state: any = initialUploadDocState,
  { type, payload }: IAction
) => {
  switch (type) {
    case UPLOAD_DOC: {
      return payload;
    }
    default:
      return state;
  }
};

export const notifications = (state: any = [], { type, payload }: IAction) => {
  switch (type) {
    case NOTIFICATIONS: {
      return payload;
    }
    default:
      return state;
  }
};

export const maintenance = (
  state: any = initialMaintenanceData,
  { type, payload }: IAction
) => {
  switch (type) {
    case MAINTENANCE: {
      return payload;
    }
    default:
      return state;
  }
};
