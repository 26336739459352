import React from 'react'
import { useSelector } from 'react-redux'
import { IData } from './IReceipt'
import { convertDateString } from '../../../../util/util';
import constants from '../../../../util/constants';

const Brief = ({data, recipient}: {data?: any, recipient?: any}) => {
   
    return (
        <div className="receiptBriefComponent">
            <div className="transaction">
                <p><b>{data?.status === constants.STATUS_PENDING ? 'Quote' : 'Transaction'}</b></p>
                <p> <span>{data?.status === constants.STATUS_PENDING ? 'Quote' : 'Transaction'}: </span>  <b>{data?.meta?.transactionId || ""}</b></p>
                {/* <p> <span>Funded</span> <b>-</b></p>
                <p> <span>Paid out</span> <b>-</b></p> */}
                {/* <p> <span>Membership number: </span> <b> {user.meta.customerId} </b></p> */}
                <p> <span>{data?.status === constants.STATUS_PENDING ? 'Quote' : 'Transaction'} Date: </span> <b> { convertDateString( data?.dateCreated ) } </b></p>
            </div>
            <div className="sender">
                <p className="bold">Sender Details</p>
                <p>{`${data?.sender?.firstname || ""} ${data?.sender?.lastname || ""} `}</p>
                <p className=""> {data?.sender?.location} </p>
            </div>
        </div>
    )
}

export default Brief
