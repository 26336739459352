import { Table, Tooltip } from "antd";
import React from "react";

import { DownloadOutlined } from "@ant-design/icons";

interface TransactionTableProps {
  tableBody?: any[];
}

export default function DocumentsTable({ tableBody }: TransactionTableProps) {
  const [dataSrc, setDataSrc] = React.useState<any>();

  const columns = [
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      ellipsis: {
        showTitle: false,
      },
      render: (file: string) => (
        <Tooltip placement="topLeft" title={file}>
          {file}
        </Tooltip>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      ellipsis: {
        showTitle: false,
      },
      render: (type: string) => (
        <Tooltip placement="topLeft" title={type}>
          {type}
        </Tooltip>
      ),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (action: any) => (
        <a href={`${action.meta.path}`}>
          <DownloadOutlined />
        </a>
      ),
    },
  ];

  React.useEffect(() => {
    const dataSource: any = [];
    tableBody?.map((item, index) => {
      const data = {
        key: index,
        file: item?.name,
        type: item.meta.uploadType,
        size: `${item.size / 1000} kb`,
        action: item,
      };
      dataSource.push(data);
    });
    setDataSrc(dataSource);
  }, [tableBody]);

  return <Table columns={columns} dataSource={dataSrc} scroll={{ x: 600 }}/>;
}
