import React from 'react'
import constants from '../../../../util/constants'
import { replaceUnderscores } from '../../../../util/util'


export const ReceiptOverview = ({data, recipient}: {data?: any, recipient?: any}) => {
    return (

        <div className="receiptMoreComponent">
            <p className="title">Preorder Details</p>
            <div className="body">
              <div className="section">
                <div className="unit">
                    <p> Total Amount Paid </p>
                    <p>{`${data?.amount || ""} ${data?.originCurrency || ""}`}</p>
                </div>
                <div className="unit">
                    <p>Payout Amount</p>
                    <p className="uppercase">{`${data?.data.toReceive || ""} ${data?.currency || ""}`}</p>
                </div>
              </div>
              <div className="section">
                <div className="unit">
                  <p>Payout Operator charges</p>
                  <p> -- </p>
                </div>
                <div className="unit">
                  <p>Exchange rate</p>
                  <p>{ data?.data?.exchangeRate }</p>
                </div>
                <div className="unit">
                  <p>Payout Method</p>
                  <p className="capitalize">{replaceUnderscores(data?.data?.method || "")}</p>
                </div>
              </div>
            </div>
        </div>
    )
}

export const ReceiptDetails = ({data, recipient}: {data?: any, recipient?: any}) => {
  return (
      <div className="receiptMoreComponent">
          <p className="title">Sender Details</p>
          <div className="body">
          <div className="section">
            <div className="unit">
                <p>Recipient Name</p>
                <p>{`${data?.sender?.firstname  || ""} ${data?.sender?.lastname  || ""}`}</p>
            </div>
            {data?.transferMethod === constants.MOBILE_MONEY && <div className="unit">
                <p>Tel No.</p>
                <p>{data?.sender?.mobile  || ""}</p>
            </div>}
            {data?.transferMethod === constants.BANK_TRANSFER && <div className="unit">
                <p>Bank Account details</p>
                <p>{data?.data?.bankName  || ""}</p>
                <p>{data?.data?.accountNumber  || ""}</p>
            </div>}
          </div>
          <div className="section">
            <div className="unit">
              <p>Reference</p>
              <p>{data?.sender?.reason  || ""}</p>
            </div>
            <div className="unit">
              <p>Email</p>
              <p>{data?.sender?.email  || ""}</p>
            </div>
            <div className="unit">
              <p>Address</p>
              <p>{data?.sender?.state  || "-"}</p>
            </div>
          </div>
        </div>
      </div>
  )
}