export const SIGN_UP = "SIGN_UP";
export const SIGN_IN = "SIGN_IN";
export const SUBMITTING = "SUBMITTING";
export const TOAST = "TOAST";
export const REDIRECT = "REDIRECT";
export const AUTH = "AUTH";
export const APP_VALUES = "APP_VALUES";
export const RECIPIENTS = "RECIPIENTS";
export const RECIPIENT = "RECIPIENT";
export const NEW_RECIPIENT = "NEW_RECIPIENT";
export const LOADING = "LOADING";
export const HORIZONTAL_LOADING = "HORIZONTAL_LOADING";
export const TRANSFER = "TRANSFER";
export const TRANSFER_DASHBOARD = "TRANSFER_DASHBOARD";

export const USERS = "USERS";
export const VERIFICATION_TYPE_STATUES = "VERIFICATION_TYPE_STATUES";
export const PERIOD = "PERIOD";
export const DATE = "DATE";
export const FILTER = "FILTER";
export const CONFIRM = "CONFIRM";
export const UPLOAD_DOC = "UPLOAD_DOC";
export const PREORDERS = "PREORDERS";
export const EXCHANGE = "EXCHANGE";
export const EXCHANGES = "EXCHANGES";
export const EXCHANGE_SPREADS = "EXCHANGES_SPREADS";
export const PROMOS = "PROMOS";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const COMPETITOR_RATES = "COMPETITOR_RATES";
export const EXCHANGE_RATES = "EXCHANGE_RATES";
export const MAINTENANCE = "MAINTENANCE";
