import { Button, Divider, Empty, Space, Tag } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { queryClient } from "src/App";
import ListVerificationData from "src/components/modules/list-verification-data/ListVerificationData";
import UpdateUserVerificationModal from "src/components/modules/parts/UpdateUserVerificationModal";
import { UploadButton } from "src/components/modules/parts/UploadButton";
import VerificationsErrors from "src/components/modules/parts/VerificationsErrors";
import DocumentsTable from "src/components/modules/table/custom-table-children/DocumetsTables";
import { CustomError, CustomLoader } from "src/util/Reuseables";
import endpoints from "src/util/endpoints";
import { paths } from "src/util/paths";
import { parseEndpointParameters, parsePathParameters } from "src/util/util";
import { checkSubmittedData } from "src/util/utility";
import styled, { css } from "styled-components";
import {
    useGetUserUploadsData,
    useGetVerificationsData,
} from "../helper/useRQuserData";

const UserDocumentsAndVerifications = ({
  userId,
  userData,
}: {
  userId: string;
  userData: any;
}) => {
  return (
    <UserDocumentsAndVerificationsWrapper>
      <UserDocuments userData={userData} userId={userId} />
      <Divider type="vertical" style={{ height: 300 }} />
      <UserVerificationHistory userData={userData} userId={userId} />
    </UserDocumentsAndVerificationsWrapper>
  );
};

export default UserDocumentsAndVerifications;

const UserVerificationHistory = ({
  userId,
  userData,
}: {
  userId: string;
  userData: any;
}) => {
  const [verificationModalData, setVerificationModalData] = useState({});
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [updateVerificationModalOpen, setUpdateVerificationModalOpen] =
    useState(false);
  const [verificationErrorModal, setVerificationErrorModal] = useState(false);
  const [verificationError, setVerificationError] = useState<any>();

  const verficationQueryKey = parseEndpointParameters(
    endpoints.USER_VERIFICATION,
    userId
  );

  const {
    data: verificationsData,
    error,
    isError,
    isLoading,
  } = useGetVerificationsData(verficationQueryKey);

  const err: any = error;

  if (isLoading) {
    return (
      <UserVerificationHistoryWrapper>
        <CustomLoader prompt="Loading user verification data..." />
      </UserVerificationHistoryWrapper>
    );
  }

  if (isError) {
    return (
      <UserVerificationHistoryWrapper>
        <CustomError message={err?.message} />
      </UserVerificationHistoryWrapper>
    );
  }

  return (
    <UserVerificationHistoryWrapper>
      <>
        <ListVerificationData
          open={verificationModalOpen}
          setOpen={setVerificationModalOpen}
          data={verificationModalData}
        />

        <UpdateUserVerificationModal
          open={updateVerificationModalOpen}
          setOpen={setUpdateVerificationModalOpen}
          user={userData}
          callback={() => {
            queryClient.invalidateQueries(verficationQueryKey);
          }}
        />

        <VerificationsErrors
          open={verificationErrorModal}
          setOpen={setVerificationErrorModal}
          verifications={verificationError}
        />
      </>
      <div className="_header">
        <p>Verification history ({verificationsData?.length})</p>
        <Button onClick={() => setUpdateVerificationModalOpen(true)}>
          Update
        </Button>
      </div>

      <div className="_content">
        {verificationsData?.length === 0 ? (
          <Empty />
        ) : (
          verificationsData?.map((verification: any, index: number) => (
            <VerificationChild
              data={verification}
              setVerificationModalOpen={setVerificationModalOpen}
              setVerificationModalData={setVerificationModalData}
              setVerificationErrorModal={setVerificationErrorModal}
              setVerificationError={setVerificationError}
              key={"verification_" + index}
            />
          ))
        )}
      </div>
    </UserVerificationHistoryWrapper>
  );
};

const VerificationChild = ({
  data,
  setVerificationModalOpen,
  setVerificationModalData,
  setVerificationErrorModal,
  setVerificationError,
}: {
  data: any;
  setVerificationModalOpen: Function;
  setVerificationModalData: Function;
  setVerificationErrorModal: Function;
  setVerificationError: Function;
}) => {
  const history = useHistory();
  return (
    <VerificationChildWrapper>
      <div className="_verification_header">
        <div className="_verification_type">
          <p>{(data?.type).toLowerCase()} verification</p>
          <VerificationStatusWrapper $type={data?.status}>
            <div className="_dot" />
            {(data?.status).toLowerCase()}
          </VerificationStatusWrapper>
        </div>
        <span>{data?.date}</span>
      </div>
      <div className="_cta">
        <div className="_btns">
          {checkSubmittedData(data?.submittedData) === "OLD_SUBMITTED_DATA" && (
            <Space>
              <Button
                size="large"
                onClick={() => {
                  setVerificationModalOpen(true);
                  setVerificationModalData(JSON.parse(data?.submittedData));
                }}
              >
                View submitted data
              </Button>
              <Button
                size="large"
                onClick={() => {
                  history.push(
                    parsePathParameters(paths.USER_VERIFICATION_SOURCES, [
                      data.id,
                    ])
                  );
                }}
              >
                View verification data source
              </Button>
            </Space>
          )}
          {checkSubmittedData(data?.submittedData) === "NO_DATA_STORED" && (
            <>
              <Tag>No data stored</Tag>
            </>
          )}
          {checkSubmittedData(data?.submittedData) === "EXPORT_URL" && (
            <Button
              type="link"
              href={data?.submittedData?.exportUrl}
              rel="noreferrer"
              target="_blank"
              download
            >
              Open verification pdf
            </Button>
          )}
          {(data?.submittedData?.primaryErrors !== undefined ||
            data?.submittedData?.secondaryErrors !== undefined) && (
            <Button
              type="primary"
              danger
              onClick={() => {
                setVerificationErrorModal(true);
                setVerificationError(data);
              }}
            >
              View Errors
            </Button>
          )}
        </div>
      </div>
    </VerificationChildWrapper>
  );
};

const UserDocuments = ({
  userId,
  userData,
}: {
  userId: string;
  userData: any;
}) => {
  const queryKey = parseEndpointParameters(endpoints.USER_FILE_UPLOAD, userId);
  const {
    data: userUploadsData,
    isLoading,
    isError,
    error,
  } = useGetUserUploadsData(queryKey);
  const err: any = error;

  if (isLoading) {
    return (
      <UserDocumentsWrapper>
        <CustomLoader prompt="Loading user uploads data..." />
      </UserDocumentsWrapper>
    );
  }

  if (isError) {
    return (
      <UserDocumentsWrapper>
        <CustomError message={err?.message} />
      </UserDocumentsWrapper>
    );
  }

  return (
    <UserDocumentsWrapper>
      <div className="_header">
        <p>Document</p>

        <UploadButton
          user={userData}
          callback={() => {
            queryClient.invalidateQueries(queryKey);
          }}
        />

        <span className="_span">Select a file to upload</span>
      </div>
      {userUploadsData && <DocumentsTable tableBody={userUploadsData} />}
    </UserDocumentsWrapper>
  );
};

const contentMargin = css`
  margin: 0px 4%;
`;

const UserDocumentsAndVerificationsWrapper = styled.section`
  ${contentMargin};
  border-radius: 8px;
  border: 1px solid rgba(144, 152, 178, 0.3);
  background: #fff;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 32px;
  min-height: 315px;
  max-height: 500px;

  display: flex;
  justify-content: space-between;
`;

const UserDocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 48%;
  overflow-y: auto;
  padding-right: 14px;

  ._header {
    display: flex;
    flex-direction: column;
    gap: 0px;
    p {
      color: #424242;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    ._span {
      color: #707070;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
    }
  }
`;

const UserVerificationHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 48%;

  ._header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    p {
      color: #424242;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  ._content {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    overflow-y: auto;
    padding-right: 14px;
  }
`;

const VerificationChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  ._verification_header {
    display: flex;
    flex-direction: column;
    gap: 18px;
    ._verification_type {
      display: flex;
      gap: 24px;
      align-items: center;

      p {
        color: #424242;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        text-transform: capitalize;
      }
    }
    span {
      color: #424242;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  ._cta {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const VerificationStatusWrapper = styled.div<{ $type: string }>`
  display: inline-flex;
  padding: 8px 12px;
  height: 26px;
  align-items: center;
  gap: 8px;
  border-radius: 200px;
  box-sizing: border-box;
  flex-shrink: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  margin: 0;
  text-transform: capitalize;

  ._dot {
    width: 4px;
    height: 4px;
    border-radius: 50px;
  }

  ${(props) => {
    switch (props.$type) {
      case "PENDING":
        return css`
          background: #f5f7f7;
          border: 1px solid #a3a3a3;
          color: #a3a3a3;
          ._dot {
            background: #a3a3a3;
          }
        `;
      case "FAILED":
      case "INVALID":
        return css`
          background: #fae5e8;
          border: 1px solid #cf0921;
          color: #cf0921;
          ._dot {
            background: #cf0921;
          }
        `;
      case "VALID":
      case "ACTIVE":
        return css`
          background: #f5f7f7;
          border: 1px solid #007b5d;
          color: #007b5d;
          ._dot {
            background: #007b5d;
          }
        `;
      default:
        return css`
          background: #f5f7f7;
          border: 1px solid #a3a3a3;
          color: #a3a3a3;
          ._dot {
            background: #a3a3a3;
          }
        `;
    }
  }}
`;
