import React from 'react'
import { getImage } from '../../../util/utility';
import styled from 'styled-components';

const Img = styled.img`
    width: 40px;
    height: 40px;
    display: inline!important;
    position: relative;
    float: right;
    vertical-align: middle;
`

const ButtonLoader = () => {
    return (
        <Img src={getImage('ripple-loader.svg', 'svgs')} alt=""/>
    )
}

export default ButtonLoader;
