import styled from 'styled-components';

export const Form = styled.div`
  background-color: #F5F7F7 ;
  padding: 100px 20px 100px 0;
  display: flex;
  justify-content: center;
  /* width: 100%; */
  .content{
    width: 90%;
    background-color: #FFF;
    box-shadow: 0px 6px 20px #00000029;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    border-radius: 12px;
    .header{
        h1{
            text-align: center;
            font: normal normal normal 40px/52px Montserrat;
            color: #424242;
            width: 691px;
        }
        p{
            font: normal normal normal 20px/32px Montserrat;
            letter-spacing: 0px;
            color: #424242;
            text-align: center;
        }
        .switch{
            display: flex;
            width: 465px;
            p{
              color: #424242;
              padding: 5px 60px;
              font-size: 14px;
              font-weight: 400;
              font-family: Montserrat;
              border-bottom: 2px solid #007B5D33;
              cursor: pointer;
              flex: 1;
            }
            p.active{
              font-weight: 500;
              border: 2px solid #007B5D33;
              border-bottom: none;
              box-shadow: -1px -7px 10px 0px rgba(211,211,211,0.55);
              -webkit-box-shadow: -1px -7px 10px 0px rgba(211,211,211,0.55);
              -moz-box-shadow: -1px -7px 10px 0px rgba(211,211,211,0.55);
              border-radius: 5px 5px 0px 0px;
              flex: 1;
            }
        }
    }
    form{
        display: flex;
        flex-wrap: wrap;
        margin: 50px 150px 80px;
        h3{
            width: 100%;
            margin: 0 20px 40px;
            font-size: 20px;
            line-height: 32px;
            font-weight: 500;
            color: #424242;
        }
        .half,
        .third,
        .full,
        .halfWithSpacer,
        .thirdWithSpacer{
            display: flex;
            flex-direction: column;
            margin: 0 20px 40px;
            label{
              font-size: 20px;
              line-height: 32px;
              font-weight: 500;
              color: #424242;
              height: 24px;
              margin: 0 0 16px;
            }
            input, select{
              border: 1px solid #B8B8B8;
              border-radius: 5px;
              height: 64px;
              font-size: 20px;
              line-height: 32px;
              color: #424242;
              padding: 0 0 0 20px;
              background: transparent;
              outline: none;
            }
        }
        .half{
            width: calc(50% - 40px);
        }
        .third{
            width: calc(33% - 40px);
        }
        .full{
            width: 100%;
        }
        .halfWithSpacer{
            width: calc(50% - 50px);
            margin: 0 20px 0 5px;
            &:first-of-type{
              margin: 0 5px 0 20px;
            }
            .box1, .box2{
              border: thin solid #B8B8B8;
              border-radius: 10px;
              display: flex;
              align-items: center;
              img{
                height: 32px;
                width: 32px;
                border-radius: 16px;
                margin: 0 15px 0 0;
              }
              select{
                width: 100%;
                border: none;
              }
              .semi-circle-container {
                width: 34px;
                overflow: hidden;
                position: relative;
                .semi-circle{
                  height: 38px;
                  /* width: 26px; */
                  border: thin solid #B8B8B8;
                  background-color: white;
                }
              }

            }
            .box1{
              padding: 0 0 0 20px;
              .semi-circle-container {
                left: 2px;
                .semi-circle{
                  border-radius: 50px/100px;
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  border-right: none;
                  margin: 0 0 0 20px;
                }
              }

            }
            .box2{
              padding: 0 20px 0 0;
              .semi-circle-container {
                right: 1px;
                .semi-circle{
                  border-radius: 50px/100px;
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                  border-left: none;
                  margin: 0 20px 0 0;
                }
              }

            }
        }
        .thirdWithSpacer{
            width: calc(33% - 48px);
        }
        .circleSpacer{
          height: 50px;
          width: 50px;
          border-radius: 100px;
          border: thin solid #B8B8B8;
          position: relative;
          top: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            height: 13px;
            width: 10.6px;
          }
        }
        .spacer{
          height: 15px;
          width: 15px;
          border-radius: 100px;
          align-self: center;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            height: 13px;
            width: 10.6px;
          }
        }
        .divider{
          height: 30px;
          width: 1px;
          background-color: #B8B8B8;
          margin: -8px 0 0 50%;
          &.last-divider {
            margin-top: -40px;
          }
          &.second-divider {
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
        .more{
            display: flex;
            height: 32px;
            align-items: center;
            padding-left: 12px;
            margin-left: 48%;
            &.last-more {
              margin-top: -10px;
            }
            .circle{
              height: 32px;
              width: 32px;
              border-radius: 16px;
              background-color: #F5F7F7;
              display: flex;
              justify-content: center;
              align-items: center;

              img{
                width: 13px;
                height: 12px;
              }
            }
            p.figure{
                margin-left: 12px;
                font-size: 14px;
                font-weight: 500;
                color: #007B5D;
            }
            p.text{
                margin-left: 16px;
                font-size: 14px;
                font-weight: 400;
                color: #424242;
            }

          }        
        .middle{
          width: 100%;
          /* height: 154px; */
          padding: 0 20px;
        }

        .info{
          width: calc(50% - 40px);
          display: flex;
          height: fit-content;
          margin: 45px 0 0 0;
          img{
              height: 24px;
              width: 24px;
              margin: 0 10px 0 0;
          }
          p{
            font: normal normal normal 14px/21px Montserrat;
            font-size: 14px;
            line-height: 21px;
            color: #424242;
            padding: 0;
            margin: 0;
          }
        }
        .checkbox{
            width: 100%;
            display: flex;
            align-items: center;
            margin: 0 0 0 20px;
            input[type=checkbox]{
                height: 25px;
                width: 25px;
                margin: 0 24px 0 0;
            }
            p{
                font-size: 20px;
                line-height: 32px;
                font-weight: 500;
                color: #424242;
            }
        }
        input[type=submit]{
          background-color: #007B5D;
          border: none;
          border-radius: 5px;
          padding: 14px 25px;
          font-size: 20px;
          line-height: 32px;
          font-weight: 500;
          color: #FFFFFF;
          cursor: pointer;
          margin: 60px 0 0 20px;
        }
        .fullRight{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding: 0 20px 0 0;
        }
        .clicks{
            margin: 100px 0 0 20px;
            display: flex;
            width: 100%;
            padding: 0 20px 0 0;
            justify-content: space-between;
            .first{
              display: flex;
              align-items: center;
              img{
                width: 20px;
                height: 20px;
                margin: 0 16px 0 0;
              }
              p{
                color: #424242;
                font-size: 14px;
                font-family: Montserrat;
              }
            }
            .second{
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                   color: #007B5D;
                   text-decoration: underline;
                   cursor: pointer;
                }
                input[type=submit]{
                    margin: 0 0 0 20px;
                }
            }
        }
        .form-error {
          input {
            background: #FAE5E8 0% 0% no-repeat padding-box;
            border: 1px solid #CF0921;
          }
          .form-error-message {
            display: flex;
            color: #CF0921;
            margin-top: 10px;
            img {
              width: 15px;
              flex: 0;
              margin-right: 10px;
            }
            .message {
              flex: 1
            }
          }
        }
    }
  }
  @media(max-width: 999px){
    padding: 50px 0;
      .content{
        width: calc(100% - 36px);
          form{
            margin: 50px 20px;
            .half, .third, .full{
                margin: 0 20px 20px;
                label{
                    font-size: 16px;
                    line-height: 24px;
                    height: 24px;
                    margin: 0 0 8px;
                }
                input, select{
                    height: 42px;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 0 0 0 15px;
                }
            }
            h3{
                width: 100%;
                padding: 0 10px;
                margin: 0 20px 20px;
                font-size: 13px;
                line-height: 20px;
            }
            .checkbox{
                width: 100%;
                display: flex;
                align-items: center;
                margin: 0 20px;
                input[type=checkbox]{
                    height: 20px;
                    width: 20px;
                    margin: 0 12px 0 0;
                }
                p{
                    font-size: 12px;
                    line-height: 16px;
                }
            }
          }
      }
  }
  @media(max-width: 499px){
      .content{
          form{
            .half, .third, .full{
                width: 100%;
                margin: 0 0 20px;
            }
            h3{
                margin: 0 0 20px;
            }
            .info{
                width: 100%;
                display: flex;
                height: fit-content;
                margin: 24px 0 0 0;
                img{
                    height: 18px;
                    width: 18px;
                    margin: 0 8px 0 0;
                }
                p{
                    font-size: 10px;
                    line-height: 16px;
                }
            }
            .checkbox{
                margin: 0;
            }
            input[type=submit]{
                padding: 10px;
                font-size: 14px;
                line-height: 24px;
                font-weight: 500;
                margin: 30px 0 0;
            }
          }
      }
  }
`;

export const FormSubmitted = styled.div`
  background-color: #F5F7F7 ;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  .box{
    width: 896px;
    height: 531px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 32px #00000029;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width: 66px;
        height: 66px;
    }
    p{
        width: 494px;
        text-align: center;
        font: normal normal normal 20px/32px Montserrat;
        font-size: 20px;
        font-weight: 32px;
        color: #424242;
    }
    .buttons{
      display: flex;
      a, button{
        width: 286px;
        height: 68px;
        border-radius: 5px;
        margin: 0 15px;
        cursor: pointer;
      }
      a{
        display: block;
        font-size: 20px;
        line-height: 21px;
        font-family: Monserrat;
        font-weight: 500;
        color: #007B5D;
        border: 2px solid #007B5D;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      button{
        width: 286px;
        height: 68px;
        background-color: #007B5D;
        border-radius: 5px;
        border: none;
        font-size: 20px;
        font-family: Monserrat;
        font-weight: 500;
        line-height: 32px;
        color: #FFFFFF;
      }
    }
  }
  @media(max-width: 999px){
    padding: 40px 0;
    .box{
        width: 318px;
        height: 335px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width: 30px;
            height: 30px;
        }
        p{
            width: 240px;
            font-size: 14px;
            line-height: 21px;
        }
        .buttons{
        flex-direction: column-reverse;
        a, button{
            width: 161px;
            height: 40px;
            border-radius: 5px;
            margin: 5px 0;
        }
        a{
            font-size: 14px;
            line-height: 24px;
        }
        button{
            font-size: 14px;
            line-height: 24px;
        }
        }
    }
  }
`;
