import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";

import { useSelector } from "react-redux";
import "./App.css";
import AppLoader from "./components/modules/app-loader/AppLoader";
import { ConfirmDialog } from "./components/modules/confirm-dialog/ConfirmDialog";
import ToastFactory from "./components/modules/toast-factory/ToastFactory";
import { AppWrapper } from "./components/pages/app/AppWrapper";
import { appValuesAction, checkAuth } from "./redux/actions/actions";
import { paths } from "./util/paths";
import { IRoute, Routing } from "./util/routes";
import CustomUploadDocModal from "./components/modules/upload-document/CustomUploadDocModal";

export const queryClient = new QueryClient();

function App() {
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const showAppLoader = useSelector((state: any) => state.loading);
  const confirmDialog = useSelector((state: any) => state.confirmDialog);
  const uploadState = useSelector((state: any) => state.uploadDoc);

  useEffect(() => {
    checkAuth();
    appValuesAction();
  }, [isAuthenticated]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#007B5D",
          colorPrimaryBg: "#E7EEEC",
          colorError: "#CF0921",
          colorSuccess: "#007B5D",
        },
        components: {
          Button: {
            colorLink: "#007B5D",
          },
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <React.Fragment>
          {confirmDialog.open ? <ConfirmDialog /> : <></>}
          {uploadState.open ? <CustomUploadDocModal /> : <></>}
          <ToastFactory />
          <AppLoader show={showAppLoader} />
          <Switch>
            {Routing.map((route: IRoute, i: number) =>
              route.protected ? (
                isAuthenticated === undefined ? (
                  <AppLoader show={true} />
                ) : !isAuthenticated ? (
                  <Redirect key={i + paths.SIGN_IN} to={paths.SIGN_IN} />
                ) : (
                  <Route
                    path={route.path}
                    component={AppWrapper}
                    key={route.path + i}
                  />
                )
              ) : (
                <Route
                  path={route.path}
                  render={() => <route.component {...route.props} />}
                  key={route.path + i}
                  exact={route.exact === false ? false : true}
                />
              )
            )}
          </Switch>
        </React.Fragment>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
