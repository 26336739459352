import React from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import config from '../../../../env';
import endpoints from '../../../../util/endpoints';
import { toastAction } from '../../../../redux/actions/actions';
import {Form} from './formStyle'
import alert from './icons/alert.png'
import _env from '../../../../env';
import emailjs from 'emailjs-com';
import { useSelector } from 'react-redux';
import { getQueryParam } from '../../../../util/util';

type IProps = {preOrderData: any, setPreOrderData: any, user: any, setStep: React.Dispatch<React.SetStateAction<"step1" | "step2" | "step3" | "completed">>}

export const Step3 = ({setStep, preOrderData, setPreOrderData, user}: IProps) => {

    const agentsData = useSelector((state: any) => state.appValues.agents)
    const agents = agentsData.data;

    const formik = useFormik({
        initialValues: {
            method: "",
            bank_name: "",
            account_name: "",
            account_number: "",
            bank_code: "",
            branch_code: "",
            rib_key: "",
            pickup_country: "",
            pickup_agent: "",
        },
        onSubmit: async (formData) => {
            const dataToSubmit = {
              ...preOrderData,
              ...formData,
              meta: {
                  byAdmin: true
              },
              userId: Number(getQueryParam('user'))
            }
            try { 
                const {data} = await axios.post(config.API_HOST + endpoints.FX_PREORDER, dataToSubmit)
                if(data.status === '200'){
                    emailjs.send(_env.EMAIL_SERVICE_ID, _env.PREORDER_EMAIL_TEMPLATE_ID, dataToSubmit, _env.EMAIL_USER_ID)
                   return setStep('completed')
                }
    
                return toastAction({
                    show: true,
                    type: 'error',
                    message: 'Some field were missing'
                  })
            } catch (error) {
    
                toastAction({
                  show: true,
                  type: 'error',
                  message: 'Server error, try again.'
                })
            }
        },
    });

    // const usersPayoutMethod = watch("method", "bankTransfer");
    const usersPayoutMethod = formik.values.method || "bankTransfer";

    return (
        <Form>
            <div className="content">
                <div className="header">
                    <p>Step 3/3</p>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <h3>How would <strong>{`${user?.profile?.firstName || preOrderData.firstname} ${user?.profile?.lastName || preOrderData.lastname}`}</strong> like to receive their funds?</h3>
                    <div className="half">
                        <label>Payout method</label>
                        <select id="method" name="method" required value={formik.values.method} onChange={formik.handleChange}>
                            <option value="bankTransfer">Bank Transfer</option>
                            <option value="prepaidCard">Pre-Paid Card</option>
                            <option value="cashPickup">Cash Pick Up</option>
                        </select>
                    </div>
                    {
                        usersPayoutMethod === "bankTransfer" ? (
                            <>
                            <div className="info">
                                <img src={alert} alt="alert" />
                                <p>Fill this section if you want your exchange completed through bank transfer (Cameroon banks only). Please note that domestic bank transfers take between 3 to 5 working days to clear.</p>
                            </div>
                            <div className="half">
                                <label>Bank name</label>
                                <select id="bank_name" name="bank_name" required value={formik.values.bank_name} onChange={formik.handleChange}>
                                    <option value="">Select a bank</option>
                                    <option value="Afriland First Bank">Afriland First Bank</option>
                                    <option value="Atlantic Bank Cameroon">Atlantic Bank Cameroon</option>
                                    <option value=" Banque International du Cameroun pour l'Epargne et le Crédit (BICEC)"> Banque International du Cameroun pour l'Epargne et le Crédit (BICEC)</option>
                                    <option value="Banque Camerounaise des Petites et Moyennes Entreprises (BC-PME SA)">Banque Camerounaise des Petites et Moyennes Entreprises (BC-PME SA)</option>
                                    <option value="BGFI Bank Cameroon">BGFI Bank Cameroon</option>
                                    <option value="SCB Cameroun">SCB Cameroun</option>
                                    <option value="Crédit Communautaire d'Afrique Bank (CCA Bank)">Crédit Communautaire d'Afrique Bank (CCA Bank)</option>
                                    <option value="Citibank">Citibank</option>
                                    <option value="Commercial Bank of Cameroon">Commercial Bank of Cameroon</option>
                                    <option value="Ecobank Cameroon">Ecobank Cameroon</option>
                                    <option value="National Financial Credit Bank (NFCB)">National Financial Credit Bank (NFCB)</option>
                                    <option value="Société Commerciale de Banque du Cameroun">Société Commerciale de Banque du Cameroun</option>
                                    <option value="Wineex Bank Cameroon (WBC)">Wineex Bank Cameroon (WBC)</option>
                                    <option value="Societe Generale des Banques au Cameroun (SGBC)">Societe Generale des Banques au Cameroun (SGBC)</option>
                                    <option value="Standard Chartered Bank">Standard Chartered Bank</option>
                                    <option value="Union Bank of Cameroon (UBC)">Union Bank of Cameroon (UBC)</option>
                                    <option value="United Bank for Africa (UBA)">United Bank for Africa (UBA)</option>
                                </select>
                            </div>
                            <div className="half">
                                <label>Account name</label>
                                <input
                                    id="account_name"
                                    name="account_name"
                                    type="text"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.account_name}
                                />
                            </div>
                            <div className="full">
                                <label>Account number</label>
                                <input
                                    id="account_number"
                                    name="account_number"
                                    type="text"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.account_number}
                                />
                            </div>
                            <div className="third">
                                <label>Bank code</label>
                                <input
                                    id="bank_code"
                                    name="bank_code"
                                    type="text"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.bank_code}
                                />
                            </div>
                            <div className="third">
                                <label>Branch code</label>
                                <input
                                    id="branch_code"
                                    name="branch_code"
                                    type="text"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.branch_code}
                                />
                            </div>
                            <div className="third">
                                <label>RIB key</label>
                                <input
                                    id="rib_key"
                                    name="rib_key"
                                    type="text"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.rib_key}
                                />
                            </div>
                            </>
                        ) : usersPayoutMethod === "cashPickup" ? (
                            <>
                            <div className="info">
                                <img src={alert} alt="alert" />
                                <p>Fill this section if you are ordering to pick-up currency from our agency.</p>
                            </div>
                            <div className="half">
                                <label>Country</label>
                                <select id="pickup_country" name="pickup_country" required value={formik.values.pickup_country} onChange={formik.handleChange}>
                                    <option value="">Select country</option>
                                    <option value="cameroon">Cameroon</option>
                                    <option value="uk">United Kingdom</option>
                                </select>
                            </div>
                            <div className="half">
                                <label>Agency</label>
                                <select id="pickup_agent" name="pickup_agent" required value={formik.values.pickup_agent} onChange={formik.handleChange}>
                                    <option value="">Select agency</option>
                                    {
                                        Object.keys(agents).map(key => (
                                            <option value={key}> {agents[key]} </option>
                                        ))
                                    }
                                </select>
                            </div>
                            </>
                        ) : null
                    }
                    <div className="checkbox">
                        <input type="checkbox" name="" id=""  required={true} />
                        <p>Please confirm the information provided above are correct</p>
                    </div>
                    <div className="clicks">
                      <div className="first"></div>
                      <div className="second">
                        <p className="back"  onClick={() => setStep('step2')}>Back</p>
                        <input type="submit" value="Complete Exchange"/>
                      </div>
                    </div>
                </form>
            </div>
        </Form>
    )
}

export default Step3
