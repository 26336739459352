/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { getAllExchangeRates } from '../../../../redux/actions/actions';
import {Form} from './formStyle'

type IProps = {preOrderData: any, setPreOrderData: any, setStep: React.Dispatch<React.SetStateAction<"step1" | "step2" | "step3" | "completed">>}

export const Step2 = ({setStep, preOrderData, setPreOrderData}: IProps) => {
    const [service, setService] = useState<'buy' | 'sell'>('buy')

    const exchanges = useSelector((state: any) => state.exchange.exchanges)
    const [exchange, setExchange]: any = useState({rate: "---"})
    const [sbRate, setSbRate] = useState(exchange?.rate)
    const [whatChanged, setWhatChanged] = useState("")

    const formik = useFormik({
        initialValues: {
            from: "",
            currency: "",
            amount: 0,
            comission: 0,
            youGet: 0,
        },
        onSubmit: (formData) =>{
            setPreOrderData({
                ...preOrderData,
                ...formData,
                service,
                exchangeRate: getSBRateText() + " " + getRatesPerText(),
              })
              if (!preOrderData.email) return setStep('step1');
              setStep('step3')
        },
    });

    const fromCurrency = formik.values?.from || "usd";
    const toCurrency = formik.values?.currency || "usd";

    const amountField = formik.values?.amount;
    const comissionField = formik.values?.comission;
    const youGetField = formik.values?.youGet;

    useEffect(() => {
        handleCommissionChange()
    }, [comissionField, exchange, service]);

    useEffect(() => {
        handleCommissionChange("amountField")
    }, [ amountField]);

    useEffect(() => {
        handleCommissionChange("youGetField")
    }, [ youGetField]);

    useEffect(() => {
        getAllExchangeRates()
    }, [])

    useEffect(() => {
        handleCurrencyChange(fromCurrency, toCurrency)
    }, [fromCurrency, toCurrency, exchanges])

    const handleCommissionChange = (field = "commission") => {
        // const comissionField = parseFloat(getValues("comission"));
        const comissionField_ = parseFloat(comissionField + '');
        let sBRate = (parseFloat(exchange.rate) + (parseFloat(exchange.rate) * (comissionField_ / 100))).toFixed(4)
        if (exchange.rate < 1) {
            sBRate =  ((1 /parseFloat(exchange.rate)) + (((1 / parseFloat(exchange.rate))) * (comissionField_ / 100))).toFixed(4)
        }
        setSbRate(sBRate);
        setWhatChanged("");

        if ((field === "amountField" && whatChanged !== "youGetField" ) || field === "commission"){
            // let youGet = ((parseFloat(amountField ) / parseFloat(exchange?.rate)) *  (parseFloat(sBRate) / parseFloat(exchange?.rate))).toFixed(2)
            let youGet = (parseFloat(amountField + '')  /  (parseFloat(sBRate) )).toFixed(2)
            if (exchange.rate < 1) {
                youGet = (parseFloat(amountField + '')  *  (parseFloat(sBRate) )).toFixed(2)
            }
            youGet = `${parseFloat(youGet) || '0.0000'}`;


            // setValue("youGet", youGet)
            formik.setFieldValue("youGet", youGet)
            setWhatChanged("amountField")
        }

        else if ((field === "youGetField" && whatChanged !== "amountField")  || field === "commission"){
            // let amount = ((parseFloat(youGetField) * Math.pow(parseFloat(exchange?.rate), 2) ) / parseFloat(sBRate) ).toFixed(2)
            let amount = (parseFloat(youGetField + '') * parseFloat(sBRate) ).toFixed(2)
            if (exchange.rate < 1) {
                amount = (parseFloat(youGetField + '') / parseFloat(sBRate) ).toFixed(2)
            }
            amount = amount || '0.00'
            amount = `${parseFloat(amount) || '0.0000'}`;
            // setValue("amount", amount);
            formik.setFieldValue("amount", amount);
            setWhatChanged("youGetField")
        }
    }

    const handleCurrencyChange = (fromCurrency: string, toCurrency: string) => {
        let exchange = findInExchangeRates(toCurrency, fromCurrency);
        setExchange(exchange ?? {rate: "---"});
    }

    const findInExchangeRates = (base: string, target: string) => {
        return exchanges?.find((xchange: any) => xchange.base.toLowerCase() === base?.toLowerCase()  && xchange.target.toLowerCase() === target?.toLowerCase()) 
    }

    const getReferenceRateText = () => {
        return (exchange?.rate < 1) ? (1/exchange?.rate).toFixed(4) : exchange?.rate; 
    }

    const getSBRateText = () => {
        const rate = parseFloat(sbRate) ||  0;
        let ret = (rate < 1 ) ? (1/rate).toFixed(4) : rate;

        return ret == Infinity ? '0.00' : ret;
    }

    const getRatesPerText = () => {
        const currency = (exchange?.rate < 1) ? exchange.target : exchange?.base;
        return (currency ? 'per ' : '') + (currency ?? '');
    }
    
    return (
    <Form>
        <div className="content">
            <div className="header">
                <p>Step 2/3</p>
                <div className="switch">
                    <p className={service === 'buy' ? 'active' : ''} onClick={() => setService('buy')}>
                      Buy
                    </p>
                    <p className={service === 'sell' ? 'active' : ''} onClick={() => setService('sell')}>
                      Sell
                    </p>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="halfWithSpacer">
                    <label>Customer has</label>
                    <div className="box1">
                        <img src={`/assets/currency/${fromCurrency}.png`} alt="" />
                        <select id="from" name="from" required value={formik.values.from} onChange={formik.handleChange}>
                            <option value="xaf">XAF - Central African CFA Franc</option>
                            {/* <option value="usd">USD - US Dollar</option>
                            <option value="eur">EUR - Euro</option>
                            <option value="gbp">GBP - British Pound</option> */}
                        </select>
                        <div className="semi-circle-container">
                            <div className="semi-circle"></div>
                        </div>
                    </div>
                </div>
                <div className="circleSpacer">
                <img src="/assets/images/exchange.png" alt="" />
                </div>
                <div className="halfWithSpacer">
                    <label>Customer wants</label>
                    <div className="box2">
                        <div className="semi-circle-container">
                            <div className="semi-circle"></div>
                        </div>
                        <img src={`/assets/currency/${toCurrency}.png`} alt="" />
                        <select id="currency" name="currency" required value={formik.values.currency} onChange={formik.handleChange}>
                            <option value="usd">USD - US Dollar</option>
                            <option value="eur">EUR - Euro</option>
                            <option value="gbp">GBP - British Pound</option>
                            {/* <option value="xaf">XAF - Central African CFA Franc</option> */}
                        </select>
                        
                    </div>
                </div>
                <div className="middle">
                    <div className="divider"></div>
                    <div className="more">
                        <div className="circle">
                            <img src="/assets/images/activity.png" alt="" />
                        </div>
                        <p className="figure uppercase">{getReferenceRateText()} <small> { getRatesPerText() } </small></p>
                        <p className="text">Reference rate</p>
                    </div>
                    <div className="divider second-divider"></div>
                </div>
                <div className="thirdWithSpacer">
                    <label>You have</label>
                    <input
                        id="amount"
                        name="amount"
                        type="float"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.youGet}
                    />
                </div>
                <div className="spacer">
                <img src="/assets/images/exchange.png" alt="" />
                </div>
                <div className="thirdWithSpacer">
                    <label> { service === "buy" ? "Buy premium" : "Sell commision" }(%)</label>
                    <input
                        id="comission"
                        name="comission"
                        type="float"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.youGet}
                    />
                </div>
                <div className="spacer">
                <img src="/assets/images/exchange.png" alt="" />
                </div>
                <div className="thirdWithSpacer">
                    <label>You get</label>
                    <input
                        id="youGet"
                        name="youGet"
                        type="float"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.youGet}
                    />
                </div>
                <div className="divider last-divider"></div>
                <div className="more last-more">
                    <div className="circle">
                    <img src="/assets/images/bar-chart.png" alt="" />
                    </div>
                    <p className="figure uppercase">{getSBRateText()} <small> { getRatesPerText() } </small> </p>
                    <p className="text">SB remit rate</p>
                </div>
                <div className="clicks">
                    <div className="first">
                        <img src="/assets/images/refresh.png" alt="" />
                        <p>Rates are refreshed every 30 seconds</p>
                    </div>
                    <div className="second">
                    <p className="back" onClick={() => setStep('step1')}>Back</p>
                    <input type="submit" value="Exchange"/>
                    </div>
                </div>

            </form>
        </div>
    </Form>
    )
}

export default Step2
