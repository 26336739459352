import React from 'react'
import styled from 'styled-components'
import { getImage } from '../../../util/utility'

const Div = styled.div`
    display: grid;
    grid-template-columns: 1fr 0fr;
    grid-gap: 20px;
    float: right;
    overflow-x: scroll;
    max-width: 1200px;
    .explainer {
        display: inline-block;
        font: normal normal normal 16px/30px Montserrat;
        letter-spacing: 0px;
        color: #707070;
        white-space: nowrap;
    }
    .paginator {
        display: inline-grid;
        grid-template-columns: 0fr 1fr 0fr;
        width: fit-content;
        background: #F5F7F7 0% 0% no-repeat padding-box;
        border: 1px solid #D1D1D1;
        border-radius: 10px;
        white-space: nowrap;
        .left {
            border-radius: 10px 0px 0px 10px;
            border-right: 1px solid #D1D1D1;
        }
        .middle {
            .page {
                display: inline-block;
                margin: 0px 10px;
                padding: 5px;
                font: normal normal normal 16px/19px Montserrat;
                letter-spacing: 0px;
                color: #424242;
                cursor: pointer;
                &.active {
                    border-bottom: 3px solid green;
                }
            }
        }

        .right {
            border-radius: 0px 10px 10px 0px;
            border-left: 1px solid #D1D1D1;

        }

        .left, .right {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            padding: 0px 10px;
            cursor: pointer;
            img {
                width: 11px;
            }
        }
    }
`

interface IPaginationProps {
    paginationTrack: any,
    setNewPage: Function,
    page: number,
    loading?: boolean
}

const Pagination = (props: IPaginationProps) => {
    const {paginationTrack, setNewPage, page, loading} = props;
    const pagesList = Array.from({length: paginationTrack.noOfPages}, (_, i) => i + 1);
    
    return (
        <Div>
            <div className="explainer">
                <span className="text">
                    Showing results: &nbsp;
                </span>
                <span className="figures">
                    {paginationTrack.from}-{paginationTrack.to} of {paginationTrack.all}
                </span>
            </div>
            <div className="paginator">
                <div className="left" onClick={() => setNewPage(page - 1)}>
                    <img src={getImage('caret-left.svg', 'svgs')} alt="<" />
                </div>
                <div className="middle">
                    {
                        pagesList.map(apage => (
                            <div className={`page ${apage === page ? "active" : ""}`} onClick={() => setNewPage(apage)} >
                                {apage}
                            </div>
                        ))
                    }
                    {loading && <img width={30} height={20} style={{marginTop: 10, position: 'absolute'}} src={getImage('circles-menu-1.gif', 'svgs')} alt="" />}
                </div>
                <div className="right" onClick={() => setNewPage(page + 1)}>
                    <img src={getImage('caret-right.svg', 'svgs')} alt=">" />
                </div>
            </div>

        </Div>
    )
}

export default Pagination
