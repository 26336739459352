import constants from "src/util/constants";

export const transactionStatuses = [
  {
    status: constants.TRANSFER_STATUS_REJECTED,
  },
  {
    status: constants.TRANSFER_STATUS_EXPIRED,
  },
  {
    status: constants.TRANSFER_FLAG_FRAUD_ALERT,
  },
  {
    status: constants.TRANSFER_STATUS_PAYMENT_FRAUD,
  },
  {
    status: constants.TRANSFER_PAYMENT_CANCELLED,
  },
  {
    status: constants.STATUS_PENDING,
  },
  {
    status: constants.TRANSFER_STATUS_PENDING_VERIFICATION,
  },
  {
    status: constants.TRANSFER_STATUS_PENDING_DOCUMENTATION,
  },
  {
    status: constants.TRANSFER_STATUS_REFUNDED,
  },
  {
    status: constants.TRANSFER_PAYMENT_PROCESSING,
  },
  {
    status: constants.TRANSFER_STATUS_PAYMENT_DECLINED,
  },
  {
    status: constants.TRANSFER_STATUS_PAYMENT_COMPLETED,
  },
  {
    status: constants.TRANSFER_STATUS_COMPLETE,
  },
];
