import { Button, Divider, Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  UpdateUserVerifications,
  confirmUserPassword,
  getVerificationTypeAndStatus,
} from "src/redux/actions/actions";
import { replaceUnderscores } from "src/util/util";

const { Option } = Select;
const { TextArea } = Input;

interface UpdateUserVerificationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  user: any;
  callback?: Function;
}

export default function UpdateUserVerificationModal({
  open,
  setOpen,
  user,
  callback,
}: UpdateUserVerificationModalProps) {
  const [form] = Form.useForm();
  const verificationTypeAndStatus = useSelector(
    (state: any) => state.usersVerificationTypeAndStatus
  );
  const [verificationLevel, setVerificationsLevel] = useState(
    "Level 1 Verification"
  );
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getVerificationTypeAndStatus();
  }, []);

  useEffect(() => {
    const verificationType = form.getFieldValue("verificationType");
    updateVerificationstate(verificationType);

    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  const updateVerificationstate = (verificationType: string) => {
    switch (verificationType) {
      case "IDENTITY":
      case "DOCUMENT":
      case "WATCHLIST":
        setVerificationsLevel("Level 1 Verification");
        break;

      case "MULTI_BUREAU":
      case "PROOF_OF_ADDRESS":
        setVerificationsLevel("Level 2 Verification");
        break;
      default:
        break;
    }
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    form.validateFields().then((values) => {
      confirmUserPassword(values.password, updateVerification);
    });

    const updateVerification = () => {
      UpdateUserVerifications(
        user.id,
        values,
        () => {
          handleCancel();
          callback?.();
          setIsSubmitting(false);
        },
        () => {
          setIsSubmitting(false);
        }
      );
    };
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      open={open}
      title="Update User verification"
      okText="Update"
      onCancel={() => setOpen(false)}
      width={550}
      style={{ top: "15%" }}
      footer={[
        <Button key="cancel" size="large" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="ok"
          type="primary"
          size="large"
          onClick={handleSubmit}
          disabled={!submittable}
          loading={isSubmitting}
        >
          Update
        </Button>,
      ]}
    >
      <Divider style={{ marginTop: "12px" }} />
      <div style={{ padding: "8px 0" }}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="verificationType"
            label="Verification Type"
            initialValue="WATCHLIST"
            rules={[
              {
                required: true,
                message: "Please select verification type!",
              },
            ]}
            extra={<div style={{ marginTop: 3 }}>{verificationLevel}</div>}
          >
            <Select
              placeholder="Type"
              defaultValue="WATCHLIST"
              size="large"
              allowClear
            >
              {verificationTypeAndStatus.type?.map(
                (type: string, index: number) => (
                  <Option value={type} key={type + index}>
                    {replaceUnderscores(type)}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
          <Form.Item
            name="verificationStatus"
            label="Verification Status"
            initialValue="VALID"
            rules={[
              {
                required: true,
                message: "Please select verification status!",
              },
            ]}
          >
            <Select defaultValue="VALID" size="large" allowClear>
              {verificationTypeAndStatus.status?.map(
                (status: string, index: number) => (
                  <Option value={status} key={status + index}>
                    {status}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
          <Form.Item
            name="reason"
            label="Reason"
            initialValue=""
            required={false}
            extra={
              <div style={{ marginTop: 3 }}>
                Captures why the user verifications is being updated
              </div>
            }
          >
            <TextArea
              rows={4}
              size="large"
              style={{ width: "100%" }}
              placeholder="type your reason..."
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Invalid user password!",
              },
            ]}
            extra={
              <div style={{ marginTop: 3 }}>
                Enter your password to confirm this action!
              </div>
            }
          >
            <Input.Password size="large" placeholder="Admin Password" />
          </Form.Item>
        </Form>
      </div>
      <Divider style={{ marginTop: "12px" }} />
    </Modal>
  );
}
