import styled from "styled-components";

export default styled.div`
  height: auto;
  box-sizing: border-box;
  width: inherit;
  height: auto;
  transition: all 0.5s ease-in-out;
  .dashboard {
    /* padding: 4.5% 5.5%; */
    transition: all 0.3s ease-in-out;

    .dashboard_section_1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 500px) {
        margin-top: 40px;
      }
      .welcome {
        h1 {
          font-weight: 500;
          font-size: clamp(20px, 4vw, 30px);
          line-height: auto;
          color: #424242;
        }
        p {
          margin-top: -8px;
          font-weight: 400;
          font-size: clamp(14px, 4vw, 18px);
          line-height: auto;
          color: #808080;
        }
      }
    }
    .dashboard_section_2 {
      margin-top: 80px;
      //background-color: red;
      flex-shrink: 0;
      width: 100%;
      overflow: auto;

      @media (max-width: 768px) {
        margin-top: 40px;
      }
      .tabs {
        display: flex;
        justify-content: space-between;
        gap: 15px;

        .tab_container,
        .active_tab {
          background: #f5f7f7;
          border: 1px solid #dedee1;
          border-radius: 12px;
          //width: clamp(200px, 24vw, 100%); // clamp(304px, 10vw ,204px)
          min-width: 250px;
          max-width: auto;
          height: 170px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          .tab {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .tab_details {
              padding: 16px;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              gap: 15px;
              .tab_name {
                width: clamp(180px, 15vw, 250px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                span {
                  font-weight: 500;
                  font-size: 20px;
                  line-height: auto;

                  color: #424242;
                }
                span:last-child {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: auto;
                  color: #808080;
                }
              }
              .tab_icon {
                border-radius: 8px;
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              //display: flex;
            }
            .count {
              border-top: 1px solid #dedee1;
              padding: 5px 16px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: space-between;
              animation: "countAnimation 1s ease-in-out";

              @keyframes spin {
                0% {
                  transform: rotate(0deg);
                }
                100% {
                  transform: rotate(360deg);
                }
              }
            }
          }
          &:hover {
            border: 1px solid rgba(128, 128, 128, 0.2);
            transition: all 0.2s ease-in-out;
          }
        }
        .more {
          width: 100px;
          display: flex;
          flex-direction: column-reverse;
          background: #f5f7f7;
          border: 1px solid #dedee1;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          flex-shrink: 0;
          box-sizing: border-box;
          p {
            font-weight: 500;
            font-size: 18px;
            line-height: 0px;
            color: #007b5d;
          }
        }
        .active_tab {
          background: #ffffff;
          border: 3px solid #007b5d;

          &:hover {
            border: 3px solid #007b5d;
          }
        }
      }
      .dropdown_tab {
        position: absolute;
        right: 0;
        margin-right: 4%;
        margin-top: -4%;
        @media (max-width: 768px) {
          margin-top: -12%;
        }
        z-index: 6;
        border-radius: 4px;
        border: 1px solid rgba(73, 79, 94, 0.2);
        background: #fff;
        box-shadow: 0px 8px 16px 0px rgba(73, 79, 94, 0.12);
        padding: 24px 16px;
        box-sizing: border-box;
        width: 304px;

        .options {
          display: flex;
          flex-direction: column;
          gap: 15px;
          .option {
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;

            &:hover {
              span {
                color: #007b5d;
              }
            }
            .inactive_checkbox,
            .active_checkbox {
              box-sizing: border-box;
              width: 24px;
              height: 24px;
              border: 1.5px solid rgba(144, 152, 178, 0.4);
              border-radius: 200px;
              &:hover {
                background-color: rgba(19, 183, 107, 0.2);
              }
              &:active {
                border: 1.5px solid #007b5d;
                display: flex;
                align-items: center;
                justify-content: center;
                .indicator {
                  width: 14px;
                  height: 14px;
                  background: #007b5d;
                  border-radius: 200px;
                }
              }
            }
            .active_checkbox {
              border: 1.5px solid #007b5d;
              display: flex;
              align-items: center;
              justify-content: center;
              .indicator {
                width: 14px;
                height: 14px;
                background: #007b5d;
                border-radius: 200px;
              }
            }
            span {
              color: #494f5e;
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
    }
    .dashboard_user_table {
      margin-top: 40px;
      transition: all 0.5s ease-in-out;
      @media (max-width: 768px) {
        margin-top: 20px;
      }
      .show_table_componets,
      .hide_table_componets {
        display: flex;
      }
      .hide_table_componets {
        display: none;
      }
    }
  }
`;
