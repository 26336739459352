import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Menu,
  MenuProps,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react"; // Make sure to import React
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { signOutAction } from "src/redux/actions/actions";
import { resources } from "src/util/constants";
import { paths } from "src/util/paths";
import { getImage, goTo } from "src/util/utility";
import { getActivePage } from "./useAppwrapper";

export const Pages = [
  {
    label: "dashboard",
    path: paths.DASHBOARD,
    svg: (
      <>
        <img src={getImage("home.svg", "svgs")} alt="dashboard" />
      </>
    ),
  },
  {
    label: "transfers",
    path: paths.TRANSFERS,
    svg: (
      <>
        <img src={getImage("transfers.svg", "svgs")} alt="transfers" />
      </>
    ),
  },
  {
    label: "users",
    path: paths.USERS,
    svg: (
      <>
        <img src={getImage("users.svg", "svgs")} alt="users" />
      </>
    ),
  },
  {
    label: "fx",
    path: paths.FX,
    svg: (
      <>
        <img src={getImage("exchange.svg", "svgs")} alt="exchange" />
      </>
    ),
  },
  {
    label: "promos",
    path: paths.PROMOS,
    svg: (
      <>
        <img src={getImage("coupon.svg", "svgs")} alt="coupon" />
      </>
    ),
  },
  // {
  //   label: "Maint.",
  //   path: paths.MAINTENANCE,
  //   svg: (
  //     <>
  //       <img src={getImage("maintenance.svg", "svgs")} alt="maintenance" />
  //     </>
  //   ),
  // },
  {
    label: "settings",
    path: "/app/settings/controls",
    svg: (
      <>
        <img src={getImage("settings.svg", "svgs")} alt="settings" />
      </>
    ),
  },
];

export const MobileMenu: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state: any) => state.auth.user);
  const [open, setOpen] = useState(false);
  const page = getActivePage(location.pathname);
  const [currentPage, setCurrentPage] = useState(page);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const items: any = [
    {
      label: "Dashboard",
      key: "dashboard",
    },
    {
      label: "Transfers",
      key: "transfers",
    },
    {
      label: "Usres",
      key: "users",
    },
    {
      label: "Fx",
      key: "fx",
    },
    {
      label: "Promos",
      key: "promos",
    },
    {
      label: "Settings",
      key: "Settings",
    },
  ];

  const onMenuClick: MenuProps["onClick"] = (e) => {
    setCurrentPage(e.key);
    goTo(`/app/${e.key}`, history);
    //close drawer on menu item clicked
    setOpen(false);
  };

  return (
    <>
      <Button onClick={showDrawer}>
        <MenuOutlined />
      </Button>
      <Drawer
        title="Menu"
        placement="left"
        onClose={onClose}
        open={open}
        footer={
          <Button
            onClick={() => signOutAction()}
            size="large"
            type="text"
            icon={<LogoutOutlined />}
            danger
          >
            Logout
          </Button>
        }
      >
        <Space
          direction="vertical"
          // size={46}
          style={{ width: "100%" }}
          split={<Divider type="horizontal" />}
        >
          <Space direction="vertical" align="center" style={{ width: "100%" }}>
            <Avatar
              size={60}
              style={{ border: "1px solid #dedee1" }}
              src={`${resources.DICE_BEAR_USER}${user.meta.customerId}.svg`}
            />
            <Space direction="vertical" align="center">
              <Typography.Text
                style={{
                  fontSize: 24,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {user?.profile.firstName.toLowerCase()}{" "}
                {user?.profile.lastName.toLowerCase()}
              </Typography.Text>
              <Typography.Text>{user?.username}</Typography.Text>
            </Space>
          </Space>

          <Menu
            items={items}
            selectedKeys={[currentPage]}
            onClick={onMenuClick}
          />
        </Space>
      </Drawer>
    </>
  );
};
