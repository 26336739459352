import React, { useEffect } from "react";
import { MaintenanceStyle } from "./Maintenance.css";
// import SwitchButton from "src/components/parts/parts/SwitchButton";
import { Button, Divider, Input, Space, Switch, Tabs, TabsProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { HORIZONTAL_LOADING } from "src/redux/actionTypes";
import { CustomError, CustomLoader } from "src/util/Reuseables";
import TandCupdate from "./TandCupdate";
import {
  useGetMaintainaceData,
  useUpdateMaintainaceData,
} from "./useRQmaintenanceData";

const MaintenanceData = [
  {
    maintenance_mode_title: "Enter site maintenance mode for web app",
    maintenance_mode_info:
      "When site maintenance mode is turned on for the web, users are going to be redirected to the site maintenance page. You can come back here to turn it off when site maintenance is done.",
    redirect_user_title: "Redirect customers to mobile app",
    redirect_user_info:
      "Automatically redirect users to the mobile app when they visit ",
    type: "web",
  },
  {
    maintenance_mode_title: "Enter maintenance mode for mobile app",
    maintenance_mode_info:
      "When site maintenance mode is turned on for the mobile app, users are going to be redirected to the site maintenance page. You can come back here to turn it off when site maintenance is done.",
    redirect_user_title: "Redirect customers to website",
    redirect_user_info:
      "Automatically redirect users to the web app when they attempt to use the app.",
    type: "app",
  },
];

const MaintenanceUpdate = () => {
  const { isLoading, isError, error } = useGetMaintainaceData();
  const err: any = error;

  const maintenanceInfo = useSelector((state: any) => state.maintenance);
  const dispatch = useDispatch();

  const { mutate: updateMaintenanceData, isLoading: isUpdating } =
    useUpdateMaintainaceData();

  useEffect(() => {
    if (isUpdating) {
      dispatch({ type: HORIZONTAL_LOADING, payload: true });
    } else {
      dispatch({ type: HORIZONTAL_LOADING, payload: false });
    }
  }, [isUpdating]);

  const [siteRedirectCustomerMode, setSiteRedirectCustomerMode] =
    React.useState(false);
  const [appRedirectCustomerMode, setAppRedirectCustomerMode] =
    React.useState(false);

  const [redirectToMobileMessage, setRedirectToMobileMessage] = React.useState(
    maintenanceInfo?.redirectToMobileMessage
  );

  const [redirectToWebMessage, setRedirectToWebMessage] = React.useState(
    maintenanceInfo?.redirectToWebMessage
  );

  const [maintenanceCarouselMessage, setMaintenanceCarouselMessage] =
    React.useState(maintenanceInfo?.maintenanceCarouselMessage);

  const onSwithChange = (swithType: any) => (checked: boolean) => {
    const switchValue = checked === true ? "1" : "0";
    //console.log(`switch to ${switchValue} on ${swithType}`);

    switch (swithType) {
      case "maintenanceModeSwitch_0":
        //console.log("webMaintenance");
        updateMaintenanceData({
          ...maintenanceInfo,
          webMaintenance: switchValue,
        });
        break;

      case "maintenanceModeSwitch_1":
        updateMaintenanceData({
          ...maintenanceInfo,
          mobileMaintenance: switchValue,
        });
        break;

      case "redirectCustomerSwitch_0":
        updateMaintenanceData({
          ...maintenanceInfo,
          redirectToMobile: switchValue,
        });
        break;

      case "redirectCustomerSwitch_1":
        updateMaintenanceData({
          ...maintenanceInfo,
          redirectToWeb: switchValue,
        });
        break;

      default:
        break;
    }
  };

  function switchState(index: number, type: string) {
    //console.log(type, index);
    if (index === 0 && type === "web_maintenance") {
      return maintenanceInfo?.webMaintenance === "1" ? true : false;
    } else if (index === 1 && type === "app_maintenance") {
      return maintenanceInfo?.mobileMaintenance === "1" ? true : false;
    } else if (index === 0 && type === "web_redirect") {
      return maintenanceInfo?.redirectToMobile === "1" ? true : false;
    } else if (index === 1 && type === "app_redirect") {
      return maintenanceInfo?.redirectToWeb === "1" ? true : false;
    }
  }

  const onInputChange =
    (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (type === "web") {
        setRedirectToMobileMessage(e.target.value);
      } else if (type === "app") {
        setRedirectToWebMessage(e.target.value);
      } else if (type === "carouselMessage") {
        setMaintenanceCarouselMessage(e.target.value);
      }
    };

  function updateRedirectMsg(type: string) {
    // redirect customer to app, update redirect message to be displayed on web
    if (type === "app") {
      updateMaintenanceData({
        ...maintenanceInfo,
        redirectToWebMessage: redirectToWebMessage,
      });
    } else if (type === "web") {
      updateMaintenanceData({
        ...maintenanceInfo,
        redirectToMobileMessage: redirectToMobileMessage,
      });
    } else if (type === "carouselMessage") {
      updateMaintenanceData({
        ...maintenanceInfo,
        maintenanceCarouselMessage: maintenanceCarouselMessage,
      });
    }
  }

  if (isLoading) {
    return (
      <MaintenanceStyle>
        <CustomLoader prompt="Loading maintenance data..." />
      </MaintenanceStyle>
    );
  }

  if (isError) {
    return (
      <MaintenanceStyle>
        <CustomError message={err?.message} />
      </MaintenanceStyle>
    );
  }

  return (
    <MaintenanceStyle>
      <div className="_site_maintenace">
        {MaintenanceData.map((data, index) => (
          <div
            className="_site_maintenace_data"
            key={"_site_maintenace" + index}
          >
            <div className="_maintenace_mode">
              <div className="_info">
                <p>{data.maintenance_mode_title}</p>
                <span>{data.maintenance_mode_info}</span>
              </div>
              <div className="_switch">
                <Switch
                  loading={isUpdating}
                  checked={switchState(index, data.type + "_maintenance")}
                  size="default"
                  onChange={(checked) =>
                    onSwithChange("maintenanceModeSwitch_" + index)(checked)
                  }
                />
              </div>
            </div>
            <div className="_redirect_customer">
              <div className="_info">
                <p>{data.redirect_user_title}</p>
                <span>
                  {data.redirect_user_info}
                  {index === 0 && (
                    <a href="https://www.sbremit.com/">www.sbremit.com</a>
                  )}
                </span>

                <div className="_customise_redirect_msg">
                  <p>Customize redirect message</p>
                  <Space direction="vertical" size="middle">
                    <Input
                      size="large"
                      className="input"
                      placeholder="Type a message"
                      value={
                        data.type === "app"
                          ? redirectToWebMessage
                          : redirectToMobileMessage
                      }
                      onChange={(e) => onInputChange(data.type)(e)}
                    />
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => updateRedirectMsg(data.type)}
                      disabled={
                        (data.type === "web" &&
                          redirectToMobileMessage ===
                            maintenanceInfo?.redirectToMobileMessage) ||
                        (data.type === "app" &&
                          redirectToWebMessage ===
                            maintenanceInfo.redirectToWebMessage)
                      }
                    >
                      Save
                    </Button>
                  </Space>
                </div>
              </div>
              <div
                className="_switch"
                onClick={() => {
                  index === 0 &&
                    setSiteRedirectCustomerMode(!siteRedirectCustomerMode);
                  index === 1 &&
                    setAppRedirectCustomerMode(!appRedirectCustomerMode);
                }}
              >
                <Switch
                  checked={switchState(index, data.type + "_redirect")}
                  size="default"
                  loading={isUpdating}
                  onChange={(checked) =>
                    onSwithChange("redirectCustomerSwitch_" + index)(checked)
                  }
                />
              </div>
            </div>
          </div>
        ))}

        <div className="_carousel_text">
          <Divider style={{ marginTop: "12px" }} />
          <div className="_info">
            <p>Maintenance Site</p>
            <span>Manage contents that goes on the maintenance site.</span>
            <p style={{ fontWeight: 400 }}>Customize carousel text</p>
            <Space direction="vertical" size="middle">
              <Input
                value={maintenanceCarouselMessage}
                size="large"
                className="input"
                onChange={(e) => onInputChange("carouselMessage")(e)}
              />
              <Button
                type="primary"
                size="large"
                placeholder="Type a message"
                disabled={
                  maintenanceCarouselMessage ===
                  maintenanceInfo?.maintenanceCarouselMessage
                }
                onClick={() => updateRedirectMsg("carouselMessage")}
              >
                Save
              </Button>
            </Space>
          </div>
        </div>
      </div>
    </MaintenanceStyle>
  );
};

export default MaintenanceUpdate;
