import { Divider, List, Modal, Tabs, TabsProps } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { fetchUserNotifications } from "src/redux/actions/actions";
import {
  convertTimestampToRegularDate,
  convertToTimeString,
  replaceUnderscores,
} from "src/util/util";
import styled from "styled-components";

interface NotificationsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface PaymentNotificationProps {
  meta: any;
  dateCreated: string;
}

interface OtherNotificationProps {
  type: string;
  meta: any;
  dateCreated: string;
}

export default function NotificationsModal({
  open,
  setOpen,
}: NotificationsProps) {
  const notifications = useSelector((state: any) => state.notifications);
  const [paymentNotifications, setPaymentNotifications] = useState<
    PaymentNotificationProps | any
  >();
  const [otherNotifications, setOtherNotifications] = useState<
    OtherNotificationProps | any
  >();

  function filterNotifications(notifications: any[], notificationType: string) {
    if (notifications?.length > 0) {
      const filteredNotifications = notifications?.filter(
        (notification: any) => notification?.type === notificationType
      );

      const otherNotifications = notifications.filter(
        (notification) => notification.type !== notificationType
      );

      setPaymentNotifications(filteredNotifications);
      setOtherNotifications(otherNotifications);
    } else {
      console.log("No notifications to filter.");
      return [];
    }
  }

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && open) {
      (async () => {
        try {
          await fetchUserNotifications();

          notifications &&
            filterNotifications(notifications, "PAYMENT_RECEIVED");
        } catch (error) {
          console.error("Error fetching notifications:", error);
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [open, notifications]);

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Payment recived (${paymentNotifications?.length})`,
      children: (
        <List
          bordered
          dataSource={paymentNotifications}
          renderItem={(item: PaymentNotificationProps) => (
            <List.Item>
              <div style={{ width: "100%" }}>
                <p>
                  <strong>Message:</strong> {item?.meta?.message}
                </p>
                <Reason>
                  <strong>Reason:</strong> {item?.meta?.title}
                </Reason>
                <Date>
                  <span>
                    {convertToTimeString(item?.dateCreated)} -
                    {convertTimestampToRegularDate(item?.dateCreated)}
                  </span>{" "}
                  <span>Transfer Id: {item?.meta?.transferId}</span>
                </Date>
              </div>
            </List.Item>
          )}
          style={{
            overflow: "auto",
            height: 540,
          }}
        />
      ),
    },
    {
      key: "2",
      label: `Others (${otherNotifications?.length})`,
      children: (
        <List
          bordered
          dataSource={otherNotifications}
          renderItem={(item: OtherNotificationProps) => (
            <List.Item>
              <div style={{ width: "100%" }}>
                <p>
                  <strong>Type: </strong> {replaceUnderscores(item?.type)}
                </p>
                <Reason>
                  <strong>Message: </strong> {item?.meta?.message}
                </Reason>
                <Date>
                  <span>
                    {convertToTimeString(item?.dateCreated)} -
                    {convertTimestampToRegularDate(item?.dateCreated)}
                  </span>{" "}
                </Date>
              </div>
            </List.Item>
          )}
          style={{
            overflow: "auto",
            height: 540,
          }}
        />
      ),
    },
  ];

  const renderNotifications = () => {
    return <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;
  };

  return (
    <Modal
      onCancel={() => setOpen(false)}
      open={open}
      title={`Notifications (${notifications.length})`}
      width={700}
      style={{ top: "15%" }}
      footer={null}
    >
      <Divider style={{ marginTop: "12px" }} />
      <Body
        style={{
          marginBottom: "10px",
          height: 600,
        }}
      >
        {renderNotifications()}
      </Body>
    </Modal>
  );
}

const Reason = styled.div`
  border-radius: 5px;
  background: #e5f1ee;
  width: 100%;
  padding: 10px 14px;
  align-items: center;
  align-self: stretch;
`;

const Date = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
`;

const Body = styled.div`
marginBottom: 10px,
height: 600px,
`;
