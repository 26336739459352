import {
  ArrowRightOutlined,
  CheckCircleTwoTone,
  EditOutlined,
  FallOutlined,
  RightOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Space,
  Table,
  theme,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { asset } from "src/util/util";
import styled, { css } from "styled-components";
import {
  CustomError,
  CustomLoader,
} from "../../../../../../../util/Reuseables";
import { calculateSbremitPercentageRateDifference } from "../../../helpers/helper";
import {
  useCompetitorRatesData,
  useUpdateCompetitorRate,
} from "../../../helpers/useRQsettingsData";
import { TitleStyles } from "./styles";
const { useToken } = theme;

const CompetitorsLogos: any = {
  sbremit: "main-logo.svg",
  westernunion: "western-union.png",
  moneygram: "money-gram.png",
  worldremit: "world-remit.png",
  ria: "ria.png",
  paysend: "paysend.png",
  taptap: "taptap.png",
  sendwave: "sendwave.png",
  remitly: "remitly.png",
};

const CompetitorsRate = () => {
  const {
    data: competitorRates,
    isLoading,
    isError,
    error,
  } = useCompetitorRatesData();
  const err: any = error;
  const [isModalopen, setIsModalOpen] = useState(false);

  if (isLoading) {
    return (
      <CompetitorsRateWrapper>
        <CustomLoader prompt="Loading competitors rates..." />
      </CompetitorsRateWrapper>
    );
  }
  if (isError) {
    return (
      <CompetitorsRateWrapper>
        <CustomError message={err?.message} />
      </CompetitorsRateWrapper>
    );
  }

  return (
    <CompetitorsRateWrapper>
      <div className="_content">
        <TitleStyles>Competitors rate</TitleStyles>

        <CardsWrapper>
          {competitorRates.slice(1, 5).map((competitor: any, index: number) => {
            const ratePercentage = calculateSbremitPercentageRateDifference(
              competitor?.rate,
              competitorRates
            );

            const isRateDifferenceNegative = Number(ratePercentage) < 0;

            return (
              <CardWrapper key={competitor?.name + index}>
                <Logo
                  src={asset("logos", CompetitorsLogos[competitor?.name])}
                  alt={competitor?.name}
                  className="_logo"
                />

                <Space direction="vertical">
                  <Space>
                    <RateTextStyle> 1{competitor?.base} </RateTextStyle>
                    <ArrowRightOutlined size={12} />
                    <RateTextStyle>
                      {competitor?.rate}
                      {competitor?.target}
                    </RateTextStyle>
                  </Space>

                  <Space size={12}>
                    <RateDifference $isLess={isRateDifferenceNegative}>
                      {isRateDifferenceNegative ? (
                        <FallOutlined />
                      ) : (
                        <RiseOutlined />
                      )}
                      {Math.abs(Number(ratePercentage))}%
                    </RateDifference>
                    <span>
                      {isRateDifferenceNegative
                        ? "Less than SB Remit"
                        : "Greater than SB Remit"}
                    </span>
                  </Space>
                </Space>

                <CardFooter>
                  <span>Transfer fee</span>
                  <span>{competitor?.transferFee}</span>
                </CardFooter>
              </CardWrapper>
            );
          })}
        </CardsWrapper>
      </div>
      <SeeAllButton onClick={() => setIsModalOpen(true)}>
        See all <RightOutlined />
      </SeeAllButton>
      <CompetitorsRateModal
        competitors={competitorRates}
        open={isModalopen}
        setOpen={setIsModalOpen}
      />
    </CompetitorsRateWrapper>
  );
};

export default CompetitorsRate;

interface TableDataType {
  provider: string;
  rate: string;
  transferFee: string;
  rateDifference: string;
  action: any;
}

const CompetitorsRateModal = ({
  open,
  setOpen,
  competitors,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  competitors: any[];
}) => {
  const [dataSrc, setDatascr] = useState<any>();

  const columns: ColumnsType<TableDataType> = [
    {
      title: "PROVIDER",
      dataIndex: "provider",
      render: (provider) => (
        <Logo
          src={asset("logos", CompetitorsLogos[provider])}
          alt={provider}
          className="_logo"
        />
      ),
    },
    {
      title: "EXCHANGE RATE",
      dataIndex: "rate",
    },
    {
      title: "TRANSFER FEE",
      dataIndex: "transferFee",
    },
    {
      title: "RATE AGAINST SB REMIT",
      dataIndex: "rateDifference",
      render: (rateDifference) => {
        const isRateDifferenceNegative = Number(rateDifference) < 0;
        return (
          <Space size={12}>
            <RateDifference $isLess={isRateDifferenceNegative}>
              {isRateDifferenceNegative ? <FallOutlined /> : <RiseOutlined />}
              {Math.abs(rateDifference)}%
            </RateDifference>
            <span>
              {isRateDifferenceNegative
                ? "Less than SB Remit"
                : "Greater than SB Remit"}
            </span>
          </Space>
        );
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (competitor) => <EditAction competitor={competitor} />,
    },
  ];

  useEffect(() => {
    const dataSource: any = [];
    const sbRate = competitors.find(
      (competitor: any) => competitor.name === "sbremit"
    )?.rate;
    competitors?.slice(1, competitors?.length).map((competitor, index) => {
      const ratePercentage = calculateSbremitPercentageRateDifference(
        competitor?.rate,
        competitors
      );

      const data = {
        key: index,
        provider: competitor?.name,
        rate: competitor?.rate,
        transferFee: competitor?.transferFee,
        rateDifference: ratePercentage,
        action: competitor,
      };
      dataSource.push(data);
    });
    setDatascr(dataSource);
  }, [competitors]);

  return (
    <Modal
      title="Competitors rate"
      open={open}
      onCancel={() => setOpen(false)}
      width={1000}
      footer={null}
    >
      <CompetitorsTableWrapper>
        <Table dataSource={dataSrc} columns={columns} pagination={false} />
      </CompetitorsTableWrapper>
    </Modal>
  );
};

const EditAction = ({ competitor }: { competitor: any }) => {
  const { token } = useToken();
  const [updated, setUpdated] = useState(false);

  const toggleUpdated = () => {
    setUpdated(true);
    setTimeout(() => {
      setUpdated(false);
    }, 3000); // 2 seconds
  };
  const { mutate: updateCompetitorMutate, isLoading } = useUpdateCompetitorRate(
    () => toggleUpdated()
  );

  const initialValues = {
    rate: competitor?.rate || "",
    transferFee: competitor?.transferFee || "",
  };

  const onSubmit = (values: any) => {
    const { rate, transferFee } = values;
    const upadatedValues = {
      ...competitor,
      rate,
      transferFee,
    };
    updateCompetitorMutate(upadatedValues);
  };

  return (
    <Dropdown
      dropdownRender={() => (
        <DropdownContentWrapper $token={token}>
          <Form
            name="edit competitor"
            layout="vertical"
            initialValues={initialValues}
            onFinish={onSubmit}
          >
            <Form.Item label="Rate" name="rate">
              <Input placeholder="rate" />
            </Form.Item>
            <Form.Item label="Transfer Fee" name="transferFee">
              <Input placeholder="transfer fee" />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Update
                </Button>
                {updated && <CheckCircleTwoTone twoToneColor="#52c41a" />}
              </Space>
            </Form.Item>
          </Form>
        </DropdownContentWrapper>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Button icon={<EditOutlined />} />
      </a>
    </Dropdown>
  );
};

const CompetitorsRateWrapper = styled.div`
  /* min-width: 938px; */
  width: 100%;
  border: 1px solid rgba(222, 222, 225, 1);
  border-radius: 12px;
  height: 372px;
  overflow: hidden;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  ._content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
  }
`;

const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`;

const CardWrapper = styled.div`
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  border: 1px solid rgba(222, 222, 225, 1);
  width: 100%;
  height: 207px;
`;

const Logo = styled.img`
  max-width: 133px;
  max-height: 46px;
`;

const RateTextStyle = styled.h4`
  font-size: 16px;
  color: rgba(66, 66, 66, 1);
  font-weight: 600;
  margin: 0;
`;

const RateDifference = styled.div<{ $isLess?: boolean }>`
  display: flex;
  gap: 8px;

  ${(props) =>
    props.$isLess
      ? css`
          color: rgba(207, 9, 33, 1);
        `
      : css`
          color: rgba(0, 123, 93, 1);
        `}
`;

const CardFooter = styled.div`
  border-top: 1px solid rgba(222, 222, 225, 1);
  padding-top: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span:last-child {
    font-weight: 500;
  }
`;

const SeeAllButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 14px 24px;
  outline: none;
  border: none;
  background: rgba(245, 245, 248, 1);
  border-top: 1px solid rgba(222, 222, 225, 1);
  cursor: pointer;

  font-weight: 500;
  color: rgba(73, 79, 94, 1);
  font-size: 16px;

  &:hover,
  &:active {
    opacity: 0.65;
  }
`;

const CompetitorsTableWrapper = styled.div`
  margin-top: 24px;
  border: 1px solid rgba(144, 152, 178, 0.3);
  border-radius: 8px;
  overflow: hidden;
`;

const DropdownContentWrapper = styled.div<{ $token: any }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: ${(props) => props.$token.colorBgElevated};
  border-radius: 4px;
  box-shadow: ${(props) => props.$token.boxShadowSecondary};
  padding: 20px;
  border: 1px solid rgba(73, 79, 94, 0.2);
  min-width: 280px;
`;
