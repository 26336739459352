export const paths = {
  BASE_APP_ROUTE: "/app",
  DASHBOARD: "/app/dashboard",
  TRANSFERS: "/app/transfers",
  TRANSFER_PROFILE: "/app/transfers/:transactionId",
  USERS: "/app/users",
  USER_PROFILE: "/app/users/:userId",
  PROMOS: "/app/promos",
  FX: "/app/fx",
  SETTINGS: "/app/settings/:tab",
  RESET_PASSWORD: "/reset-password",
  SIGN_IN: "/",

  // transfer flow
  TRANSFER_METHOD: "/app/transfer-method",
  GET_QUOTE: "/app/get-quote",
  VERIFICATION: "/app/verification",
  RECIPIENT: "/app/recipient",
  REVIEW: "/app/review",
  PAYMENT_METHOD: "/app/payment-method",
  CREATE_TRANSFER: "/app/create-transfer",
  TRANSFER_COMPLETE: "/app/transfer-complete",
  USER_VERIFICATION_SOURCES: "/app/user/verification/:id",
  TRUELAYER_PROVIDERS: "/truelayer-providers",
  COMPETITOR_RATES: "/app/competitor-rates",

  MAINTENANCE: "/app/maintenance",

  // preorder flow
  PREORDER: "/app/fx/preorder",
};
