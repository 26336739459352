import React from "react";
import { Steps } from "antd";

interface StepProps {
  currentState: number;
  status: "error" | "process" | "wait" | "finish";
  date: string;
  state: string;
}

export default function TransactionTimeline({
  currentState,
  status,
  date,
  state,
}: StepProps) {
  const items = [
    {
      title: "Transfer Created",
      description: date,
    },
    {
      title: "Received Payment",
      description: state,
    },
    {
      title: "Vendor Processing Transfer",
      description: state,
    },
    {
      title: "Recipient Received Payment",
      description: state,
    },
  ];

  return <Steps current={currentState} status={status} items={items} />;
}
