import React, { useEffect } from "react";
import { generateRandomString } from "../../../util/utility";
import { ITable } from "./ITable";
import styled from "styled-components";

const StyledTable = styled.table`
  border: 1px solid grey;
  width: 100%;
  th,
  td {
    border: 5px double grey;
    padding: 5px;
  }
`;
const Table = ({ headings, rows, name = generateRandomString() }: ITable) => {
  return (
    <StyledTable cellPadding={1} cellSpacing={4}>
      {headings?.map((heading, i) => (
        <tr key={`table-${name}-hd-tr-${i}`}>
          {heading.map((cell, j) => (
            <th
              key={`table-${name}-cell-th-${j}`}
              rowSpan={cell.rowSpan || 1}
              colSpan={cell.colSpan || 1}
            >
              {cell.component ? (
                <cell.component />
              ) : cell.html ? (
                <div
                  className="wrapper"
                  dangerouslySetInnerHTML={{ __html: cell.html }}
                ></div>
              ) : (
                cell.text
              )}
            </th>
          ))}
        </tr>
      ))}
      {rows.map((row, i) => (
        <tr key={`table-${name}-row-tr-${i}`}>
          {row.map((cell, j) => (
            <td
              key={`table-${name}-cell-td-${j}`}
              rowSpan={cell.rowSpan || 1}
              colSpan={cell.colSpan || 1}
            >
              {cell.component ? (
                <cell.component />
              ) : cell.html ? (
                <div
                  className="wrapper"
                  dangerouslySetInnerHTML={{ __html: cell.html }}
                ></div>
              ) : (
                cell.text
              )}
            </td>
          ))}
        </tr>
      ))}
    </StyledTable>
  );
};

export default Table;
