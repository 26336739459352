import sha1 from "sha1";
import env from "src/env";
import { checkAuth, toastAction } from "src/redux/actions/actions";
import endpoints from "src/util/endpoints";

export const baseCurrency = [
  {
    value: "GBP",
  },
  {
    value: "CAD",
  },
  {
    value: "EUR",
  },
  {
    value: "DKK",
  },
  {
    value: "NOK",
  },
  {
    value: "SEK",
  },
  {
    value: "CHF",
  },
];

export const targetCurrency = [
  {
    value: "XAF",
  },
  {
    value: "XOF",
  },
  {
    value: "NGN",
  },
  {
    value: "KES",
  },
  {
    value: "UGX",
  },
  {
    value: "TZS",
  },
  {
    value: "USD",
  },
  {
    value: "GHS",
  },
  {
    value: "ZAR",
  },
  {
    value: "BWP",
  },
];

export const handleInputChange = (
  fieldName: string,
  value: string,
  form: any,
  rate: any
) => {
  const numericValue = parseFloat(value);
  const exchangeRate = Number(rate?.rate);

  if (fieldName === "spread") {
    const percentageSpread = (numericValue / exchangeRate) * 100;
    form.setFieldValue(
      "percentageSpread",
      isNaN(percentageSpread) ? 0 : percentageSpread.toFixed(2)
    );
  } else if (fieldName === "percentageSpread") {
    const spread = (numericValue * exchangeRate) / 100;
    form.setFieldValue("spread", isNaN(spread) ? 0 : spread.toFixed(2));
  }
};

export const getSpreadandPercentageSpreadEquivalent = (
  form: any,
  rate: any
) => {
  const spread = form.getFieldValue("spread");
  const percentageSpread = form.getFieldValue("percentageSpread");
  handleInputChange("spread", spread, form, rate);
};

export const extractUniquePropertyValues = (
  propertyName: string,
  data: any[]
) => {
  const foundValues: string[] = [];

  data?.forEach((obj: any) => {
    if (!foundValues.includes(obj?.[propertyName])) {
      foundValues.push(obj?.[propertyName]);
    }
  });

  return foundValues;
};

export const filterSpreadData = (
  base: string,
  target: string,
  spreadData: any[]
) => {
  if (!base || !target) {
    return spreadData.filter(
      (data) => data.base === base || data.target === target
    );
  } else {
    return spreadData.filter(
      (data) => data.base === base && data.target === target
    );
  }
};

export const calculateSbremitPercentageRateDifference = (
  competitorRate: string,
  competitors: any
) => {
  const sbRate = competitors.find(
    (competitor: any) => competitor.name === "sbremit"
  )?.rate;

  const competitorRateFloat = Number(competitorRate);
  const rateDifference = competitorRateFloat - Number(sbRate);

  const ratePercentage = (rateDifference / sbRate) * 100;

  return ratePercentage?.toFixed(1) || 0;
};

export const uploadCSVDocument = async (
  data: any,
  resetSelectedFile: Function,
  userId: string
) => {
  const file = data[0]?.originFileObj;

  const authData: any = checkAuth();

  const formData = new FormData();

  formData.append("upload", file);

  const endpoint = endpoints.AML + `/upload?AMLId=${userId}`;

  const url = env.API_PROXY
    ? env.API_PROXY + endpoint
    : env.API_HOST + endpoint;
  const authToken = authData.authToken;

  const requestHash = sha1(url + authToken);

  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        "X-SERVICE-PROVIDER": authData.serviceProvider,
        "X-SERVICE-USER-NAME": authData.sessionId,
        "X-REQUEST-HASH": requestHash,
      },
    });

    const result = await response.json();
    // console.log(result.error.message);

    if (result.status === "200") {
      // console.log("Document uploaded successfully");
      resetSelectedFile();
    } else {
      throw new Error(result?.error?.message || "Failed to upload document");
    }
  } catch (error: any) {
    throw new Error(error?.message);
  } finally {
    // console.log("End");
  }
};
