import { Button, Tabs, TabsProps } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { HORIZONTAL_LOADING } from "src/redux/actionTypes";
import { confirmDialog, confirmUserPassword } from "src/redux/actions/actions";
import { CustomError, CustomLoader } from "src/util/Reuseables";
import styled from "styled-components";
import { MaintenanceStyle } from "./Maintenance.css";
import {
  useGetMaintainaceData,
  useUpdateMaintainaceData,
} from "./useRQmaintenanceData";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ fonts: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
  ],
};

export default function TandCupdate() {
  const { isLoading, isError, error } = useGetMaintainaceData();
  const err: any = error;

  if (isLoading) {
    return (
      <MaintenanceStyle>
        <CustomLoader prompt="Loading maintenance data..." />
      </MaintenanceStyle>
    );
  }

  if (isError) {
    return (
      <MaintenanceStyle>
        <CustomError message={err?.message} />
      </MaintenanceStyle>
    );
  }

  return (
    <TandCupdateStyles>
      <TitleAndDescript>
        <p>Terms and Conditions</p>
        <span>The legal agreements between Users and SBremit</span>
      </TitleAndDescript>
      <TandCcontainerStyles>
        <EditorContainer>
          <EditorAndPreviewTabs />
        </EditorContainer>
      </TandCcontainerStyles>
      {/* <ConfirmWithPassowrdStyles>
        <span>Enter your password to save changes</span>
        <div className="input_n_btn">
          <Input.Password placeholder="password" size="large" />
          <Button type="primary" size="large">
            Save changes
          </Button>
        </div>
      </ConfirmWithPassowrdStyles> */}
    </TandCupdateStyles>
  );
}

interface EditorProps {
  value: string;
  setValue: (value: string) => void;
}

const EditorMemoized = ({ value, setValue }: EditorProps) => {
  const EditorMemoized = useMemo(
    () => <Editor value={value} setValue={setValue} />,
    [value]
  );

  return <div className="editor_input_wrapper">{EditorMemoized}</div>;
};

const Editor = ({ value, setValue }: EditorProps) => {
  const quillRef = useRef<ReactQuill | null>(null);

  const handleBlur = () => {
    if (quillRef.current) {
      quillRef.current.focus();
    }
  };

  return (
    <ReactQuill
      // ref={quillRef}
      modules={modules}
      theme="snow"
      value={value}
      onChange={setValue}
      onBlur={handleBlur}
      className="editor_input"
      placeholder="type some word..."
    />
  );
};

const Preview = ({ value }: { value: string }) => {
  return (
    <div
      className="preview_container"
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

const EditorAndPreviewTabs = () => {
  const maintenanceInfo = useSelector((state: any) => state.maintenance);
  const [value, setValue] = useState(maintenanceInfo?.termsAndCondition);
  const dispatch = useDispatch();

  const { mutate: updateMaintenanceData, isLoading: isUpdating } =
    useUpdateMaintainaceData();

  useEffect(() => {
    if (isUpdating) {
      dispatch({ type: HORIZONTAL_LOADING, payload: true });
    } else {
      dispatch({ type: HORIZONTAL_LOADING, payload: false });
    }
  }, [isUpdating]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Preview",
      children: <Preview value={value} />,
    },
    {
      key: "2",
      label: "Editor",
      children: <EditorMemoized value={value} setValue={setValue} />,
    },
  ];

  const updateTandC = () => {
    confirmDialog({
      message: `Please, input your account password to make this change`,
      isPositive: undefined,
      open: true,
      field: {
        title: "Password:",
        placeholder: "Your account password here...",
        required: true,
      },
      callback: (fieldValue: string) =>
        confirmUserPassword(fieldValue, () => {
          updateMaintenanceData({
            ...maintenanceInfo,
            termsAndCondition: value,
          });
        }),
    });
  };
  return (
    <Tabs
      defaultActiveKey="1"
      items={items}
      tabBarExtraContent={
        <Button
          type="primary"
          onClick={updateTandC}
          disabled={isUpdating || value === maintenanceInfo?.termsAndCondition}
        >
          Save Changes
        </Button>
      }
    />
  );
};

const TandCupdateStyles = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 42px;
`;

const TitleAndDescript = styled.div`
  p {
    color: #424242;
    font-size: 28px;
    font-weight: 600;
    margin: 0;
  }
  span {
    color: #a3a3a3;
    font-size: 16px;
  }
`;

const TandCcontainerStyles = styled.div`
  display: flex;
  width: 100%;
  min-height: 500px;
  /* min-height: 500px; */
  //max-height: 300px;
  //overflow-y: scroll;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid rgba(144, 152, 178, 0.3);

  .title {
    p {
      color: #424242;
      font-size: 20px;
      font-weight: 500;
      margin: 0;
    }
    span {
      color: #424242;
      font-size: 14px;
      font-weight: 400;
    }
  }
  input {
    width: 100%;
  }
`;

const ConfirmWithPassowrdStyles = styled.div`
  .input_n_btn {
    display: flex;
    gap: 14px;
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  align-items: flex-start;
  max-width: 500px;
  gap: 12px;
  p {
    margin: 0;
    color: #424242;
    font-size: 20px;
    font-weight: 500;
  }
`;

const EditorContainer = styled.div`
  width: 100%;
  min-height: 400px;
  overflow-y: auto;
  padding: 12px 0px;
  .editor_input_wrapper {
    position: relative;
    width: 100%;
    min-height: 400px;
    overflow-y: auto;
    .editor_input {
      min-height: 300px;
      width: inherit;
    }
  }
  .preview_container {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
  }
`;
