import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { approvePreorder, approveTransfer, cancelPreorder, cancelTransfer, getPreorders, getRecipients, getTransfers, getUsers, resendTransfer } from '../../../../redux/actions/actions';
import store from '../../../../redux/store';
import constants from '../../../../util/constants';
import { paths } from '../../../../util/paths';
import { convertDateString, filterByDateRange, filterByProperty, formatCurrency, genPaginationHashTable, replaceUnderscores } from '../../../../util/util';
import { getImage } from '../../../../util/utility';
import Pagination from '../../../modules/pagination/Pagination';
import PreorderDetail from '../../../modules/preorder-detail-modal/PreorderDetail';
import TSearch from '../../../modules/t-search/TSearch';
import TransactionDetail from '../../../modules/transaction-detail-modal/TransactionDetail';
import UserSelectorForTransaction from '../../../modules/user-selector-for-transaction/UserSelectorForTransaction';

const Body = styled.div`
    margin-top: 80px;
    width: 95%;
    .title {
        font: normal normal 500 20px/30px Montserrat;
        color: #424242;
        span {
            color: #A3A3A3;
        }
    }


    .grey-bg {
        background: #F5F7F7;
    }
    .add-user-btn {
        padding: 10px 30px;
        border-radius: 10px;
        color: #fff;
        background: #007B5D;
        border: none;
        /* box-shadow: 1px 3px 4px #CCCCCC; */
        float: right;
        margin: 0px 80px;
        cursor: pointer;
        font: normal normal normal 20px Montserrat;
    }

    .calculator-btn {
        padding: 7px 10px;
        margin: 5px;
        margin-top: -45px;
        img {
            width: 25px;
        }
    }
    .filters {
        padding: 0px 0px;
        margin: 0px 40px;
        max-width: 600px;
        >div{
            width: fit-content;
            border-bottom: 1px solid #E6E8F1;
            span.filter {
                font: normal normal normal 16px/19px Montserrat;
                color: #424242;
                display: inline-block;
                margin: 0px 10px;
                padding: 10px 10px;
                cursor: pointer;
            }
            span.selected {
                border-bottom: 3px solid #007B5D;
                color: #007B5D;
                font: normal normal 600 16px/19px Montserrat;
            }
            span.pagination {
                float: right;
                color: #007B5D;
                font: normal normal 600 16px/19px Montserrat;
                .of {
                    color: #A3A3A3;
                    font-weight: lighter;
                }
                img {
                    width: 15px;
                    margin: 0px 20px;
                    cursor: pointer;
                }
            }
        }
    }

    .table {

        .tbody {
            width: 100%;
            /* width: 894px; */
            min-height: 476px;
            max-height: 676px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 10px 12px #CCCCCC80;
            border-radius: 15px;
            margin-top: 20px;
            padding: 15px 0px 30px;
            overflow-y: scroll;
            ::-webkit-scrollbar {
                width: 5px;
            }

            ::-webkit-scrollbar-track {
                /* -webkit-box-shadow: inset 0 0 6px #ffffff;  */
                border-radius: 10px;
            }

            ::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px #165a05; 
            }

            .theading {
                display: grid;
                grid-template-columns: 2.5fr 1fr 1fr;
                margin-bottom: 60px;
                margin-top: 20px;
                .tsearch {
                    
                }
            }

            .thead {
                display: grid;
                grid-template-columns: 0.15fr 1.1fr 0.7fr 0.7fr 0.7fr 1fr 0.7fr 0.7fr 0fr;
                padding: 0px 30px 0px 40px;
                .th {
                    font: normal normal normal 16px/44px Montserrat;
                    color: #A3A3A3;
                }
            }
            hr {
                border: none;
                border-top: 1px solid #F0F0F0;
                border-radius: 15px;
            }
            .h-hr {
                margin: 0px;
            }
            .t-hr {
                margin: 0px 2.5%;
            }
            .tr {
                display: grid;
                grid-template-columns: 0.15fr 1.1fr 0.7fr 0.7fr 0.7fr 1fr 0.7fr 0.7fr 0fr;
                padding: 20px 30px 20px 40px;
                .td {
                    font: normal normal normal 16px/40px Montserrat;
                    color: #424242;
                    img.arrow-right {
                        width: 12px;
                        height: 12px;
                    }
                }
                .statuses {
                    .status {
                        display: inline-block;
                        border-radius: 15px;
                        text-align: center;
                        font: normal normal normal 13px Montserrat;
                        color: #FFFFFF;
                        padding: 7px 15px;
                    }
                }
                .dots {
                    position: relative;
                    img.three-dots {
                        width: 19px;
                        height: 19px;
                        position: absolute;
                        bottom: 10px;
                        cursor: pointer;
                    }
                    .actions {
                        width: 200px;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 10px #00000027;
                        border-radius: 15px;
                        position: absolute;
                        left: -210px;
                        top: 30px;
                        padding: 20px 30px;
                        ul {
                            list-style-type: none;
                            padding: 0px;
                            margin: 0px;
                            li {
                                padding: 10px 0px;
                                margin: 0px;
                                display: grid;
                                grid-template-columns: 0fr 1fr;
                                grid-gap: 15px;

                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                                span {
                                    font: normal normal normal 13px/14px Montserrat;
                                    color: #4D565C;
                                }
                                :hover {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .td-1 {
                    display: grid;
                    grid-template-columns: 0fr 1fr;
                    img {
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    span {

                    }
                }
                :hover {
                    box-shadow: 0px 3px 6px #00000029;
                }
            }
            .table-check {
                width: 15px;
                height: 15px;
            }
        }
    }
`

export const Preorders = () => {
    const [statusFilter, setStatusFilter]: any = useState(null);
    const preorders = useSelector((state: any) => state.preorders.preorders)
    const filters = useSelector((state: any) => state.filters)
    const dateFilter = filters.date;
    const [page, setPage] = useState(1);
    const [preordersFormatted, setPreordersFormatted] = useState([])
    const [paginationTrack, setPaginationTrack]: any[] = useState({});
    const [dotClick, setDotClick] = useState(-1)
    const history = useHistory();
    const [tUserSelectorOpen, setTUserSelectorOpen] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [preorderSearchValue, setPreorderSearchValue] = useState("")
    const [openTDModal, handleOpenTDModal] = useState(false);
    const [modalData, setModalData] = useState({});


    useEffect(() => {
        getPreorders()
    }, [])


    useEffect(() => {
        const preordersModified = preorders.map((preorder: any) => {preorder.checked = false; return preorder;})
        setAllPreorders(preordersModified)
    }, [preorders])

    useEffect(() => {
        handleOpenTDModal(false)
    }, [dotClick])

    const getPreorder = (id: number) => {
        return preorders.filter((preorder: any) => preorder.id == id)[0]
    }

    let preordersObj: any[] = []

    const [allPreorders, setAllPreorders] = useState(preordersObj)
    const [allChecked, setAllChecked] = useState(false);

    const setChecked = (key?: number): void => {
        if (key !== undefined){
            allPreorders[key].checked = !Boolean(allPreorders[key].checked);
            setAllPreorders([...allPreorders])
            const findUncheckedBox = allPreorders.filter((preorder: any) => !Boolean(preorder.checked))[0]
            setAllChecked(!Boolean(findUncheckedBox))
        }
        else {
            allPreorders.forEach((preorder: any) => {preorder.checked = !allChecked})

            return setAllChecked(!allChecked)
        }
    }

    useEffect(() => {
        getPreordersFormatted(allPreorders);
    }, [allPreorders, page, dateFilter, preorderSearchValue, statusFilter])

    const getPreordersFormatted = (preorders: any) => {
        const searchedPreorders = searchPreorders(preorders, preorderSearchValue)
        const filtered = filterByDateRange(searchedPreorders, dateFilter[0], dateFilter[1])
        const filteredByStatus = filterByProperty(filtered, "status", statusFilter)
        const noPerPage = 50
        const pagination: any = genPaginationHashTable(filteredByStatus, noPerPage)
        setPreordersFormatted(pagination.paginated[page] || [])
        setPaginationTrackValues(pagination, page, noPerPage)
    }

    const searchPreorders = (preorders: any[], keyword: string) => {
        const searchText = keyword?.toLowerCase();
        const filtered = preorders.filter((preorder: any) => {
            return (("sbr" + preorder.dateCreated).indexOf(searchText) !== -1 || (
                preorder?.sender?.firstname?.toLowerCase() + " " + preorder?.sender?.lastname?.toLowerCase()
                ).indexOf(searchText) !== -1
            || (
                preorder?.sender?.lastname?.toLowerCase() + " " + preorder?.sender?.firstname?.toLowerCase()
                ).indexOf(searchText) !== -1)
        })

        return filtered;
    }

    const setPaginationTrackValues = (pagination: any, page: number, noPerPage: number) => {
        const all = (pagination.paginated[1]?.length * (pagination.pages?.length - 1) + pagination.paginated[pagination.pages?.length]?.length) || allPreorders?.length;
        const from = (noPerPage * page) - ((noPerPage) - 1);
        const to = (noPerPage * page) < all ? (noPerPage * page) : all;
        const firstPage = pagination.pages?.[0];
        const lastPage = pagination.pages?.[pagination.pages?.length - 1]
        const noOfPages = Math.ceil(all/noPerPage)
        setPaginationTrack({all, from, to, firstPage, lastPage, noOfPages})
        return {all, from, to, firstPage, lastPage, noOfPages}
    }

    const setNewPage = (newPage: number) => {
        if (newPage < paginationTrack.firstPage || newPage > paginationTrack.lastPage) return;
        setPage(newPage)
    }

    
    return (
        <Body>
            <PreorderDetail openTDModal={openTDModal} data={modalData} handleOpenTDModal={handleOpenTDModal}  />
            <div className="title">
                Exchanges <span>({preorders.length})</span>
                <Pagination paginationTrack={paginationTrack} setNewPage={setNewPage} page={page} />
            </div>

            <div className="table">
                <div className="tbody">
                    <div className="theading">
                        <div className="tnav">
                            <div className="filters">
                                <div>
                                    <span onClick={()=>{setStatusFilter(null)}} className={`filter ${!statusFilter ? 'selected' : ''}`}>All</span>
                                    <span onClick={()=>{setStatusFilter('active')}} className={`filter ${statusFilter === 'active' ? 'selected' : ''}`}>Active</span>
                                    <span onClick={()=>{setStatusFilter(constants.TRANSFER_STATUS_PENDING.toLowerCase())}} className={`filter ${statusFilter === constants.TRANSFER_STATUS_PENDING.toLowerCase() ? 'selected' : ''}`}>Pending</span>
                                    <span onClick={()=>{setStatusFilter('complete')}} className={`filter ${statusFilter === 'complete' ? 'selected' : ''}`}>Closed</span>
                                    <span onClick={()=>{setStatusFilter('cancelled')}} className={`filter ${statusFilter === 'cancelled' ? 'selected' : ''}`}>Cancelled</span>
                                    <span onClick={()=>{setStatusFilter('refunds')}} className={`filter ${statusFilter === 'refunds' ? 'selected' : ''}`}>Refunds</span>
                                </div>
                            </div>
                        </div>
                        <div className="tsearch">
                            <TSearch placeholder="Search for an exchange" setSearchValue={(value: string) => setPreorderSearchValue(value)}  />
                        </div>
                        <div className="tbutton">
                            <button  onClick={() => setTUserSelectorOpen(!tUserSelectorOpen)} className="add-user-btn" >New exchange</button>
                            {tUserSelectorOpen && <UserSelectorForTransaction onUserSelect={(userId: string) => history.push(paths.PREORDER + "?user=" + userId)} setSearchValue={setSearchValue} searchValue={searchValue} closeSelector={() => setTUserSelectorOpen(false)} />}
                            <button className="add-user-btn calculator-btn" onClick={() => history.push(paths.PREORDER + '?step=2')}> <img src={getImage('calculator.svg', 'svgs')} alt="calculator" /> </button>
                        </div>
                    </div>

                    <div className="thead">
                        <div className="th"> <input className="table-check" type="checkbox" onChange={()=>setChecked()} checked={allChecked}/> </div>
                        <div className="th">User</div>
                        <div className="th">Transaction #</div>
                        <div className="th">Date</div>
                        <div className="th">Method</div>
                        <div className="th">Amount</div>
                        <div className="th">Service</div>
                        <div className="th">Status</div>
                        <div className="th"></div>
                    </div>
                    <hr className="h-hr"/>
                    {
                        preordersFormatted.map((preorder: any, i: number)=> {
                            const statusClass = preorder.status?.toLowerCase() === 'complete' ? 'success' : (preorder.status?.toLowerCase() === constants.TRANSFER_STATUS_PENDING.toLowerCase() ? 'warning' : (preorder.status?.toLowerCase() === 'cancelled'? 'danger' : 'dark'));
                            return (
                                <React.Fragment>
                                    <div className={`tr ${i%2 === 0 ? 'grey-bg' : ''}`} key={i} onClick={() => {
                                         setModalData(preorder);
                                        //  handleOpenTDModal(true);
                                        }
                                    }>
                                        <div className="td"> <input onChange={()=>setChecked(i)} checked={preorder.checked} className="table-check" type="checkbox"/> </div>
                                        <div className="td td-1">
                                            <img src={getImage('noimage.png', 'images')} alt="user"/>
                                            <span>{preorder.sender?.firstname } {preorder.sender?.lastname}</span>
                                        </div>
                                        <div className="td">SBR{preorder?.meta?.transactionId}</div>
                                        <div className="td">{convertDateString(preorder.dateCreated)}</div>
                                        <div className="td capitalize">{replaceUnderscores(preorder.data.method)}</div>
                                        <div className="td uppercase">{formatCurrency(preorder.amount)} <img className="arrow-right" src={getImage('arrow-right.svg', 'svgs')} alt="->" /> {preorder.currency} </div>
                                        <div className="td capitalize"> {preorder.service}</div>
                                        <div className="td statuses"> <span className={`${statusClass}-bg-light ${statusClass}-text pill capitalize`}> {preorder.status?.toLowerCase()}</span> </div>
                                        <div className="td dots">
                                            <img onClick={()=>{
                                                setDotClick(dotClick === i ? -1 : i)
                                                }} className="three-dots" src={getImage('three-dots-vertical.svg', 'svgs')} alt="..."/>
                                            <div className={`actions ${dotClick === i ? ""  : "hide"}`}>
                                                <ul>
                                                    <li onClick={() => approvePreorder(()=>{getPreorders()}, preorder.id)}>
                                                        <img src={getImage('approve-grey.svg', 'svgs')} alt="x"/>
                                                        <span>Set Completed</span>
                                                    </li>
                                                    <li onClick={preorder.status?.toLowerCase() !== "cancelled" ? () => cancelPreorder(()=>{getPreorders()}, preorder.id) : ()=>{}}>
                                                        <img src={getImage('cancel-grey.svg', 'svgs')} alt="x"/>
                                                        <span>Reject</span>
                                                    </li>
                                                    <li onClick={() => resendTransfer(preorder, () => {getTransfers()})}>
                                                        <img src={getImage('reload-grey.svg', 'svgs')} alt="resend"/>
                                                        <span>Resend</span>
                                                    </li>
                                                    <li onClick={()=>{
                                                        setModalData(preorder);
                                                        handleOpenTDModal(true);
                                                    }}>
                                                        <img src={getImage('show-grey.svg', 'svgs')} alt="view"/>
                                                        <span>View details</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="t-hr"/>
                            </React.Fragment>
                            )
                        })
                    }
                </div>
            </div>

        </Body>
    )
}

export default Preorders;
