import { Tabs, TabsProps } from "antd";
import MaintenanceUpdate from "src/components/pages/settings/components/controls/maintenance/Maintenance ";
import TandCupdate from "src/components/pages/settings/components/controls/maintenance/TandCupdate";
import AmlList from "./aml-list/AmlList";

const Controls = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Maintenance",
      children: <MaintenanceUpdate />,
    },
    {
      key: "2",
      label: "T & C update",
      children: <TandCupdate />,
    },
    {
      key: "3",
      label: "AML List",
      children: <AmlList />,
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
};

export default Controls;
