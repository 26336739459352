import { IAction } from ".";
import { COMPETITOR_RATES } from "../actionTypes";

const initialCompetitorRatesState: {} = {
    competitorRates: []
}

export const competitorRates = (state: any = initialCompetitorRatesState, {type, payload}: IAction) => {
    switch (type) {
        case COMPETITOR_RATES: {
          return {
              ...payload
          }
        }
        default:
          return state;
      }
}
