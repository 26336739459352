import { Badge } from "antd";
import { ColumnsType } from "antd/es/table";
import store from "src/redux/store";
import {
  convertDateString,
  convertToTimeString,
  getValueFromArray,
} from "src/util/util";

export const PromosTableTabs = [
  {
    label: "All",
    key: "ALL",
  },
  {
    label: "Active",
    key: "ACTIVE",
  },
  {
    label: "Expired",
    key: "EXPIRED",
  },
];

export const UasageDataTabs = [
  { label: "Promo Codes", key: "promoCodes" },
  { label: "Loyalty", key: "vouchers" },
  { label: "Referral", key: "referrals" },
];

export const UsageButtonTab = [
  { label: "Codes", key: "codes" },
  { label: "Usage Data", key: "usageData" },
];

export const filterPromos = (
  data: any[],
  searchValue: string,
  activeTab: string,
  activeUasageTab: string
): any[] => {
  const searchText = searchValue.trim().toLowerCase();

  if (data?.length > 0) {
    const filtered = data?.filter((item) => {
      if (activeUasageTab === "promoCodes") {
        const promoCode = `${item?.promoCode}`?.toLowerCase();
        const customerId = `${item?.customerCode}`?.toLowerCase();
        const status = item?.promoStatus?.toUpperCase();

        return (
          (promoCode?.includes(searchText) ||
            customerId?.includes(searchText)) &&
          (activeTab === "ALL" ? status : status === activeTab)
        );
      } else if (activeUasageTab === "vouchers") {
        const customerId = `${item?.UserID}`?.toLowerCase();
        const status = item?.VoucherBonus?.toUpperCase();

        return (
          customerId?.includes(searchText) &&
          (activeTab === "ALL" ? status : status === activeTab)
        );
      } else if (activeUasageTab === "referrals") {
        const refferalCode = `${item?.ReferralCode}`?.toLowerCase();
        const customerId = `${item?.RefereeID}`?.toLowerCase();
        const status = item?.ReferralBonus?.toUpperCase();

        return (
          (refferalCode?.includes(searchText) ||
            customerId?.includes(searchText)) &&
          (activeTab === "ALL" ? status : status === activeTab)
        );
      } else {
        const name = `${item?.name}`?.toLowerCase();
        const type = item?.type?.toLowerCase();
        const code = `${item?.code}`?.toLowerCase();
        const status = item?.status?.toUpperCase();
        return (
          (name?.includes(searchText) ||
            type?.includes(searchText) ||
            code?.includes(searchText)) &&
          (activeTab === "ALL" ? status : status === activeTab)
        );
      }
    });

    return filtered;
  }

  return [];
};

export const usageTableBody = (
  data: any[],
  tableBodyType: string,
  appValues: any
) => {
  const referralSettings: any = getValueFromArray(
    "settings",
    "name",
    appValues?.values?.data || []
  );

  const dataSource: any = [];
  data &&
    data?.map((item, index) => {
      const promoCodes = {
        key: index,
        promoCode: item?.promoCode,
        customerId: item?.customerCode,
        minSpend:
          tableBodyType === "promoCodes" && getMinimumSpend(item?.promoCode),
        usageCount:
          tableBodyType === "promoCodes" &&
          getUsageCount(data, item?.promoCode, item?.customerCode),
        value: item?.promoDiscount,
        lastDateUsed: item?.transferDate,
        expiryDate: item?.promoExpiry,
        status: item?.promoStatus,
      };

      const referrals = {
        key: index,
        refferalCode: item?.ReferralCode,
        customerId: item?.CustomerCode,
        progress:
          tableBodyType === "referrals" &&
          Math.min(
            Number(
              getPercentage(
                item.CummulativeTransfer,
                referralSettings?.data?.referralActivationAmount
              )
            ),
            100
          ),

        //refreeBonus: 10,
        referralBonus: item?.Bonus,
        activationDate: `${convertDateString(
          item?.Activated
        )} - ${convertToTimeString(item?.Activated)}`,
        expiryDate: `${convertDateString(
          item?.Expires
        )} - ${convertToTimeString(item?.Expires)}`,
        status: item?.ReferralBonus,
      };

      const vouchers = {
        key: index,
        customerId: item?.CustomerCode,
        totalPoints: item?.Bonus,
        dateActivated: `${convertDateString(
          item?.Activated
        )} - ${convertToTimeString(item?.Activated)}`,
        dateUsed: `${convertDateString(item?.Used)} - ${convertToTimeString(
          item?.Used
        )}`,
        expiryDate: `${convertDateString(
          item?.Expires
        )} - ${convertToTimeString(item?.Expires)}`,
        status: item?.VoucherBonus,
      };

      const promos = {
        key: index,
        name: item?.name,
        code: item?.code,
        type: item?.type,
        value: `${
          item.settings?.rate ||
          item.settings?.percentage ||
          item.settings?.discountAmount ||
          "N/A"
        }`,
        date: item?.endDate,
        status: item?.status,
        action: item,
      };
      if (tableBodyType === "promoCodes") {
        dataSource.push(promoCodes);
      } else if (tableBodyType === "referrals") {
        dataSource.push(referrals);
      } else if (tableBodyType === "vouchers") {
        dataSource.push(vouchers);
      } else dataSource.push(promos);
    });

  return dataSource;
};

const getUsageCount = (
  promoCodes: any,
  promoCode: string,
  customerCode: string
) => {
  const matchedData: any[] = promoCodes.filter((item: any) => {
    return item?.promoCode === promoCode && item?.customerCode === customerCode;
  });

  return matchedData.length;
};

const getPercentage = (
  needle: number | string,
  haystack: number | string,
  rounding = 0
) => {
  return (((Number(needle) || 0) / Number(haystack)) * 100).toFixed(rounding);
};

const getMinimumSpend = (promoCode: string) => {
  const { promos } = store.getState().promos || [];

  const matchedData: any =
    promos &&
    promos.find((promo: any) => {
      return promo?.code === promoCode;
    });
  return numbersWithCommas(matchedData?.settings?.minimumSpend) ?? 0;
};

const numbersWithCommas = (numberString: any) => {
  const number = parseFloat(numberString?.replace(/,/g, ""));
  return number.toLocaleString();
};
