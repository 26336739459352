const constants = {
  MOBILE_MONEY: "mobile_money",
  BANK_TRANSFER: "bank_transfer",
  CASH_PICKUP: "cash_pickup",

  STATUS_PENDING: "PENDING_PAYMENT",
  TRANSFER_STATUS_PENDING_VERIFICATION: "PENDING_VERIFICATION",
  TRANSFER_STATUS_PENDING_DOCUMENTATION: "PENDING_DOCUMENTATION",
  TRANSFER_STATUS_PENDING: "PENDING_PAYMENT",
  TRANSFER_STATUS_PAYMENT_COMPLETED: "PAYMENT_COMPLETED",
  TRANSFER_STATUS_COMPLETE: "COMPLETE",
  TRANSFER_STATUS_CANCELLED: "CANCELLED",
  TRANSFER_STATUS_EXPIRED: "EXPIRED",
  TRANSFER_STATUS_REJECTED: "REJECTED",
  TRANSFER_STATUS_REFUNDED: "REFUNDED",
  TRANSFER_STATUS_PAYMENT_SUSPENDED: "PAYMENT_SUSPENDED",
  TRANSFER_STATUS_WATCHLIST_FAILED: "WATCHLIST_FAILED",
  TRANSFER_STATUS_PAYMENT_FRAUD: "PAYMENT_FRAUD",
  TRANSFER_FLAG_FRAUD_ALERT: "FRAUD_ALERT",
  TRANSFER_BLOCKED: "BLOCKED",
  TRANSFER_PAYMENT_CANCELLED: "PAYMENT_CANCELLED",
  TRANSFER_PAYMENT_PROCESSING: "PAYMENT_PROCESSING",
  TRANSFER_STATUS_PAYMENT_DECLINED: "PAYMENT_DECLINED",
  TRANSFER_STATUS_PENDING_APPROVAL: "PENDING_APPROVAL",

  USER_STATUS_WATCHLIST_HIT: "WATCHLIST_HIT",
  VERIFICATION_STAGE_FINAL: "FINAL",
  FREE_OPERATOR_FEE: "FREE_OPERATOR_FEE",

  COMPETITOR_RATES_BASE_CURRENCY: "GBP",
  COMPETITOR_RATES_TARGET_CURRENCY: "XAF",
  COMPETITOR_RATES_COMPARE_AMOUNT: 1,

  ORIGIN_CURRENCIES: ["GBP", "CAD", "EUR"],
};

export const resources = {
  DICE_BEAR_USER: "https://avatars.dicebear.com/api/avataaars/",
  DICE_BEAR_RECIPIENT: "https://avatars.dicebear.com/api/adventurer/",
  TRUST_PAYMENT_URL:
    "https://payments.securetrading.net/process/payments/choice",
};

export const maxTransfersUnverified: any = {
  GBP: 800,
  CAD: Number.MAX_SAFE_INTEGER,
  EUR: Number.MAX_SAFE_INTEGER,
};

export const transferMethodsInWords: any = {
  1: "Mobile money",
  2: "Bank transfer",
  3: "Cash pickup",
  4: "Mobile money",
  5: "Mobile money",
  mobile_money: "Mobile money",
  bank_transfer: "Bank transfer",
  cash_pickup: "Cash pickup",
};

export const UsersTableTabs = [
  {
    label: "All",
    key: "1",
    format: "ALL",
  },
  {
    label: "Active",
    key: "2",
    format: "ACTIVE",
  },
  {
    label: "Suspended",
    key: "3",
    format: "SUSPENDED",
  },
  {
    label: "Locked",
    key: "4",
    format: "TEMPORARILY_LOCKED",
  },
  {
    label: "Flagged",
    key: "5",
    format: "WATCHLIST_HIT",
  },
  {
    label: "Duplicate",
    key: "6",
    format: "DUPLICATE",
    disabled: true,
  },
  {
    label: "Deleted",
    key: "7",
    format: "DELETED",
  },
];

export interface CountryType {
  countryCode: string;
  name: string;
  phoneCode: string;
  countryCodeAlt: string;
}
export const targetCurrency: CountryType[] = [
  {
    countryCode: "UG",
    name: "Uganda",
    phoneCode: "256",
    countryCodeAlt: "UGA",
  },
  {
    countryCode: "KE",
    name: "Kenya",
    phoneCode: "254",
    countryCodeAlt: "KEN",
  },
  {
    countryCode: "CM",
    name: "Cameroon",
    phoneCode: "237",
    countryCodeAlt: "CMR",
  },
  {
    countryCode: "TZ",
    name: "Tanzania",
    phoneCode: "255",
    countryCodeAlt: "TZA",
  },
];

export const baseCurrency: CountryType[] = [
  {
    countryCode: "AT",
    name: "Austria",
    phoneCode: "EUR",
    countryCodeAlt: "AUT",
  },
  {
    countryCode: "BE",
    name: "Belgium",
    phoneCode: "EUR",
    countryCodeAlt: "BEL",
  },
  {
    countryCode: "CA",
    name: "Canada",
    phoneCode: "CAD",
    countryCodeAlt: "CAN",
  },
  {
    countryCode: "HR",
    name: "Croatia",
    phoneCode: "EUR",
    countryCodeAlt: "HRV",
  },
  {
    countryCode: "CY",
    name: "Cyprus",
    phoneCode: "EUR",
    countryCodeAlt: "CYP",
  },
  {
    countryCode: "DK",
    name: "Denmark",
    phoneCode: "DKK",
    countryCodeAlt: "DNK",
  },
  {
    countryCode: "EE",
    name: "Estonia",
    phoneCode: "EUR",
    countryCodeAlt: "EST",
  },
  {
    countryCode: "EU",
    name: "European Union",
    phoneCode: "EUR",
    countryCodeAlt: "EUR",
  },
  {
    countryCode: "FI",
    name: "Finland",
    phoneCode: "EUR",
    countryCodeAlt: "FIN",
  },
  {
    countryCode: "FR",
    name: "France",
    phoneCode: "EUR",
    countryCodeAlt: "FRA",
  },
  {
    countryCode: "DE",
    name: "Germany",
    phoneCode: "EUR",
    countryCodeAlt: "DEU",
  },
  {
    countryCode: "GR",
    name: "Greece",
    phoneCode: "EUR",
    countryCodeAlt: "GRC",
  },
  {
    countryCode: "IE",
    name: "Ireland",
    phoneCode: "EUR",
    countryCodeAlt: "IRL",
  },
  {
    countryCode: "IT",
    name: "Italy",
    phoneCode: "EUR",
    countryCodeAlt: "ITA",
  },
  {
    countryCode: "LV",
    name: "Latvia",
    phoneCode: "EUR",
    countryCodeAlt: "LVA",
  },
  {
    countryCode: "LT",
    name: "Lithuania",
    phoneCode: "EUR",
    countryCodeAlt: "LTU",
  },
  {
    countryCode: "LU",
    name: "Luxembourg",
    phoneCode: "EUR",
    countryCodeAlt: "LUX",
  },
  {
    countryCode: "MT",
    name: "Malta",
    phoneCode: "EUR",
    countryCodeAlt: "MLT",
  },
  {
    countryCode: "NL",
    name: "Netherlands",
    phoneCode: "EUR",
    countryCodeAlt: "NLD",
  },
  {
    countryCode: "NO",
    name: "Norway",
    phoneCode: "NOK",
    countryCodeAlt: "NOR",
  },
  {
    countryCode: "PT",
    name: "Portugal",
    phoneCode: "EUR",
    countryCodeAlt: "PRT",
  },
  {
    countryCode: "SK",
    name: "Slovakia",
    phoneCode: "EUR",
    countryCodeAlt: "SVK",
  },
  {
    countryCode: "SI",
    name: "Slovenia",
    phoneCode: "EUR",
    countryCodeAlt: "SVN",
  },
  {
    countryCode: "ES",
    name: "Spain",
    phoneCode: "EUR",
    countryCodeAlt: "ESP",
  },
  {
    countryCode: "SE",
    name: "Sweden",
    phoneCode: "SEK",
    countryCodeAlt: "SWE",
  },
  {
    countryCode: "CH",
    name: "Switzerland",
    phoneCode: "CHF",
    countryCodeAlt: "CHE",
  },
  {
    countryCode: "GB",
    name: "United Kingdom",
    phoneCode: "GBP",
    countryCodeAlt: "GBR",
  },
];

export default constants;

//TODO: THIS hooks helps simplyfy the server app value
const PayinCountries = [
  {
    name: "Belgium",
    countryCode: "BE",
    dialCode: "+32",
    currency: "EUR",
  },
  {
    name: "Canada",
    countryCode: "CA",
    dialCode: "+1",
    currency: "CAD",
  },
  {
    name: "Denmark",
    countryCode: "DK",
    dialCode: "+45",
    currency: "DKK",
  },
  {
    name: "Finland",
    countryCode: "FI",
    dialCode: "+358",
    currency: "EUR",
  },
  {
    name: "France",
    countryCode: "FR",
    dialCode: "+33",
    currency: "EUR",
  },
  {
    name: "Germany",
    countryCode: "DE",
    dialCode: "+49",
    currency: "EUR",
  },
  {
    name: "Ireland",
    countryCode: "IE",
    dialCode: "+353",
    currency: "EUR",
  },
  {
    name: "Italy",
    countryCode: "IT",
    dialCode: "+39",
    currency: "EUR",
  },
  {
    name: "Netherlands",
    countryCode: "NL",
    dialCode: "+31",
    currency: "EUR",
  },
  {
    name: "Norway",
    countryCode: "NO",
    dialCode: "+47",
    currency: "NOK",
  },
  {
    name: "Spain",
    countryCode: "ES",
    dialCode: "+34",
    currency: "EUR",
  },
  {
    name: "Sweden",
    countryCode: "SE",
    dialCode: "+46",
    currency: "SEK",
  },
  {
    name: "Switzerland",
    countryCode: "CH",
    dialCode: "+41",
    currency: "CHF",
  },
  {
    name: "United Kingdom",
    countryCode: "GB",
    dialCode: "+44",
    currency: "GBP",
  },
  {
    name: "Andorra",
    countryCode: "AD",
    dialCode: "+376",
    currency: "EUR",
  },
] as const;

export const PayoutCountries = [
  //  LOG  {"Cameroon": "XAF", "Kenya": "KES", "Tanzania": "TZS", "Uganda": "UGX"}
  {
    name: "Cameroon",
    countryCode: "CM",
    dialCode: "+237",
    currency: "XAF",
  },
  {
    name: "Kenya",
    countryCode: "KE",
    dialCode: "+254",
    currency: "KES",
  },
  {
    name: "Tanzania",
    countryCode: "TZ",
    dialCode: "+255",
    currency: "TZS",
  },
  {
    name: "Uganda",
    countryCode: "UG",
    dialCode: "+256",
    currency: "UGX",
  },
];

export const userAppValues = () => {
  return {
    PayinCountries,
    PayoutCountries,
  };
};
