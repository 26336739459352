import React from 'react'

export const ReceiptBottom = () => {
    return (
        <div className="receiptBottomComponent">
            <p>
                SB Capital is a private company limited by shares with subscribed capital of XAF 50,000,000, registered in
                Cameroon with RC/DLN/2021/B/634 and Unique Tax Number M022118493160X. SB Capital is authorised by MINFI
                Cameroon, approved and regulated by BEAC as a Bureau de Change.
            </p>
        </div>
    )
}
