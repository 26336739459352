import styled from "styled-components";

export default styled.div`
  width: 100%;
  height: auto;
  @media (max-width: 500px) {
    margin-top: 40px;
  }
  .user_nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;

    p {
      font-weight: 400;
      font-size: clamp(26px, 4vw, 30px);
      line-height: auto;
      color: #424242;
    }
  }
  .user_table_container {
    //margin-top: 50px;
    background: #ffffff;
    border: 1px solid rgba(144, 152, 178, 0.3);
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    height: auto;
    .section_1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 32px 24px;
      gap: 10px;
      flex-wrap: wrap;
      @media (max-width: 768px) {
        padding: 14px;
      }
      .show_add_user_btn_sm {
        display: none;
        @media (max-width: 768px) {
          display: flex;
        }
      }
      p {
        font-weight: 400;
        font-size: clamp(18px, 4vw, 24px);
        line-height: 39px;
        color: #424242;

        flex-shrink: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .right_content_space_btw {
        display: flex;
        gap: 16px;

        @media (max-width: 768px) {
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
        }

        .searchbar_section {
          display: flex;
          align-items: center;
          gap: 24px;
        }
        .buttons {
          display: flex;
          align-items: center;

          gap: 16px;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
    .section_2 {
      padding: 0px 24px;
    }
  }
`;
