import { Button, Descriptions, Divider, Image, Modal } from "antd";
import { downloadPdf, replaceUnderscores } from "src/util/util";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";

interface ListVerificationDataProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  data?: any;
}

const ListVerificationData = ({
  open,
  setOpen,
  data,
}: ListVerificationDataProps) => {
  function formatLabel(label: string) {
    const lowercaseLabel = label.toLowerCase();
    return replaceUnderscores(lowercaseLabel);
  }
  const columnSize = { xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 };

  const generatePDF = () => {
    const tableRows: any = [];
    const header = ["Field Name", "Value"];
    const type = "Submitted";
    // Iterate over the data and add rows to the table
    Object.keys(data).forEach((item, index) => {
      const label = formatLabel(item);
      const value =
        data[item] && data[item]?.indexOf("data:image/jpeg") !== -1
          ? {
              content: {
                image: data[item]?.replace(
                  "data:image/jpeg;",
                  "data:image/jpeg;charset=utf-8;base64,"
                ),
                width: 40,
                height: 40,
              },
            }
          : data[item] || "-";

      tableRows.push([label, value]);
    });
    downloadPdf(tableRows, header, type);
  };

  return (
    <Modal
      open={open}
      title="Submitted data"
      onCancel={() => {
        setOpen(false);
      }}
      footer={null}
      width={900}
      style={{ top: "15%" }}
    >
      <Divider style={{ marginTop: "12px" }} />
      <div style={{ marginTop: "32px" }}>
        <Descriptions column={columnSize} layout="horizontal" bordered>
          {Object.keys(data).map((item, index) => (
            <Descriptions.Item
              key={"submitted_data_" + index}
              label={formatLabel(item)}
            >
              {data[item] && data[item]?.indexOf("data:image/jpeg") !== -1 ? (
                <Image
                  width={100}
                  height={100}
                  style={{
                    objectFit: "cover",
                    borderRadius: 10,
                  }}
                  src={data[item]?.replace(
                    "data:image/jpeg;",
                    "data:image/jpeg;charset=utf-8;base64,"
                  )}
                  alt="submitted"
                />
              ) : (
                data[item] || "-"
              )}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 16,
        }}
      >
        <Button type="primary" onClick={generatePDF}>
          Download PDF format
        </Button>
      </div>
    </Modal>
  );
};

export default ListVerificationData;
