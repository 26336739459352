import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import Table from "../../parts/table/Table";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Descriptions, Empty, Space, Tag } from "antd";
import { useSelector } from "react-redux";
import {
  downloadPdf,
  getParsedVerificationData,
  getParsedVerificationSubmittedData,
  replaceUnderscores,
} from "src/util/util";
import { getVerificationData } from "src/redux/actions/actions";

type UserInfo = {
  Name: string;
  Country: string;
  Transaction_Record_ID: string;
  Transaction_Date: string;
  Verification_Rule: string;
  Account_Name: string;
  Username: string;
  Transaction_Type: string;
  Watchlist: string;
};

const UserVerificationData = () => {
  const countries = useSelector((state: any) => state.appValues.countries);
  const [verification, setVerification]: any = useState({});

  const parsedData = getParsedVerificationData(verification);
  const submittedData = getParsedVerificationSubmittedData(verification);
  const appendedData: any = parseVerificationData(verification);
  const verificationData: any = parsedData.Record?.DatasourceResults;

  const params = useParams<any>();

  useEffect(() => {
    getVerificationData(params.id, setVerification);
  }, []);

  function parseVerificationData(verification?: any) {
    try {
      const rawResponse = verification?.meta?.rawResponse;
      if (rawResponse) {
        const parsedRawResponse = JSON.parse(rawResponse);
        const body = JSON.parse(parsedRawResponse?.body);
        const data = body?.Record?.DatasourceResults;

        if (data) {
          for (const d of data) {
            if (d.AppendedFields?.length > 0) {
              return d.AppendedFields;
            }
          }
        }
      } else {
        console.error("Raw response is not available.");
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }

  function getUserInfo(): UserInfo {
    return {
      Name: `${submittedData?.firstName} ${submittedData?.lastName}`,
      Country: `${countries[submittedData?.location_country]}`,
      Transaction_Record_ID: parsedData?.Record?.TransactionRecordID,
      Transaction_Date: parsedData?.UploadedDt,
      Verification_Rule: `${parsedData?.Record?.Rule.RuleName} ${parsedData?.Record?.Rule.Note}`,
      Account_Name: "Sukate & Bezeboh Ltd | Sub-Account",
      Username: "",
      Transaction_Type: "",
      Watchlist: "",
    };
  }
  const userInfo = getUserInfo();

  function getEquivalentTag(status: string) {
    if (status === "match") {
      return {
        status: "match",
        color: "green",
        icon: <CheckCircleOutlined />,
      };
    } else if (status === "nomatch") {
      return {
        status: "nomatch",
        color: "red",
        icon: <CloseCircleOutlined />,
      };
    } else {
      return {
        status: status,
        color: "",
        icon: <MinusCircleOutlined />,
      };
    }
  }

  const columnSIze = { xxl: 3, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 };

  const generatePdf = () => {
    const tableRows1: any = [];
    const tableRows2: any = [];
    const header = ["Field Name", "Value"];
    const type = "Verification";

    // card 1 data
    Object.keys(userInfo)?.map((item, index) => {
      const label = replaceUnderscores(item);
      const value = userInfo[item as keyof typeof userInfo] || "-";
      tableRows1.push([label, value]);
    });

    // card  data 2
    parsedData.Record?.DatasourceResults?.map((item: any) => {
      const title = item?.DatasourceName;
      tableRows2.push([title, "==================================>"]);
      item.DatasourceFields.map((field: any, index: string) => {
        const label = field.FieldName;
        const value = field.Status;
        tableRows2.push([label, value]);
      });
    });

    const globalTale = [tableRows1, tableRows2];
    downloadPdf(globalTale, header, type);
  };

  return (
    <div>
      <Space direction="vertical" size="large">
        {/* card 1 */}
        <Card
          style={{ width: "82vw" }}
          title={parsedData?.ProductName || "Verification Data Source"}
          extra={
            <Button type="primary" onClick={generatePdf}>
              Download PDF formart
            </Button>
          }
        >
          <Descriptions
            column={columnSIze}
            layout="horizontal"
            bordered
            style={{ width: "100%" }}
          >
            {Object.keys(userInfo)?.map((data, index) => (
              <Descriptions.Item
                label={replaceUnderscores(data)}
                key={"user_verification_" + index}
              >
                {userInfo[data as keyof typeof userInfo] || "-"}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
        {/* card 2 */}
        <Card title="Match Indicator" style={{ width: "82vw" }}>
          {parsedData.Record?.DatasourceResults ? (
            parsedData.Record?.DatasourceResults?.map(
              (item: any, index: number) => (
                <Descriptions
                  bordered
                  key={item?.DatasourceName + index}
                  title={item?.DatasourceName || "Verification title"}
                  column={columnSIze}
                  layout="horizontal"
                  style={{ marginTop: 24, width: "100%" }}
                >
                  {item.DatasourceFields.map((feild: any, index: number) => (
                    <Descriptions.Item
                      label={feild.FieldName}
                      key={feild.FieldName + index}
                    >
                      <Tag
                        icon={getEquivalentTag(feild.Status).icon}
                        color={getEquivalentTag(feild.Status).color}
                      >
                        {feild.Status}
                      </Tag>
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              )
            )
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Card>
        {/* card 3 */}
        <Card style={{ width: "82vw" }} title="Appended Information">
          <Descriptions column={columnSIze} bordered>
            {appendedData ? (
              appendedData?.map((data: any, index: number) => {
                if (data?.FieldName === "Score") {
                  return null; // Skip rendering for FieldName === "Score"
                }

                return (
                  <Descriptions.Item
                    label={data?.FieldName}
                    key={data?.FieldName + index}
                  >
                    {data?.Data}
                  </Descriptions.Item>
                );
              })
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Descriptions>
        </Card>
        {/* card 4 */}
        <Card style={{ width: "82vw" }} title="Verification Details">
          <Descriptions column={columnSIze} bordered>
            {verificationData ? (
              verificationData?.map((item: any, index: number) => (
                <Descriptions.Item
                  label={item?.DatasourceName}
                  key={`${item?.DatasourceName}_verification_${index}`}
                >
                  {item.DatasourceFields.length > 0 ? "Yes" : "No"}
                </Descriptions.Item>
              ))
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Descriptions>
        </Card>
        {/* card 5 */}
        <Card style={{ width: "82vw" }} title="Score Report">
          <Descriptions column={columnSIze} bordered>
            {appendedData?.map((data: any, index: number) => {
              if (data?.FieldName === "Score") {
                return (
                  <Descriptions.Item
                    label={data?.FieldName}
                    key={data?.FieldName + index}
                  >
                    {data?.Data}
                  </Descriptions.Item>
                );
              }
              return null; // Add this to handle other cases when FieldName !== "Score"
            })}
          </Descriptions>
        </Card>
      </Space>
    </div>
  );
};

export default UserVerificationData;
