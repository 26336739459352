import styled from "styled-components";
import CompetitorsRate from "./components/CompetitorsRate";
import CurrencySpread from "./components/CurrencySpread";
import ExchangeRate from "./components/ExchangeRate";

const Rates = () => {
  return (
    <RatesWrapper>
      <Flex>
        <ExchangeRate />
        <CompetitorsRate />
      </Flex>
      <CurrencySpread />
    </RatesWrapper>
  );
};

export default Rates;

const RatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 32px 0px;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
`;
