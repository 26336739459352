import styled from "styled-components";

export default styled.div`
  width: 100%;
  height: 100vh;
  flex-direction: row;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      justify-content: center;
    }
    .img {
      @media (max-width: 768px) {
        display: none;
      }
      height: 100vh;
      width: 50vw;
      background: url("./assets/bg/signin.png");
      background-size: cover;
      background-repeat: no-repeat;
      image-rendering: crisp-edges;
    }
    .form_container {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        width: auto;
        height: 100vh;
      }
      .form {
        display: flex;
        flex-direction: column;
        //align-items: center;
        gap: 42px;
        .text {
          text-align: center;

          span {
            color: gray;
          }
        }
        .login_form {
          width: 400px;
          @media (max-width: 500px) {
            width: auto;
          }
          .login-form-forgot {
            color: gray;
            cursor: pointer;
            &:hover {
              color: #007b5d;
            }
          }
        }
      }
    }
  }
`;
