import { useQuery } from "react-query";
import store from "src/redux/store";
import constants from "src/util/constants";
import endpoints from "src/util/endpoints";
import { getRequest } from "src/util/http";
import { convertDateString, parseEndpointParameters } from "src/util/util";

export const fetchTimelinesStatus = (
  transferDetail: any,
  transferStatus: string,
  setTimelineState: Function
) => {
  let currentState = 0;
  let status = "wait";
  let date = convertDateString(transferDetail?.dateCreated);

  switch (transferStatus) {
    case constants.TRANSFER_STATUS_PENDING:
      status = "finish";
      break;

    case constants.TRANSFER_STATUS_CANCELLED:
      status = "error";
      currentState = 1;
      break;

    case constants.TRANSFER_STATUS_EXPIRED:
      status = "error";
      currentState = 1;
      break;

    case constants.TRANSFER_FLAG_FRAUD_ALERT:
      status = "error";
      currentState = 2;
      break;

    case constants.TRANSFER_STATUS_COMPLETE:
      status = "finish";
      currentState = 3;
      break;

    default:
      status = "process";
      currentState = 2;
      break;
  }

  if (transferDetail?.status === constants.TRANSFER_STATUS_PAYMENT_FRAUD) {
    return setTimelineState({
      currentState: currentState,
      status: status,
      date: date,
      state:
        transferDetail?.status + ` (${transferDetail?.meta?.fraud_reason || "N/A"})`,
    });
  } else if (
    transferDetail?.status === constants.TRANSFER_STATUS_PAYMENT_DECLINED
  ) {
    return setTimelineState({
      currentState: currentState,
      status: status,
      date: date,
      state:
        transferDetail?.status + ` (${transferDetail?.meta?.declinedReason || "N/A"})`,
    });
  } else {
    return setTimelineState({
      currentState: currentState,
      status: status,
      date: date,
      state: transferDetail?.status,
    });
  }
};

export const useTransferDetailsData = (transferId: string) => {
  const user = store.getState().auth.user;
  const custumEndpoint = parseEndpointParameters(
    endpoints.GET_TRANSFER,
    user.id,
    transferId
  );
  return useQuery(custumEndpoint, () =>
    getRequest(
      parseEndpointParameters(custumEndpoint),
      "Failed to fetch transaction details"
    )
  );
};

export const useRecipientsData = (userId: string, enabled: boolean) => {
  const custumEndpoint = parseEndpointParameters(endpoints.RECIPIENTS, userId);
  return useQuery(
    custumEndpoint,
    () =>
      getRequest(
        parseEndpointParameters(custumEndpoint),
        "Failed to fetch Recipients details"
      ),
    {
      enabled: enabled,
    }
  );
};

export const getUserRecipient = (
  recipientId: string | number,
  recipients: any[]
) => {
  return recipients?.find((recipient: any) => recipient.id === recipientId);
};
