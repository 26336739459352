import styled from "styled-components";

export const MaintenanceStyle = styled.div`
  margin-top: 40px;
  ._site_maintenace {
    display: flex;
    flex-direction: column;
    gap: 60px;
    ._site_maintenace_data {
      width: 100%;
      ._maintenace_mode,
      ._redirect_customer {
        display: flex;
        align-items: flex-start;
        gap: 50px;

        ._info {
          width: 50%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          @media only screen and (max-width: 500px) {
            width: 100%;
          }
          p {
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            //color: #424242;
            margin: 0;
          }
          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #707070;

            a {
              color: #007b5d;
              text-decoration: underline;
            }
          }
        }
      }
      ._redirect_customer {
        margin-top: 60px;
        ._customise_redirect_msg {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .input {
            width: 450px;
            height: 54px;
            padding: 20px;
            @media only screen and (max-width: 500px) {
              width: 100%;
            }
          }

          p {
            font-weight: 400;
            color: #424242;
            margin: 0;
          }
          span {
            color: #ffffff;
          }
        }
      }
    }
    ._carousel_text {
      ._info {
        margin-top: 60px;
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        @media only screen and (max-width: 500px) {
          width: 100%;
        }

        .input {
          width: 450px;
          height: 54px;
          padding: 20px;
          @media only screen and (max-width: 500px) {
            width: 100%;
          }
        }

        p {
          font-weight: 500;
          font-size: 20px;
          line-height: 150%;
          margin: 0;
          color: #424242;
        }
        p:last-child {
          font-weight: 400;
          color: #424242;
        }
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #707070;
        }
        span:last-child {
          color: #ffffff;
        }
      }
    }
  }
`;
