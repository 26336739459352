import { DeleteOutlined, MailOutlined } from "@ant-design/icons";
import { Avatar, Button, Descriptions, Tag } from "antd";
import { FaUserTimes } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { queryClient } from "src/App";
import {
  VerifyUserByEmail,
  deleteUser,
  sendActivationMail,
} from "src/redux/actions/actions";
import { CustomError, CustomLoader } from "src/util/Reuseables";
import { getAcctStatus } from "src/util/util";
import { formatUserDOB, getFirstLetter } from "src/util/utility";
import styled, { css } from "styled-components";
import UserDocumentsAndVerifications from "./components/UserDocsAndVerifications";
import { useUserData } from "./helper/useRQuserData";
import FlagAndAddSoftblock from "./components/FlagAndAddSoftblock";
import CustomerLogs from "./components/CustomerLogs";

const contentPadding = css`
  padding: 32px 4%;
`;

const contentMargin = css`
  margin: 0px 4%;
`;

const CustomerDetails = () => {
  const { userId } = useParams<any>();
  const userEndpoint = `/user/${userId}`;

  const {
    data: userDetail,
    isError,
    isLoading,
    error,
  } = useUserData(userEndpoint);
  const err: any = error;

  if (isLoading) {
    return (
      <CustomerDetailsWrapper $center>
        <CustomLoader prompt="Loading user data..." />
      </CustomerDetailsWrapper>
    );
  }

  if (isError) {
    return (
      <CustomerDetailsWrapper $center>
        <CustomError message={err?.message} />
      </CustomerDetailsWrapper>
    );
  }

  return (
    <CustomerDetailsWrapper>
      <IntroSection userData={userDetail} queryKey={userEndpoint} />
      <UserPersonalDetails
        queryKey={userEndpoint}
        userPersonalInfo={userDetail}
      />

      <FlagAndAddSoftblock userData={userDetail} />

      <UserDocumentsAndVerifications
        userData={userDetail}
        userId={userDetail?.id}
      />

      {/* --end-- */}
      <CustomerLogs userId={userDetail?.id} />
    </CustomerDetailsWrapper>
  );
};

export default CustomerDetails;

const IntroSection = ({
  queryKey,
  userData,
}: {
  queryKey: string;
  userData: any;
}) => {
  const fullName = `${userData?.profile.firstName} ${userData?.profile.lastName}`;
  const userCode = `${userData?.referral?.code}`;
  const isMailVerified = userData && Boolean(Number(userData?.meta?.confirmed));

  const verifyUserEmail = () => {
    VerifyUserByEmail(userData?.id, userData?.meta, userData?.profile, () => {
      queryClient.invalidateQueries(queryKey);
    });
  };

  return (
    <IntroSectionWrapper>
      <p>
        Users / Customer details - {fullName} ({userCode})
      </p>
      <div className="_info">
        <h1>Customer details</h1>
        <span>View customers information and transaction history</span>
      </div>

      {!isMailVerified && (
        <VerifyEmailWrapper $slideIn={true}>
          <div className="_left_content">
            <div className="_icon">
              <FaUserTimes size={24} color="#FFFF" />
            </div>
            <div className="_content">
              <p>Verify account</p>
              <span>This customer account is yet to be verified</span>
            </div>
          </div>
          <Button onClick={verifyUserEmail} type="primary">
            Verify customer
          </Button>
        </VerifyEmailWrapper>
      )}
    </IntroSectionWrapper>
  );
};

const columnSize = { xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 };
const descriptionItemStyle = {
  marginRight: 24,
  fontWeight: 450,
};

const UserPersonalDetails = ({
  userPersonalInfo,
  queryKey,
}: {
  userPersonalInfo: any;
  queryKey: string;
}) => {
  const userStatus = getAcctStatus(userPersonalInfo?.status);

  return (
    <UserPersonalDetailsWrapper>
      <div className="_descriptions">
        <Descriptions
          title="User information"
          layout="vertical"
          column={columnSize}
          size="default"
        >
          <Descriptions.Item
            contentStyle={descriptionItemStyle}
            label="First name"
          >
            {userPersonalInfo?.profile.firstName || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item
            contentStyle={descriptionItemStyle}
            label="Last name"
          >
            {userPersonalInfo?.profile.lastName || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item contentStyle={descriptionItemStyle} label="DOB">
            {formatUserDOB(
              userPersonalInfo?.profile.day,
              userPersonalInfo?.profile.month,
              userPersonalInfo?.profile.year
            ) ||
              userPersonalInfo?.dob ||
              "N/A"}
          </Descriptions.Item>
          <Descriptions.Item contentStyle={descriptionItemStyle} label="Gender">
            {userPersonalInfo?.profile.gender || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Email" contentStyle={descriptionItemStyle}>
            {userPersonalInfo?.username || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile" contentStyle={descriptionItemStyle}>
            {userPersonalInfo?.profile.phoneCode || ""}{" "}
            {userPersonalInfo?.profile.mobile || "N/A"}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="Address"
          layout="vertical"
          column={columnSize}
          size="default"
        >
          <Descriptions.Item
            label="Country"
            contentStyle={descriptionItemStyle}
          >
            {userPersonalInfo?.profile.city || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="State" contentStyle={descriptionItemStyle}>
            {userPersonalInfo?.profile.streeName || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item
            label="Address 1"
            contentStyle={descriptionItemStyle}
          >
            {userPersonalInfo?.profile.address1 || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item
            label="Address 2"
            contentStyle={descriptionItemStyle}
          >
            {userPersonalInfo?.profile.address1 || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item
            contentStyle={descriptionItemStyle}
            label="City / Town"
          >
            {userPersonalInfo?.profile.city || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item
            contentStyle={descriptionItemStyle}
            label="Postal / Zip code"
          >
            {userPersonalInfo?.profile.zip || "N/A"}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="_footer">
        <div className="_user_status">
          <Avatar size={48} className="_avatar">
            {getFirstLetter(userPersonalInfo?.profile.firstName)}
            {getFirstLetter(userPersonalInfo?.profile.lastName)}
          </Avatar>

          <span>
            {userPersonalInfo?.profile.firstName}{" "}
            {userPersonalInfo?.profile.lastName}
          </span>

          <Tag
            color={userStatus?.color}
            style={{
              padding: "3px 15px",
              color: userStatus?.color,
              textTransform: "capitalize",
            }}
          >
            {userStatus?.value}
          </Tag>
        </div>
        <div className="_cta">
          <Button
            size="large"
            onClick={() =>
              deleteUser(() => {
                queryClient.invalidateQueries(queryKey);
              }, userPersonalInfo)
            }
            icon={<DeleteOutlined />}
            type="text"
          >
            Delete User
          </Button>
          <Button
            size="large"
            icon={<MailOutlined />}
            type="text"
            onClick={() => sendActivationMail(userPersonalInfo?.id)}
          >
            Resend activation mail
          </Button>
        </div>
      </div>
    </UserPersonalDetailsWrapper>
  );
};

const CustomerDetailsWrapper = styled.div<{ $center?: boolean }>`
  ${(props) =>
    props.$center &&
    css`
      align-items: center;
      justify-content: center;
      height: 600px;
    `};

  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  right: 0;
  top: 0;
  margin: 0;
  gap: 32px;
  padding-bottom: 42px;
`;

const IntroSectionWrapper = styled.section`
  box-sizing: border-box;
  background: #0a4436;
  height: 320px;
  width: 100%;
  position: relative;
  color: #fff;
  position: relative;

  ${contentPadding};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ._info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 42px;

    h1 {
      color: #fff;
      margin: 0;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    span {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
`;

const VerifyEmailWrapper = styled.div<{ $slideIn: boolean }>`
  ${(props) =>
    props.$slideIn
      ? css`
          animation: slideIn 0.5s ease-in-out forwards;
        `
      : css`
          animation: slideOut 0.5s ease-in-out forwards;
        `}

  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 4%;
  margin-bottom: -14px;

  width: auto;
  height: 88px;
  flex-shrink: 0;

  color: #212121;
  overflow: hidden;

  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 6.88px 15.02px 0px rgba(0, 0, 0, 0.06);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 36px;

  padding: 16px;

  Button {
    display: inline-flex;
    padding: 16px 24px !important;
    align-items: center;
    gap: 16px;

    border-radius: 8px;

    font-size: 20px;
    font-weight: 500;
    height: 56px;
  }

  ._left_content {
    display: flex;
    align-items: center;
    gap: 10px;

    ._icon {
      background-color: #e24d58;
      width: 43px;
      height: 43px;
      border-radius: 50px;
      box-sizing: border-box;
      flex-shrink: 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }
    ._content {
      p {
        margin: 0;
        color: #212121;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px; /* 131.25% */
      }

      span {
        color: #808080;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  }
`;

const UserPersonalDetailsWrapper = styled.section`
  ${contentMargin};
  border-radius: 8px;
  border: 1px solid rgba(144, 152, 178, 0.3);
  background: #fff;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;

  ._descriptions {
    display: flex;
    justify-content: space-between;
  }

  ._footer {
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid rgba(144, 152, 178, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;

    ._user_status {
      display: flex;
      align-items: center;
      gap: 24px;

      ._avatar {
        background-color: #ffc859;
        span {
          color: #fff;
        }
      }

      span {
        color: #424242;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }

    ._cta {
      display: flex;
      gap: 42px;
    }
  }
`;
