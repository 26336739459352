import {
  CheckCircleTwoTone,
  EditOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  Table,
  theme,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { IoIosMore } from "react-icons/io";
import { queryClient } from "src/App";
import TableSearchBar from "src/components/modules/parts/SearchBar";
import endpoints from "src/util/endpoints";
import styled, { css } from "styled-components";
import {
  CustomError,
  CustomLoader,
} from "../../../../../../../util/Reuseables";
import {
  baseCurrency,
  extractUniquePropertyValues,
  filterSpreadData,
  getSpreadandPercentageSpreadEquivalent,
  handleInputChange,
  targetCurrency,
} from "../../../helpers/helper";
import {
  useExchangeRate,
  useSpreadData,
  useUpdateFixedRate,
  useUpdateSpread,
  useUpdateSpreadDowntime,
} from "../../../helpers/useRQsettingsData";
import { Flex } from "../Rates";
import { TitleStyles } from "./styles";
const { useToken } = theme;

const filterCurrencySpread = (searchValue: string, data: any) => {
  const searchLowerCase = searchValue.trim().toLowerCase();

  const filtered = data?.filter((item: any) => {
    const baseCurrency = item?.base.toLowerCase();
    const targetCurrency = item?.target.toLowerCase();

    return (
      baseCurrency?.includes(searchLowerCase) ||
      targetCurrency?.includes(searchLowerCase)
    );
  });
  return filtered;
};

const CurrencySpread = () => {
  const [newSpreadModal, setNewSpreadModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading, isError, error, refetch } = useSpreadData();
  const [spreadData, setSpreadData] = useState<any>();

  const handleSearchInputChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleClearInput = () => {
    setSearchValue("");
  };

  useEffect(() => {
    setSpreadData(filterCurrencySpread(searchValue, data));
  }, [searchValue, data]);

  return (
    <CurrencySpreadWrapper>
      <Flex>
        <TitleStyles>Currency Spread</TitleStyles>
        <Space size={14}>
          <TableSearchBar
            value={searchValue}
            placeholder="Search"
            onChange={handleSearchInputChange}
            onCancel={handleClearInput}
          />

          <FilterSpreadTable spreads={data} setSpreads={setSpreadData} />

          <ButtonWrapper type="primary" onClick={() => setNewSpreadModal(true)}>
            Add new
          </ButtonWrapper>
        </Space>
      </Flex>

      <AddNewPairModal open={newSpreadModal} setOpen={setNewSpreadModal} />
      <CurrencySpreadTable props={{ spreadData, isLoading, isError, error, refetch }} />
    </CurrencySpreadWrapper>
  );
};

export default CurrencySpread;

const FilterSpreadTable = ({
  spreads,
  setSpreads,
}: {
  spreads: any;
  setSpreads: Function;
}) => {
  const { token } = useToken();
  const [form] = Form.useForm();

  const [isFiltered, setIsFilterd] = useState(false);
  const [baseCurrencies, setBaseCurrencies] = useState<any>();
  const [targetCurrencies, setTargetCurrencies] = useState<any>();

  useEffect(() => {
    if (spreads !== undefined) {
      setBaseCurrencies(extractUniquePropertyValues("base", spreads));
      setTargetCurrencies(extractUniquePropertyValues("target", spreads));
    }
  }, [spreads]);

  const onFinish = (values: any) => {
    const base = values.baseCurrrency;
    const target = values.targetCurrrency;
    setSpreads(filterSpreadData(base, target, spreads));
    setIsFilterd(true);
  };

  const onReset = () => {
    form.resetFields();
    setSpreads(spreads);
    setIsFilterd(false);
  };

  return (
    <Dropdown
      dropdownRender={() => (
        <DropdownContentWrapper $token={token}>
          <DropdownTitle $fontSize={16}>Filter Spread</DropdownTitle>
          <Form
            name="spread_filter"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
            style={{ width: 300 }}
          >
            <Form.Item label="Base Currency" name="baseCurrrency">
              <Select placeholder="base">
                {baseCurrencies.map((base: string) => (
                  <Select.Option key={base} value={base}>
                    {base}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Target Currency" name="targetCurrrency">
              <Select placeholder="target">
                {targetCurrencies.map((target: string) => (
                  <Select.Option key={target} value={target}>
                    {target}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" htmlType="submit">
                  Apply
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </DropdownContentWrapper>
      )}
    >
      <ButtonWrapper type={isFiltered ? "primary" : "default"}>
        <FilterOutlined className="_icon" />
      </ButtonWrapper>
    </Dropdown>
  );
};

interface TableDataType {
  base: string;
  target: string;
  spread: string;
  percentageSpread: string;
  manualOveride: boolean;
  downtimeStatus: boolean;
  action: any;
}

const CurrencySpreadTable = ({ props }: any) => {
  const { spreadData, isLoading, isError, error, refetch } = props || {};
  const err: any = error;
  const [dataSrc, setDatascr] = useState<any>();

  useEffect(() => {
    const dataSource: any = [];
    spreadData?.reverse().map((item: any, index: number) => {
      const data = {
        key: index,
        base: item?.base,
        target: item?.target,
        spread: item,
        percentageSpread: item?.percentageSpread,
        manualOveride: item?.exchangeRateManualOverride,
        downtimeStatus: Boolean(Number(item?.downtimeStatus)),
        action: item,
      };
      dataSource.push(data);
    });
    setDatascr(dataSource);
  }, [spreadData]);

  if (isLoading) {
    return (
      <SpreadTableWrapper>
        <CustomLoader prompt="Loading spread data..." />
      </SpreadTableWrapper>
    );
  }
  if (isError) {
    return (
      <SpreadTableWrapper>
        <CustomError message={err?.message} retryAction={() => refetch()} />
      </SpreadTableWrapper>
    );
  }

  const columns: ColumnsType<TableDataType> = [
    {
      title: "BASE CURRENCY",
      dataIndex: "base",
    },
    {
      title: "TARGET CURRENCY",
      dataIndex: "target",
    },
    {
      title: "SPREAD",
      dataIndex: "spread",
      render: (spread) => (
        <Space size={16}>
          <div style={{ width: 80 }}>{spread?.spread}</div>
          <SpreadEditDropdown activeSpread={spread} />
        </Space>
      ),
    },
    {
      title: "SPREAD %",
      dataIndex: "percentageSpread",
    },
    {
      title: "MANUAL OVERIDE",
      dataIndex: "manualOveride",
      render: (manualOveride: string) => {
        const isManualOveride = Boolean(Number(manualOveride));
        const status = isManualOveride ? "YES" : "NO";
        return <TagWrapper $isValid={isManualOveride}> {status} </TagWrapper>;
      },
    },
    {
      title: "DOWNTIME",
      dataIndex: "downtimeStatus",
      render: (downtimeStatus) => {
        const status = downtimeStatus ? "YES" : "NO";
        return <TagWrapper $isValid={downtimeStatus}> {status} </TagWrapper>;
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (action) => <SpreadActionDropdown activeSpread={action} />,
    },
  ];

  return (
    <SpreadTableWrapper>
      <Table
        dataSource={dataSrc}
        columns={columns}
        pagination={{
          position: ["bottomCenter"],
        }}
        scroll={{ x: 1200 }}
      />
    </SpreadTableWrapper>
  );
};

const SpreadEditDropdown = ({ activeSpread }: { activeSpread: any }) => {
  const { token } = useToken();
  const [form] = Form.useForm();
  const [updated, setUpdated] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleUpdated = () => {
    setUpdated(true);
    setTimeout(() => {
      setUpdated(false);
    }, 3000); // Reset updated after 3 seconds
  };

  const { mutate: updateSpreadMutate, isLoading } = useUpdateSpread(() =>
    toggleUpdated()
  );

  const {
    data: rate,
    isLoading: isRateLoading,
    error,
    isError,
  } = useExchangeRate(activeSpread?.base, activeSpread?.target, open);
  const err: any = error;

  const initialValues = {
    spread: Number(activeSpread?.spread) || 0,
    percentageSpread: Number(activeSpread?.percentageSpread) || 0,
  };

  const onSubmit = (values: any) => {
    const { percentageSpread, spread } = values;

    const updatedValues = {
      ...activeSpread,
      spread,
      percentageSpread,
    };

    updateSpreadMutate(updatedValues);
  };

  const onDropdownOpen = (state: boolean) => {
    setOpen(state);
  };

  return (
    <Dropdown
      onOpenChange={onDropdownOpen}
      dropdownRender={() => (
        <DropdownContentWrapper $token={token}>
          {isRateLoading && (
            <CustomLoader
              prompt={`Loading exhange rate for ${activeSpread?.base}/${activeSpread?.target}...`}
            />
          )}
          {isError && <CustomError message={err?.message} />}
          {!isRateLoading && !isError && (
            <>
              <DropdownTitle $fontSize={16}>Edit spread</DropdownTitle>
              <DropdownSecondaryText $fontSize={14} $isBold>
                {activeSpread?.base}/{activeSpread?.target}
              </DropdownSecondaryText>
              <Form
                form={form}
                name="edit spread"
                layout="vertical"
                initialValues={initialValues}
                onFinish={onSubmit}
              >
                <Form.Item name="spread">
                  <Input
                    type="number"
                    placeholder="Spread"
                    suffix="Spread"
                    onChange={(e) =>
                      handleInputChange("spread", e.target.value, form, rate)
                    }
                  />
                </Form.Item>
                <Form.Item name="percentageSpread">
                  <Input
                    type="number"
                    placeholder="Spread %"
                    suffix="%Spread"
                    onChange={(e) =>
                      handleInputChange(
                        "percentageSpread",
                        e.target.value,
                        form,
                        rate
                      )
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button
                      loading={isLoading}
                      type="primary"
                      htmlType="submit"
                    >
                      Update
                    </Button>
                    {updated && <CheckCircleTwoTone twoToneColor="#52c41a" />}
                  </Space>
                </Form.Item>
              </Form>
            </>
          )}
        </DropdownContentWrapper>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Button icon={<EditOutlined />} />
      </a>
    </Dropdown>
  );
};

const SpreadActionDropdown = ({ activeSpread }: { activeSpread: any }) => {
  const { token } = useToken();
  const { downtimeStatus, exchangeRateManualOverride } = activeSpread || {};

  const isDowntimeEnabled = Boolean(Number(downtimeStatus));
  const isManualOverriden = Boolean(Number(exchangeRateManualOverride));

  const { mutate: updateSpreadMutate, isLoading } = useUpdateSpreadDowntime(
    () => {}
  );

  const { mutate: updateFixedRateMutate, isLoading: isUpdatingFixedRate } =
    useUpdateFixedRate(() => {});

  const onDowntimeSwitchChange = (checked: boolean) => {
    const upadatedValues = {
      baseCurrency: activeSpread?.base,
      targetCurrency: activeSpread?.target,
      status: checked,
    };
    updateSpreadMutate(upadatedValues);
  };

  const onManualOverrideSwitchChange = (checked: boolean) => {
    const upadatedValues = {
      base: activeSpread?.base,
      target: activeSpread?.target,
      rate: activeSpread?.fixedRate,
      isActive: checked,
    };
    updateFixedRateMutate(upadatedValues);
  };

  return (
    <Dropdown
      dropdownRender={() => (
        <DropdownContentWrapper $token={token}>
          <Space direction="vertical" size={16}>
            <DropdownTitle $fontSize={20}>
              Take action on currency pair
            </DropdownTitle>
            <DropdownSecondaryText $fontSize={14} $isBold>
              {activeSpread?.base}/{activeSpread?.target}
            </DropdownSecondaryText>
          </Space>
          <DropdownActionWrapper>
            <Space size={8} direction="vertical">
              <DropdownTitle $fontSize={16}>
                Manually override exchange rate
              </DropdownTitle>
              <DropdownSecondaryText $fontSize={13}>
                You can only turn off manual override here. To turn it, you need
                to go “Exchange Rate” to manually override exchange rate.
              </DropdownSecondaryText>
            </Space>
            <Switch
              disabled={!isManualOverriden}
              checked={isManualOverriden}
              onChange={onManualOverrideSwitchChange}
              loading={isUpdatingFixedRate}
            />
          </DropdownActionWrapper>

          <DropdownActionWrapper>
            <Space size={8} direction="vertical">
              <DropdownTitle $fontSize={16}>Activate downtime</DropdownTitle>
              <DropdownSecondaryText $fontSize={13}>
                Activate downtime for currency pair
              </DropdownSecondaryText>
            </Space>
            <Switch
              checked={isDowntimeEnabled}
              onChange={onDowntimeSwitchChange}
              loading={isLoading}
            />
          </DropdownActionWrapper>
        </DropdownContentWrapper>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <IoIosMore size={24} color="#8b8a8a" />
      </a>
    </Dropdown>
  );
};

const AddNewPairModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  const [form] = Form.useForm();
  const [baseCurrencyValue, setBaseCurrencyValue] = useState("GBP");
  const [targetCurrencyValue, setTargetCurrencyValue] = useState("XAF");
  const [isPairExist, setIsPairExist] = useState(false);

  const {
    data: rate,
    isLoading: isRateLoading,
    isFetching,
    error,
    isError,
  } = useExchangeRate(baseCurrencyValue, targetCurrencyValue, open);

  //get equivalent spread and percentageSpread values
  useEffect(() => {
    if (rate !== undefined)
      return getSpreadandPercentageSpreadEquivalent(form, rate);
  }, [rate]);

  //check if pair exist in previous spread
  useEffect(() => {
    const prevSpreadData: any = queryClient.getQueryData(
      endpoints.EXCHANGE_RATE_SPREADS
    );

    if (prevSpreadData !== undefined) {
      const isMatchedFound = Object?.keys(prevSpreadData)?.some((id) => {
        const spreadObject = prevSpreadData[id];
        return (
          spreadObject.base === baseCurrencyValue &&
          spreadObject.target === targetCurrencyValue
        );
      });

      setIsPairExist(isMatchedFound);
    }
  }, [baseCurrencyValue, targetCurrencyValue, open]);

  const err: any = error;

  const { mutate: saveSpread, isLoading } = useUpdateSpread(() =>
    setOpen(false)
  );

  const onSubmit = (values: any) => {
    saveSpread(values);
  };

  const onSelectChange = () => {
    setBaseCurrencyValue(form.getFieldValue("base"));
    setTargetCurrencyValue(form.getFieldValue("target"));
  };

  return (
    <Modal
      title="Add New Spread "
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Divider style={{ marginTop: 10 }} />
      <Form form={form} name="New spread" layout="vertical" onFinish={onSubmit}>
        <Form.Item
          name="base"
          label="Base"
          initialValue={baseCurrencyValue}
          rules={[
            {
              required: true,
              message: "Please select base currency!",
            },
          ]}
        >
          <Select
            size="large"
            defaultValue={baseCurrencyValue}
            onChange={onSelectChange}
          >
            {baseCurrency.map((currency, index) => (
              <Select.Option
                value={currency.value}
                key={currency.value + index}
              >
                {currency.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="target"
          label="Target"
          initialValue={targetCurrencyValue}
          rules={[
            {
              required: true,
              message: "Please select target currency!",
            },
          ]}
        >
          <Select
            size="large"
            defaultValue={targetCurrencyValue}
            onChange={onSelectChange}
          >
            {targetCurrency.map((currency, index) => (
              <Select.Option
                value={currency.value}
                key={currency.value + index}
              >
                {currency.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {isPairExist && (
          <>
            <Alert
              message={
                <span>
                  Please note that the spread data for{" "}
                  <b>
                    {baseCurrencyValue}/{targetCurrencyValue}
                  </b>{" "}
                  already exists. Proceeding with this action will directly
                  update the existing spread.
                </span>
              }
              type="warning"
            />
            <br />
          </>
        )}

        {(isFetching || isRateLoading) && (
          <CustomLoader
            prompt={`Calculating equivalent spread and spread percentage...`}
          />
        )}
        {isError && <CustomError message={err?.message} />}

        {!isFetching && !isError && !isRateLoading && (
          <>
            <Form.Item
              name="spread"
              label="Spread"
              initialValue={0.0}
              rules={[
                {
                  required: true,
                  message: "Please enter spread value!",
                },
              ]}
            >
              <Input
                size="large"
                type="number"
                onChange={(e) =>
                  handleInputChange("spread", e.target.value, form, rate)
                }
              />
            </Form.Item>
            <Form.Item
              name="percentageSpread"
              label="Spread %"
              initialValue={0.0}
              rules={[
                {
                  required: true,
                  message: "Please enter spread percentage value!",
                },
              ]}
            >
              <Input
                size="large"
                type="number"
                onChange={(e) =>
                  handleInputChange(
                    "percentageSpread",
                    e.target.value,
                    form,
                    rate
                  )
                }
              />
            </Form.Item>
          </>
        )}

        <Divider style={{ marginBottom: 10 }} />
        <Form.Item>
          <Space>
            <Button
              size="large"
              loading={isLoading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const CurrencySpreadWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(222, 222, 225, 1);
  border-radius: 12px;
  height: auto;
  padding: 24px;
  overflow: hidden;
`;

const ButtonWrapper = styled(Button)`
  height: 44px;
  font-size: 18px;
  font-weight: 500;
  padding: 0px 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  ._icon {
    font-size: 24px;
  }
`;

const SpreadTableWrapper = styled.div`
  border: 1px solid rgba(144, 152, 178, 0.3);
  border-radius: 8px;
  overflow: hidden;
`;

const TagWrapper = styled.div<{ $isValid: boolean }>`
  padding: 8px;
  border-radius: 4px;
  width: 45px;
  font-size: 14px;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${(props) =>
    props.$isValid
      ? css`
          background-color: rgba(229, 241, 238, 1);
          color: rgba(0, 123, 93, 1);
        `
      : css`
          background-color: rgba(237, 237, 237, 1);
          color: rgba(66, 66, 66, 1);
        `}
`;

const DropdownContentWrapper = styled.div<{ $token: any }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: ${(props) => props.$token.colorBgElevated};
  border-radius: 4px;
  box-shadow: ${(props) => props.$token.boxShadowSecondary};
  padding: 20px;
  border: 1px solid rgba(73, 79, 94, 0.2);
  max-width: 450px;
`;

const DropdownTitle = styled.p<{ $fontSize: number }>`
  font-weight: 500;
  color: rgba(66, 66, 66, 1);
  margin: 0;
  font-size: ${(props) => props.$fontSize + "px"};
`;

const DropdownSecondaryText = styled.span<{
  $fontSize: number;
  $isBold?: boolean;
}>`
  font-size: ${(props) => props.$fontSize + "px"};
  font-weight: ${(props) => (props.$isBold ? "500" : "400")};
  color: rgba(66, 66, 66, 1);
`;

const DropdownActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
`;
