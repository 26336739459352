import React from 'react'

import styled from 'styled-components'
import { getImage } from '../../../util/utility'

const Container = styled.div`
    .tsearch-container {
        background: #F5F7F7 0% 0% no-repeat padding-box;
        border: 1px solid #B8B8B8;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 0fr 1fr;
        max-width: 432px;
        img{
            width: 25px;
            height: 25px;
            margin: 6px 30px 8px 20px;
        }
        input {
            background: transparent;
            border: none;
            height: 100%;
            font: normal normal normal 20px/24px Montserrat;
            letter-spacing: 0px;
            color: #424242;
            outline: none;
        }
    }
`

const TSearch = ({placeholder = "Search", setSearchValue}: any) => {


    return (
        <Container>
            <div className="tsearch-container">
                <img src={getImage('search2.svg', 'svgs')} alt="search"/>
                <input onChange={(e) => setSearchValue(e.target.value)} type="text" placeholder={placeholder} />
            </div>
        </Container>
    )
}

export default TSearch
