import { IAction } from ".";
import { EXCHANGES, EXCHANGE_SPREADS, PREORDERS } from "../actionTypes";

const initialPreordersState: {} = {
    preorders: []
}

export const preorders = (state: any = initialPreordersState, {type, payload}: IAction) => {
    switch (type) {
        case PREORDERS: {
          return {
              ...payload
          }
        }
        default:
          return state;
      }
}

const initialExchangeValues = {
  exchanges: [],
  exchangeRateSpreads: []
}

export const exchange = (state: any = initialExchangeValues, {type, payload}: IAction) => {
    switch (type) {
        case EXCHANGES: {
          return { ...state, exchanges: [...payload] }
        }
        case EXCHANGE_SPREADS: {
          return { ...state, exchangeRateSpreads: [...payload] }
        }
        default:
          return state;
    }
  }