import { Button, Divider, Form, Input, Modal, Select } from "antd";
import React from "react";
import { updateReferralConfigs } from "src/redux/actions/actions";

interface ReferralConfigFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  data?: any;
}

const { Option } = Select;

export default function ReferralConfigForm({
  open,
  setOpen,
  data,
}: ReferralConfigFormProps) {
  const [form] = Form.useForm();
  const [submittable, setSubmittable] = React.useState(false);

  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  function handleCancel() {
    setOpen(false);
  }
  function handleOk() {
    const convertedValues = {
      ...values, // Copy all properties from the input object
      referralActivationAmount: parseInt(values.referralActivationAmount, 10),
      referredUserDiscountValue: parseInt(values.referredUserDiscountValue, 10),
      referrerDiscountValue: parseInt(values.referrerDiscountValue, 10),
    };

    updateReferralConfigs(convertedValues);
    setOpen(false);
  }
  const confirmInput = () => {};
  return (
    <Modal
      open={open}
      title="Referral configuration"
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      //confirmLoading={true}
      footer={[
        <Button key="cancel" size="large" onClick={handleCancel}>
          cancel
        </Button>,
        <Button
          key="ok"
          type="primary"
          size="large"
          onClick={handleOk}
          disabled={!submittable}
        >
          Update referral
        </Button>,
      ]}
      width={550}
      style={{ top: "15%" }}
    >
      <Divider style={{ marginTop: "12px" }} />
      <div style={{ marginTop: "32px" }}>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: "public" }}
          onChange={confirmInput}
        >
          <Form.Item
            name="referrerDiscountValue"
            rules={[
              {
                required: true,
                message: "Please input discount value!",
              },
            ]}
          >
            <Input
              size="large"
              style={{ width: "100%" }}
              placeholder="Referrer's Discount Value"
              type="number"
            />
          </Form.Item>

          <Form.Item
            name="referredUserDiscountValue"
            rules={[
              {
                required: true,
                message: "Please input referred discount value!",
              },
            ]}
          >
            <Input
              size="large"
              style={{ width: "100%" }}
              placeholder="Referred user's discount value"
              type="number"
            />
          </Form.Item>

          <Form.Item
            name="referralDiscountType"
            rules={[
              {
                required: true,
                message: "Please select discount type",
              },
            ]}
          >
            <Select placeholder="Discount Type" size="large" allowClear>
              <Option value="AMOUNT">Amount</Option>
              <Option value="PERCENTAGE">Percentage</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="referralActivationAmount"
            rules={[
              {
                required: true,
                message: "Please input activation amount!",
              },
            ]}
          >
            <Input
              size="large"
              style={{ width: "100%" }}
              placeholder="Referral activation amount"
              type="number"
            />
          </Form.Item>
        </Form>
      </div>
      <Divider style={{ marginTop: "32px" }} />
    </Modal>
  );
}
