import {
  ArrowRightOutlined,
  CheckCircleTwoTone,
  DownOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Space,
  theme,
} from "antd";
import { useEffect, useState } from "react";
import { convertDateString, convertToTimeString } from "src/util/util";
import styled, { css } from "styled-components";
import {
  CustomError,
  CustomLoader,
} from "../../../../../../../util/Reuseables";
import {
  useExchangeRatesData,
  useUpdateFixedRate,
} from "../../../helpers/useRQsettingsData";
import { Flex } from "../Rates";
import { TitleStyles } from "./styles";
const { useToken } = theme;

const ExchangeRate = () => {
  const {
    isLoading,
    data: exchangeRates,
    isError,
    error,
    refetch,
  } = useExchangeRatesData();
  const err: any = error;
  const [activePair, setActivePair] = useState<any>();

  useEffect(() => {
    setActivePair(exchangeRates?.[0]);
  }, [exchangeRates]);

  if (isLoading) {
    return (
      <ExchangeRateWrapper>
        <CustomLoader prompt="Loading exchanges..." />
      </ExchangeRateWrapper>
    );
  }
  if (isError) {
    return (
      <ExchangeRateWrapper>
        <CustomError message={err?.message} retryAction={() => refetch()} />
      </ExchangeRateWrapper>
    );
  }

  return (
    <ExchangeRateWrapper>
      <Space direction="vertical">
        <Flex>
          <TitleStyles>Exchange Rate</TitleStyles>
          <EditRateDropdown activePair={activePair} />
        </Flex>

        <CurrencyPairs
          pairs={exchangeRates}
          setSelected={setActivePair}
          value={activePair}
        />
      </Space>

      <Space direction="vertical">
        <Space>
          <H3> 1 {activePair?.base} </H3>
          <ArrowRightOutlined size={12} />
          <H3>
            {activePair?.rate} {activePair?.target}
          </H3>
        </Space>

        <Graph rate={activePair?.rate} />
        <Divider style={{ margin: 8 }} />

        <Space direction="vertical" size={0}>
          <span>Last updated</span>
          <span>
            {convertDateString(activePair?.readingDate)},{" "}
            {convertToTimeString(activePair?.readingDate)}
          </span>
        </Space>
      </Space>
    </ExchangeRateWrapper>
  );
};

export default ExchangeRate;

const CurrencyPairs = ({
  value,
  setSelected,
  pairs,
}: {
  value: any;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  pairs: any[];
}) => {
  const { token } = useToken();
  const [filterPairs, setFilterPairs] = useState<any[]>(pairs);

  const onChange = (e: RadioChangeEvent) => {
    setSelected(e.target.value);
  };

  const onSearchPairs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lowerCaseSearchString = e.target.value.toLowerCase();

    const isPairFormat = lowerCaseSearchString.includes("/");

    const result = lowerCaseSearchString
      ? pairs?.filter((item: any) => {
          const base = item?.base.toLowerCase();
          const target = item?.target.toLowerCase();

          // If search string is in pair format like EUR/XAF, check both base and target
          if (isPairFormat) {
            return `${base}/${target}`.includes(lowerCaseSearchString);
          }

          // Otherwise, check base or target individually
          return (
            base.includes(lowerCaseSearchString) ||
            target.includes(lowerCaseSearchString)
          );
        })
      : pairs;

    setFilterPairs(result);
  };

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => (
        <DropdownContentWrapper $token={token}>
          <Input
            placeholder="Search pairs"
            suffix={<SearchOutlined />}
            onChange={onSearchPairs}
          />
          <Radio.Group onChange={onChange} value={value}>
            <RadioWrapper>
              {filterPairs === undefined || filterPairs.length === 0 ? (
                <Empty />
              ) : (
                filterPairs?.map((item, index) => (
                  <Radio value={item} key={item?.base + index}>
                    1 {item?.base} = {item?.rate} {item?.target}
                  </Radio>
                ))
              )}
            </RadioWrapper>
          </Radio.Group>
        </DropdownContentWrapper>
      )}
    >
      <a
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <Space>
          Currency pair
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};

const EditRateDropdown = ({ activePair }: { activePair: any }) => {
  const { token } = useToken();
  const [form] = Form.useForm();
  const [updated, setUpdated] = useState(false);

  const toggleUpdated = () => {
    setUpdated(true);
    setTimeout(() => {
      setUpdated(false);
    }, 3000); // Reset updated after 3 seconds
  };

  const { mutate: updateFixedRateMutate, isLoading: isUpdatingFixedRate } =
    useUpdateFixedRate(() => {
      toggleUpdated();
    });

  const onSubmit = (values: any) => {
    const { rate } = values;

    const payload = {
      ...activePair,
      rate: parseFloat(rate),
      isActive: true,
    };

    updateFixedRateMutate(payload);
  };

  useEffect(() => {
    if (activePair) {
      form.setFieldsValue({
        base: activePair.base,
        target: activePair.target,
        rate: activePair.rate,
      });
    }
  }, [activePair, form]);

  return (
    <Dropdown
      dropdownRender={() => (
        <DropdownContentWrapper $token={token} $width={300}>
          <DropdownTitle $fontSize={16}>Edit rate</DropdownTitle>
          <Form
            form={form}
            name="edit rate"
            layout="vertical"
            onFinish={onSubmit}
          >
            <Form.Item
              name="rate"
              rules={[
                {
                  validator: (_, value) => {
                    if (value === undefined) {
                      return Promise.reject(new Error("Required!"));
                    }
                    if (value === activePair?.rate) {
                      return Promise.reject(
                        new Error("Rate must be different!")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="0.00" />
            </Form.Item>

            <Form.Item>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isUpdatingFixedRate}
                >
                  Manual override
                </Button>
                {updated && <CheckCircleTwoTone twoToneColor="#52c41a" />}
              </Space>
            </Form.Item>
          </Form>
        </DropdownContentWrapper>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Button icon={<EditOutlined />} />
      </a>
    </Dropdown>
  );
};

const Graph = ({ rate }: { rate: string }) => {
  const numRate = Math.max(Number(rate), 0);
  const rates = [0, 0, 0, 0, 0, 0, 0, 0, 0, numRate]; //mock rate dta over the last 9 days

  const maxRate = Math.max(...rates);

  const scaleFactor = maxRate > 0 ? 52 / maxRate : 0;

  const barHeights = rates.map((rate) => rate * scaleFactor);

  return (
    <GraphWrapper>
      <Space align="baseline">
        {barHeights.map((barHeight, index) => (
          <GraphBar
            key={index}
            $height={barHeight === 0 ? 12 : barHeight}
            $isActive={rate === String(barHeight / scaleFactor)}
          />
        ))}
      </Space>
    </GraphWrapper>
  );
};

const ExchangeRateWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  width: 414px;
  box-sizing: border-box;
  border: 1px solid rgba(222, 222, 225, 1);
  border-radius: 12px;
  height: 372px;
  padding: 24px;
  overflow: hidden;
`;

const DropdownContentWrapper = styled.div<{ $token: any; $width?: number }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: ${(props) => props.$token.colorBgElevated};
  border-radius: 4px;
  box-shadow: ${(props) => props.$token.boxShadowSecondary};
  padding: 16px;
  border: 1px solid rgba(73, 79, 94, 0.2);
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 300px;
  overflow-y: auto;
`;

const H3 = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: rgba(66, 66, 66, 1);
`;

const GraphWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  height: 52px;
  margin: 8px 0px;
`;

const GraphBar = styled.div<{ $height: number; $isActive: boolean }>`
  width: 8px;
  border-radius: 12px;
  height: ${(props) => props.$height + "px"};

  ${(props) =>
    props.$isActive
      ? css`
          background-color: rgba(0, 123, 93, 1);
        `
      : css`
          background-color: rgba(0, 123, 93, 1);
          opacity: 0.2;
        `}
`;

const DropdownTitle = styled.p<{ $fontSize: number }>`
  font-weight: 500;
  color: rgba(66, 66, 66, 1);
  margin: 0;
  font-size: ${(props) => props.$fontSize + "px"};
`;

const DropdownSecondaryText = styled.span<{
  $fontSize: number;
  $isBold?: boolean;
}>`
  font-size: ${(props) => props.$fontSize + "px"};
  font-weight: ${(props) => (props.$isBold ? "500" : "400")};
  color: rgba(66, 66, 66, 1);
`;

const DropdownActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
`;
