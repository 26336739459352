export function getCurrentFormattedDate(): string {
  const currentDate: Date = new Date();
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const formattedDate: string = currentDate.toLocaleDateString(
    "en-US",
    options
  );
  return formattedDate;
}

export function filterTableByTab(users: any, tab: string) {
  if (users.length > 0) {
    const filtered = users?.filter((user: any) => {
      if (tab.toUpperCase() === "ALL") {
        return true;
      }
      return user.status === tab;
    });

    return filtered?.length;
  }
}

export const updateFilterBox = (
  index: number,
  newValue: any,
  filterBox: any,
  setFilterBox: Function
) => {
  const updatedTabs = [...filterBox]; // Create a copy of the filterBoxes array
  updatedTabs[index] = newValue; // Update the value at the specified index
  setFilterBox(updatedTabs); // Update the state with the modified filterBoxes array
};

export const handleFilterBoxClick = (
  index: number,
  tab: any,
  setFilterBoxactivestate: Function,
  setActiveTab: Function,
  setDropdownTabs: Function,
  updateFilterBox: Function
) => {
  setFilterBoxactivestate(tab.format);
  setActiveTab(index);
  setDropdownTabs((prev: boolean) => !prev);

  if (index === 3) {
    updateFilterBox(3, tab);
  }
  index > 3 && updateFilterBox(3, tab);
};
