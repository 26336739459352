import { FrownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styled, { css } from "styled-components";

export const CustomLoader = ({ prompt }: { prompt: string }) => {
  return (
    <CustomWrapper>
      <LoadingOutlined className="_icon" />
      {prompt}
    </CustomWrapper>
  );
};

export const CustomError = ({
  message,
  retryAction,
}: {
  message: string;
  retryAction?: () => void;
}) => {
  return (
    <CustomWrapper $isError>
      <FrownOutlined className="_icon" />
      {message}
      <Button onClick={() => retryAction?.()}>Retry</Button>
    </CustomWrapper>
  );
};

const CustomWrapper = styled.div<{ $isError?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  text-align: center;
  padding: 16px;

  ${(props) =>
    props.$isError &&
    css`
      color: rgba(207, 9, 33, 1);
    `}

  ._icon {
    font-size: 32px;
  }
`;
