import { Button, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import TableSearchBar from "src/components/modules/parts/SearchBar";
import { SelectDays } from "src/components/modules/parts/SelectDays";
import TransfersTable from "src/components/modules/table/custom-table-children/TransfersTable";
import UserSelectorForTransaction from "src/components/modules/user-selector-for-transaction/UserSelectorForTransaction";
import { HORIZONTAL_LOADING, TRANSFER } from "src/redux/actionTypes";
import store from "src/redux/store";
import { paths } from "src/util/paths";
import { TransfersTableTabs } from "src/util/utility";
import style from "./Transfers.css";
import { useTransactionsData } from "./components/helpers/useRQtransfersData";

const Body = style;

export const Transfers = ({
  dashboard = true,
  activeFilterBox = "dashbordFilter",
}: {
  dashboard: boolean;
  activeFilterBox: string;
}) => {
  const dispatch = useDispatch();

  const daysState = useSelector((state: any) => state.transfer.days);
  const statusState = useSelector((state: any) => state.transfer.status);
  const searchState = useSelector((state: any) => state.transfer.search);
  const [tUserSelectorOpen, setTUserSelectorOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(searchState);
  const { limit, offset, search, status, currentTransactionsPage } =
    useSelector((state: any) => state.transfer) || {};

  const [days, setDays] = React.useState(daysState);
  const [transferStatus, setTransferStatus] = React.useState(statusState);

  const { data, isError, isLoading, isFetching } = useTransactionsData({
    days,
    limit,
    offset,
    search,
    status,
  });

  const { collections: transactions, total: totalTransactions } = data || {};

  const indexOfTableTab = TransfersTableTabs.findIndex(
    (tab) => tab.format === statusState
  );

  useEffect(() => {
    if (isFetching || isLoading) {
      store.dispatch({ type: HORIZONTAL_LOADING, payload: true });
    } else {
      store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
    }
  }, [isFetching, isLoading]);

  const [activeTableTab, setActiveTableTab] = React.useState(
    String(indexOfTableTab + 1)
  );

  const history = useHistory();

  const handleSearchInputChange = (event: any) => {
    setSearchValue(event.target.value);
    updateStoreSearchValue(event.target.value);
  };

  const handleClearInput = () => {
    setSearchValue("");
    updateStoreSearchValue("");
  };

  const onChange = (key: string) => {
    if (activeFilterBox === "dashbordFilter") {
      setActiveTableTab(key);
      setTransferStatus(TransfersTableTabs[Number(key) - 1].format);
    }
  };

  const updateStoreSearchValue = (value: string) => {
    const transfersState = store.getState().transfer;
    dispatch({
      type: TRANSFER,
      payload: {
        ...transfersState,
        currentTransactionsPage: 1, //reset current page for new offset calc
        search: value,
      },
    });
  };

  const NewTransfers = () => {
    return (
      <Button
        type="primary"
        size="large"
        onClick={() => setTUserSelectorOpen(!tUserSelectorOpen)}
        className="btn_contained"
      >
        New transfer
      </Button>
    );
  };

  return (
    <Body>
      <div className="user_table_container">
        <div className="section_1">
          <p>Transfers ({totalTransactions || 0})</p>
          <div className="show_add_user_btn_sm">
            <NewTransfers />
          </div>

          <div className="right_content_space_btw">
            {dashboard && (
              <div className="date_select">
                <SelectDays days={days} setDays={setDays} />
              </div>
            )}

            <div className="searchbar_section">
              <TableSearchBar
                value={searchValue}
                placeholder="search transfers by ID"
                onChange={handleSearchInputChange}
                onCancel={handleClearInput}
              />
            </div>
            <div className="buttons">
              <div className="adduser">
                <NewTransfers />
                {tUserSelectorOpen && (
                  <UserSelectorForTransaction
                    onUserSelect={(userId: string) =>
                      history.push(paths.TRANSFER_METHOD + "?user=" + userId)
                    }
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    closeSelector={() => setTUserSelectorOpen(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {dashboard && (
          <div className="section_2">
            <Tabs
              items={TransfersTableTabs}
              onChange={onChange}
              activeKey={activeTableTab}
              defaultActiveKey="1"
            />
          </div>
        )}
        <TransfersTable
          tableBody={transactions}
          total={totalTransactions}
          currentTransactionsPage={currentTransactionsPage}
          days={days}
          status={transferStatus}
        />
      </div>
    </Body>
  );
};
