import { Breadcrumb, Button, Space, Typography } from "antd";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import style from "./TransactionDetail.css";

import { useUserData } from "src/components/pages/customer-detail/helper/useRQuserData";
import {
  cancelTransfer,
  getTransfers,
  getUserTransactions,
  resendTransfer,
} from "src/redux/actions/actions";
import { CustomError, CustomLoader } from "src/util/Reuseables";
import constants from "src/util/constants";
import { paths } from "src/util/paths";
import { convertDateString } from "src/util/util";
import { getFirstLetter, getImage } from "src/util/utility";
import TransactionTimeline from "../parts/TransactionTimeline";
import {
  fetchTimelinesStatus,
  getUserRecipient,
  useRecipientsData,
  useTransferDetailsData,
} from "./TransactionDetailHelper";
import TransferInfos, { AMLFlag } from "./components/TransferInfos";

const { Text, Link, Title } = Typography;

const Body = style;

export default function TransactionDetail() {
  const history = useHistory();
  const { transactionId } = useParams<any>();
  const [recipientDetail, setRecipientDetail] = React.useState<any>();
  const [timelineState, setTimelineState] = React.useState<any>({});

  const {
    data: transferDetail,
    isLoading,
    isError,
    error,
  } = useTransferDetailsData(transactionId);

  const userEndpoint = `/user/${transferDetail?.meta?.userId}`;

  const {
    data: userDetail,
    isError: isErrorUserDetail,
    isLoading: isLoadingUserDetail,
    // error,
  } = useUserData(userEndpoint, transferDetail !== undefined);

  const { data: recipients } = useRecipientsData(
    transferDetail?.userId,
    transferDetail !== undefined
  );

  // Dynamic update on transfer status
  const transferStatus =
    transferDetail?.meta?.FRAUD_ALERT === "FRAUD_ALERT"
      ? "FRAUD_ALERT"
      : transferDetail?.status;

  useEffect(() => {
    const recipient =
      transferDetail !== undefined &&
      getUserRecipient(transferDetail?.recipientId, recipients);
    setRecipientDetail(recipient);

    fetchTimelinesStatus(transferDetail, transferStatus, setTimelineState);
  }, [recipients, transferDetail]);

  const handleResend = () => {
    resendTransfer(transferDetail, () => {
      getTransfers();
    });
  };

  if (isLoading) {
    return (
      <Body>
        <CustomLoader prompt="Loading transaction details" />
      </Body>
    );
  }

  if (isError) {
    const err: any = error;
    return (
      <Body>
        <CustomError message={err?.message} />
      </Body>
    );
  }

  return (
    <Body>
      <div className="_header">
        <Breadcrumb
          items={[
            {
              title: (
                <a onClick={() => history.push(paths.TRANSFERS)}>Transfers</a>
              ),
            },
            {
              title: `${transferDetail?.user?.firstName} ${transferDetail?.user?.lastName}`,
            },
          ]}
        />

        {transferDetail?.meta?.receipt_url && (
          <Button size="large" type="primary">
            <a
              href={transferDetail?.meta?.receipt_url}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </Button>
        )}
      </div>
      <div className="transfer_detail">
        <div className="td_section_1">
          <div className="transfer_details">
            <div className="transfer_details_content_1">
              <p>Transfer details</p>
              <div className="pending_verification">
                <span>{transferStatus || "--"}</span>
                <span>#SBR - {transferDetail?.meta?.transactionId}</span>
              </div>
            </div>
            <div className="transfer_details_content_2">
              <div className="exchange_amount">
                <span>{transferDetail?.originAmount} </span>
                <span>{transferDetail?.originCurrency}</span>
                <img
                  className="arrow-right"
                  src={getImage("transfer_icon.svg", "svgs")}
                  alt="->"
                />{" "}
                <span>{transferDetail?.destinationAmount} </span>
                <span>{transferDetail?.destinationCurrency}</span>
              </div>
              <div className="userinfo">
                <div className="name_and_date">
                  <div className="name">
                    <span>
                      {transferDetail?.user.firstName}{" "}
                      {transferDetail?.user.lastName}
                    </span>
                  </div>
                  <div className="date">
                    <span>
                      {convertDateString(transferDetail?.dateCreated)}
                    </span>
                  </div>
                </div>
                <div className="user_initials">
                  <span>
                    {getFirstLetter(transferDetail?.user.firstName)}
                    {getFirstLetter(transferDetail?.user.lastName)}
                  </span>
                </div>
              </div>

              <Space direction="vertical" align="end">
                <span>AML STATUS</span>
                {isErrorUserDetail || isLoadingUserDetail ? (
                  <span>N/A</span>
                ) : (
                  <AMLFlag
                    $isFlagged={userDetail?.meta?.amlScreen === "ATTENTION"}
                  >
                    {userDetail?.meta?.amlScreen === "ATTENTION"
                      ? "Failed"
                      : "Successful"}
                  </AMLFlag>
                )}
              </Space>
            </div>
            <div className="transfer_details_content_3">
              <button
                className={
                  transferStatus === constants.TRANSFER_STATUS_CANCELLED
                    ? "disabled_btn"
                    : "btn_1"
                }
                disabled={
                  transferStatus === constants.TRANSFER_STATUS_CANCELLED
                }
                onClick={() =>
                  cancelTransfer(() => {
                    getUserTransactions();
                  }, transferDetail.id)
                }
              >
                Cancel
              </button>
              <button className="btn_2" onClick={handleResend}>
                Resend
              </button>
            </div>
          </div>
          <div className="transction_details">
            <TransferInfos
              transferDetail={transferDetail}
              recipientDetail={recipientDetail}
            />
          </div>
        </div>
        <div className="td_section_2">
          <p>Transaction timeline</p>
          <>
            <TransactionTimeline
              state={timelineState?.state}
              date={timelineState?.date}
              status={timelineState?.status}
              currentState={timelineState?.currentState}
            />
          </>
        </div>
      </div>
    </Body>
  );
}
