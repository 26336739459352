import { IAction } from ".";
import { TRANSFER, TRANSFER_DASHBOARD } from "../actionTypes";

const initialTransferState: {} = {
  transfers: [],
  total: 0,
  days: 1,
  limit: 10,
  offset: 0,
  status: "ALL",
  search: "",

  conversionRate: {},
  serviceFee: 0,
  service: {},
  toSend: { value: "", currency: "GBP", image: "UK", isSend: true },
  toReceive: { value: `${0}`, currency: "XAF", image: "CM" },
  paymentMethod: "",
  transactionDetails: undefined,
  transferMethod: "",
  transactions: [],
  paginatedTransactions: {},
  paginatedCompletedTransactions: {},
  paginatedCancelledTransactions: {},
  paginatedPendingTransactions: {},
  currentTransactionsPage: 1,
};

export const transfer = (
  state: any = initialTransferState,
  { type, payload }: IAction
) => {
  switch (type) {
    case TRANSFER: {
      return {
        ...state, // Spread the existing state
        transfers: payload.transfers, // Update 'transfers' from the payload
        total: payload.total, // Update 'total' from the payload
        days: payload.days, // Update 'days' from the payload
        limit: payload.limit, // Update 'limit' from the payload
        offset: payload.offset, // Update 'offset' from the payload
        status: payload.status, // Update 'status' from the payload
        search: payload.search, // Update 'search' from the payload
        currentTransactionsPage: payload.currentTransactionsPage,
      };
    }
    default:
      return state;
  }
};

export const transferDasboard = (
  state: any = initialTransferState,
  { type, payload }: IAction
) => {
  switch (type) {
    case TRANSFER_DASHBOARD: {
      return {
        ...state, // Spread the existing state
        transfers: payload.transfers, // Update 'users' from the payload
        total: payload.total, // Update 'total' from the payload
      };
    }
    default:
      return state;
  }
};
