import { DoubleRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { useState } from "react";
import { transferMethodsInWords } from "src/util/constants";
import {
  convertDateString,
  convertToTimeString,
  replaceUnderscores,
} from "src/util/util";
import styled, { css } from "styled-components";

const { Panel } = Collapse;

const TransferInfos = ({
  transferDetail,
  recipientDetail,
}: {
  transferDetail: any;
  recipientDetail: any;
}) => {
  const [activePanel, setActivePanel] = useState<string | string[]>(["0"]);

  const {
    transferMethod,
    destinationCurrency,
    destinationAmount,
    originCurrency,
    originAmount,
    dateCreated,
  } = transferDetail || {};
  const {
    clientIp,
    exchangeRate,
    exchangeTarget,
    serviceFee,
    totalToPay,
    paymentGatewayUsed,
  } = transferDetail?.meta || {};

  const {
    firstName,
    lastName,
    accountNumber,
    recipientAccountNumber,
    state,
    mobile,
    bankName,
    email,
    reason,
    amlScreen,
  } = recipientDetail?.profile || {};

  const transferDetailArrays = [
    {
      label: "Transfer Method",
      value: transferMethodsInWords[transferMethod],
      width: 244,
    },
    {
      label: "They get",
      value: `${destinationAmount} ${destinationCurrency}`,
      width: 244,
    },
    {
      label: "Client IP used",
      value: clientIp,
      width: 244,
    },
    {
      label: "Rate",
      value: `1 ${originCurrency} = ${exchangeRate} ${exchangeTarget}`,
      width: 244,
    },
    {
      label: "Service fee",
      value: `${serviceFee} ${originCurrency}`,
      width: 244,
    },
    {
      label: "Total to pay",
      value: `${totalToPay} ${originCurrency}`,
      width: 244,
    },
    {
      label: "You send",
      value: `${originAmount} ${originCurrency}`,
      width: 244,
    },
    {
      label: "Payment gateway",
      value: replaceUnderscores(paymentGatewayUsed),
      width: 244,
    },
    {
      label: "Transfer time",
      value: `${convertDateString(dateCreated)} - ${convertToTimeString(
        dateCreated
      )}`,
      width: 244,
    },
  ];

  const recipientDetailArrays = [
    {
      label: "Name",
      value: `${firstName || "N/A"} ${lastName || "N/A"}`,
      width: 260,
    },
    {
      label: "Acct No",
      value: accountNumber || recipientAccountNumber,
      width: 260,
    },
    {
      label: "City",
      value: state,
      width: 244,
    },
    {
      label: "Mobile No",
      value: mobile,
      width: 260,
    },
    {
      label: "Recipient’s bank name",
      value: bankName,
      width: 260,
    },
    {
      label: "AML STATUS",
      value: (
        <AMLFlag $isFlagged={amlScreen === "ATTENTION"}>
          {amlScreen === "ATTENTION" ? "Failed" : "Successful"}
        </AMLFlag>
      ),
      width: 260,
    },
    {
      label: "Email",
      value: email,
      width: 260,
    },
    {
      label: "Reason",
      value: reason,
      width: 260,
    },
  ];

  const items = [
    {
      key: "1",
      title: "Transaction details",
      content: (
        <ContentStyles>
          <ContentItems infoArray={transferDetailArrays} />
        </ContentStyles>
      ),
    },
    {
      key: "2",
      title: "Recipient details",
      content: (
        <ContentStyles>
          <ContentItems infoArray={recipientDetailArrays} />
        </ContentStyles>
      ),
    },
  ];

  const togglePanel = (key: string | string[]) => {
    setActivePanel(key);
  };

  return (
    <TransferInfosStyles>
      <Collapse
        size="large"
        accordion
        className="_collapse"
        bordered={false}
        defaultActiveKey={["0"]}
        onChange={(key) => togglePanel(key)}
      >
        {items.map((item, index) => (
          <Panel
            className="_panel"
            showArrow={false}
            header={
              <HeaderStyles>
                <TitleStyles>{item.title}</TitleStyles>
                <DoubleRightOutlined
                  rotate={activePanel.includes(`${index}`) ? 90 : 0}
                />
              </HeaderStyles>
            }
            key={index}
          >
            <ContentStyles>{item.content}</ContentStyles>
          </Panel>
        ))}
      </Collapse>
    </TransferInfosStyles>
  );
};

export default TransferInfos;

const ContentItems = ({ infoArray }: { infoArray: any[] }) => {
  const numberOfRows = 3;

  return (
    <ContentItemsStyles>
      {Array(numberOfRows)
        .fill(null)
        .map((_, rowIndex) => {
          const startIndex = rowIndex * 3;
          const endIndex = startIndex + 3;

          return (
            <RowStyles
              key={"contentitemsinfos_" + rowIndex}
              $noBorder={rowIndex === 2}
            >
              {infoArray.slice(startIndex, endIndex).map((item, cellIndex) => (
                <CellStyles
                  $cellWidth={item.width}
                  key={`contentitemsinfos_cell_${rowIndex}_${cellIndex}`}
                >
                  <p>{item.label}</p>
                  <p>{item.value || "N/A"}</p>
                </CellStyles>
              ))}
            </RowStyles>
          );
        })}
    </ContentItemsStyles>
  );
};

const TransferInfosStyles = styled.div`
  color: #424242;
  ._collapse {
    background: none;
    padding: 0px !important;
    .ant-collapse-header,
    .ant-collapse-content-box {
      padding: 0px 0px !important;
    }
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }
`;

const ContentStyles = styled.div`
  background: rgba(248, 249, 251, 1);
  border-radius: 8px;
  margin-bottom: 20px;
`;

const HeaderStyles = styled.div`
  padding: 16px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TitleStyles = styled.p`
  font-size: 20px !important;
  font-weight: 500;
  margin: 0;
  color: rgba(66, 66, 66, 1) !important;
`;

const ContentItemsStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const RowStyles = styled.div<{ $noBorder?: boolean }>`
  padding: 24px;
  ${(props) =>
    props.$noBorder
      ? ``
      : `
    border-bottom: 1px solid rgba(222, 222, 225, 0.8);
  `}

  display: flex;
  align-items: center;
  gap: 40px;
`;

const CellStyles = styled.div<{ $cellWidth?: number }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: ${(props) => props.$cellWidth + "px"};
  width: 100%;

  p {
    color: rgba(112, 112, 112, 1);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
  }
  p:last-child {
    color: rgba(66, 66, 66, 1);
    text-transform: none;
    font-weight: 600;
  }
`;

export const AMLFlag = styled.span<{ $isFlagged: boolean }>`
  font-weight: 500;
  text-transform: capitalize;

  ${(props) =>
    props.$isFlagged
      ? css`
          color: #cf0921;
        `
      : css`
          color: #007b5d;
        `}
`;
