import { Tabs, TabsProps } from "antd";
import PaymentMethods from "./payment-methods/PaymentMethods";
import Rates from "./rates/Rates";

const Payments = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Rates",
      children: <Rates />,
    },
    {
      key: "2",
      label: "Payment method",
      children: <PaymentMethods />,
      disabled: true,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  return <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;
};

export default Payments;
