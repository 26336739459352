import React, { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  cancelTransfer,
  getTransfers,
  getUserTransactions,
  resendTransfer,
  toastAction,
} from "../../../redux/actions/actions";
import { LOADING, RECIPIENT, TRANSFER } from "../../../redux/actionTypes";
import { paths } from "../../../util/paths";
import {
  asset,
  convertDateString,
  formatCurrency,
  getValueFromArray,
} from "../../../util/util";
import PageHeading from "../page-heading/PageHeading";
import { getImage } from "../../../util/utility";
import Receipt from "../preorder-receipt/Receipt";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const style = () => styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: ${(document.body.clientWidth ||
    document.documentElement.clientWidth ||
    window.innerWidth) + 1000}px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  z-index: 1;
  .disable {
    opacity: 0.2;
    pointer-events: none;
  }
  .is-resending {
    transition: 3s ease-out;
    transform: rotateZ(-720deg);
  }
  .modal {
    box-shadow: 0px 10px 12px #cccccc80;
    border-radius: 15px;
    width: 75%;
    background: #fff;
    margin: 47px auto;
    padding: 60px 0px 30px;
    .head {
      border-bottom: 1px solid #f7f6f7;
      display: grid;
      grid-template-columns: 3fr 3fr 4fr;
      width: 85%;
      margin: 0px auto;
      padding: 20px 0px;
      .t-id {
        font: normal normal normal 20px/24px Montserrat;
        color: #a3a3a3;
        span {
          color: #424242;
        }
      }
      .status {
        span {
          display: inline-block;
          border-radius: 15px;
          font: normal normal normal 13px Montserrat;
          color: #ffffff;
          padding: 7px 15px;
          /* height: 30px; */
        }
      }
      .close {
        text-align: right;
        font: normal normal normal 26px/24px Montserrat;
        color: #a3a3a3;
        cursor: default;
      }
    }

    .sub {
      display: grid;
      grid-template-columns: 3fr 2fr;
      margin: 30px auto;
      width: 85%;
      .name {
        display: grid;
        grid-template-columns: 1fr 5fr 2fr;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 12px #cccccc80;
        border-radius: 15px;
        padding: 30px;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        > div {
          :nth-child(2) {
            div {
              :first-child {
                font: normal normal normal 16px Montserrat;
                color: #a3a3a3;
                margin-bottom: 10px;
              }
              :last-child {
                font: normal normal normal 20px Montserrat;
                color: #424242;
              }
            }
          }
          :last-child {
            div {
              text-align: right;
              :first-child {
                font: normal normal normal 20px Montserrat;
                color: #424242;
              }
              :last-child {
                font: normal normal normal 16px Montserrat;
                color: #a3a3a3;
              }
            }
          }
        }
      }

      .actions {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        > div {
          border-radius: 15px;
          width: 124px;
          height: 124px;
          text-align: center;
          margin: auto;
          padding-top: 26px;
          div {
            font: normal normal normal 16px/44px Montserrat;
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
        .export {
          color: #a3a3a3;
          border: 2px solid #a3a3a3;
        }
        .cancel {
          color: #cf0921;
          border: 2px solid #cf0921;
        }
        .resend {
          color: #007b5d;
          border: 2px solid #007b5d;
        }
      }
    }

    .timeline {
      background: #007b5d 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 4px #cccccc80;
      padding: 30px 6%;
      margin-bottom: 50px;
      .bar {
        height: 8px;
        width: 80%;
        border-radius: 15px;
        background: #3f896f;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 0fr;
        padding: 0px;
        margin: auto;
        .point {
          background: #fff;
          width: 4px;
          height: 4px;
          border-radius: 15px;
          margin: 2px;
        }
        .point-1 {
          width: 43px;
          height: 6px;
        }
      }
      .point-labels {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-top: 20px;
        > div {
          width: 200%;
          padding-left: 0px;
          margin-left: -50%;
          text-align: center;
          div:first-child {
            font: normal normal normal 16px/16px Montserrat;
            color: #ffffff;
          }
          div:last-child {
            font: normal normal normal 13px/18px Montserrat;
            color: #a3a3a3;
            /* width: 300px; */
          }
        }
      }
    }

    .details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 90%;
      margin: 0px auto 50px;
      .recipient-details {
        margin-left: 25px;
      }
      hr {
        margin: 20px 0px;
        border: 1px solid #f8f7f8;
      }
      > div {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 12px #cccccc80;
        border-radius: 15px;
        width: 90%;
        padding: 50px;
        margin: 0px auto;
        .heading {
          display: grid;
          grid-template-columns: 1fr 1fr;
          .title {
            font: normal normal normal 20px/24px Montserrat;
            color: #a3a3a3;
          }
          .update {
            text-align: right;
            font: normal normal normal 16px/19px Montserrat;
            color: #007b5d;
          }
        }
        .row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin: 5px 0px;
          font: normal normal normal 16px/44px Montserrat;
          .left {
            color: #a3a3a3;
          }
          .right {
            text-align: right;
            color: #424242;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .green {
    color: #007b5d;
  }

  @media only screen and (max-width: 900px) {
    padding: 0px;
    background: #fff;
    height: 100vh;
    position: fixed;
    overflow-y: scroll;
    .modal {
      margin: 0px;
      width: 100%;
      box-shadow: none;
      overflow-y: hidden;
      .head {
        display: none;
      }
      .sub {
        grid-template-columns: 1fr;
        margin: 5px auto;
        .name {
          padding: 15px 10px;
          border-radius: 8px;
          margin-bottom: 20px;
          > div {
            img:nth-child(1) {
              width: 30px;
              height: 30px;
            }
            :nth-child(2) {
              div {
                :first-child {
                  font: normal normal normal 8px Montserrat;
                  margin-bottom: 5px;
                }
                :last-child {
                  font: normal normal normal 11px Montserrat;
                }
              }
            }
            :nth-child(3) {
              div {
                font: normal normal normal 8px Montserrat !important;
                margin-bottom: 5px;
              }
            }
          }
        }
        .actions {
          grid-template-columns: 1fr 1fr 1fr;
          padding-left: 60%;
          > div {
            text-align: center;
            width: 25px;
            height: 25px;
            border-radius: 5px;
            padding-top: 0px;
            border-width: 1px !important;
            img {
              width: 10px;
              height: 10px;
            }
            div {
              margin-top: -3px;
              font: normal normal normal 4px Montserrat;
            }
          }
          .export {
          }
        }
      }
      .timeline {
        display: none;
      }
      .details {
        grid-template-columns: 1fr;
        grid-gap: 15px;
        width: 100%;
        > div {
          padding: 15px 20px;
          .heading {
            .title {
              font: normal normal normal 13px/16px Montserrat;
            }
            .update {
              font: normal normal normal 11px/14px Montserrat;
            }
          }
          .row {
            font: normal normal normal 11px/22px Montserrat;
          }
        }
        .recipient-details {
          margin-left: auto;
        }
        .transfer-details {
        }
      }
    }
    .mobile-modal {
      div.status {
        span {
          position: absolute;
          top: 20px;
          left: 82%;
          z-index: 3;
          /* width: 54px;
                    height: 16px; */
          display: inline-block;
          display: inline-block;
          background: #fcd20f 0% 0% no-repeat padding-box;
          border-radius: 15px;
          font: normal normal normal 8px Montserrat;
          color: #ffffff;
          padding: 3px 10px;
        }
      }
      div.view-details {
        font: normal normal normal 8px/44px Montserrat;
        color: #a3a3a3;
        position: absolute;
        top: 140px;
        left: 6%;
      }
    }

    .timeline-modal-container {
      .overlay {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;
        width: 100%;
        background: #000000;
        opacity: 0.3;
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        height: 130vh;
      }
      .timeline-modal {
        width: 80%;
        background: #007b5d;
        /* box-shadow: 0px 2px 4px #CCCCCC80; */
        border-radius: 8px;
        height: 330px;
        margin: auto auto;
        position: fixed;
        top: 175px;
        left: 10%;
        display: block;
        z-index: 3;
        padding: 30px 50px;
        .timeline {
          display: grid;
          grid-template-columns: 1fr 4fr;
          grid-gap: 10%;
          .bar {
            width: 5px;
            height: 244px;
            background: #3f896f;
            display: grid;
            grid-template-rows: 1fr 1fr 1fr 0fr;
            grid-gap: 0px;
            .point {
              background: #fff;
              width: 2px;
              height: 2px;
              border-radius: 15px;
              margin: 2px;
            }
            .point-1 {
              width: 3px;
              height: 100%;
            }
          }
          .point-labels {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr 0fr;
            > div {
              width: 250px;
              div:first-child {
                font: normal normal normal 13px/14px Montserrat;
                color: #ffffff;
              }
              div:last-child {
                font: normal normal normal 11px/14px Montserrat;
                color: #a3a3a3;
              }
            }
          }
        }
      }
    }
  }
`;

const Modal = style();

const PreorderDetail = (props: any) => {
  const { openTDModal, handleOpenTDModal, data } = props;
  const recipients = useSelector((state: any) => {
    return state.recipients.recipients;
  });
  const [isResending, setIsResending] = useState(false);
  const dispatch = useDispatch();
  const receiptRef = useRef(null);

  const recipient: any = data.recipientId
    ? getValueFromArray(data?.recipientId, "id", recipients)
    : {};

  const handleResend = () => {
    setIsResending(true);
    toastAction({
      show: true,
      type: "info",
      timeout: 10000,
      message: "Resending...",
    });
    resendTransfer(data, () => {
      getTransfers();
    });

    setTimeout(() => {
      // history.push(paths.TRANSFER_METHOD)
      setIsResending(false);
    }, 1000);
  };

  const downloadReceipt = () => {
    dispatch({ type: LOADING, payload: true });

    const receiptDiv: HTMLDivElement = document.querySelector(
      "#receipt"
    ) as HTMLDivElement;
    receiptDiv.style.visibility = "visible";
    html2canvas(receiptDiv).then((canvas) => {
      receiptDiv.style.visibility = "hidden";
      const imgData = canvas.toDataURL("image/jpg", 1.0);
      const pdf = new jsPDF({ orientation: "p", format: "a3", unit: "px" });
      pdf.addImage(imgData, "JPG", -140, -10, 860, 900);
      pdf.save("SB-payment-receipt.pdf");
      dispatch({ type: LOADING, payload: false });
    });
  };

  return (
    openTDModal &&
    data && (
      <Modal>
        <div
          ref={receiptRef}
          id="receipt"
          style={{
            width: "100%",
            height: "fit-content",
            margin: "auto",
            position: "absolute",
            zIndex: -200,
            visibility: "hidden",
          }}
        >
          <Receipt data={data} recipient={recipient} />
        </div>
        <div className="modal" id="TD-Modal">
          <div className="head">
            <div className="t-id">
              Transaction #: <span>SBR{data.dateCreated}</span>
            </div>
            <div className="status">
              {" "}
              <span
                className={`"sentence-case ${data.status?.toLowerCase()}-bg`}
              >
                {data.status}
              </span>{" "}
            </div>
            <div className="close" onClick={() => handleOpenTDModal(false)}>
              x
            </div>
          </div>
          <div className="sub">
            <div className="name">
              <div>
                {" "}
                <img src={getImage("noimage.png", "images")} alt="" />{" "}
              </div>
              <div>
                {" "}
                <div>{convertDateString(data.dateCreated)}</div>{" "}
                <div>
                  {" "}
                  Initiated by{" "}
                  {data.meta.byAdmin && (
                    <Fragment>
                      {" "}
                      <b className="green"> Admin </b> for{" "}
                    </Fragment>
                  )}{" "}
                  <b className={`${!data.meta.byAdmin && "green"}`}>
                    {data.sender?.firstname} {data.sender?.lastname}
                  </b>
                </div>{" "}
              </div>
              <div className="uppercase">
                {" "}
                <div>{data.service}</div>{" "}
                <div>
                  {formatCurrency(data.amount)} {data.currency}
                </div>{" "}
              </div>
            </div>
            <div className="actions">
              <div className="export" onClick={() => downloadReceipt()}>
                <img src={getImage("export.svg", "svgs")} alt="export" />
                <div>
                  {" "}
                  Download <span className="mobile-hide"></span>
                </div>
              </div>
              <div
                className={`cancel ${
                  data.status?.toLowerCase() === "cancelled" ? "disable" : ""
                }`}
                onClick={() =>
                  cancelTransfer(() => {
                    getUserTransactions();
                    handleOpenTDModal(false);
                  }, data.id)
                }
              >
                <img src={getImage("cancel.svg", "svgs")} alt="cancel" />
                <div>Cancel</div>
              </div>
              <div className="resend" onClick={handleResend}>
                <img
                  className={isResending ? "is-resending" : ""}
                  src={getImage("reload.svg", "svgs")}
                  alt="reload"
                />
                <div>Resend</div>
              </div>
            </div>
          </div>

          <div className="timeline">
            <div className="bar">
              <div className="point point-1"></div>
              <div className="point point-2"></div>
              <div className="point point-3"></div>
              <div className="point point-4"></div>
            </div>
            <div className="point-labels">
              <div className="label-1">
                {" "}
                <div>Transfer created</div>{" "}
                <div>{convertDateString(data.dateCreated)}</div>{" "}
              </div>
              <div className="label-2">
                {" "}
                <div>Received {data.originCurrency} payment</div>{" "}
                <div>{convertDateString(data.dateCreated)}</div>{" "}
              </div>
              <div className="label-3">
                {" "}
                <div>Vendor processing transfer</div>{" "}
                <div>{convertDateString(data.dateCreated)}</div>{" "}
              </div>
              <div className="label-4">
                {" "}
                <div>Recipient receives {data.destinationCurrency}</div>{" "}
                <div>{convertDateString(data.dateCreated)}</div>{" "}
              </div>
            </div>
          </div>

          <div className="details">
            <div className="recipient-details mobile-hide">
              <div className="heading">
                <div className="title">Sender's Details</div>
                <div className="update">Update</div>
              </div>
              <hr />
              <div className="row">
                <div className="left">Name</div>
                <div className="right">
                  {data.sender?.firstname} {data.sender?.lastname}
                </div>
              </div>
              <div className="row">
                <div className="left">Mobile No.</div>
                <div className="right">{data?.sender?.mobile || "-"}</div>
              </div>
              <div className="row">
                <div className="left">Email</div>
                <div className="right">{data?.sender?.email || "-"}</div>
              </div>
              <div className="row">
                <div className="left">City</div>
                <div className="right">{data?.sender?.state || "-"}</div>
              </div>
              <div className="row">
                <div className="left">Reason</div>
                <div className="right">{data?.sender?.reason || "-"}</div>
              </div>
              <div className="row">
                <div className="left">Initiated by</div>
                <div className="right">
                  {data?.meta?.byAdmin ? "Admin" : "User"}
                </div>
              </div>
            </div>
            <div className="transfer-details">
              <div className="heading">
                <div className="title">Preorder Details</div>
                <div className="update">Update</div>
              </div>
              <hr />
              <div className="row">
                <div className="left">Payout method</div>
                <div className="right sentence-case">
                  {data.data?.method?.replace("_", " ")}
                </div>
              </div>
              <div className="row">
                <div className="left">Service</div>
                <div className="right capitalize"> {data.service} </div>
              </div>
              <div className="row">
                <div className="left">Amount</div>
                <div className="right uppercase">
                  <b className="green">
                    {formatCurrency(data.amount)} {data.currency}
                  </b>
                </div>
              </div>

              {data.data?.method === "bank_transfer" && (
                <>
                  <div className="row">
                    <div className="left">Account name</div>
                    <div className="right"> {data.data.account_name} </div>
                  </div>
                  <div className="row">
                    <div className="left"> Account number </div>
                    <div className="right">{data.data.account_number}</div>
                  </div>
                  <div className="row">
                    <div className="left">Bank name</div>
                    <div className="right"> {data.data.bank_name} </div>
                  </div>
                  <div className="row">
                    <div className="left">Bank code</div>
                    <div className="right"> {data.data.bank_code} </div>
                  </div>
                  <div className="row">
                    <div className="left">Branch code</div>
                    <div className="right"> {data.data.branch_code} </div>
                  </div>
                  <div className="row">
                    <div className="left">RIB Key</div>
                    <div className="right"> {data.data.rib_key} </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    )
  );
};

export default PreorderDetail;
