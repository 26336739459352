import { Space } from "antd";
import { useHistory, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import Controls from "./components/controls/Controls";
import Payments from "./components/payments/Payments";

const settingsTabs = [
  {
    label: "Controls",
    value: "controls",
  },
  {
    label: "Payments",
    value: "payments",
  },
];

const Settings = () => {
  const history = useHistory();
  const { tab: activeTabs }: any = useParams();

  return (
    <SettingsWrapper>
      <Space size={16}>
        {settingsTabs.map((tab, index) => (
          <ButtonTabStyle
            key={tab.value + index}
            $active={activeTabs == tab.value}
            onClick={() => history.push(`/app/settings/${tab.value}`)}
          >
            {tab.label}
          </ButtonTabStyle>
        ))}
      </Space>

      {activeTabs === "controls" ? <Controls /> : <Payments />}
    </SettingsWrapper>
  );
};

export default Settings;

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 72px;
`;

const ButtonTabStyle = styled.button<{ $active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 304px;
  height: 88px;
  border-radius: 12px;
  background: rgba(245, 247, 247, 1);
  border: none;
  cursor: pointer;
  transition: all 1.5s linear ease-in-out;

  color: rgba(66, 66, 66, 1);
  font-size: 20px;
  font-weight: 500;

  ${(props) =>
    props.$active &&
    css`
      border: 3px solid rgba(0, 123, 93, 1);
      color: rgba(0, 123, 93, 1);
      background: #fff;
    `};

  &:hover {
    opacity: 0.8;
  }

  &:active {
    scale: 0.98;
  }
`;
