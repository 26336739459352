import React from 'react';
import { useFormik } from 'formik';
import { createUserSideEffect } from '../../../../redux/actions/actions';
import { generateRandomString, getImage } from '../../../../util/utility';
import {Form} from './formStyle'

type IProps = {preOrderData: any, setPreOrderData: any, user: any, setStep: React.Dispatch<React.SetStateAction<"step1" | "step2" | "step3" | "completed">>}

export const Step1 = ({setStep, preOrderData, setPreOrderData, user}: IProps) => {


    const formik = useFormik({
        initialValues: {
          firstname: user?.profile?.firstName || preOrderData.firstname,
          lastname: user?.profile?.lastName || preOrderData.lastname,
          email: user?.username || preOrderData.email,
          mobile: user?.profile?.mobile || preOrderData.mobile,
          location_country: '',
          reason: '',
          
        },
        onSubmit: (formData) =>{
            if (!user) {
    
                createUserSideEffect({
                    username: formData.email,
                    password: generateRandomString(),
                    firstName: formData.firstname,
                    lastName: formData.lastname,
                    location_country: "",
                    mobile: formData.mobile,
                    reason: formData?.reason,
                    autogen: true,
                },
                    () => {
                        setPreOrderData({
                            ...preOrderData,
                            ...formData
                        })
    
                        setStep('step2')
                    }
                )
            } else {
                setPreOrderData({
                    ...preOrderData,
                    ...formData
                })
    
                setStep('step2')
            }
    
    
        },
    });

    return (
    <Form>
        <div className="content">
            <div className="header">
                <h1>Create a New Currency Exchange Transaction</h1>
                <p>Step 1/3</p>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className={`half ${formik.errors?.firstname && "form-error"}`}>
                    <label>First name</label>
                    <input
                        id="firstname"
                        name="firstname"
                        type="text"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.firstname}
                    />
                    { formik.errors?.firstname &&  <div className="form-error-message">
                        <img src={getImage('error-exclamation.svg', 'svgs')} alt="!" />
                        <div className="message">Please fill this section correctly</div>
                    </div>}
                </div>
                <div className={`half ${formik.errors?.lastname && "form-error"}`}>
                    <label>Surname</label>
                    <input
                        id="lastname"
                        name="lastname"
                        type="text"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.lastname}
                    />
                    { formik.errors?.lastname &&  <div className="form-error-message">
                        <img src={getImage('error-exclamation.svg', 'svgs')} alt="!" />
                        <div className="message">Please fill this section correctly</div>
                    </div>}
                </div>
                <div className={`full ${formik.errors?.email && "form-error"}`}>
                    <label>Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                    { formik.errors?.email &&  <div className="form-error-message">
                        <img src={getImage('error-exclamation.svg', 'svgs')} alt="!" />
                        <div className="message">Please fill this section correctly</div>
                    </div>}
                </div>
                <div className={`full ${formik.errors?.mobile && "form-error"}`}>
                    <label>Phone number</label>
                    <input
                        id="mobile"
                        name="mobile"
                        type="tel"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.firstname}
                    />
                    { formik.errors?.mobile &&  <div className="form-error-message">
                        <img src={getImage('error-exclamation.svg', 'svgs')} alt="!" />
                        <div className="message">Please fill this section correctly</div>
                    </div>}
                </div>
                <div className={`half ${formik.errors?.location_country && "form-error"}`}>
                    <label>Country</label>
                    <input
                        id="location_country"
                        name="location_country"
                        type="text"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.location_country}
                    />
                    { formik.errors?.location_country &&  <div className="form-error-message">
                        <img src={getImage('error-exclamation.svg', 'svgs')} alt="!" />
                        <div className="message">Please fill this section correctly</div>
                    </div>}
                </div>
                <div className={`half ${formik.errors?.reason && "form-error"}`}>
                    <label>Reason</label>
                    <input
                        id="reason"
                        name="reason"
                        type="text"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.reason}
                    />
                    { formik.errors?.reason &&  <div className="form-error-message">
                        <img src={getImage('error-exclamation.svg', 'svgs')} alt="!" />
                        <div className="message">Please fill this section correctly</div>
                    </div>}
                </div>
                <div className="fullRight">
                    <input type="submit" value="Continue"/>
                </div>
            </form>
        </div>
    </Form>
    )
}

export default Step1
