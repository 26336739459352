import { useMutation, useQuery } from "react-query";
import { queryClient } from "src/App";
import { toastAction } from "src/redux/actions/actions";
import endpoints from "src/util/endpoints";
import { getRequest, putRequest } from "src/util/http";
import { parseEndpointParameters } from "src/util/util";

export const useTransactionsData = (
  query: any,
  onSuccess?: any,
  onError?: any
) => {
  const { days, limit, offset, search, status } = query || {};

  //--This encode the email address to include it as a query parameter
  const formatSearchValue = encodeURIComponent(search);

  const customEndpoint =
    status === "ALL"
      ? `${endpoints.TRANSFERS}?days=${days}&limit=${limit}&offset=${offset}&transactionId=${formatSearchValue}`
      : `${endpoints.TRANSFERS}?days=${days}&limit=${limit}&offset=${offset}&transactionId=${formatSearchValue}&status=${status}`;

  return useQuery(
    customEndpoint,
    () => getRequest(customEndpoint, "Failed to fetch transactions"),
    {
      refetchIntervalInBackground: true,
      keepPreviousData: true,
      onError,
      onSuccess,
    }
  );
};

export const useUpdateTransfersStatus = (
  currentTransferQkey: string,
  onSuccess: Function
) => {
  return useMutation(
    (values: any) => {
      return putRequest(
        values,
        parseEndpointParameters(endpoints.ADMIN_UPDATE),
        "Failed to update transaction status, Pls try again"
      );
    },
    {
      onSuccess: () => {
        toastAction({
          show: true,
          type: "success",
          timeout: 10000,
          message: "Transcation status updated",
        });
        queryClient.invalidateQueries(currentTransferQkey);
        onSuccess();
      },
    }
  );
};
