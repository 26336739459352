import styled from "styled-components";

export default styled.div`
  //min-width: 1440px;
  .fallback {
    width: 100%;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 10px;

    p {
      text-align: center;
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }
  .app_wrapper {
    width: 100%;
    height: 100vh;
    //overflow: hidden;
    box-sizing: border-box;
    display: flex;

    @media (min-width: 1200px) {
      display: flex;
    }
    .sidebar {
      width: 10%;

      @media (min-width: 2000px) {
        width: 8%;
      }
      @media (max-width: 768px) {
        display: none;
      }
      height: auto;
      background: #ffffff;
      border-right: 1px solid #dedee1;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      user-select: none;
      position: relative;
      overflow: overlay;
      overflow-y: auto;
      overflow-x: hidden;
      transition: opacity 0.3s ease-in-out;

      &::-webkit-scrollbar {
        opacity: 0;
        width: 0.7rem;
        height: 0.5rem;
      }

      &:hover {
        &::-webkit-scrollbar {
          opacity: 0.5;

          transition: opacity 0.3s ease-in-out;
        }
        &::-webkit-scrollbar-track {
          background: rgba(104, 166, 151, 0.05);
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(104, 166, 151, 0.2);
          border-radius: 0.25rem;
          transition: background 0.3s ease-in-out;
          cursor: pointer;
        }
      }

      .logo {
        width: 90%;
        //padding: 52px 0px;
        padding-top: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          //padding: inherit;
          width: inherit;
        }
      }
      .options {
        margin: 90px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        @media (max-width: 1024px) {
          margin: 80px 50px;
        }

        .opt {
          margin-bottom: 50px;
          width: clamp(66px, 5vw, 86px); //86px;
          height: clamp(66px, 5vw, 86px);
          cursor: pointer;
          @media (max-width: 1024px) {
            width: 70px;
            height: 70px;
          }
          gap: 50px;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: inherit;
            height: inherit;
            background: #f5f7f7;
            border-radius: 15px;
            margin: auto;
            img {
              width: 35px;
            }
            &:hover {
              border: 0.5px solid rgba(104, 166, 151, 0.4);
            }
          }
          .label {
            margin-top: 15px;
            text-align: center;
            text-transform: capitalize;
            font: normal normal normal 13px Montserrat;
            color: #707070;
          }
          .logout {
            background: #a61a2b !important;
            &:hover {
              background: #cf0921 !important;
            }
            img {
              filter: invert(0%) sepia(21%) saturate(28%) hue-rotate(346deg)
                brightness(204%) contrast(97%);
            }
          }
          .selected {
            background: #007b5d !important;
            img {
              animation: bounceAnimation 0.5s;
              filter: invert(0%) sepia(21%) saturate(28%) hue-rotate(346deg)
                brightness(204%) contrast(97%);

              @keyframes bounceAnimation {
                0% {
                  transform: translateY(0);
                }
                50% {
                  transform: translateY(-15px);
                }
                100% {
                  transform: translateY(0);
                }
              }
            }
          }
        }
      }
      .footer {
        border-top: 1px solid #dedee1;
        border-right: 1px solid #dedee1;
        background: white;
        position: fixed;
        margin-top: -200px;
        padding: 15px 10px;
        bottom: 0;
        left: 0;
        width: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .appWrapperContent {
      width: 90%;
      @media (min-width: 2000px) {
        width: 92%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      height: 100vh;
      flex-shrink: 0;
      box-sizing: border-box;
      /* background-color: red; */
      //overflow: hidden;

      .Navbar {
        height: clamp(100px, 5vw, 121px);
        width: inherit;
        box-sizing: border-box;
        border-bottom: 1px solid #dedee1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        position: fixed;
        background: #ffffff;
        z-index: 8;

        .loading {
          margin-bottom: -7px;
          position: absolute;
          bottom: 0;
          width: 100%;
          .loading-container {
            width: 100%;
            height: 6px;
            //border-top: 1px solid #ccc;
            overflow: hidden;
            position: relative;
            background: white;
            .loading-progress {
              width: 70%;
              height: 100%;
              background-color: rgba(0, 123, 93, 1);
              position: absolute;
              animation: slide 2s linear infinite;
            }
            @keyframes slide {
              0% {
                transform: translateX(-100%);
              }
              100% {
                transform: translateX(100%);
              }
            }
          }
        }
        .menu {
          display: none;
          margin-left: 24px;
          @media (max-width: 768px) {
            display: flex;
          }
        }
        .content {
          width: 100%;
          padding: 32px 4%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            text-transform: capitalize;
            font-weight: 400;
            font-size: clamp(18px, 4vw, 24px);
            line-height: 29px;
            color: #424242;
            overflow: hidden;
          }
          .navLeftComponet {
            display: flex;
            align-items: center;
            gap: 40px;

            .notification {
              width: 56px;
              height: 56px;
              background: #f5f7f7;
              border-radius: 200px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              &:hover {
                background: rgba(229, 241, 238, 0.8);
              }
            }
            .profileInfo {
              @media (max-width: 768px) {
                display: none;
              }
              display: flex;
              align-items: center;
              gap: 12px;
              .username {
                text-align: right;
                width: 300px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                span {
                  text-transform: capitalize;
                  color: #292a2e;
                  font-size: 16px;
                  font-weight: 500;
                }
                span:last-child {
                  text-transform: none;
                  color: rgba(73, 79, 94, 0.6);
                  font-size: 14px;
                  font-weight: 500;
                  letter-spacing: 0.14px;
                }
              }
              .profile_img {
                display: flex;
                align-items: center;
                gap: 12px;
                border-radius: 200px;
                cursor: pointer;
                img {
                  width: 56px;
                  height: 56px;
                  border-radius: inherit;
                  border: 1px solid #dedee1;
                }
              }
            }
          }
        }
      }
      .body {
        margin-top: 10vh;
        width: 100%;
        box-sizing: border-box;
        height: 90vh;
        padding: 4.5% 4%;
        position: relative;
        overflow-y: auto;
      }
    }
  }
`;
