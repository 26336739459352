import { useMutation, useQuery } from "react-query";
import { queryClient } from "src/App";
import { toastAction } from "src/redux/actions/actions";
import constants from "src/util/constants";
import endpoints from "src/util/endpoints";
import { getRequest, postRequest, putRequest } from "src/util/http";
import { parseEndpointParameters } from "src/util/util";

export const useExchangeRatesData = (onSuccess?: any, onError?: any) => {
  return useQuery(
    endpoints.EXCHANGES,
    () => getRequest(endpoints.EXCHANGES, "Failed to fetch exchanges"),
    {
      onError,
      onSuccess,
    }
  );
};

export const useCompetitorRatesData = () => {
  return useQuery("competitors-rates", () =>
    getRequest(
      parseEndpointParameters(
        endpoints.GET_COMPETITOR_RATES_FORMATTED,
        constants.COMPETITOR_RATES_BASE_CURRENCY,
        constants.COMPETITOR_RATES_TARGET_CURRENCY,
        constants.COMPETITOR_RATES_COMPARE_AMOUNT as any
      ),
      "Failed to fetch competitors rates"
    )
  );
};

export const useUpdateCompetitorRate = (onSuccess: Function) => {
  return useMutation(
    (values) =>
      putRequest(
        values,
        parseEndpointParameters(
          endpoints.POST_COMPETITOR_RATES,
          constants.COMPETITOR_RATES_BASE_CURRENCY,
          constants.COMPETITOR_RATES_TARGET_CURRENCY
        ),
        "Failed to update competitor rate, Pls try again"
      ),
    {
      onSuccess: () => {
        toastAction({
          show: true,
          type: "success",
          timeout: 10000,
          message: "Competitor rate updated",
        });
        queryClient.invalidateQueries("competitors-rates");
        onSuccess();
      },
    }
  );
};

export const useSpreadData = () => {
  return useQuery(endpoints.EXCHANGE_RATE_SPREADS, () =>
    getRequest(
      parseEndpointParameters(endpoints.EXCHANGE_RATE_SPREADS),
      "Failed to fetch spread"
    )
  );
};

export const useUpdateSpread = (onSuccess: Function) => {
  return useMutation(
    (newSpreadObject: any) => {
      const prevSpreadData: any = queryClient.getQueryData(
        endpoints.EXCHANGE_RATE_SPREADS
      );

      const matchedSpreadObjectId = Object.keys(prevSpreadData).find((id) => {
        const spreadObject = prevSpreadData[id];
        return (
          spreadObject.base === newSpreadObject.base &&
          spreadObject.target === newSpreadObject.target
        );
      });

      const prevSpreadDataLength = Object.keys(prevSpreadData).length;

      const newSpreadValues = {
        ...prevSpreadData,
        [matchedSpreadObjectId || prevSpreadDataLength]: newSpreadObject,
      };
      return postRequest(
        { spreads: newSpreadValues },
        parseEndpointParameters(endpoints.EXCHANGE_RATE_SPREADS),
        "Failed to update spread, Pls try again"
      );
    },
    {
      onSuccess: () => {
        toastAction({
          show: true,
          type: "success",
          timeout: 10000,
          message: "Exchange rate spread updated",
        });
        queryClient.invalidateQueries(endpoints.EXCHANGE_RATE_SPREADS);
        onSuccess();
      },
    }
  );
};

export const useUpdateSpreadDowntime = (onSuccess: Function) => {
  return useMutation(
    (payload: any) => {
      return putRequest(
        payload,
        parseEndpointParameters(endpoints.EXCHANGE_RATE_SPREADS),
        "Failed to update spread, Pls try again"
      );
    },
    {
      onSuccess: () => {
        toastAction({
          show: true,
          type: "success",
          timeout: 10000,
          message: "Exchange rate spread updated",
        });
        queryClient.invalidateQueries(endpoints.EXCHANGE_RATE_SPREADS);
        onSuccess();
      },
    }
  );
};

export const useUpdateFixedRate = (onSuccess: Function) => {
  return useMutation(
    (payload: any) => {
      return putRequest(
        payload,
        parseEndpointParameters(endpoints.FIXED_RATE),
        "Failed to update rate, Pls try again"
      );
    },
    {
      onSuccess: () => {
        toastAction({
          show: true,
          type: "success",
          timeout: 10000,
          message: "Fixed rate updated",
        });
        queryClient.invalidateQueries(endpoints.EXCHANGES);
        queryClient.invalidateQueries(endpoints.EXCHANGE_RATE_SPREADS);
        onSuccess();
      },
    }
  );
};

export const useExchangeRate = (
  base: string,
  target: string,
  enabled: boolean
) => {
  const endpoint = `/exchange/${base}/${target}`;
  return useQuery(
    endpoint,
    () => getRequest(endpoint, "Failed to fetch rate"),
    { enabled }
  );
};

export const useAMLData = () => {
  const endpoint = endpoints.AML + "/list";
  return useQuery(endpoint, () =>
    getRequest(endpoint, "Failed to fetch EML upload history")
  );
};
