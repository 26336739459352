const config = {
  SESSION_KEY: "AJ2sj09_KSsa200kmmn",
  SESSION_ID: "X_SESSION__ID",
  EMAIL_SERVICE_ID: "service_f9bvv1b",
  EMAIL_TEMPLATE_ID: "template_f6ym5do",
  EMAIL_USER_ID: "user_fFlawFLgYuTWojArH7jC8",
  PREORDER_EMAIL_TEMPLATE_ID: "template_c5z9j98",
  SERVICE_PROVIDER: "sbremit-web-uat",
};

export default config;
