import { Badge, Button, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getPromos } from "../../../redux/actions/actions";

import { ColumnsType } from "antd/es/table/InternalTable";
import NewPromoModal from "src/components/modules/new-promo-modal/NewPromoModal";
import TableSearchBar from "src/components/modules/parts/SearchBar";
import ReferralConfigForm from "src/components/modules/referral-config-form/ReferralConfigForm";
import PromosTable from "src/components/modules/table/custom-table-children/PromosTable";
import { Body, ButtonTabsStyle, PromosConatinerStyles } from "./Promo.css";
import {
  PromosTableTabs,
  UasageDataTabs,
  UsageButtonTab,
  filterPromos,
  usageTableBody,
} from "./PromoHelper";

export const Promos = () => {
  const appValues = useSelector((state: any) => state.appValues);
  const promos = useSelector((state: any) => state.promos.promos);
  const promoCodes = useSelector((state: any) => state.promos.promoCodes);
  const vouchers = useSelector((state: any) => state.promos.vouchers);
  const referrals = useSelector((state: any) => state.promos.referrals);

  const [searchValue, setSearchValue] = useState("");
  const [activeTableTab, setActiveTableTab] = React.useState("ALL");
  const [activeUasageTab, setActiveUsageTab] = React.useState("");

  const [addPromo, setAddPromo] = React.useState(false);
  const [refConfig, setRefConfig] = React.useState(false);
  const [activeButtonTab, setActiveButtonTab] = React.useState("codes");

  useEffect(() => {
    const requestType =
      activeButtonTab === "codes" ? "promos" : activeUasageTab;
    getPromos(requestType);
  }, [activeButtonTab, activeUasageTab]);

  const filteredData = (data: any) =>
    filterPromos(data, searchValue, activeTableTab, activeUasageTab);

  const tableBody = (() => {
    if (activeButtonTab === "codes") {
      return usageTableBody(filteredData(promos), "promos", appValues);
    } else if (activeButtonTab === "usageData") {
      const data =
        activeUasageTab === "promoCodes"
          ? promoCodes
          : activeUasageTab === "vouchers"
          ? vouchers
          : activeUasageTab === "referrals"
          ? referrals
          : null;
      return usageTableBody(filteredData(data), activeUasageTab, appValues);
    }

    return null;
  })();

  const onTabChange = (key: string) => {
    setActiveTableTab(key);
  };

  const onUasgeTabChange = (key: string) => {
    setActiveUsageTab(key);
  };

  const handleSearchInputChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleSearchCancelClick = () => {
    setSearchValue("");
  };

  const handleButtonTabsChange = (key: string) => {
    setActiveButtonTab(key);

    key === "usageData"
      ? setActiveUsageTab("promoCodes")
      : setActiveUsageTab("");
  };

  return (
    <Body>
      <NewPromoModal open={addPromo} setOpen={setAddPromo} />
      <ReferralConfigForm open={refConfig} setOpen={setRefConfig} />
      <div className="_header">
        {UsageButtonTab.map((item) => (
          <ButtonTabsStyle
            $active={activeButtonTab === item.key}
            key={item.key}
            onClick={() => handleButtonTabsChange(item.key)}
          >
            {item.label}
          </ButtonTabsStyle>
        ))}
      </div>

      {/* Useage header tab */}
      {activeButtonTab === "usageData" && (
        <Tabs
          items={UasageDataTabs}
          onChange={onUasgeTabChange}
          activeKey={activeUasageTab}
          defaultActiveKey="promoCodes"
        />
      )}

      <PromosConatinerStyles>
        <div className="user_table_container">
          <div className="section_1">
            {activeButtonTab === "codes" && (
              <p>Promos ({filteredData(promos)?.length ?? 0})</p>
            )}

            <div className="right_content_space_btw">
              <div className="searchbar_section">
                <TableSearchBar
                  value={searchValue}
                  placeholder="search promos"
                  onChange={handleSearchInputChange}
                  onCancel={handleSearchCancelClick}
                />
              </div>

              {activeButtonTab === "codes" && (
                <div className="buttons">
                  <button
                    className="ref_config"
                    onClick={() => setRefConfig(true)}
                  >
                    Referral configs
                  </button>
                  <div className="adduser">
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        setAddPromo((prev) => !prev);
                      }}
                    >
                      <span>Add Promo</span>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="section_2">
            <Tabs
              items={PromosTableTabs}
              onChange={onTabChange}
              activeKey={activeTableTab}
              defaultActiveKey="ALL"
            />
          </div>

          <PromosTable
            tableBody={tableBody}
            tableDataType={activeUasageTab}
            promoCodeUsagecolumns={promoCodeUsagecolumns}
            referralsUsagecolumns={referralsUsagecolumns}
            vouchersUsagecolumns={vouchersUsagecolumns}
          />
        </div>
      </PromosConatinerStyles>
    </Body>
  );
};

interface PromoCodesDataType {
  key: React.Key;
  promoCode: string;
  customerId: string;
  minSpend: string;
  usageCount: number;
  value: string;
  lastDateUsed: string;
  expiryDate: string;
  status: string;
}

interface ReferralsDataType {
  key: React.Key;
  refferalCode: string;
  customerId: string;
  progress: string;
  //refreeBonus: string;
  referralBonus: string;
  activationDate: string;
  expiryDate: string;
  status: string;
}

interface VoucherDataType {
  key: React.Key;
  customerId: string;
  totalPoints: string;
  dateActivated: string;
  dateUsed: string;
  expiryDate: string;
  status: string;
}

export const promoCodeUsagecolumns: ColumnsType<PromoCodesDataType> = [
  {
    title: "PROMO CODE",
    dataIndex: "promoCode",
  },
  {
    title: "CUSTOMER ID",
    dataIndex: "customerId",
  },
  {
    title: "MIN. SPEND",
    dataIndex: "minSpend",
  },
  {
    title: "USAGE COUNT",
    dataIndex: "usageCount",
  },
  {
    title: "VALUE",
    dataIndex: "value",
  },
  {
    title: "LAST DATE USED",
    dataIndex: "lastDateUsed",
  },
  {
    title: "EXPIRY DATE",
    dataIndex: "expiryDate",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    render: (status) => {
      const statusToCapitalize = status?.toUpperCase();
      return (
        <Badge
          status={
            (statusToCapitalize === "ACTIVE" && "success") ||
            (statusToCapitalize === "EXPIRED" && "error") ||
            "default"
          }
          text={statusToCapitalize}
        />
      );
    },
  },
];

export const referralsUsagecolumns: ColumnsType<ReferralsDataType> = [
  {
    title: "REFERRAL CODE",
    dataIndex: "refferalCode",
  },
  {
    title: "CUSTOMER ID",
    dataIndex: "customerId",
  },
  {
    title: "PROGRESS (%)",
    dataIndex: "progress",
  },
  // {
  //   title: "REFEREE BONUS",
  //   dataIndex: "refreeBonus",
  // },
  {
    title: "REFERRAL BONUS",
    dataIndex: "referralBonus",
  },
  {
    title: "ACTIVATION DATE",
    dataIndex: "activationDate",
  },
  {
    title: "EXPIRY DATE",
    dataIndex: "expiryDate",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    render: (status) => {
      const statusToCapitalize = status?.toUpperCase();
      return (
        <Badge
          status={
            (statusToCapitalize === "ACTIVE" && "success") ||
            (statusToCapitalize === "EXPIRED" && "error") ||
            "default"
          }
          text={statusToCapitalize}
        />
      );
    },
  },
];

export const vouchersUsagecolumns: ColumnsType<VoucherDataType> = [
  {
    title: "CUSTOMER ID",
    dataIndex: "customerId",
  },
  {
    title: "TOTAL POINTS",
    dataIndex: "totalPoints",
  },
  {
    title: "DATE ACTIVATED",
    dataIndex: "dateActivated",
  },
  {
    title: "DATE USED",
    dataIndex: "dateUsed",
  },
  {
    title: "EXPIRY DATE",
    dataIndex: "expiryDate",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    render: (status) => {
      const statusToCapitalize = status?.toUpperCase();
      return (
        <Badge
          status={
            (statusToCapitalize === "ACTIVE" && "success") ||
            (statusToCapitalize === "EXPIRED" && "error") ||
            "default"
          }
          text={statusToCapitalize}
        />
      );
    },
  },
];
