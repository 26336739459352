import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getQuoteService, getServiceRate, getServiceRateValue, getUser, setNewQuote, toastAction, updateAppValues } from '../../../redux/actions/actions';
import { TRANSFER } from '../../../redux/actionTypes';
import constants from '../../../util/constants';
import { paths } from '../../../util/paths';
import { formatCurrency, getMax, getQueryParam, getUserReferralDiscount } from '../../../util/util';
// import { asset } from '../../../util/util';
import ExchangeRateInput from '../../modules/exchange-rate-input/ExchangeRateInput';
import FancyToggle from '../../modules/parts/FancyToggle';
import ProgressBar from '../../modules/progress-bar/ProgressBar';
import PromoCodeField from '../../modules/promo-code-field/PromoCodeField';
import Body from './GetQuote.css';

const GetQuote = () => {

    const transfer = useSelector((state: any)=>state.transfer);
    const appValues = useSelector((state: any) => state.appValues);
    
    // const user = useSelector((state: any) => state.auth.user);

    const [user, setUser]:any = useState({});
    const userId = getQueryParam('user')

    const promo = transfer.promo
    const [promoText, setPromoText] = useState("")

    const conversionRate = transfer.conversionRate;
    const toSend = transfer.toSend;
    const toReceive = transfer.toReceive;

    const serviceFee = Number(toSend.value) ? transfer.serviceFee : formatCurrency("0");
    const payInCountries = appValues.payInCountries;
    const payOutCountries = appValues.payOutCountries;
    const dispatch = useDispatch()
    const history = useHistory();
    const transferMethod = transfer.transferMethod
    const [changedInput, setChangedInput]: any = useState(null);
    const allowOperatorFee = transfer.allowOperatorFee; 
    const max  = getMax(transferMethod);

    const userReferralDiscount = getUserReferralDiscount(user, appValues);

    let rate= conversionRate?.rate;
    if (
        promo?.type === "FIXED_RATE"
        && toSend.currency === promo.settings.baseCurrency
        && toReceive.currency === promo.settings.targetCurrency
        && Number(toSend.value) >= Number(promo.settings.minimumSpend)
        && Number(toSend.value) <= Number(promo.settings.maximumSpend)
    ) {
        rate = promo.settings.rate
    }

    useEffect(() => {

        setUser(getUser(userId));
        if (!transferMethod) {
            history.replace(paths.TRANSFER_METHOD + `?user=${userId}`)
        }
        updateAppValues();
    }, [])


    const setAllowOperatorFee = (allow: boolean) => {
        dispatch({
            type: TRANSFER, 
            payload: {
                ...transfer,
                allowOperatorFee: allow
            }
        })
    }
    const handleXInputChange = (e: any, data: any) => {        
        try {
                const caret = e.target.selectionStart
                const element = e.target
                window.requestAnimationFrame(() => {
                    element.selectionStart = caret
                    element.selectionEnd = caret
                })

        } catch ( error ) {
        }
        const value = e.target.value;

        if (value.split('.')[1]?.length > 2) {
            return;
        }

        if (isNaN(value)) {
            return
        }

        let rate = conversionRate?.rate;

        if (data.isSend) {
            if (
                promo?.type === "FIXED_RATE"
                && toSend.currency === promo.settings.baseCurrency
                && toReceive.currency === promo.settings.targetCurrency
                && Number(value) >= Number(promo.settings.minimumSpend)
                && Number(value) <= Number(promo.settings.maximumSpend)
            ) {
                rate = promo.settings.rate
            }

            dispatch({
                type: TRANSFER,
                payload: {
                    ...transfer,
                    toSend: {...toSend, adjusted: value - ( allowOperatorFee ? 0 : Number(getServiceRateValue(value * rate, transfer.transferMethod, false, false))), value: `${value}`}, 
                    // toReceive: {...toReceive, value: `${value * rate}`, total: Number(value * rate) + Number(getServiceRateValue(value, transfer.transferMethod, true))},
                    toReceive: {...toReceive, value: `${value * rate}`, total: Number(value * rate) - ( allowOperatorFee ? 0 : Number(getServiceRateValue(value, transfer.transferMethod, true, false))) },
                    referralDiscount: {
                        value: userReferralDiscount?.value,
                        type: userReferralDiscount?.type
                    }
                }
            })

        } else {
            if (
                promo?.type === "FIXED_RATE"
                && toSend.currency === promo.settings.baseCurrency
                && toReceive.currency === promo.settings.targetCurrency
                && (Number(value) / promo.settings.rate) >= Number(promo.settings.minimumSpend)
                && (Number(value) / promo.settings.rate) <= Number(promo.settings.maximumSpend)
            ) {
                rate = promo.settings.rate
            } else {
                setPromoText("")
            }

            dispatch({
                type: TRANSFER, 
                payload: {
                    ...transfer,
                    toSend: {...toSend, adjusted: (value / rate) - ( allowOperatorFee ? 0 : Number(getServiceRateValue(value, transfer.transferMethod, false, false))), value: `${(value / rate).toFixed(2)}`}, 
                    toReceive: {...toReceive, value: `${value}`, total: Number(value) - ( allowOperatorFee ? 0 : Number(getServiceRateValue(value, transfer.transferMethod, true, false)))},
                    // toReceive: {...toReceive, value: `${value}`, total: Number(value)},
                    referralDiscount: {
                        value: userReferralDiscount?.value,
                        type: userReferralDiscount?.type
                    }
                }
            })
        }
    }
    useEffect(()=>{
        getServiceRate();
    }, [transfer.toSend, transfer.allowOperatorFee, rate])

    useEffect(() => {
        getQuoteService(toSend.currency, toReceive.currency);
    }, [])

    useEffect(() => {
        setTotalValue()
    }, [promo, toSend.value, toReceive.value, serviceFee, promo?.code, rate, userReferralDiscount?.value])

    const mutateInputValueDirectly = (rate: any) => {
        if (changedInput === 'toSend') {
            toReceive.value = toSend.value * rate
        } else if (changedInput === 'toReceive'){
            toSend.value = (toReceive.value / rate).toFixed(2)
        } else {

        }
    }

    const setTotalValue = () => {
        let total = Number(toSend.value) + Number(serviceFee) - Number(userReferralDiscount?.value);

        if (
            promo
            && isAcceptablePromoValue(promo)
            ) {
            switch (promo.type) {
                case 'PERCENTAGE':
                    total =  total - ((Number(promo.settings.percentage) * total) / 100)
                    setPromoText(`${promo.settings.percentage}% off`);
                    break;
                case 'FIXED_AMOUNT':
                    total = total - Number(promo.settings.discountAmount);
                    setPromoText(`${promo.settings.discountAmount}${toSend.currency} off`);
                    break;
                case 'FIXED_RATE':
                    if (toSend.currency === promo.settings.baseCurrency && toReceive.currency === promo.settings.targetCurrency) {
                        setPromoText(`1 ${promo.settings.baseCurrency} = ${promo.settings.rate} ${promo.settings.targetCurrency} fixed rate`);
                        // handleXInputChange( 0 , {isSend: true})
                        mutateInputValueDirectly(promo.settings.rate)
                    } else {
                        mutateInputValueDirectly(conversionRate?.rate)
                    }
                    break;
                case 'FREE_OPERATOR_FEE':
                    setPromoText(`0.00${toSend.currency} Operator Fee`);
                    total = Number(toSend.value)
                    break;
                default:
                    setPromoText('');
                    mutateInputValueDirectly(conversionRate?.rate)
                    total = total * 1
            }
        } else {
            setPromoText("");
            mutateInputValueDirectly(conversionRate?.rate)
        }


        dispatch({
            type: TRANSFER, 
            payload: {
                ...transfer,
                toSend: {...toSend, adjusted: toSend.value - ( allowOperatorFee ? 0 : Number(getServiceRateValue(toReceive.value, transfer.transferMethod, false, false))), total: `${total}`},
                // toReceive: {...toReceive, total: Number(toReceive.value) + Number(getServiceRateValue(toReceive.value, transfer.transferMethod, true))},
                toReceive: {...toReceive, total: Number(toReceive.value) - ( allowOperatorFee ? 0 : Number(getServiceRateValue(toReceive.value, transfer.transferMethod, true, false)))},
            }
        })

    }

    const isAcceptablePromoValue = (promo: any) => {
        return Number(toSend.value) >= Number(promo.settings.minimumSpend)
        && Number(toSend.value) <= Number(promo.settings.maximumSpend)
        // && Math.floor(Number(toSend.value) * Number(promo.settings.rate)) === Math.floor(toReceive.value);
    }

    const handleContinue = () => {
        if ( Number(toSend.total) <= 0) {
            toastAction({
                name: "no-value-sent",
                show: true,
                type: "warning",
                timeout: 10000,
                message: "You can't send "+(formatCurrency(toSend.total) || '0.00')+ " "+toSend.currency
            })
            return
        }

        // if (Number(toSend.value) < 1 && toSend?.currency?.toLowerCase() === 'gbp' ) {
        //     toastAction({
        //         show: true,
        //         type: "warning",
        //         timeout: 10000,
        //         message: "You can't send less than 1 GBP"
        //     })
        //     return
        // }

        if (transferMethod === "mobile_money" && (Number(toReceive.total)) > max) {
            toastAction({
                show: true,
                type: "warning",
                timeout: 15000,
                title: "Exceeded maximum!",
                message: `The maximum transferrable amount inclusive of Mobile Operator <a href="#" class='light-green click-hover-tab'> Transfer Fees </a> for mobile money is ${formatCurrency(`${max}`)} frs
                    <div class="hover-tab">
                        <div class="tab-list"> <a href="https://mtn.cm/momo/fees" target="_blank">MTN MOMO Fees</a> </div>
                        <div class="tab-list"> <a href="https://www.orange.cm/fr/tarification-orange-money.html" target="_blank"> Orange Money Fees </a> </div>
                    </div>
                `
            })
            return
        }

        if ((transferMethod === "bank_transfer" || transferMethod === "cash_pickup") && (Number(toSend.value) + Number(serviceFee)) > max ) {
            toastAction({
                show: true,
                type: "warning",
                timeout: 15000,
                title: "Exceeded maximum!",
                message: `The maximum transferrable amount for ${transferMethod.replace('_', ' ')} is ${formatCurrency(`${max}`)} ${toSend.currency}`
            })
            return;
        }
        setNewQuote(toSend.currency, toReceive.currency);
        history.push(paths.RECIPIENT + `?user=${userId}`)
    }


    const getTransferFeeText = (selectedMethod: string) => {
        const texts: any = {
            "mobile_money": `Mobile Operator <a href="#" class='light-green click-hover-tab'>Cash Out Fee </a> from: 
                <div class="hover-tab">
                    <div class="tab-list"> <a href="https://mtn.cm/momo/fees" target="_blank">MTN MOMO Fees</a> </div>
                    <div class="tab-list"> <a href="https://www.orange.cm/fr/tarification-orange-money.html" target="_blank"> Orange Money Fees </a> </div>
                </div>
            `,
            "bank_transfer": "Bank Pay Out Fee: ",
            "cash_pickup": "Cash Pick-up Partner Fee: "
        }

        return texts[selectedMethod];
    }

    const getXInputMarginAdjust = (newLinesValues: boolean[]) => {
        let initialMargin = 150;

        for( const newLine of newLinesValues ) {
            if (newLine) {
                initialMargin += 50
            }
        }

        return initialMargin + "px";
    }

    return (
        <Body>
            <ProgressBar />
            <div className="page-content">
                <div className="box">
                    <div className="head">
                        <span className="capitalize">{transferMethod?.replace("_", " ")}</span>
                        <span><Link to={paths.TRANSFER_METHOD}>Change transfer method</Link></span>
                    </div>

                    <div className="calc">
                        <div className="hero-rect">
                            <div>
                                <ExchangeRateInput setChangedInput={() => setChangedInput('toSend')} max={transferMethod !== constants.MOBILE_MONEY ? max : undefined} data={toSend} handleXInputChange={handleXInputChange} countries={payInCountries}/>
                            </div>
                            <div className="wrapper">
                                <div className="timeline-box">
                                    <div className="timeline timeline-1"> <span><i><img src="./assets/svgs/times.svg" alt=""/></i> <span className={`deep-green no-wrap ${promo?.type === "FIXED_RATE" && promoText ? "strikethrough" : ""}`} >1 {toSend.currency} = {formatCurrency(conversionRate?.rate)} {toReceive.currency}</span></span></div>
                                    {Boolean(Number(serviceFee)) && <div className={`timeline timeline-2`}>
                                        <span><i><img src="./assets/svgs/plus.svg" alt=""/></i> 
                                            <span className={`${allowOperatorFee ? "" : "strikethrough"} wide`}>
                                                <div style={{display: 'inline'}} dangerouslySetInnerHTML={{__html: getTransferFeeText(transferMethod)}}></div>
                                                <span className={`deep-green ${(promo?.type === "FREE_OPERATOR_FEE"  && isAcceptablePromoValue(promo) || !allowOperatorFee) ? "strikethrough" : ""}`}>{serviceFee} {toSend.currency}</span>
                                            </span>
                                        </span>
                                    </div>}
                                    <div className="timeline timeline-3"> <span><i><img src="./assets/svgs/minus.svg" alt=""/></i>  <span className="sb-charges">SB Remit charges you <span className="deep-green">0.00 {toSend.currency}</span> for this transfer </span> </span></div>
                                    {promo && <div className="timeline timeline-2"> <span><i><img src="./assets/svgs/plus.svg" alt="" /></i>  <span>Promo code { promoText ? <span className="deep-green"> {promoText} </span> : <span className="red-txt"> *Spend btw: {promo?.settings?.minimumSpend} {toSend.currency} and {promo?.settings?.maximumSpend} {toSend.currency}  </span> }</span> </span></div>}
                                    { Boolean(Number(user?.referral?.useCount) || user?.referral?.newUserBonusActive) && <div className="timeline timeline-2"> <span><i><img src="./assets/svgs/minus.svg" alt="" /></i>  <span> Referral bonus { <span className="deep-green"> { userReferralDiscount?.value } {toSend?.currency} </span> }</span> </span></div>}
                                    <div className="timeline timeline-4"> <span><i><img src="./assets/svgs/equal.svg" alt=""/></i>  <span>Total to pay <span className="deep-green">{formatCurrency(`${toSend.total}`)} {toSend.currency}</span></span></span></div>
                                    <div className="timeline timeline-5"> <span><i className="fas fa-circle"></i> <span className="not-mobile"><p>Transfer arrives <b>Within 30 minutes</b></p></span> </span></div>
                                </div>
                            </div>
                            <div className="receive" style={{marginTop: getXInputMarginAdjust([Boolean(promo),  Boolean(Number(user?.referral?.useCount) || user?.referral?.newUserBonusActive), Boolean(Number(serviceFee))])}}>
                                <ExchangeRateInput setChangedInput={() => setChangedInput('toReceive')} max={transferMethod === constants.MOBILE_MONEY ? max : undefined} data={toReceive} handleXInputChange={handleXInputChange} countries={payOutCountries} />
                            </div>
                            <div className="toggle">
                                <FancyToggle label="Include operator fee" isActive={allowOperatorFee} setIsActive={() => setAllowOperatorFee(!allowOperatorFee)} />
                            </div>
                            {/* <div className="footnote desktop-hide">SBremit charges you <b className="green-txt">0.00 {toSend.currency}</b> for this transfer</div> */}

                            <PromoCodeField transfer={transfer} />
                        </div>
                    </div>
                </div>
                <div className="btns"><span>Cancel</span> <button onClick={()=>handleContinue()}>Continue</button> </div>
            </div>
        </Body>
    )


}

export default GetQuote;
