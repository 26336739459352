import React from 'react';
import {FormSubmitted} from './formStyle'
import tick from './icons/tick.png'

export const PreorderCompleted = (
  {setStep}: {setStep: React.Dispatch<React.SetStateAction<"step1" | "step2" | "step3" | "completed">>}
) => {
    return (
        <FormSubmitted>
            <div className="box">
                <img src={tick} alt="success" />
                <p>You have successfully completed the pre-order form. A service agent from our side will contact you shortly.</p>
                <div className="buttons">
                <button onClick={() => setStep('step1')}>Start new exchange</button>
                </div>
            </div>
        </FormSubmitted>
    )
}

export default PreorderCompleted 
