import styled from "styled-components";

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  transition: all 0.5s ease-in-out;

  @media (max-width: 500px) {
    margin-top: 40px;
  }

  ._header {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

export const PromosConatinerStyles = styled.section`
  width: 100%;
  height: auto;
  //margin: 32px 0;

  .user_table_container {
    //margin-top: 50px;
    background: #ffffff;
    border: 1px solid rgba(144, 152, 178, 0.3);
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    height: auto;
    .section_1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 32px 24px;
      gap: 10px;
      flex-wrap: wrap;
      p {
        font-weight: 400;
        font-size: clamp(18px, 4vw, 24px);
        line-height: 39px;
        color: #424242;
        flex-shrink: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .right_content_space_btw {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        .searchbar_section {
          display: flex;
          align-items: center;
          gap: 24px;
        }
        .buttons {
          display: flex;
          align-items: center;
          //margin-left: 50px;
          gap: 16px;
          @media (max-width: 768px) {
            margin-left: 0px;
          }
          .add_user {
            span {
              color: #ffffff;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
            }
          }
          .ref_config {
            background: rgba(104, 166, 151, 0.2);
            border-radius: 8px;
            padding: 10px 24px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #007b5d;
            outline: none;
            border: none;
            cursor: pointer;

            &:hover {
              background: rgba(104, 166, 151, 0.25);
            }
          }
        }
      }
      .right_content_space_btw {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
    .section_2 {
      padding: 0px 24px;
      //margin-bottom: 32px;
      .table_tabs {
        width: 100%;
        display: flex;
        //justify-content: space-between;
        gap: 2%;
        border-bottom: 1px solid #dedee1;

        .active_table_tab,
        .table_tab {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 18px;
          //line-height: 18px;
          color: #808080;
          cursor: pointer;
        }
        .active_table_tab {
          color: #007b5d;
          font-weight: 500;
          border-bottom: 3.5px solid #007b5d;
          //transition: backgorund 0.2s ease-in-out;
          cursor: pointer;
        }
      }
    }
  }
`;

export const ButtonTabsStyle = styled.button<{ $active: boolean }>`
  display: flex;
  padding: 20px 14px;
  width: 166px;
  align-items: center;
  justify-content: center;
  gap: 20px;

  border-radius: 8px;
  background: rgba(0, 123, 93, 0.05);
  outline: none;
  border: ${(props) =>
    props.$active ? "1px solid #007b5d" : "1px solid rgba(0, 123, 93, 0.05)"};
  cursor: pointer;

  color: ${(props) => (props.$active ? "#007b5d" : "#292a2e")};
  font-size: 20px;
  font-weight: 500;

  &:hover {
    //border: 1px solid #007b5d;
    color: #007b5d;
  }
`;
