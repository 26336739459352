import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "./Users.css";

import { Button, Tabs } from "antd";

import NewUserModal from "src/components/modules/new-user-modal/NewUserModal";
import TableSearchBar from "src/components/modules/parts/SearchBar";
import { SelectDays } from "src/components/modules/parts/SelectDays";
import UsersTable from "src/components/modules/table/custom-table-children/UsersTable";
import { HORIZONTAL_LOADING, USERS } from "src/redux/actionTypes";
import store from "src/redux/store";
import { UsersTableTabs } from "src/util/constants";
import { useUsersData } from "./helpers/useRQusersData";

const Body = styled;

export const Users = ({
  dashboard = true,
  activeFilterBox = "setDashbordFilter",
}: {
  dashboard: boolean;
  activeFilterBox: string;
}) => {
  const dispatch = useDispatch();
  const { limit, offset, search, status, currentUsersPage } = useSelector(
    (state: any) => state.users
  );
  const total = useSelector((state: any) => state.users.total);
  const daysState = useSelector((state: any) => state.users.days);
  const statusState = useSelector((state: any) => state.users.status);
  const searchState = useSelector((state: any) => state.users.search);
  const [days, setDays] = React.useState(daysState);

  const { data, isFetching, isLoading } = useUsersData({
    days,
    limit,
    offset,
    search,
    status,
  });
  const { collections: users } = data || {};

  useEffect(() => {
    if (isFetching || isLoading) {
      store.dispatch({ type: HORIZONTAL_LOADING, payload: true });
    } else {
      store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
    }
  }, [isFetching, isLoading]);

  const indexOfTableTab = UsersTableTabs.findIndex(
    (tab) => tab.format === statusState
  );

  const [openUserModal, setOpenUserModal] = useState(false);
  const [searchValue, setSearchValue] = useState(searchState);
  const [activeTableTab, setActiveTableTab] = React.useState(
    String(indexOfTableTab + 1)
  );
  const [userStatus, setUserStatus] = React.useState(statusState);

  const handleSearchInputChange = (event: any) => {
    setSearchValue(event.target.value);
    updateStoreSearchValue(event.target.value);
  };

  const handleSearchCancelClick = () => {
    setSearchValue("");
  };

  const updateStoreSearchValue = (value: string) => {
    const usersState = store.getState().users;
    dispatch({
      type: USERS,
      payload: {
        ...usersState,
        currentUsersPage: 1, //reset current page for new offset calc
        search: value,
      },
    });
  };

  React.useEffect(() => {
    const usersState = store.getState().users;
    // const encodedSearchValue = encodeURIComponent(searchValue)//searchValue.replace(/\s+/g, "&");
    dispatch({
      type: USERS,
      payload: {
        ...usersState,
        search: searchValue,
      },
    });
  }, [searchValue]);

  const onChange = (key: string) => {
    setActiveTableTab(key);
    if (activeFilterBox !== "setDashbordFilter") {
      setUserStatus(activeFilterBox);
    } else {
      setUserStatus(UsersTableTabs[Number(key) - 1].format);
    }
  };

  const Adduser = () => {
    return (
      <Button
        type="primary"
        size="large"
        className="btn_contained"
        //style={{ height: 48 }}
        onClick={() => {
          setOpenUserModal((prev) => !prev);
        }}
      >
        Add User
      </Button>
    );
  };

  return (
    <Body>
      <NewUserModal open={openUserModal} setOpen={setOpenUserModal} />

      <div className="user_table_container">
        <div className="section_1">
          <p>Users ({total})</p>
          <div className="show_add_user_btn_sm">
            <Adduser />
          </div>
          <div className="right_content_space_btw">
            {dashboard && (
              <div className="date_select">
                <SelectDays days={days} setDays={setDays} />
              </div>
            )}

            <div className="searchbar_section">
              <TableSearchBar
                value={searchValue}
                placeholder="search user's profile"
                onChange={handleSearchInputChange}
                onCancel={handleSearchCancelClick}
              />
            </div>

            <div className="buttons">
              <div className="adduser">
                <Adduser />
              </div>
            </div>
          </div>
        </div>
        {dashboard && (
          <div className="section_2">
            <Tabs
              items={UsersTableTabs}
              onChange={onChange}
              activeKey={activeTableTab}
              defaultActiveKey="1"
            />
          </div>
        )}
        <UsersTable
          currentUsersPage={currentUsersPage}
          tableBody={users}
          days={days}
          status={userStatus}
        />
      </div>
    </Body>
  );
};
