import { toastAction, updateUserStatus } from "src/redux/actions/actions";
import { IActionButtons } from "../components/modules/action-buttons/ActionButtons";
import { ITable, TableCell } from "../components/modules/table/ITable";
import Table from "../components/modules/table/Table";
import constants from "./constants";
import { settings } from "./settings";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getImage } from "./utility";

export const asset = (folder: string, name: string) => {
  folder = folder === "icons" ? "svgs" : folder;
  return `/assets/${folder}/${name}`;
};

export const getQueryParam = (key: string): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const searchParam = urlParams.get(key);
  return searchParam || "";
};

export const parseEndpointParameters = (
  endpoint: string,
  $_1: string = "",
  $_2: string = "",
  $_3: string = "",
  $_4: string = ""
) => {
  return endpoint
    .replace("$_1", $_1)
    .replace("$_2", $_2)
    .replace("$_3", $_3)
    .replace("$_4", $_4);
};

export const parsePathParameters = (path: string, params: string[]) => {
  for (const param of params) {
    path = path.replace(/\/:([a-zA-Z0-9]*)/, "/" + param);
  }
  return path;
};

export const formatCurrency = (
  value: string,
  currency: string = ""
): string => {
  value = value || "0";
  value = getMoneyValue(value).toFixed(2);
  return (
    currency + value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  );
};

export const getMoneyValue = (formattedAmount: string): number => {
  return Number(formattedAmount?.toString()?.replace(",", ""));
};

export const convertDateString = (value: any) => {
  if (value === undefined) return "N/A";
  value = parseInt(value) * 1000;
  let d: any = new Date(value);
  d = d.toDateString();
  d = d.split(" ");
  return `${d[2]} ${d[1]} ${d[3]}`;
};

export const convertToTimeString = (value: any) => {
  if (value === undefined) return "N/A";
  value = parseInt(value) * 1000;
  const d = new Date(value);
  return d.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

export const getValueFromArray = <T>(
  id: string | number,
  targetId: string | number,
  array: any[],
  keyToReturn?: any
): T => {
  // eslint-disable-next-line eqeqeq
  const value = array?.filter((a) => a[targetId] == id)[0];
  return value?.[keyToReturn] || value;
};

export const genPaginationHashTable = (array: any[], noPerPage: number) => {
  const hashTable: any = {};
  // const paginationList = Math.ceil(array.length / noPerPage)
  array?.forEach((val: any, i: number) => {
    const temp = Math.floor(i / noPerPage) + 1;
    if (hashTable[temp]) {
      hashTable[temp].push(val);
    } else {
      hashTable[temp] = [val];
    }
  });

  const pages = Object.keys(hashTable);
  return { paginated: hashTable, pages };
};

export const sortByTimeDesc = (array: any[]) => {
  return array?.sort((a, b) =>
    a.dateCreated < b.dateCreated ? 1 : b.dateCreated < a.dateCreated ? -1 : 0
  );
};

export const getWeekStart = (d: any) => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day;
  return new Date(d.setDate(diff));
};

export const getWeekEnd = (d: any) => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() + (6 - day);
  return new Date(d.setDate(diff));
};

export const getMonthStart = (d: any) => {
  const date = new Date(d);
  const y = date.getFullYear();
  const m = date.getMonth();
  const firstDate = new Date(y, m, 1);
  return firstDate;
};

export const getMonthEnd = (d: any) => {
  const date = new Date(d);
  const y = date.getFullYear();
  const m = date.getMonth();
  const lastDate = new Date(y, m + 1, 0);
  return lastDate;
};

export const convertToJSTimestamp = (timestamp: number = 0) => {
  return timestamp * 1000;
};

export const convertFromJSTimestamp = (timestamp: number = 0) => {
  return Math.floor(timestamp / 1000);
};

export const getDayStartTimestamp = (date: string) => {
  const day = new Date(date);
  day.setHours(0, 0, 0, 0);
  return day.getTime();
};

export const getDayEndTimestamp = (date: string) => {
  const day = new Date(date);
  day.setHours(23, 59, 59, 999);
  return day.getTime();
};

export const filterByDateRange = (
  array: any[],
  upperBound: any,
  lowerBound: any
) => {
  if (!upperBound && !lowerBound) return array;

  return array.filter((entity: any, i) => {
    return (
      Number(entity.dateCreated) <=
        convertFromJSTimestamp(getDayEndTimestamp(lowerBound?.getTime())) &&
      entity.dateCreated >=
        convertFromJSTimestamp(getDayStartTimestamp(upperBound?.getTime()))
    );
  });
};

export const filterByProperty = (
  array: any[],
  property?: string,
  propertyValue?: any
) => {
  if (!property || propertyValue === null) return array;
  if (property === "status" && propertyValue === "active") {
    return array.filter(
      (entity) =>
        entity[property]?.toLowerCase() ===
          constants.TRANSFER_STATUS_PENDING.toLowerCase() && entity["approved"]
    );
  }
  return array.filter(
    (entity) => entity[property]?.toLowerCase() === propertyValue?.toLowerCase()
  );
};
export const formatCurrencyWithoutFloats = (
  value: any,
  currency: string = ""
): string => {
  value = value || "0";
  value = getMoneyValue(value);
  return (
    currency + value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  );
};

export const replaceUnderscores = (method: string) => {
  return method?.replace("_", " ");
};

export const compareDatesXLessThanY = (x: string, y: string) => {
  const xDate = new Date(x);
  const yDate = new Date(y);
  return xDate < yDate;
};

export const getElapsedMinutesFromDate = (dateString: string) => {
  return Math.abs(new Date(dateString).getMinutes() - new Date().getMinutes());
};

export const getCorrespondingActions = (
  status: string,
  callbacks: any,
  flag = ""
): IActionButtons["details"] => {
  let actionDetails: IActionButtons["details"] = [];
  const approve: any = {
    text: "Approve",
    className: "btn-green-outline",
  };
  const reject: any = {
    text: "Reject",
    className: "btn-red-outline",
  };

  if (callbacks?.approve) {
    approve["function"] = callbacks.approve;
  }

  if (callbacks?.reject) {
    reject["function"] = callbacks.reject;
  }

  switch (status.toUpperCase()) {
    case constants.TRANSFER_STATUS_PENDING_VERIFICATION:
    case constants.TRANSFER_STATUS_PENDING_DOCUMENTATION:
    case constants.TRANSFER_STATUS_WATCHLIST_FAILED:
    case constants.TRANSFER_STATUS_PAYMENT_SUSPENDED:
    case constants.TRANSFER_STATUS_PENDING_APPROVAL:
      actionDetails.push(approve);
      actionDetails.push(reject);
      break;

    case constants.TRANSFER_FLAG_FRAUD_ALERT:
    case constants.TRANSFER_STATUS_PAYMENT_FRAUD:
      reject["text"] = "Cancel transfer";
      actionDetails.push(approve);
      actionDetails.push(reject);
      break;

    case constants.TRANSFER_STATUS_REJECTED:
      approve["text"] = "Mark as refunded";
      reject["text"] = "Cancel transfer";
      actionDetails.push(approve);
      actionDetails.push(reject);
      break;

    case constants.TRANSFER_STATUS_PAYMENT_COMPLETED:
      approve["text"] = "Remit funds to recipient with Pivot";
      reject["text"] = "Manually mark transfer as complete";
      actionDetails.push(approve);
      actionDetails.push(reject);
      break;

    case constants.TRANSFER_STATUS_PENDING:
    case constants.TRANSFER_STATUS_CANCELLED:
    case constants.TRANSFER_STATUS_COMPLETE:
    case constants.TRANSFER_STATUS_EXPIRED:
    case constants.TRANSFER_STATUS_REFUNDED:
    default:
      actionDetails.push({ text: "No action" });
      break;
  }

  // if (flag) {
  //   switch (flag.toUpperCase()) {
  //     case constants.TRANSFER_FLAG_FRAUD_ALERT:
  //       approve["text"] = "Approve transfer";
  //       reject["text"] = "Block transfer and suspend user";
  //       actionDetails = [];
  //       actionDetails.push(approve);
  //       actionDetails.push(reject);
  //       break;

  //     default:
  //       break;
  //   }
  // }

  return actionDetails;
};

export const getUserActionCallbacks = (user: any): any => {
  const actionCallbacks: any = {};

  actionCallbacks["SUSPENDED"] = {
    approve: () => updateUserStatus(user?.id, "ACTIVE"),
    reject: () => updateUserStatus(user?.id, "TEMPORARILY_LOCKED"),
  };

  actionCallbacks["TEMPORARILY_LOCKED"] = {
    approve: () => updateUserStatus(user?.id, "ACTIVE"),
    reject: () => updateUserStatus(user?.id, "SUSPENDED"),
  };

  return actionCallbacks[user?.status?.toUpperCase()];
};

export const getCorrespondingUserActions = (
  status: string,
  callbacks: any,
  flag = ""
): IActionButtons["details"] => {
  let actionDetails: IActionButtons["details"] = [];
  const approve: any = {
    text: "Approve",
    className: "btn-green-outline",
  };
  const reject: any = {
    text: "Reject",
    className: "btn-red-outline",
  };

  if (callbacks?.approve) {
    approve["function"] = callbacks.approve;
  }

  if (callbacks?.reject) {
    reject["function"] = callbacks.reject;
  }
  switch (status?.toUpperCase()) {
    case "SUSPENDED":
      approve["text"] = "Reactivate";
      reject["text"] = "Revert to temp locked";
      actionDetails.push(approve);
      actionDetails.push(reject);
      break;
    case "TEMPORARILY_LOCKED":
      approve["text"] = "Reactivate";
      reject["text"] = "Block";
      actionDetails.push(approve);
      actionDetails.push(reject);
      break;
    default:
      break;
  }

  return actionDetails;
};
export const getMatchStatusIndicator = (status: string) => {
  const indicators: any = {
    match: `<img style="width: 25px" src="https://res.cloudinary.com/codennerd/image/upload/v1645687011/sbremit/correct_uqbasa.png" />`,
    nomatch: `<img style="width: 25px" src="https://res.cloudinary.com/codennerd/image/upload/v1645687012/sbremit/wrong_r4miwc.png" />`,
    missing: `<img style="width: 25px" src="https://res.cloudinary.com/codennerd/image/upload/v1645687011/sbremit/minus_cim42i.png" />`,
    noinput: `<img style="width: 25px" src="https://res.cloudinary.com/codennerd/image/upload/v1645687012/sbremit/empty_kwmody.png" />`,
    hidden: `<img style="width: 25px" src="https://res.cloudinary.com/codennerd/image/upload/v1645687012/sbremit/question_j44ram.png" />`,
  };

  return indicators[status?.toLowerCase()?.replaceAll(" ", "")];
};

export const getParsedVerificationData = (rawData: any) => {
  try {
    const rawResponse = JSON.parse(rawData?.meta?.rawResponse);
    const body = JSON.parse(rawResponse.body);
    return body;
  } catch (error) {}
  return {};
};

export const getParsedVerificationSubmittedData = (rawData: any) => {
  try {
    const rawResponse = JSON.parse(rawData?.data?.submittedData);
    return rawResponse;
  } catch (error) {
    console.log(error, "error");
  }
  return {};
};

export const processVerificationDataForHeroTable = (rawData: any): ITable => {
  const headings: TableCell[][] = [];
  let rows: TableCell[][] = [];
  const countries: any = {
    GB: "United Kingdom",
  };
  try {
    const rawResponse = JSON.parse(rawData?.meta?.rawResponse);
    const body = JSON.parse(rawResponse.body);

    rows = [
      [
        {
          text: "Country:",
        },
        {
          html: countries[body.CountryCode],
        },
        {
          text: "Legend",
        },
      ],
      [
        {
          text: "Transaction Record ID:",
        },
        {
          text: body.Record.TransactionRecordID,
        },
        {
          html: `
                        <div style="min-width: 500px;">
                            <div class="grid-col-1-1 m-5"> 
                                <span>Match</span> 
                                <span>${getMatchStatusIndicator(
                                  "match"
                                )}</span> 
                            </div>

                            <div class="grid-col-1-1 m-5"> 
                                <span>No Match</span> 
                                <span>${getMatchStatusIndicator(
                                  "nomatch"
                                )}</span> 
                            </div>

                            <div class="grid-col-1-1 m-5"> 
                                <span>Missing</span> 
                                <span>${getMatchStatusIndicator(
                                  "missing"
                                )}</span> 
                            </div>

                            <div class="grid-col-1-1 m-5"> 
                                <span>No Input</span> 
                                <span>${getMatchStatusIndicator(
                                  "noinput"
                                )}</span> 
                            </div>

                            <div class="grid-col-1-1 m-5"> 
                                <span>Hidden</span> 
                                <span>${getMatchStatusIndicator(
                                  "hidden"
                                )}</span> 
                            </div>

                        </div>
                    `,
          rowSpan: 7,
        },
      ],
      [
        {
          text: "Transaction Date:",
        },
        {
          text: body.UploadedDt,
        },
      ],
      [
        {
          text: "Verification Rule:",
        },
        {
          text: `
                        ${body?.Record?.Rule?.RuleName} 
                        ${body?.Record?.Rule?.Note} 
                    `,
        },
      ],
      [
        {
          text: "Account Name:",
        },
        {
          text: "Sukate & Bezeboh Ltd | Sub-Account",
        },
      ],
      [
        {
          text: "Username:",
        },
        {
          text: "-",
        },
      ],
      [
        {
          text: "Transaction Type: ",
        },
        {
          text: "-",
        },
      ],
      [
        {
          text: "Watchlist",
        },
        {
          text: "-",
        },
      ],
    ];
  } catch (error) {}

  return { headings, rows };
};

export const processVerificationDataForMatchTable = (rawData: any): ITable => {
  const headings: TableCell[][] = [];
  let rows: TableCell[][] = [];

  let body;

  try {
    const rawResponse = JSON.parse(rawData?.meta?.rawResponse);
    body = JSON.parse(rawResponse.body);
    const dataSourceResults = body.Record.DatasourceResults;
    headings.push([
      {
        text: "",
      },
      {
        text: "Data sources",
        colSpan: dataSourceResults.length,
      },
    ]);

    const secondaryHeading: TableCell[] = [
      {
        html: "<b>Fields</b>",
      },
    ];

    rows = getVerificationDataFields(dataSourceResults);

    for (const data of dataSourceResults) {
      const heading = {
        text: data.DatasourceName,
      };
      secondaryHeading.push(heading);
    }

    headings.push(secondaryHeading);
  } catch (error) {
    console.log(error);
  }

  return { headings, rows };
};

const getVerificationDataFields = (
  data: any[],
  target = "DatasourceFields"
) => {
  let fields: any[] = [];
  data.forEach((d) => {
    if (fields.length < d[target]?.length) {
      fields = d[target];
    }
  });

  const rows = [];
  for (const field of fields) {
    const fieldName = field.FieldName;
    const row: TableCell[] = [
      {
        text: fieldName,
      },
    ];
    for (const d of data) {
      const currentResult = d[target].find(
        (t: any) => t.FieldName === fieldName
      );
      const cell = {
        html: getMatchStatusIndicator(currentResult?.Status),
      };
      row.push(cell);
    }
    rows.push(row);
  }

  return rows;
};

export const processVerificationDataForAppendedInfoTable = (
  rawData: any
): ITable => {
  const headings: TableCell[][] = [];
  let rows: TableCell[][] = [];

  try {
    const rawResponse = JSON.parse(rawData?.meta?.rawResponse);
    const body = JSON.parse(rawResponse.body);
    const data = body.Record.DatasourceResults;

    for (const d of data) {
      if (d.AppendedFields.length) {
        let row: TableCell[] = [
          {
            text: `Datasource: ${d.DatasourceName}`,
          },
        ];

        rows.push(row);

        const dataRows: TableCell[][] = [];

        for (const field of d.AppendedFields) {
          if (field.FieldName === "Score") {
            continue;
          }
          const dataRow: TableCell[] = [
            {
              text: field.FieldName,
            },
            {
              text: field.Data,
            },
          ];

          dataRows.push(dataRow);
        }

        row = [
          {
            component: () => Table({ rows: dataRows }),
            __component: {
              headings: [],
              rows: dataRows,
            },
          },
        ];

        rows.push(row);
      }
    }
  } catch (error) {}

  return { headings, rows };
};

export const processVerificationDataForAppendedDetailsTable = (
  rawData: any
): ITable => {
  const headings: TableCell[][] = [];
  let rows: TableCell[][] = [];

  try {
    const rawResponse = JSON.parse(rawData?.meta?.rawResponse);
    const body = JSON.parse(rawResponse.body);
    const datasourceResults = body.Record.DatasourceResults;

    for (const result of datasourceResults) {
      const errorCell: TableCell[] = [];

      if (result?.Errors?.length) {
        const errorRows: TableCell[][] = [
          [
            {
              text: "Error Code",
            },
            {
              text: "Message",
            },
          ],
        ];

        for (const error of result?.Errors) {
          const errorRow: TableCell[] = [
            {
              text: error.Code,
            },
            {
              text: error.Message,
            },
          ];

          errorRows.push(errorRow);
        }

        errorCell.push({
          text: "Errors",
        });
        errorCell.push({
          component: () => Table({ rows: errorRows }),
          __component: { headings: [], rows: errorRows },
        });
      }

      const newRow = [
        [
          {
            text: "Datasource",
          },
          {
            text: result.DatasourceName,
          },
        ],
        [
          {
            text: "Result found",
          },
          {
            text: result?.DatasourceFields?.length ? "Yes" : "No",
          },
        ],
        errorCell,
      ];
      const row: TableCell[] = [
        {
          component: () => Table({ rows: newRow }),
          __component: {
            headings: [],
            rows: newRow,
          },
        },
      ];

      rows.push(row);
    }
  } catch (error) {}

  return { headings, rows };
};

export const processVerificationDataForScoreReportTable = (rawData: any) => {
  const headings: TableCell[][] = [];

  let rows: TableCell[][] = [];

  try {
    const rawResponse = JSON.parse(rawData?.meta?.rawResponse);
    const body = JSON.parse(rawResponse.body);
    const datasourceResults = body.Record.DatasourceResults;
    for (const d of datasourceResults) {
      if (d.AppendedFields.length) {
        const dataRows: TableCell[][] = [];

        for (const field of d.AppendedFields) {
          if (field.FieldName === "Score") {
            let cellContent = `${d.DatasourceName} 
                        <br/>
                        
                        `;
            const scoreData = JSON.parse(field.Data);
            let count = 1;
            for (const data of scoreData) {
              cellContent += `
                                <ul>
                                ${count}:
                                <br/>
                                <ul>
                                Type: <b>${data.Type}</b>
                                <br />
                                Value: <b> ${data.Value}</b>
                                <br/>
                                Description: <b>${data.Description}</b>
                                </ul>
                                <ul>
                            `;
              count++;
            }
            const dataRow: TableCell[] = [
              {
                html: cellContent,
              },
            ];

            rows.push(dataRow);
          }
        }
      }
    }
  } catch (error) {}

  return { headings, rows };
};

export const getUserReferralDiscount = (user: any, appValues: any) => {
  const referralSettings: any = getValueFromArray(
    "settings",
    "name",
    appValues?.values?.data || []
  );
  let discount =
    Number(user?.referral?.useCount || 0) *
    Number(referralSettings?.data?.referrerDiscountValue);

  if (user?.referral?.newUserBonusActive) {
    discount += Number(referralSettings?.data?.referredUserDiscountValue);
  }

  return {
    value: discount,
    type: referralSettings?.data?.referralDiscountType,
  };
};

export const getMax = (transferMethod: string) => {
  return settings[(transferMethod + "_MAX").toUpperCase()];
};

export const isUserFirstTransaction = (user: any): boolean => {
  return !Boolean(user.meta.exceededMaxUnverifiedTransactions);
};

export const userHasReachedFinalVerificationStage = (user: any) => {
  return (
    user?.settings?.verificationStage === constants.VERIFICATION_STAGE_FINAL
  );
};

export const userIsVerified = (user: any): boolean => {
  return Boolean(user.meta.verified) && user?.meta?.verified !== "retry";
};

export const getInclusiveText = (transferMethod: string) => {
  const texts: any = {
    mobile_money: "(inclusive of cash out fee)",
    bank_transfer: "(inclusive of payout partner fee)",
    cash_pickup: "(inclusive of pick-up partner fee)",
    default: "(operator fee inclusive)",
  };

  return texts[transferMethod || "default"];
};

export const parseWithUnderscores = (method: string) => {
  return method?.replace(" ", "_")?.toLowerCase();
};

export const getDateTimeNowInYYYY_MM_DD__HH_MM_SS = () => {
  const date = new Date();
  const aaaa = date.getUTCFullYear();
  let gg: any = date.getUTCDate();
  let mm: any = date.getUTCMonth() + 1;

  if (gg < 10) gg = "0" + gg;

  if (mm < 10) mm = "0" + mm;

  const cur_day = aaaa + "-" + mm + "-" + gg;

  let hours: any = date.getUTCHours();
  let minutes: any = date.getUTCMinutes();
  let seconds: any = date.getUTCSeconds();

  if (hours < 10) hours = "0" + hours;

  if (minutes < 10) minutes = "0" + minutes;

  if (seconds < 10) seconds = "0" + seconds;

  return cur_day + " " + hours + ":" + minutes + ":" + seconds;
};

export function getFirstLetter(str: string) {
  if (typeof str !== "string" || str.length === 0) {
    //console.log("Invalid input. Please provide a non-empty string.");
    return;
  }

  return str[0];
}

export const copyToClipBoard = (text: string) => {
  const elem = document.createElement("textarea");
  elem.value = text;
  document.body.appendChild(elem);
  elem.select();
  document.execCommand("copy");
  document.body.removeChild(elem);

  toastAction({
    show: true,
    type: "info",
    timeout: 5000,
    message: "Text copied to clipboard",
  });
};

export function convertTimestampToRegularDate(timestamp: any) {
  const date = new Date(timestamp * 1000);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  const convertedDate = `${day}/${month}/${year}`;
  return convertedDate;
}

export const getAcctStatus = (status: string) => {
  const lowercaseStatus = status?.toLowerCase();

  if (lowercaseStatus === "active") {
    return { value: "active", color: "green" };
  } else if (lowercaseStatus === "deleted") {
    return { value: "deleted", color: "red" };
  } else {
    const formattedStatus = replaceUnderscores(lowercaseStatus);
    if (formattedStatus === "temporarily locked") {
      return { value: formattedStatus, color: "blue" };
    } else {
      // Handle unknown status or status with null value from replaceUnderscores
      return { value: lowercaseStatus || "unknown", color: "" };
    }
  }
};

// Function to get the transaction status type for the badge
export const getStatusType = (status: string) => {
  switch (status) {
    case constants.TRANSFER_STATUS_PAYMENT_COMPLETED:
    case constants.TRANSFER_STATUS_COMPLETE:
      return "success";
    case constants.TRANSFER_BLOCKED:
    case constants.TRANSFER_STATUS_REFUNDED:
    case constants.TRANSFER_STATUS_CANCELLED:
      return "default";
    case constants.TRANSFER_STATUS_PENDING_DOCUMENTATION:
    case constants.TRANSFER_STATUS_PENDING_VERIFICATION:
    case constants.TRANSFER_STATUS_PENDING:
      return "warning";
    case constants.TRANSFER_STATUS_PENDING_APPROVAL:
      return "processing";
    case constants.TRANSFER_PAYMENT_CANCELLED:
    case constants.TRANSFER_STATUS_EXPIRED:
    case constants.TRANSFER_FLAG_FRAUD_ALERT:
    case constants.TRANSFER_STATUS_REJECTED:
    case constants.TRANSFER_STATUS_PAYMENT_FRAUD:
      return "error";
    default:
      return "default";
  }
};

// Function to get the transaction status text for the badge
export const getStatusText = (status: string) => {
  if (status.includes("_")) {
    return status
      .toLowerCase()
      .split("_")
      .slice(1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } else {
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }
};

export const getActionButtonText = (btnText: string) => {
  switch (btnText) {
    case "Mark as refunded":
      return "Refunded";
    case "Cancel transfer":
      return "Cancel";
    case "Remit funds to recipient with Pivot":
      return "Pivot";
    case "Manually mark transfer as complete":
      return "Manual";
    default:
      return btnText;
  }
};

function getFormattedDate() {
  const today = new Date();

  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
  const year = String(today.getFullYear()).slice(-2); // Get the last two digits of the year

  let hours = today.getHours();
  const minutes = String(today.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "pm" : "am";

  // Convert to 12-hour format
  hours %= 12;
  hours = hours || 12;

  const time = `${hours}:${minutes}${ampm}`;
  const date = `${day}/${month}/${year}`;

  return `${date} - ${time}`;
}

export const downloadPdf = (data: any, header: any, type: string) => {
  const doc = new jsPDF();
  const singledataCopy = data.map((obj: any) => Object.values(obj));

  const logoUrl = getImage("main-logo.svg", "svgs");
  const logoWidth = 28;
  const logoHeight = 8;

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Set the grid banner color
  const gridColor = "#007B5D";

  // Calculate the X and Y coordinates to place the logo at the bottom center of the page
  const logoX = (pageWidth - logoWidth) / 2;
  const logoY = pageHeight - 6 - logoHeight;

  const startY = 15;

  //doc.addImage(logoUrl, "PNG", logoX, logoY, logoWidth, logoHeight);

  doc.text(
    `${type} Data Report - Downloaded on (${getFormattedDate()})`,
    20,
    10
  );
  doc.setTextColor("red ");
  if (type === "Verification") {
    data.forEach((element: any, index: number) => {
      const dataCopy = element?.map((obj: any) => Object.values(obj));

      autoTable(doc, {
        theme: "grid",
        head: [header], // Use header as a simple array
        headStyles: {
          fillColor: gridColor,
        },
        columns: header,
        body: dataCopy,
        columnStyles: { 1: { halign: "left" } },
        styles: { halign: "left" },
        //startY,
      });
    });
  } else {
    autoTable(doc, {
      theme: "grid",
      head: [header],
      headStyles: {
        fillColor: gridColor,
      },
      columns: header,
      body: singledataCopy,
      columnStyles: { 1: { halign: "left" } },
      styles: { halign: "left" },
      startY,
    });
  }

  doc.save(`${type}.pdf`);
};
