import styled from "styled-components";

export default styled.div.attrs((props) => ({
  className: "about-custom",
}))`
  //padding: 4.5% 5.5%; //5.5%
  @media (max-width: 500px) {
    margin-top: 40px;
  }
  ._header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .transfer_detail {
    padding-top: 32px;
    .td_section_1 {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;
      flex-wrap: wrap;
      .transfer_details {
        background: #ffffff;
        border: 1px solid #dedee1;
        border-radius: 12px;
        padding: 24px;
        box-sizing: border-box;
        width: 35%;
        height: 480px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        @media (max-width: 768px) {
          width: 100%;
        }
        .transfer_details_content_1 {
          display: flex;
          flex-direction: column;
          gap: 0px;
          p {
            font-weight: 400;
            font-size: clamp(18px, 4vw, 28px);
            line-height: 10px;
            color: #424242;
          }
          .pending_verification {
            display: flex;
            flex-direction: column;
            gap: 5px;
            span {
              font-weight: 500;
              font-size: clamp(14px, 4vw, 18px);
              color: #424242;
            }
            span:last-child {
              font-weight: 500;
              font-size: clamp(10px, 4vw, 14px);
              line-height: 17px;
              color: rgba(66, 66, 66, 0.7);
            }
          }
        }
        .transfer_details_content_2 {
          //width: 300px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 25px;
          .exchange_amount {
            width: auto;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: flex;
            gap: 10px;
            align-items: center;
            box-sizing: border-box;
            //justify-content: flex-end;

            span {
              font-weight: 600;
              font-size: clamp(16px, 4vw, 20px);
              line-height: 29px;
              color: #424242;
              text-align: right;
            }
            img {
              width: auto;
              height: auto;
            }
          }
          .userinfo {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            align-items: center;
            gap: 10px;
            .name_and_date {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 3px;
              .name {
                span {
                  font-weight: 500;
                  font-size: clamp(16px, 4vw, 20px);
                  line-height: 24px;
                }
              }
              .date {
                span {
                  font-weight: 500;
                  font-size: clamp(10px, 4vw, 14px);
                  line-height: 17px;
                  color: rgba(66, 66, 66, 0.7);
                }
              }
            }
          }
          .user_initials {
            width: 40px;
            height: 40px;
            background: #ffc859;
            border-radius: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              font-weight: 500;
              font-size: 16px;
              //line-height: 19px;
              color: #ffffff;
            }
          }
        }
        .transfer_details_content_3 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          padding-top: 20px;
          border-top: 1px solid #dedee1;
          .btn_1,
          .btn_2,
          .disabled_btn {
            height: 56px;
            background: rgba(207, 12, 12, 0.1);
            color: #cf0c0c;
            border-radius: 8px;
            padding: 16px 24px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            outline: none;
            border: none;
          }
          .btn_2 {
            background: rgba(0, 123, 93, 0.1);
            color: #007b5d;
          }
          .disabled_btn {
            cursor: not-allowed;
            background: rgba(222, 222, 225, 0.8);
            color: grey;
            opacity: 0.5;
          }
        }
      }
      .transction_details {
        background: #ffffff;
        border: 1px solid #dedee1;
        border-radius: 12px;
        padding: 24px;
        height: 480px;
        width: 62%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .td_section_2 {
      margin-top: 32px;
      background: #f8f9fb;
      border: 1px solid #dedee1;
      border-radius: 12px;
      width: 100%;
      padding: 24px;
      p {
        font-weight: 400;
        font-size: clamp(22px, 4vw, 28px);
        line-height: auto;
        color: #424242;
      }
      .transfer_timeline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 28px 0px;
        gap: 10px;
        @media (max-width: 768px) {
          flex-direction: column;
          gap: 20px;
        }
        .timeline_state,
        .active_timeline_state {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          .step_outline {
            width: 60px;
            height: 60px;
            background: #f8f9fb;
            border: 3px solid #dedee1;
            border-radius: 200px;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;

            cursor: pointer;
            .step {
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              background: #dedee1;
              border-radius: 200px;
              color: #007b5d;
              font-weight: 600;
              font-size: 20px;
            }
          }
          .state_n_date {
            display: flex;
            flex-direction: column;
            gap: 3px;
            span {
              font-size: 16px;
            }
            span:last-child {
            }
          }
          .hr {
            width: 36px;
            border: 2px solid #dedee1;
          }
        }
        .timeline_state:last-child,
        .active_timeline_state:last-child {
          .hr {
            display: none;
          }
        }
        .active_timeline_state {
          .step_outline {
            border: 3px solid #007b5d;
            .step {
              background: #007b5d;
              color: #ffffff;
            }
            /* .hr:last-child {
              display: none;
            } */
          }
          .hr {
            border: 2px solid #007b5d;
          }
        }
      }
    }
  }
`;
