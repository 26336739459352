import { LoadingOutlined, RobotFilled } from "@ant-design/icons";
import {
  Avatar,
  Empty,
  Pagination,
  PaginationProps,
  Tabs,
  TabsProps,
} from "antd";
import { CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectDays } from "src/components/modules/parts/SelectDays";
import { USERS } from "src/redux/actionTypes";
import { CustomError, CustomLoader } from "src/util/Reuseables";
import endpoints from "src/util/endpoints";
import { convertDateString, convertToTimeString } from "src/util/util";
import { generateAlphabetColor, getFirstLetter } from "src/util/utility";
import styled from "styled-components";
import { useGetActivityLogs } from "../helper/useRQuserData";

const tabBarStyle: CSSProperties = {
  paddingLeft: "4%",
  paddingRight: "4%",
  background: "#FFF",
};

const CustomerLogs = ({ userId }: { userId: string }) => {
  const onChange = (key: string) => {
    // console.log(key)
  };

  const items: TabsProps["items"] = [
    // {
    //   key: "1",
    //   label: "All",
    //   children: <ActivityLogs userId={userId} />, //<AllCustomerLogs />,
    // },
    {
      key: "1",
      label: "Activity Log",
      children: <ActivityLogs userId={userId} />,
    },
    {
      key: "2",
      label: "Notes",
      children: "Content of Tab Pane 3",
      disabled: true,
    },
  ];

  return (
    <CustomerLogsStyles>
      <Tabs
        tabBarStyle={tabBarStyle}
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />
    </CustomerLogsStyles>
  );
};

export default CustomerLogs;

const AllCustomerLogs = () => {
  return <LogsWrapper>All logs</LogsWrapper>;
};

const ActivityLogs = ({ userId }: { userId: string }) => {
  const users = useSelector((state: any) => state.users);
  const [currentPage, setCurrentPage] = useState(1);
  const [days, setDays] = useState(15);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const dispatch = useDispatch();

  const endpoint =
    endpoints.USER_ACTIVITY +
    `/${userId}?days=${days}&limit=${limit}&offset=${offset}`;
  const { data, isError, isLoading, isFetching, error } =
    useGetActivityLogs(endpoint);
  const { total, collections: userLogs } = data || {};
  const err: any = error;

  const isLogsEmpty = userLogs?.length === 0 || userLogs === undefined;
  const totalLogs = total || 0;

  useEffect(() => {
    const calcOffset = (currentPage - 1) * limit;
    setOffset(calcOffset);

    dispatch({
      type: USERS,
      payload: {
        ...users,
        activityLogsQueryKey: endpoint,
      },
    });
  }, [data, currentPage]);

  if (isLoading) {
    return (
      <LogsWrapper>
        <CustomLoader prompt="Loading user activity logs..." />
      </LogsWrapper>
    );
  }

  if (isError) {
    return (
      <LogsWrapper>
        <CustomError message={err?.message} />
      </LogsWrapper>
    );
  }

  const onPaginationChange: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };

  return (
    <LogsWrapper>
      <div className="_header">
        <p>Activity log ({total || 0})</p>
        <SelectDays days={days} setDays={setDays} />
      </div>
      <div className="_content">
        {isLogsEmpty ? (
          <Empty />
        ) : (
          userLogs
            ?.slice()
            ?.reverse()
            ?.map((log: any) => {
              const note = log && JSON.parse(log.meta.note);
              //is the log from the system: the log userId will be equal to the customerID
              const isSystemLog = Number(log?.userId) === Number(userId);

              return (
                <div className="_log" key={log.id}>
                  <div className="_header">
                    <div className="_left">
                      <Avatar
                        size={48}
                        style={{
                          background: generateAlphabetColor(
                            String(
                              isSystemLog
                                ? "e"
                                : getFirstLetter(note?.firstname)
                            )
                          ),
                        }}
                      >
                        {isSystemLog ? (
                          <RobotFilled />
                        ) : (
                          <>
                            {getFirstLetter(note?.firstname) || "N/A"}
                            {getFirstLetter(note?.lastname) || "N/A"}
                          </>
                        )}
                      </Avatar>
                      <div className="_admin_name">
                        <p>
                          {note?.firstname || "N/A"} {note?.lastname || "N/A"}
                        </p>
                        <span>
                          {convertDateString(note?.date)} -{" "}
                          {convertToTimeString(note?.date)}
                        </span>
                      </div>
                    </div>

                    <div className="_action">
                      {note?.action?.toLowerCase() || "N/A"}
                    </div>
                  </div>

                  <div className="_note">
                    <p>Note:</p>
                    <div className="_note_content">
                      <span>{note?.note || "N/A"}</span>
                    </div>
                  </div>
                </div>
              );
            })
        )}
        {isFetching && (
          <div className="_fetching_state">
            <LoadingOutlined className="_loding_icon" />{" "}
            <span>fetching logs...</span>
          </div>
        )}
      </div>

      {!isLogsEmpty && (
        <Pagination
          current={currentPage}
          onChange={onPaginationChange}
          total={totalLogs}
          className="_pagination"
          defaultPageSize={limit}
        />
      )}
    </LogsWrapper>
  );
};

const CustomerLogsStyles = styled.section`
  background: #fcfcfc;
  height: auto;
`;

const LogsWrapper = styled.div`
  margin: 32px 4%;
  padding: 32px;
  background: #ffffff;
  min-height: 350px;
  border: 1px solid rgba(144, 152, 178, 0.3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 56px;

  ._pagination {
    align-self: center;
  }

  ._header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: #424242;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
  }

  ._content {
    display: flex;
    flex-direction: column;
    gap: 32px;

    ._log {
      display: flex;
      flex-direction: column;
      gap: 24px;

      ._header {
        display: flex;
        justify-content: space-between;
        ._left {
          display: flex;
          align-items: center;
          gap: 12px;

          ._admin_name {
            display: flex;
            gap: 4px;
            flex-direction: column;
            p {
              color: #424242;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            span {
              color: #424242;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        ._action {
          color: #007b5d;
          text-align: right;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }

      ._note {
        margin-left: 60px; //avatar with plus gap (48 + 12)
        border-radius: 8px;
        border: 1px solid rgba(144, 152, 178, 0.3);
        background: #fff;
        padding: 16px 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 16px;

        p {
          color: #424242;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;
        }

        ._note_content {
          margin-right: 40%;
          span {
            color: #707070;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }
      }
    }

    ._fetching_state {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
  }
`;
