import { Select } from "antd";

type SelectDaysProps = {
  days: Number,
  setDays: Function;
};

export const SelectDays: React.FC<SelectDaysProps> = ({ days, setDays }) => {
  const handleDateChange = (value: string) => {
    setTimeout(() => {
      setDays(Number(value));
    }, 1000);
  };
  return (
    <Select
      defaultValue={String(days)}
      size="large"
      style={{ width: 180 }}
      //bordered={false}
      onChange={handleDateChange}
      options={[
        { value: "1", label: "Last day" },
        { value: "15", label: "Last 15 days" },
        { value: "30", label: "Last 30 days" },
        { value: "90", label: "Last 3 month" },
        { value: "180", label: "Last 6 month" },
        { value: "365", label: "Last year" },
        { value: "8000", label: "All time" },
      ]}
    />
  );
};
