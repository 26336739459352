import { IAction } from ".";
import { PROMOS } from "../actionTypes";

const initialUserState: {} = {
    promos: [],
    promo: null,
    promoCodes: [],
    referrals: [],
    vouchers: []
}

export const promos = (state: any = initialUserState, {type, payload}: IAction) => {
    switch (type) {
        case PROMOS: {
          return {
              ...payload
          }
        }
        default:
          return state;
      }
}
