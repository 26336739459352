import { horizontalLoader, uploadDoc } from "src/redux/reducers/app";
import { combineReducers } from "redux";
import auth from "./auth";
import {
  submitting,
  toast,
  redirect,
  appValues,
  loading,
  filters,
  confirmDialog,
  notifications,
  maintenance
} from "./app";
import { recipients } from "./recipients";
import { transfer, transferDasboard } from "./transfer";
import { users, usersVerificationTypeAndStatus } from "./users";
import { competitorRates } from "./competitorRates";
import { promos } from "./promos";
import { preorders, exchange } from "./preorders";

export interface IAction {
  type: string;
  payload: any;
}

export default combineReducers({
  auth,
  submitting,
  toast,
  redirect,
  appValues,
  filters,
  recipients,
  loading,
  transfer,
  users,
  confirmDialog,
  uploadDoc,
  preorders,
  exchange,
  promos,
  notifications,
  competitorRates,
  horizontalLoader,
  transferDasboard,
  usersVerificationTypeAndStatus,
  maintenance
});
