import { IAction } from ".";
import { USERS, VERIFICATION_TYPE_STATUES } from "../actionTypes";

const initialUserState: {} = {
  users: [],
  total: 0,
  days: 1,
  limit: 10,
  offset: 0,
  status: "ALL",
  search: "",
  queryKey: "",
  activityLogsQueryKey: "", //todo: create a new reducer to manage all active queryKeys
  currentUsersPage: 1,
};

const usersVerificationTypeAndStatusState: {} = {
  status: [],
  type: [],
};

export const users = (
  state: any = initialUserState,
  { type, payload }: IAction
) => {
  switch (type) {
    case USERS: {
      return {
        ...state, // Spread the existing state
        users: payload.users, // Update 'users' from the payload
        total: payload.total, // Update 'total' from the payload
        days: payload.days, // Update 'days' from the payload
        limit: payload.limit, // Update 'limit' from the payload
        offset: payload.offset, // Update 'offset' from the payload
        status: payload.status, // Update 'status' from the payload
        search: payload.search, // Update 'search' from the payload
        queryKey: payload.queryKey,
        activityLogsQueryKey: payload.activityLogsQueryKey,
        currentUsersPage: payload.currentUsersPage,
      };
    }
    default:
      return state;
  }
};

export const usersVerificationTypeAndStatus = (
  state: any = usersVerificationTypeAndStatusState,
  { type, payload }: IAction
) => {
  switch (type) {
    case VERIFICATION_TYPE_STATUES:
      return {
        ...state,
        status: payload.status,
        type: payload.type,
      };

    default:
      return state;
  }
};
