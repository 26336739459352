import { UploadOutlined } from "@ant-design/icons";
import { Button, Space, Upload, UploadProps } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import sha1 from "sha1";
import { queryClient } from "src/App";
import env from "src/env";
import { LOADING, UPLOAD_DOC } from "src/redux/actionTypes";
import { checkAuth, toastAction } from "src/redux/actions/actions";
import endpoints from "src/util/endpoints";
import { parseEndpointParameters } from "src/util/util";

export const UploadButton = ({
  user,
  transfer,
  callback,
  isExternalAction,
  fileFormat,
}: {
  user?: any;
  transfer?: any;
  callback?: Function;
  isExternalAction?: boolean;
  fileFormat?: string;
}) => {
  const [selectedFile, setSelectedFile] = useState<any>();
  const uploadState = useSelector((state: any) => state.uploadDoc);
  const dispatch = useDispatch();
  const {
    status: transferStatus,
    days,
    limit,
    offset,
    search,
  } = useSelector((state: any) => state.transfer) || {};

  const currentTransferQkey =
    transferStatus === "ALL"
      ? `${endpoints.TRANSFERS}?days=${days}&limit=${limit}&offset=${offset}&transactionId=${search}`
      : `${endpoints.TRANSFERS}?days=${days}&limit=${limit}&offset=${offset}&transactionId=${search}&status=${transferStatus}`;

  const handleFileChange = (info: any) => {
    setSelectedFile(info.fileList);
  };
  //console.log(user);

  const handleSubmission = () => {
    // If isExternalAction is true, call the callback with the
    // selectedFile and an updater function to reset selectedFile, then exit the function.
    if (isExternalAction) {
      callback?.(selectedFile, () => {
        setSelectedFile(null);
      });
      return;
    }

    dispatch({ type: LOADING, payload: true });
    const formData = new FormData();

    formData.append("Document", selectedFile[0].originFileObj);

    const endpoint = transfer
      ? parseEndpointParameters(
          endpoints.USER_FILE_UPLOAD_ON_TRANSFER,
          user ? user : user?.id,
          transfer?.id
        )
      : parseEndpointParameters(endpoints.USER_FILE_UPLOAD, user.id);
    const authData: any = checkAuth();
    const url = env.API_PROXY
      ? env.API_PROXY + endpoint
      : env.API_HOST + endpoint;
    const authToken = authData.authToken;

    const requestHash = sha1(url + authToken);
    fetch(env.API_HOST + endpoint, {
      method: "POST",
      body: formData,
      headers: {
        "X-SERVICE-PROVIDER": authData.serviceProvider,
        // 'Content-Type'          : 'application/json',
        "X-SERVICE-USER-NAME": authData.sessionId,
        "X-REQUEST-HASH": requestHash,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 15000,
            message: `Document uploaded successfully`,
          });
          dispatch({
            type: UPLOAD_DOC,
            payload: { ...uploadState, open: false },
          });
          callback?.();
          queryClient.invalidateQueries(currentTransferQkey);
          setSelectedFile(null);
        } else {
          toastAction({
            show: true,
            type: "error",
            timeout: 15000,
            message: `${result.error.message}`,
          });
        }
      })
      .catch((error) => {})
      .then(() => {
        dispatch({ type: LOADING, payload: false });
      });
  };

  const handleCustomUpload = (options: any) => {
    setTimeout(() => {
      options.onSuccess();
    }, 1000);
  };

  const checkFileFormat = (file: any) => {
    const isPNG = file.type === "image/png";
    const isPDF = file.type === "application/pdf";
    const isCsv = file.type === "text/csv";

    const isFileFormatValid = fileFormat === "CSV" ? isCsv : isPNG || isPDF;
    const filetype = fileFormat === "CSV" ? "CSV" : "PNG or PDF";

    if (!isFileFormatValid) {
      toastAction({
        show: true,
        type: "error",
        timeout: 10000,
        title: "Invalid format",
        message: `Format not supported, please upload a ${filetype} file`,
      });
    }
    return isFileFormatValid || Upload.LIST_IGNORE;
  };

  return (
    <Space
      direction="vertical"
      style={{ width: "100%", marginBottom: 15 }}
      size="large"
    >
      <Space>
        <Upload
          beforeUpload={checkFileFormat}
          // listType="picture"
          action={""}
          //method="PATCH"
          maxCount={1}
          fileList={selectedFile}
          onChange={handleFileChange}
          customRequest={handleCustomUpload}
        >
          <Button>Choose a file</Button>
        </Upload>
        {selectedFile?.length === 0 && <span>No file chosen</span>}
      </Space>
      {selectedFile?.length > 0 && (
        <Button
          onClick={handleSubmission}
          type="primary"
          icon={<UploadOutlined />}
        >
          Upload now
        </Button>
      )}{" "}
    </Space>
  );
};
