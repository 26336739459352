
import SignIn from '../components/pages/sign-in/SignIn';
import {Dashboard} from '../components/pages/dashboard/Dashboard';

import { paths } from './paths';
import { Transfers } from '../components/pages/transfers/Transfers';
import { AppWrapper } from '../components/pages/app/AppWrapper';


export interface IRoute {
    path: string,
    component: (props: any) => JSX.Element,
    protected?: boolean,
    props?: any
    exact?: boolean
}
export const Routing: IRoute[] = [
    {
        path: paths.SIGN_IN,
        component: SignIn,
        protected: false,
    },
    {
        path: paths.BASE_APP_ROUTE, 
        component: AppWrapper, 
        protected: true 
    },

]
         