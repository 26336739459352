import { TableCell } from "../../modules/new-table/ITable"
import React, { createElement, useState } from "react"
import { asset, getValueFromArray } from "../../../util/util"
import XComparisonTextStack from "./parts/XComparisonTextStack"
import constants from "../../../util/constants"


const getFromRateData = (ratesData: any, competitor: string, returnKey: string) => { 
    const value = getValueFromArray( competitor, 'name', ratesData, returnKey );

    if (value && (typeof value === 'string' || typeof value === 'number')) {
        return value
    }
    return '-'
}


export const getExchangeRateTableData = (ratesData: any, setCurrentEdit?: Function): {heading: TableCell[][], rows: TableCell[][]}  => {
    return {
        heading: [
            [
                {
                    content: 'Provider'
                },
                {
                    content: XComparisonTextStack({content1: 'Exchange rate', content2: <div> (1 {constants.COMPETITOR_RATES_BASE_CURRENCY} <img src={asset('icons', 'white-arrow.svg')} alt="->" className="mobile-hide" /> {constants.COMPETITOR_RATES_TARGET_CURRENCY}  ) </div>, className: 'w-fit mx-auto text-align-left' })
                },
                {
                    content: XComparisonTextStack({ content1: <div className="w-60 mx-auto"> Rate compared to SB Remit </div> , content2: '', className: 'text-align-left' })
                },
                {
                    content: XComparisonTextStack({content1: 'Transfer fee', className: 'text-align-center'})
                },
                {
                    content: ''
                }

            ]
        ],
        rows: [
            [
                {
                    content:  <img className="sbremit-logo" src={asset('', 'main-logo.svg')} alt="sbremit" />,
                    className: 'sbremit'
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'sbremit', 'rate'), content2: '', status: 'success', format: 'rate', className: 'text-align-center' }),
                    className: 'sbremit'
                },
                {
                    content: XComparisonTextStack({ content1: <button onClick={() => {}} className="table-send-btn is-link mx-auto">Start sending money</button> , status: 'neutral', format: 'none', className: 'text-align-left' }),
                    className: 'sbremit'
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'sbremit', 'transferFee'), content2: '', status: 'neutral', format: 'rate', className: 'text-align-center' }),
                    className: 'sbremit'
                },
                {
                    content: <div className="competitor-update-btn" onClick={() => {setCurrentEdit?.(getFromRateData(ratesData, 'sbremit', 'id'))}}  > <img src={asset('icons', 'pencil.svg')} alt="" /> <span>Update</span> </div>
                }
            ],
            [
                {
                    content: <img src={asset('logos', 'western-union.png')} alt="western-union" />
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'westernunion', 'rate'), content2: '', status: 'success', format: 'rate' })
                },
                {
                    content: XComparisonTextStack({ content1: '', content2: getFromRateData(ratesData, 'westernunion', 'rateDifference'), status: `${getFromRateData(ratesData, 'westernunion', 'rateDifference') >= 0 ? 'success' : 'danger'}`, format: 'received' })
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'westernunion', 'transferFee'), content2: '', status: 'neutral', format: 'rate' })
                },
                {
                    content: <div className="competitor-update-btn" onClick={() => {setCurrentEdit?.(getFromRateData(ratesData, 'westernunion', 'id'))}}  > <img src={asset('icons', 'pencil.svg')} alt="" /> <span>Update</span> </div>
                }
            ],
            [
                {
                    content: <img src={asset('logos', 'money-gram.png')} alt="money-gram" />
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'moneygram', 'rate'), content2: '', status: 'success', format: 'rate' })
                },
                {
                    content: XComparisonTextStack({ content1: '', content2: getFromRateData(ratesData, 'moneygram', 'rateDifference'), status: `${getFromRateData(ratesData, 'moneygram', 'rateDifference') >= 0 ? 'success' : 'danger'}`, format: 'received' })
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'moneygram', 'transferFee'), content2: '', status: 'neutral', format: 'rate' })
                },
                {
                    content: <div className="competitor-update-btn" onClick={() => {setCurrentEdit?.(getFromRateData(ratesData, 'moneygram', 'id'))}}  > <img src={asset('icons', 'pencil.svg')} alt="" /> <span>Update</span> </div>
                }
            ],
            [
                {
                    content: <img src={asset('logos', 'world-remit.png')} alt="world-remit" />
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'worldremit', 'rate'), content2: '', status: 'success', format: 'rate' })
                },
                {
                    content: XComparisonTextStack({ content1: '', content2: getFromRateData(ratesData, 'worldremit', 'rateDifference'), status: `${getFromRateData(ratesData, 'worldremit', 'rateDifference') >= 0 ? 'success' : 'danger'}`, format: 'received' })
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'worldremit', 'transferFee'), content2: '', status: 'neutral', format: 'rate' })
                },
                {
                    content: <div className="competitor-update-btn" onClick={() => {setCurrentEdit?.(getFromRateData(ratesData, 'worldremit', 'id'))}}  > <img src={asset('icons', 'pencil.svg')} alt="" /> <span>Update</span> </div>
                }
            ],
            [
                {
                    content: <img src={asset('logos', 'ria.png')} alt="ria" />
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'ria', 'rate'), status: 'danger', format: 'rate' })
                },
                {
                    content: XComparisonTextStack({ content1: '', content2: getFromRateData(ratesData, 'ria', 'rateDifference'), status: `${getFromRateData(ratesData, 'ria', 'rateDifference') >= 0 ? 'success' : 'danger'}`, format: 'received' })
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'ria', 'transferFee'), status: 'neutral', format: 'rate' })
                },
                {
                    content: <div className="competitor-update-btn" onClick={() => {setCurrentEdit?.(getFromRateData(ratesData, 'ria', 'id'))}}  > <img src={asset('icons', 'pencil.svg')} alt="" /> <span>Update</span> </div>
                }
            ],
            [
                {
                    content: <img src={asset('logos', 'paysend.png')} alt="paysend" />
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'paysend', 'rate'), status: 'danger', format: 'rate' })
                },
                {
                    content: XComparisonTextStack({ content1: '', content2: getFromRateData(ratesData, 'paysend', 'rateDifference'), status: `${getFromRateData(ratesData, 'paysend', 'rateDifference') >= 0 ? 'success' : 'danger'}`, format: 'received' })
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'paysend', 'transferFee'), status: 'neutral', format: 'rate' })
                },
                {
                    content: <div className="competitor-update-btn" onClick={() => {setCurrentEdit?.(getFromRateData(ratesData, 'paysend', 'id'))}}  > <img src={asset('icons', 'pencil.svg')} alt="" /> <span>Update</span> </div>
                }
            ],
            [
                {
                    content: <img src={asset('logos', 'taptap.png')} alt="taptap" />
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'taptap', 'rate'), status: 'danger', format: 'rate' })
                },
                {
                    content: XComparisonTextStack({ content1: '', content2: getFromRateData(ratesData, 'taptap', 'rateDifference'), status: `${getFromRateData(ratesData, 'taptap', 'rateDifference') >= 0 ? 'success' : 'danger'}`, format: 'received' })
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'taptap', 'transferFee'), status: 'neutral', format: 'rate' })
                },
                {
                    content: <div className="competitor-update-btn" onClick={() => {setCurrentEdit?.(getFromRateData(ratesData, 'taptap', 'id'))}}  > <img src={asset('icons', 'pencil.svg')} alt="" /> <span>Update</span> </div>
                }
            ],
            [
                {
                    content: <img src={asset('logos', 'sendwave.png')} alt="sendwave" />
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'sendwave', 'rate'), status: 'danger', format: 'rate' })
                },
                {
                    content: XComparisonTextStack({ content1: '', content2: getFromRateData(ratesData, 'sendwave', 'rateDifference'), status: `${getFromRateData(ratesData, 'sendwave', 'rateDifference') >= 0 ? 'success' : 'danger'}`, format: 'received' })
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'sendwave', 'transferFee'), status: 'neutral', format: 'rate' })
                },
                {
                    content: <div className="competitor-update-btn" onClick={() => {setCurrentEdit?.(getFromRateData(ratesData, 'sendwave', 'id'))}}  > <img src={asset('icons', 'pencil.svg')} alt="" /> <span>Update</span> </div>
                }
            ],
            [
                {
                    content: <img src={asset('logos', 'remitly.png')} alt="remitly" />
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'remitly', 'rate'), status: 'danger', format: 'rate' })
                },
                {
                    content: XComparisonTextStack({ content1: '', content2: getFromRateData(ratesData, 'remitly', 'rateDifference'), status: `${getFromRateData(ratesData, 'remitly', 'rateDifference') >= 0 ? 'success' : 'danger'}`, format: 'received' })
                },
                {
                    content: XComparisonTextStack({ content1: getFromRateData(ratesData, 'remitly', 'transferFee'), status: 'neutral', format: 'rate' })
                },
                {
                    content: <div className="competitor-update-btn" onClick={() => {setCurrentEdit?.(getFromRateData(ratesData, 'remitly', 'id'))}}  > <img src={asset('icons', 'pencil.svg')} alt="" /> <span>Update</span> </div>
                }
            ], 
        ]
    }
}


    
