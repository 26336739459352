import { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { SIGN_IN } from "../../../redux/actionTypes";
import { signInAction } from "../../../redux/actions/actions";
import { paths } from "../../../util/paths";
import ForgotPasswordModal from "../../modules/forgot-password-modal/ForgotPasswordModal";
import SBRemitLogo from "../../modules/sbremit-logo/SBRemitLogo";
import styled from "./Signin.css";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";

const Body = styled;

const SignIn = () => {
  const [showModal, setShowModal] = useState(false);
  const submitting = useSelector((state: any) => state.submitting);
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  const handleForgotPassword: Function = (): void => setShowModal(true);

  const onFinish = (values: any) => {
    signInAction(values);
  };

  const storedUrl = sessionStorage.getItem("previousUrl");

  return !isAuthenticated ? (
    <Body>
      <ForgotPasswordModal show={showModal} setShow={setShowModal} />
      <div className="logo">
        <SBRemitLogo />
      </div>
      <div className="content">
        <div className="img"></div>
        <div className="form_container">
          <div className="form">
            <div className="text">
              <h1>Welcome back</h1>
              <span>Please enter your details.</span>
            </div>
            <Form
              name="normal_login"
              className="login_form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username"
                  size="large"
                  type="email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input.Password
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <div
                  className="login-form-forgot"
                  onClick={() => handleForgotPassword()}
                >
                  Forgot password?
                </div>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: "100%" }}
                  size="large"
                  loading={submitting === SIGN_IN}
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Body>
  ) : storedUrl ? (
    <Redirect to={storedUrl} />
  ) : (
    <Redirect to={paths.DASHBOARD} />
  );
};

export default SignIn;
