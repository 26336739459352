import { useState, useEffect, RefObject } from "react";
import { useLocation } from "react-router-dom";

export const useAppWrapperScrollToTop = (ref: RefObject<HTMLElement>) => {
  const location = useLocation();
  const page = getActivePage(location.pathname);

  useEffect(() => {
    // Scroll to top when the component is mounted or the URL changes
    ref.current?.scrollTo(0, 0);
    getActivePage(location.pathname);
  }, [location]);

  return page;
};

export const getActivePage = (argument: string) => {
  if (!argument) {
    throw new Error("Page unknown");
  }

  const stringsToCheck: string[] = [
    "dashboard",
    "transfers",
    "transfer",
    "users",
    "user",
    "fx",
    "promos",
    "segments",
    "settings",
    "competitor",
    "maintenance",
  ];

  const foundWord = stringsToCheck.find((word) => argument.includes(word));

  if (foundWord === "maintenance") {
    return "Maint.";
  }

  if (foundWord) {
    return foundWord;
  } else return "Page";
};
