/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { BsGlobe } from "react-icons/bs";
import { FcPhoneAndroid } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  InfoCircleOutlined,
  ReloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { IoIosMore } from "react-icons/io";
import { getTransfers, resendTransfer } from "src/redux/actions/actions";
import styled from "styled-components";

import { Badge, Button, Dropdown, Space, Table, Tag, Tooltip } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import UpdateTransferStatus from "src/components/pages/transfers/components/UpdateTransferStatus";
import { TRANSFER } from "src/redux/actionTypes";
import store from "src/redux/store";
import { transferMethodsInWords } from "src/util/constants";
import {
  convertDateString,
  convertToTimeString,
  formatCurrency,
  getActionButtonText,
  getStatusType,
  replaceUnderscores,
  sortByTimeDesc,
} from "src/util/util";
import { getEqivalentText } from "src/util/utility";
import DownloadData from "../../parts/DownloadData";

const UsersTransactionsTableStyle = styled.div``;

interface TransactionTableProps {
  tableBody?: any[];
  tabletype?: string;
  status?: string;
  days?: number;
  total: number;
  currentTransactionsPage: number;
}

interface DataType {
  key: React.Key;
  name: string;
  customerID: string;
  country: string;
  date: string;
  kyc: string;
  account_verification: string;
  action: string;
}

const TransferActions = ({ action }: { action: any }) => {
  return (
    <Space size="middle" onClick={(e) => e.stopPropagation()}>
      {getEqivalentText(action).map((btn, index) => (
        <Tooltip
          placement="topLeft"
          title={btn.text}
          key={"transfer_actions_btn_" + index}
        >
          <Button
            type={
              btn.text === "Remit funds to recipient with Pivot" ||
              btn.text === "Approve"
                ? "primary"
                : btn.text === "No action"
                ? "text"
                : undefined
            }
            danger={
              btn.text === "Cancel transfer" || btn.text === "Reject"
                ? true
                : false
            }
            onClick={() => btn.text !== "No action" && btn.function()}
          >
            {getActionButtonText(btn.text)}
          </Button>
        </Tooltip>
      ))}
    </Space>
  );
};

export default function TransfersTable({
  tableBody,
  status,
  days,
  total,
  currentTransactionsPage,
}: TransactionTableProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [datasrc, setDatascr] = React.useState<any>([]);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [columnData, setColumnData] = React.useState<any>();

  const [openDownload, setOpenDownload] = React.useState(false);

  // csv - column and row data
  const [header, setHeader] = React.useState<any>([]);
  const [csvData, setCSVData] = React.useState<any[]>([]);

  // const [currentPage, setCurrentPage] = React.useState(currentTransactionsPage);
  const [pageSize, setPageSize] = React.useState(10);

  const [changeTransactionModalState, setChnageTransactionModalState] =
    React.useState({
      open: false,
      activeStatus: "",
      transferId: "",
    });

  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  //Dropdown contetnt
  const items = [
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            resendTransfer(columnData, () => {
              getTransfers();
            });
          }}
        >
          Resend transfer
        </a>
      ),
      icon: <ReloadOutlined />,
    },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            history.push(`/app/transfers/${columnData?.id}`);
          }}
        >
          view details
        </a>
      ),
      icon: <UserOutlined />,
    },
  ];

  // handle on row selected change
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataType[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // custom filed to be printed on csv or pdf
    splitSlectedData(
      [
        "name",
        "transactionID",
        "baseCurrency",
        "targetCurrency",
        "date",
        "method",
        "status",
      ],
      selectedRows
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // separete the head and datasource into header and csv for easy download
  const splitSlectedData = (keys: string[], data: any[]) => {
    const newHeader: any = keys.map((key: string) => {
      // @ts-ignore
      const column = columns.find((col) => col.dataIndex === key);
      return column ? column.title : `Column with key '${key}' not found.`;
    });

    const newCSVData: any[] = data.map((item: any) => {
      const newRow: any = {};
      keys.forEach((key) => {
        // @ts-ignore
        const column = columns.find((col) => col.dataIndex === key);
        if (column) {
          // @ts-ignore
          newRow[column.title] = item[key];
        }
      });
      return newRow;
    });

    setHeader(newHeader);
    setCSVData(newCSVData);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionID",

      ellipsis: {
        showTitle: false,
      },
      render: (transactionID) => (
        <Tooltip placement="topLeft" title={transactionID}>
          {transactionID}
        </Tooltip>
      ),
    },
    {
      title: "Base Currency",
      dataIndex: "baseCurrency",
      ellipsis: {
        showTitle: false,
      },
      render: (baseCurrency) => (
        <Tooltip placement="topLeft" title={baseCurrency}>
          {baseCurrency}
        </Tooltip>
      ),
    },
    {
      title: "Target Currency",
      dataIndex: "targetCurrency",
      ellipsis: {
        showTitle: false,
      },
      render: (targetCurrency) => (
        <Tooltip placement="topLeft" title={targetCurrency}>
          {targetCurrency}
        </Tooltip>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      ellipsis: {
        showTitle: false,
      },
      render: (date) => (
        <Tooltip placement="topLeft" title={date}>
          {date}
        </Tooltip>
      ),
    },
    {
      title: "Payment method",
      dataIndex: "method",
      //width: 280,
      ellipsis: {
        showTitle: false,
      },
      render: (method) => (
        <Tooltip placement="topLeft" title={method}>
          {method}
        </Tooltip>
      ),
    },
    {
      title: "Payment Gateway",
      dataIndex: "gateway",
      ellipsis: {
        showTitle: false,
      },
      render: (gateway) => (
        <Tooltip placement="topLeft" title={gateway}>
          {gateway}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 280,
      ellipsis: {
        showTitle: false,
      },
      render: (item) => {
        const status =
          item?.meta?.FRAUD_ALERT === "FRAUD_ALERT"
            ? "FRAUD_ALERT"
            : item?.status;

        return (
          <div //convert to style component: Flex
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip placement="topLeft" title={status}>
              <Badge
                status={getStatusType(status)}
                text={replaceUnderscores(status)}
              />
            </Tooltip>
            {/* <Button
              icon={<EditOutlined />}
              onClick={() =>
                setChnageTransactionModalState({
                  open: true,
                  activeStatus: status,
                  transferId: item.id,
                })
              }
            /> */}
          </div>
        );
      },
    },
    {
      title: "Auto Remittance",
      dataIndex: "auto_remittance",
      width: 280,
      render: (action) => {
        const remittanceStatus = action?.meta?.remittanceStatus;
        const remittance = action?.meta?.remittance;
        const QRResponse =
          action?.meta?.QRResponse && JSON.parse(action?.meta?.QRResponse);

        const QRRMessage = QRResponse
          ? `Message: ${QRResponse?.Message}: ${QRResponse?.ErrorMessageList[0]}`
          : "";

        const getTagColor = () => {
          if (remittance === "SUCCESS") return "green";
          else if (remittance === "PENDING") return "processing";
          else if (remittance === "FAILED") return "error";
          else return "default";
        };

        if (remittanceStatus || remittance) {
          return (
            <div>
              {QRRMessage === "" ? (
                <Tag color={getTagColor()}>{remittance || "NOT PROCESSED"}</Tag>
              ) : (
                <Space size={2}>
                  <Tag color={getTagColor()}>
                    {remittance || "NOT PROCESSED"}
                  </Tag>
                  <Tooltip placement="topLeft" title={QRRMessage}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              )}
            </div>
          );
        } else {
          return <div>--</div>;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "right",
      //width: 230,
      render: (action) => {
        return <TransferActions action={action} />;
      },
    },

    {
      title: "More",
      dataIndex: "more",
      align: "right",
      fixed: "right",
      width: 100,
      render: (more) => {
        const isTransferFromMobileApp = Boolean(Number(more?.meta?.isMobile));

        return (
          <Space size="middle" onClick={(e) => e.stopPropagation()}>
            <Dropdown menu={{ items }}>
              <Space>
                <a onMouseEnter={() => setColumnData(more)}>
                  <IoIosMore size={24} color="#CCCCCC" />
                </a>
                {more?.meta?.isMobile !== undefined ? (
                  isTransferFromMobileApp ? (
                    <FcPhoneAndroid size={24} />
                  ) : (
                    <BsGlobe size={24} style={{ color: "#1677FF" }} />
                  )
                ) : (
                  <></>
                )}
              </Space>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  React.useEffect(() => {
    const dataSource = sortByTimeDesc(tableBody as any[])?.map(
      (item, index) => {
        return {
          key: index,
          name: `${item.user?.firstName} ${item.user?.lastName}`,
          transactionID: `${item.meta.transactionId}`,
          baseCurrency: `${formatCurrency(item?.originAmount)} ${
            item.originCurrency
          }`,
          targetCurrency: `${formatCurrency(item.destinationAmount)} ${
            item.destinationCurrency
          }`,
          date: `${convertDateString(
            item?.dateCreated
          )} - ${convertToTimeString(item?.dateCreated)}`,
          method: `${transferMethodsInWords[item?.transferMethod]}`,
          gateway: item.meta?.paymentGatewayUsed,
          status: item,
          // item?.meta?.FRAUD_ALERT === "FRAUD_ALERT"
          //   ? "FRAUD_ALERT"
          //   : item?.status,
          auto_remittance: item,
          action: item,
          more: item,
        };
      }
    );

    setDatascr(dataSource || []);
  }, [tableBody]);

  React.useEffect(() => {
    return fetchTransfersData();
  }, [status, days, currentTransactionsPage, pageSize]);

  function fetchTransfersData() {
    const limit = pageSize;
    let offset = (currentTransactionsPage - 1) * limit;
    // limit, offset, days, status
    const transfers = store.getState().transfer;
    dispatch({
      type: TRANSFER,
      payload: {
        ...transfers,
        status: status,
        limit: limit,
        offset: offset,
        days: days,
      },
    });
    // fetch transfer data
    // return getTransfers();
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    if (pagination && pagination.current) {
      // setCurrentPage(pagination.current);
      setPageSize(Number(pagination?.pageSize));
      const transfers = store.getState().transfer;
      dispatch({
        type: TRANSFER,
        payload: {
          ...transfers,
          currentTransactionsPage: pagination.current,
        },
      });
    }
  };

  return (
    <UsersTransactionsTableStyle className="td_row_container">
      <UpdateTransferStatus
        transferId={changeTransactionModalState.transferId}
        activeStatus={changeTransactionModalState.activeStatus}
        open={changeTransactionModalState.open}
        setOpen={setChnageTransactionModalState}
      />

      {openDownload && (
        <DownloadData
          header={header}
          data={csvData}
          open={openDownload}
          setOpen={setOpenDownload}
          type="User"
        />
      )}
      <Table
        onChange={handleTableChange}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={datasrc}
        pagination={{
          position: ["bottomCenter"],
          pageSizeOptions: [10, 20, 50, 100],
          total: total,
          current: currentTransactionsPage,
        }}
        footer={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tooltip title="Click to reload selected user(s)">
                <Button
                  onClick={start}
                  disabled={!hasSelected}
                  loading={loading}
                >
                  Reload
                </Button>
              </Tooltip>
              <span style={{ marginLeft: 8 }}>
                {hasSelected
                  ? ` ${selectedRowKeys.length} Transfer(s) selected`
                  : ""}
              </span>
            </div>
            <Tooltip title="Click to dwonload selected user(s)">
              <Button
                disabled={!hasSelected}
                onClick={() => setOpenDownload(true)}
              >
                Download
              </Button>
            </Tooltip>
          </div>
        )}
        scroll={{ x: 2200 }}
      />
    </UsersTransactionsTableStyle>
  );
}
