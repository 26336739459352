import { notification } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TOAST } from "src/redux/actionTypes";

type ToastType = "success" | "info" | "warning" | "error";

interface ToastProps {
  config: {
    show: boolean;
    title: string;
    message: string;
    type: ToastType;
  };
}

export default function Toast(props: ToastProps) {
  const { config } = props;
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const close = () => {
    dispatch({
      type: TOAST,
      payload: {},
    });
  };

  useEffect(() => {
    if (config.show === true) {
      api[config.type]({
        message: config.title,
        description: config.message,
        placement: "topRight",
        duration: 5,
        onClose: close,
      });
    }
  }, [config.show]);

  return <> {contextHolder}</>;
}
