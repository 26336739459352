import type { RadioChangeEvent } from "antd";
import { Badge, Button, Divider, Input, Modal, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import endpoints from "src/util/endpoints";
import { getStatusType, replaceUnderscores } from "src/util/util";
import styled from "styled-components";
import { transactionStatuses } from "./helpers/transfersHelper";
import { useUpdateTransfersStatus } from "./helpers/useRQtransfersData";

const { TextArea } = Input;

interface UpdateTransferStatusProps {
  open?: boolean;
  setOpen: Function;
  activeStatus: string;
  transferId: string;
}

const UpdateTransferStatus = ({
  activeStatus,
  open,
  setOpen,
  transferId,
}: UpdateTransferStatusProps) => {
  const [radioValue, setRadioValue] = useState("");
  const [textAreaValue, setTextAreaValue] = useState("");
  const { limit, offset, search, status, days } =
    useSelector((state: any) => state.transfer) || {};

  useEffect(() => {
    setRadioValue(activeStatus);
  }, [activeStatus]);

  const divider1Styles = { marginTop: 12 };
  const divider2Styles = { marginBottom: 12 };

  const transfersQuerykey =
    status === "ALL"
      ? `${endpoints.TRANSFERS}?days=${days}&limit=${limit}&offset=${offset}&transactionId=${search}`
      : `${endpoints.TRANSFERS}?days=${days}&limit=${limit}&offset=${offset}&transactionId=${search}&status=${status}`;

  const { mutate: updateTransactionStatusMutate, isLoading } =
    useUpdateTransfersStatus(transfersQuerykey, () => {
      closeModal();
    });

  const onChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const onTextAreaChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTextAreaValue(e.target.value);
  };

  const closeModal = () => {
    setTextAreaValue("");
    setOpen({
      open: false,
      activeStatus: "",
      transferId: 0,
    });
  };

  const onSubmit = () => {
    const combinedValues = {
      id: transferId,
      status: radioValue,
      note: textAreaValue,
    };
    updateTransactionStatusMutate(combinedValues);
  };

  return (
    <Modal
      title="Change Transaction Status"
      open={open}
      onCancel={closeModal}
      width={450}
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={activeStatus === radioValue}
          loading={isLoading}
        >
          Save
        </Button>,
      ]}
    >
      <Divider style={divider1Styles} />
      <Radio.Group onChange={onChange} value={radioValue}>
        <RadioWrappers>
          {transactionStatuses.map((item, index) => (
            <RadioStyles value={item.status} key={item.status + index}>
              <div className="_badge_wrapper">
                <Badge
                  status={getStatusType(item.status)}
                  text={replaceUnderscores(item.status)}
                />
              </div>
            </RadioStyles>
          ))}
        </RadioWrappers>
      </Radio.Group>
      <Divider style={divider2Styles} />

      <CaptureReasonContainer>
        <div className="header">
          <p>Add a note</p>
          <span>Captures why the transaction status is being changed</span>
        </div>
        <TextArea
          placeholder="start typing..."
          allowClear
          onChange={onTextAreaChange}
          rows={6}
        />
      </CaptureReasonContainer>
    </Modal>
  );
};

export default UpdateTransferStatus;

const RadioWrappers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* max-height: 300px; */
  /* overflow-y: auto; */
  /* padding-right: 12px; */
`;

const RadioStyles = styled(Radio)`
  display: flex;
  flex-direction: row-reverse;
  /* justify-content: space-between; */
  width: 100%;
  padding: 0px !important;
  margin: 0 !important;

  ._badge_wrapper {
    align-self: left;
    min-width: 360px;
  }
`;

const Header = styled.h2`
  font-weight: 600;
  color: #424242;
  margin: 0;
`;

const CaptureReasonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }
    span {
      font-size: 12px;
    }
  }
`;
