import { LogoutOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";

import { Button } from "antd";
import { IoIosNotificationsOutline } from "react-icons/io";
import { useIdleTimer } from "react-idle-timer";
import NotificationsModal from "src/components/modules/parts/notification-modal/NotificationModal";
import TransactionDetail from "src/components/modules/transaction-detail-modal/TransactionDetail";
import { getElapsedMinutesFromDate } from "src/util/util";
import { signOutAction, toastAction } from "../../../redux/actions/actions";
import { resources } from "../../../util/constants";
import { paths } from "../../../util/paths";
import { getImage, goTo } from "../../../util/utility";
import { CompetitorRates } from "../competitor-rates/CompetitorRates";
import CustomerDetail from "../customer-detail/CustomerDetail";
import { Dashboard } from "../dashboard/Dashboard";
import Preorder from "../fx/preorder-steps/Preorder";
import Preorders from "../fx/preorders/Preorders";
import GetQuote from "../get-quote/GetQuote";
import PaymentMethod from "../payment-method/PaymentMethod";
import { Promos } from "../promo/Promo";
import Recipient from "../recipient/Recipient";
import Review from "../review/Review";
import Settings from "../settings/Settings";
import TransferComplete from "../transfer-complete/TransferComplete";
import TransferMethod from "../transfer-method/TransferMethod";
import { Transfers } from "../transfers/Transfers";
import UserVerificationData from "../transfers/user-verification-data/UserVerificationData";
import { Users } from "../users/Users";
import styled from "./AppWrapper.css";
import { MobileMenu, Pages } from "./AppWrapperConstants";
import { useAppWrapperScrollToTop } from "./useAppwrapper";

const Body = styled;

export const AppWrapper = () => {
  const history = useHistory();
  const user = useSelector((state: any) => state.auth.user);
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const horizontalLoader = useSelector((state: any) => state.horizontalLoader);

  const bodyContainerRef = useRef<HTMLDivElement | null>(null);
  const currentPage = useAppWrapperScrollToTop(bodyContainerRef);
  const [openNotification, setOpenNotification] = useState(false);

  const idleTimer = useIdleTimer({
    timeout: 1000 * 60 * 5, // 10 minutes
    onIdle: () => {
      if (isAuthenticated) {
        if (getElapsedMinutesFromDate(`${idleTimer.getLastActiveTime()}`)) {
          signOutAction(false, true);
        }
      }
    },
    onPrompt: () => {
      toastAction({
        show: true,
        timeout: 1000 * 10,
        title: "No activity detected",
        message: `Signing out in ${5}s`,
        type: "info",
      });
    },
    promptTimeout: 1000 * 5,
    onActive: () => {
      idleTimer.pause();
    },
    debounce: 500,
    crossTab: true,
  });

  return (
    <Body>
      <NotificationsModal
        open={openNotification}
        setOpen={setOpenNotification}
      />
      <div className="fallback">
        <p>
          SBremit admin is not yet available on smaller devices. Please access
          it on a desktop.
        </p>
      </div>

      <div className="app_wrapper">
        <div className="sidebar">
          <div className="logo">
            <img src={getImage("main-logo.svg", "svgs")} alt="sbremit-logo" />
          </div>

          <div className="options">
            {Pages.map((tab, index) => (
              <div
                className="dash-opt opt"
                key={tab.label + index}
                onClick={() => {
                  goTo(tab.path, history);
                }}
              >
                <div
                  className={`icon  ${
                    currentPage === tab.label ? "selected" : ""
                  }`}
                >
                  {tab.svg}
                </div>
                <div className="label">{tab.label}</div>
              </div>
            ))}
          </div>

          <div className="footer">
            <div className="signout">
              <div onClick={() => signOutAction()}>
                <Button
                  size="large"
                  type="default"
                  icon={<LogoutOutlined />}
                  danger
                >
                  Logout
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="appWrapperContent">
          <div className="Navbar">
            <div className="menu">
              <MobileMenu />
            </div>
            <div className="content">
              <p>{currentPage}</p>
              <div className="navLeftComponet">
                <div
                  className="notification"
                  onClick={() => setOpenNotification(true)}
                >
                  <IoIosNotificationsOutline
                    size={30}
                    color="rgba(66, 66, 66, 0.9)"
                  />
                  <div className="alert_dot"></div>
                </div>
                <div className="profileInfo">
                  <div className="username">
                    <span>
                      {" "}
                      {user?.profile.firstName.toLowerCase()}{" "}
                      {user?.profile.lastName.toLowerCase()}
                    </span>
                    <br></br>
                    <span>{user?.username}</span>
                  </div>
                  <div className="profile_img">
                    <img
                      alt="profile img"
                      src={`${resources.DICE_BEAR_USER}${user.meta.customerId}.svg`}
                    />
                  </div>
                </div>
              </div>
            </div>
            {horizontalLoader && (
              <div className="loading">
                <div className="loading-container">
                  <div className="loading-progress"></div>
                </div>
              </div>
            )}
          </div>
          <div className="body" ref={bodyContainerRef}>
            <div className="">
              <Switch>
                <Route path={paths.DASHBOARD} component={Dashboard} exact />
                <Route path={paths.TRANSFERS} component={Transfers} exact />
                <Route path={paths.USERS} component={Users} exact />
                <Route
                  path={paths.USER_PROFILE}
                  component={CustomerDetail}
                  exact
                />
                <Route
                  path={paths.TRANSFER_PROFILE}
                  component={TransactionDetail}
                  exact
                />
                <Route
                  path={paths.USER_VERIFICATION_SOURCES}
                  component={UserVerificationData}
                  exact
                />
                <Route path={paths.PROMOS} component={Promos} exact />
                {/* <Route path={paths.SEGMENTS} component={Segments} exact /> */}
                <Route path={paths.FX} component={Preorders} exact />
                <Route path={paths.PREORDER} component={Preorder} exact />
                {/* <Route path={paths.MAINTENANCE} component={Maintenance} exact /> */}
                <Route path={paths.SETTINGS} component={Settings} exact />
                <Route
                  path={paths.COMPETITOR_RATES}
                  component={CompetitorRates}
                  exact
                />
                <div className="transfer-flow">
                  <Route
                    path={paths.TRANSFER_METHOD}
                    component={TransferMethod}
                    exact
                  />
                  <Route path={paths.GET_QUOTE} component={GetQuote} exact />
                  <Route path={paths.RECIPIENT} component={Recipient} exact />
                  <Route path={paths.REVIEW} component={Review} exact />
                  <Route
                    path={paths.PAYMENT_METHOD}
                    component={PaymentMethod}
                    exact
                  />
                  <Route
                    path={paths.TRANSFER_COMPLETE}
                    component={TransferComplete}
                    exact
                  />
                </div>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </Body>
  );
};
