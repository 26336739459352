import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { getTransfers, getCompetitorRates, sendActivationMail } from '../../../redux/actions/actions';
import constants from '../../../util/constants';
import { paths } from '../../../util/paths';
import { getImage } from '../../../util/utility';
import Pagination from '../../modules/pagination/Pagination';
import TSearch from '../../modules/t-search/TSearch';
import Modal from "../../modules/modal/Modal";
import ReferralConfigForm from 'src/components/modules/referral-config-form/ReferralConfigForm';
import CompetitorRateForm from 'src/components/modules/competitor-rate-form/CompetitorRateForm';
import Table from 'src/components/modules/new-table/Table';
import { RESPONSIVE_TYPE_COLLAPSE_ALL } from 'src/components/modules/new-table/ITable';
import { getExchangeRateTableData } from './CompetitorRates.helper';


const Body = styled.div`
                 .table { 
                    width: 60%;
                    margin: 32px auto;
                    min-width: 850px;
                    @media only screen and (max-width: 900px) {
                        width: 90%;
                        min-width: unset;
                    }


                    .landing-page-table {
                        border-collapse: collapse;
                        width: 100%;
                        @media only screen and (max-width: 900px) {
                            border: none!important;;
                        }
                        td {
                            text-align: center;
                            padding: 13px 0;
                            max-height: 73px;
                            &.sbremit {
                                img.sbremit-logo {
                                    max-width: 100px;
                                }
                            }
                        }

                        th {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 21px;
                            color: #000000;
                            padding: 16px 0;
                        }

                        th, td {
                            border: 1px solid black;
                            border-collapse: collapse;
                        }

                        .competitor-update-btn {
                            display: flex;
                            gap: 10px;
                            background: white;
                            border: 1px solid lightgrey;
                            border-radius: 5px;
                            padding: 5px 10px;
                            margin: 5px 10px;
                            cursor: pointer;
                            img {
                                width: 15px;
                            }

                            span {
                                font-size: 14px;
                            }

                        }

                        @media only screen and (max-width: 900px) {


                            td, th, tr {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 21px;
                                color: #000000;
                                border: none;


                                .inline-heading {
                                    .line {
                                        font-weight: 500;
                                        text-align: left!important;
                                    }
                                }

                                .content-wrapper {
                                    .line {
                                        &.line-1 {
                                            display: grid;
                                            grid-template-areas: 'b a';

                                            span {
                                                grid-area: b;
                                                margin-top: 10px;
                                                margin-right: 10px;
                                            }
                                        }
                                    }
                                }
                            }

                            td {
                                &:last-child {
                                    margin-bottom: 30px;
                                    border-bottom: 1px solid #00000010;
                                }
                            }
                        }
                        &.landing-page-compare-features-table {
                            td.sbremit {
                                padding: 24px;
                                background: #F3F5F5;
                                @media only screen and (max-width: 900px) {
                                    padding: 13px 0px;
                                    background: none;
                                }
                            }
                        }

                        &.landing-page-compare-exchange-rates-table {
                            border: 1px solid #0A0E0D77;
                            th, td {
                                border: none;
                                border-bottom: 1px solid #0A0E0D77;
                                &:first-child {
                                    border-right: 1px solid #0A0E0D33;
                                }
                            }

                            th {
                                background: #227863;
                                color: white;
                                border: 1px solid #227863;
                                padding: 30px 0px;
                            }

                            td {
                                color: #424242;
                            }

                            td.sbremit {
                                background: #F3F5F5;
                                @media only screen and (max-width: 900px) {
                                    background: none;
                                }
                            }

                            @media only screen and (max-width: 900px) {
                                td {
                                    border: none;
                                    padding-bottom: 21px;
                                    &:last-child {
                                        margin-bottom: 10px;
                                        border-bottom: 1px solid #00000010;
                                        padding-bottom: 76px;
                                    }
                                }
                            }

                            .table-send-btn {
                                padding: 8px 16px;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                text-align: center;
                                letter-spacing: -0.01em;
                                background: #FCD20F;
                                border-radius: 6px;
                                border: none;
                                color: #000000; 
                            }
                        }
                    }

                }
`

export const CompetitorRates = () => {
    const competitorRates = useSelector((state: any) => state.competitorRates.competitorRates)
    const [addRateModalOpen, setAddRateModalOpen] = useState(false)
    const [currentCompetitorEdit, setCurrentCompetitorEdit]: any = useState(null);


    useEffect(() => {
        getCompetitorRates()
    }, [])

    useEffect(() => {
        if (!addRateModalOpen) {
            setCurrentCompetitorEdit(null)
        }
    }, [addRateModalOpen])

    useEffect(() => {
        if (currentCompetitorEdit) {
            setAddRateModalOpen(true)
        }
    }, [currentCompetitorEdit])


    const getCompetitorRate = (id: number) => {
        return competitorRates.filter((competitorRate: any) => competitorRate.id == id)[0]
    }


    return (
        <Body>
            <Modal component={<CompetitorRateForm closeModal={() => setAddRateModalOpen(false)} editId={currentCompetitorEdit} />} open={addRateModalOpen} setOpen={setAddRateModalOpen} />
            {competitorRates?.length < 9 && <button className="add-competitorRate-btn" onClick={() => setAddRateModalOpen(true)} >Add new rate</button>}

            <div className="table">
                    <Table
                        rows = {getExchangeRateTableData(competitorRates, setCurrentCompetitorEdit).rows}
                        headings={getExchangeRateTableData(competitorRates).heading}
                        config={{
                            customClassName: 'landing-page-table landing-page-compare-exchange-rates-table',
                            cellSpacing: 0,
                            cellPadding: 0,
                            responsiveType: RESPONSIVE_TYPE_COLLAPSE_ALL
                        }}
                    />

                </div>

        </Body>
    )
}


