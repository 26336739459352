import { useMutation, useQuery } from "react-query";
import { queryClient } from "src/App";
import { USERS } from "src/redux/actionTypes";
import { toastAction } from "src/redux/actions/actions";
import store from "src/redux/store";
import endpoints from "src/util/endpoints";
import { getRequest, postRequest, putRequest } from "src/util/http";
import { parseEndpointParameters, sortByTimeDesc } from "src/util/util";

export const useUserData = (queryKey: string, enabled?: boolean) => {
  const customEndpoint = queryKey;
  return useQuery(
    customEndpoint,
    () => getRequest(customEndpoint, "Failed to fetch users"),
    {
      refetchIntervalInBackground: true,
      keepPreviousData: true,
      enabled,
    }
  );
};

export const useGetUserUploadsData = (queryKey: string) => {
  const customEndpoint = queryKey;
  return useQuery(
    customEndpoint,
    () => getRequest(customEndpoint, "Failed to fetch user uploads data"),
    {
      refetchIntervalInBackground: true,
      keepPreviousData: true,
    }
  );
};

export const useGetVerificationsData = (queryKey: string) => {
  const customEndpoint = queryKey;
  return useQuery(
    customEndpoint,
    () => getRequest(customEndpoint, "Failed to fetch user verification data"),
    {
      refetchIntervalInBackground: true,
      keepPreviousData: true,
    }
  );
};

export const useUpdateUserAction = (onSuccess: Function) => {
  const endpoint = endpoints.USER_ACTIVITY;
  return useMutation(
    (values: any) =>
      postRequest(
        values,
        parseEndpointParameters(endpoint),
        "Failed to update user account, Pls try again"
      ),
    {
      onSuccess: (data: any) => {
        if (data.status === "200" || data.status === 200) {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: "User account updated",
          });
          onSuccess();
        }
        // queryClient.invalidateQueries(endpoint);
      },
    }
  );
};

export const useGetActivityLogs = (queryKey: string) => {
  const customEndpoint = queryKey;
  return useQuery(
    customEndpoint,
    () => getRequest(customEndpoint, "Failed to fetch user activity logs"),
    {
      refetchIntervalInBackground: true,
      keepPreviousData: true,
    }
  );
};
