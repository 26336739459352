
export const settings : any = {
    MOBILE_MONEY_MAX: 500000,
    CASH_PICKUP_MAX: 20000,
    BANK_TRANSFER_MAX: 20000,
    TRANSFER_TIME_LIMIT_IN_SECONDS: 60 * 60 * 24, // 24 hrs


    TRUST_PAYMENT_SITE_REFERENCE: process.env.REACT_APP_TRUST_PAYMENT_SITE_REFERENCE,
    TRUST_NOTIFICATION_WEBHOOK_URL: process.env.REACT_APP_TRUST_NOTIFICATION_WEBHOOK_URL,
    TRUST_SUCCESSFUL_REDIRECT_URL: process.env.REACT_APP_TRUST_SUCCESSFUL_REDIRECT_URL

}