import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import { getQueryParam } from '../../../../util/util';
import Step1 from '../preorder-steps/Step1'
import Step3 from '../preorder-steps/Step3'
import Step2 from '../preorder-steps/Step2'
import PreorderCompleted from '../preorder-steps/PreorderCompleted'



const Preorder = () => {
    const [step, setStep] = useState<'step1' | 'step2' | 'step3' | 'completed'>('step1')
    const [preOrderData, setPreOrderData] = useState({})
    const users = useSelector((state: any) => state.users.users)
    const [user, setUser]: any = useState(null)
    const getUser = (id: number) => users.filter((user: any) => user.id === id)[0];

    useEffect(() => {
        const userId = Number(getQueryParam('user'));
        setUser(getUser(userId));

        const stepFromParam = Number(getQueryParam('step'))
        if ( stepFromParam <= 3 && stepFromParam >= 1 ) {
          const step: any = "step"+stepFromParam;
          setStep(step);
        }
    }, [])

    // return null
    return (
        <>
          {
            step === 'step1' ? <Step1 setStep={setStep} user={user} preOrderData={preOrderData} setPreOrderData={setPreOrderData}/> :
            step === 'step2' ? <Step2 setStep={setStep} preOrderData={preOrderData} setPreOrderData={setPreOrderData}/> :
            step === 'step3' ? <Step3 setStep={setStep} user={user} preOrderData={preOrderData} setPreOrderData={setPreOrderData}/> :
            <PreorderCompleted setStep={setStep} />
          }
        </>
    )
}

export default Preorder
