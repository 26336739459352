import React from "react";
import { Divider, Modal, Radio, RadioChangeEvent, Space } from "antd";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { getImage } from "src/util/utility";

interface DownloadDataProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  header: [];
  data: any;
  type: string;
}

//extending the CSVLink component's type

export default function DownloadData({
  open,
  setOpen,
  header,
  data,
  type,
}: DownloadDataProps) {
  const [value, setValue] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const csvLinkRef = React.useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const handleOk = async () => {
    if (value === 1) {
      if (csvLinkRef.current) {
        csvLinkRef?.current?.link.click(); // Trigger CSV download using CSVLink
      }
    } else {
      downloadPdf();
    }

    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  function getFormattedDate() {
    const today = new Date();

    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const year = String(today.getFullYear()).slice(-2); // Get the last two digits of the year

    let hours = today.getHours();
    const minutes = String(today.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";

    // Convert to 12-hour format
    hours %= 12;
    hours = hours || 12;

    const time = `${hours}:${minutes}${ampm}`;
    const date = `${day}/${month}/${year}`;

    return `${date} - ${time}`;
  }

  const downloadPdf = () => {
    const doc = new jsPDF();
    const dataCopy = data.map((obj: any) => Object.values(obj));

    const logoUrl = getImage("main-logo.svg", "svgs");
    const logoWidth = 28;
    const logoHeight = 8;

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Set the grid banner color
    const gridColor = "#007B5D";

    // Calculate the X and Y coordinates to place the logo at the bottom center of the page
    const logoX = (pageWidth - logoWidth) / 2;
    const logoY = pageHeight - 6 - logoHeight;

    //doc.addImage(logoUrl, "PNG", logoX, logoY, logoWidth, logoHeight);

    doc.text(
      `${type} Data Report - Downloaded on (${getFormattedDate()})`,
      20,
      10
    );
    doc.setTextColor("red ");
    autoTable(doc, {
      theme: "grid",
      headStyles: {
        fillColor: gridColor,
      },
      columns: header,
      body: dataCopy,
    });
    doc.save("sbremit-table-data.pdf");
  };

  const csvLink = {
    filename: `${type}_Data_Report.csv`,
    header: header,
    data: data,
  };

  return (
    <Modal
      title="Choose how to download selected data"
      okText="Download"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ marginTop: "10%" }}
      okButtonProps={{ size: "large", loading: loading }}
      cancelButtonProps={{ size: "large" }}
    >
      <Divider style={{ marginTop: "12px" }} />
      <div style={{ marginTop: "-20px" }}>
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            <Radio value={1}>
              <div style={{ marginLeft: "10px", marginTop: "22px" }}>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#424242",
                  }}
                >
                  CSV file ({data.length} users selected)
                </span>
                <br></br>
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 400,
                    color: "#424242",
                  }}
                >
                  Works with Google Sheets and other spreadsheet tools
                </span>
              </div>
            </Radio>
            <Radio value={2}>
              <div style={{ marginLeft: "10px", marginTop: "22px" }}>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#424242",
                  }}
                >
                  PDF ({data.length} users selected)
                </span>
                <br></br>
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 400,
                    color: "#424242",
                  }}
                >
                  Works with Adobe Acrobat and other tools
                </span>
              </div>
            </Radio>
          </Space>
        </Radio.Group>
      </div>
      <Divider style={{ marginTop: "24px" }} />
      {/* Attach the CSVLink component with the ref */}
      <CSVLink ref={csvLinkRef} {...csvLink} style={{ display: "none" }}>
        Download CSV
      </CSVLink>
    </Modal>
  );
}
