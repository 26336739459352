import React from 'react'
import constants from '../../../../util/constants'
import {getImage} from '../../../../util/utility'

const Header = ({data}: any) => {
    return (
        <div className="receiptHeaderComponent">
            <img src={getImage('sbcapital-logo.svg', 'svgs')} alt="Logo" />
            <p>{ data?.status === constants.STATUS_PENDING ? 'Quote' : 'Transaction Receipt'} </p>
        </div>
    )
}

export default Header
