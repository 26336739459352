import { Divider, Modal } from "antd";
import { UploadButton } from "../parts/UploadButton";
import { useDispatch, useSelector } from "react-redux";
import { UPLOAD_DOC } from "src/redux/actionTypes";

export default function CustomUploadDocModal() {
  const uploadState = useSelector((state: any) => state.uploadDoc);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch({ type: UPLOAD_DOC, payload: { ...uploadState, open: false } });
  };

  return (
    <Modal
      style={{ top: "15%" }}
      width={550}
      open={uploadState.open}
      title="Upload document"
      onCancel={() => closeModal()}
      footer={null}
    >
      {" "}
      <Divider style={{ marginTop: "12px" }} />
      <div style={{ marginTop: 8, height: 300 }}>
        <UploadButton user={uploadState.user} transfer={uploadState.transfer} />
      </div>
    </Modal>
  );
}
