import { Button, Input, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { queryClient } from "src/App";
import { confirmDialog, confirmUserPassword } from "src/redux/actions/actions";
import styled from "styled-components";
import { useUpdateUserAction } from "../helper/useRQuserData";
const { TextArea } = Input;

const FlagAndAddSoftblock = ({ userData }: { userData: any }) => {
  const userId = userData?.id || "";

  const isUserAcctFlagged = userData?.status === "SUSPENDED";
  const isUserAcctSoftBlocked = userData?.status === "TEMPORARILY_LOCKED";

  return (
    <FlagAndAddSoftblockWrapper>
      <Action
        title="Flag user account"
        description="All transfers from this user will not be auto fulfilled once the account is flagged. To change this, you’ll need to turn it off again."
        action={isUserAcctFlagged ? "unflagged" : "flagged"}
        userId={userId}
        originState={isUserAcctFlagged}
      />
      <Action
        title="Add a soft block"
        description="Users account will be temporarily blocked once soft block is activated, but will still be able to login successfully to their account."
        action={isUserAcctSoftBlocked ? "unblocked" : "blocked"}
        userId={userId}
        originState={isUserAcctSoftBlocked}
      />
    </FlagAndAddSoftblockWrapper>
  );
};

export default FlagAndAddSoftblock;

interface ActionProps {
  userId: string;
  title: string;
  description: string;
  action: "flagged" | "blocked" | "unflagged" | "unblocked";
  originState: boolean;
}

const Action = ({
  originState,
  userId,
  title,
  description,
  action,
}: ActionProps) => {
  const { activityLogsQueryKey } = useSelector((state: any) => state.users);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [switchState, setSwitchState] = useState(originState);

  useEffect(() => {
    setSwitchState(originState); //update flag whenever user data(status) changes
  }, [originState]);

  const userEndpoint = `/user/${userId}`;

  const { mutate: updateUserAcct, isLoading } = useUpdateUserAction(() => {
    queryClient.invalidateQueries(activityLogsQueryKey);
    queryClient.invalidateQueries(userEndpoint);
    setTextAreaValue("");
  });

  const onTextAreaChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTextAreaValue(e.target.value);
  };

  const onSwitchChange = (checked: boolean) => {
    setSwitchState(checked);
  };

  const getActionOption = (): string => {
    if (action === "blocked" || action === "unblocked") {
      // 'Account Soft Deleted', 'Account Activated'
      return switchState ? "Account Soft Blocked" : "Account Activated";
    } else {
      //else falgged
      // 'Account Flagged', 'Account Unflagged',
      return switchState ? "Account Flagged" : "Account Unflagged";
    }
  };

  const onSubmit = () => {
    const payload = {
      userId: userId,
      action: getActionOption(),
      note: textAreaValue,
    };

    confirmDialog({
      message: `Please, input your account password to make this change`,
      isPositive: undefined,
      open: true,
      field: {
        title: "Password:",
        placeholder: "Your account password here...",
        required: true,
      },
      callback: (fieldValue: string) =>
        confirmUserPassword(fieldValue, () => {
          updateUserAcct(payload);
        }),
    });
  };

  return (
    <ActionWrapperStyles>
      <div className="_header">
        <div className="_title_and_switch">
          <p>{title}</p>
          <Switch checked={switchState} onChange={onSwitchChange} />
        </div>

        <div className="_description">
          <span>{description}</span>
        </div>
      </div>

      {switchState !== originState && (
        <div className="_add_note">
          <div className="_header">
            <p>Add a note</p>
            <span>Captures why the user account is being {action}</span>
          </div>

          <TextArea
            placeholder="Start typing..."
            allowClear
            onChange={onTextAreaChange}
            value={textAreaValue}
            rows={8}
          />

          <Button
            // disabled={textAreaValue === ""}
            type="primary"
            size="large"
            onClick={onSubmit}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      )}
    </ActionWrapperStyles>
  );
};

const FlagAndAddSoftblockWrapper = styled.section`
  margin: 0px 4%;
  display: flex;
  gap: 32px;
  align-items: flex-start;
  justify-content: space-between;
`;

const ActionWrapperStyles = styled.div`
  flex: 1;
  min-height: 176px;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1px solid rgba(144, 152, 178, 0.3);
  padding: 24px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;

  ._header {
    width: 100%;
    ._title_and_switch {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    ._description {
      color: #707070;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      max-width: 90%;
    }
  }

  ._add_note {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    ._header {
      display: flex;
      flex-direction: column;
      gap: 6px;

      p {
        color: #424242;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
      }
      span {
        color: #424242;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
    }
  }
`;
