import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCompetitorRate, getCompetitorRates, updateReferralConfigs } from 'src/redux/actions/actions'
import { CompetitorRateValidator } from 'src/util/form-validators'
import { paths } from 'src/util/paths'
import FormButton from '../form-button/FormButton'
import styled from 'styled-components'
import { getValueFromArray } from 'src/util/util'


const Div = styled.div`
    
    .form {
            width: 80%;
            margin: 30px auto 0px;
            >div{
                margin-top: 20px;
                font: normal normal normal 15px/19px Montserrat;
                color: #A3A3A3;
                i{
                    color: #FCD20F;
                }
                .show-hide{
                    width: 16px;
                    height: 16px;
                    position: relative;
                    top: -33px;
                    left: 90%;
                }
                >div{
                    font: normal normal normal 15px/19px Montserrat;
                    line-height: 19px;
                }
            }
            input, select{
                margin-bottom: 5px;
                width: 100%;
                height: 48px;
                border: 2px solid #7FBCAD;
                border-radius: 4px;
                background: #ffffff;
                outline: none;
                font: normal normal normal 14px Montserrat;
                color: #A3A3A3;
                padding: 20px;
                ::placeholder{
                    color: #A3A3A3; 
                }
            }
            select{
                -webkit-appearance: none;
                -moz-appearance: none;
                background: transparent;
                background-image: url("data:image/svg+xml;utf8,<svg fill='rgb(127, 188, 173)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                background-repeat: no-repeat;
                background-position-x: 95%;
                background-position-y: 10px;
                padding: 0px;
                padding-left: 15px;
            }
            select.phone  {
                background-position-x: 15%;
                padding-left: 75px;
            }
            input.phone-no {
                position: relative;
                top: 51px;
                width: 79%;
                height: 44px;
                margin-left: 20%;
                border: 2px solid transparent;
                background: #fff;
            }
            div.mobile-head {
                margin-bottom: -50px;
            }
            select+img{
                position: relative;
                top: -35px;
                left: 20px;
                width: 25px;
                height: 19px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 1px;
                pointer-events: none;
            }
            div.margin-adjust {
                margin-bottom: -44px;
            }
        }
        .modal-btns {
            text-align: right;
            margin: 65px 10%;
            span {
                display: inline-block;
                margin-right: 50px;
                font: normal normal normal 16px/19px Montserrat;
                color: #424242;
                cursor: default;
            }
            button {
                background: #FCD20F;
                border-radius: 4px;
                width: 200px;
                height: 48px;
                text-align: center;
                font: normal normal normal 16px/19px Montserrat;
                color: #424242;
                border: none;
                outline: none;
            }
        }
`
const CompetitorRateForm = ({editId, closeModal}: any) => {
    const competitorRates = useSelector((state: any) => state.competitorRates.competitorRates)


    let initialValues: any = {
        name: '',
        baseCurrency: 'GBP',
        targetCurrency: 'XAF',
        rate:  '',
        transferFee: ''
    }

    if (editId) {

        const currentEdit = competitorRates.find((rate: any) => rate.id == editId);

        initialValues = {
            ...currentEdit
        }
    }

  return (
            <Div>
                <Formik
                initialValues={{...initialValues}}
                validationSchema={CompetitorRateValidator}
                onSubmit={values => {
                    createCompetitorRate(values, () => {
                        closeModal()
                        getCompetitorRates()
                    }, editId)
                }}>
                {
                    ({errors, touched, values}: any) => (

                        <Form className="form">
                            <div className="inputs">
                                <div>
                                    <div className={(touched.name && errors.name) ? 'form-error': '' } >
                                        <div>Name<i>*</i></div>
                                        <Field as="select" name="name" disabled={Boolean(editId)} >
                                            <option value="sbremit">SB Remit</option>
                                            <option value="westernunion">Western Union</option>
                                            <option value="moneygram">Money gram</option>
                                            <option value="worldremit">World Remit</option>
                                            <option value="ria">Ria</option>
                                            <option value="paysend">Paysend</option>
                                            <option value="taptap">Tap tap</option>
                                            <option value="sendwave">Sendwave</option>
                                            <option value="remitly">Remitly</option>
                                        </Field>
                                        {(touched.name && errors.name) && <div className="form-error-message">{errors.name}</div>}
                                    </div>
                                    <div></div>
                                    <div className={(touched.baseCurrency && errors.baseCurrency) ? 'form-error': ''} hidden>
                                        <div>Base Currency <i>*</i></div>
                                        <Field as="select" name="baseCurrency" type="number" >
                                            <option value="GBP">GBP</option>
                                            <option value="CAD">CAD</option>
                                            <option value="EUR">EUR</option>
                                        </Field>
                                        {(touched.baseCurrency && errors.baseCurrency) && <div className="form-error-message">{errors.baseCurrency}</div>}
                                    </div>
                                </div>

                                <div>
                                    <div className={(touched.targetCurrency && errors.targetCurrency) ? 'form-error': ''} hidden>
                                        <div>Target Currency<i>*</i></div>
                                        <Field as="select" name="targetCurrency" type="number" >
                                            <option value="XAF">XAF</option>
                                            <option value="GBP">GBP</option>
                                            <option value="CAD">CAD</option>
                                            <option value="EUR">EUR</option>
                                        </Field>
                                        {(touched.targetCurrency && errors.targetCurrency) && <div className="form-error-message">{errors.targetCurrency}</div>}
                                    </div>
                                    <div></div>
                                    <div className={(touched.rate && errors.rate) ? 'form-error': ''}>
                                        <div>Rate <i>*</i></div>
                                        <Field name="rate" type="number"/>
                                        {(touched.rate && errors.rate) && <div className="form-error-message">{errors.rate}</div>}
                                    </div>

                                    <div className={(touched.transferFee && errors.transferFee) ? 'form-error': ''}>
                                        <div>Transfer Fee <i>*</i></div>
                                        <Field name="transferFee" type="number"/>
                                        {(touched.transferFee && errors.transferFee) && <div className="form-error-message">{errors.transferFee}</div>}
                                    </div>
                                </div>


                                <div className="modal-btns"><FormButton onClick={alert} label={editId ? "Update" : "Save"} formName={paths.USERS + "competitor-rates"} /> </div>

                            </div>
                        </Form>
                    )
                }
            </Formik>
        </Div>
  )
}

export default CompetitorRateForm