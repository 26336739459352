import { LoadingOutlined } from "@ant-design/icons";
import { Button, Space, Tag } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { queryClient } from "src/App";
import { UploadButton } from "src/components/modules/parts/UploadButton";
import { toastAction } from "src/redux/actions/actions";
import { CustomError, CustomLoader } from "src/util/Reuseables";
import endpoints from "src/util/endpoints";
import { convertDateString, convertToTimeString } from "src/util/util";
import styled from "styled-components";
import { uploadCSVDocument } from "../../helpers/helper";
import { useAMLData } from "../../helpers/useRQsettingsData";

const AmlList = () => {
  return (
    <AmlListWrapper>
      <UploadEMLList />
      <History />
    </AmlListWrapper>
  );
};

export default AmlList;

const UploadEMLList = () => {
  const user = useSelector((state: any) => state.auth.user);
  const [isUploading, setIsuploading] = useState(false);

  const toastMessage = (type: string, message: string) => {
    return toastAction({
      show: true,
      type: type,
      message: `${message}`,
    });
  };

  return (
    <UploadEMLListWrapper>
      <h2>Uploaded Consolidated List</h2>

      <div className="_upload_action">
        <UploadButton
          fileFormat="CSV"
          callback={async (data: any, resetSelectedFile: Function) => {
            // Perform the file upload
            console.log(data);
            setIsuploading(true);
            uploadCSVDocument(data, resetSelectedFile, user?.id)
              .then(() => {
                setIsuploading(false);
                toastMessage("success", "Document uploaded successfully");
                queryClient.invalidateQueries(endpoints.AML + "/list"); //invalidate file upload history history
              })
              .catch((error: any) => {
                setIsuploading(false);
                toastMessage("error", error?.message);
              });
          }}
          isExternalAction={true}
        />

        <div className="note">
          {isUploading ? (
            <span style={{ color: "blue" }}>
              <LoadingOutlined /> Uploading file...
            </span>
          ) : (
            "Please upload a CSV file"
          )}
        </div>
      </div>
    </UploadEMLListWrapper>
  );
};

const History = () => {
  const user = useSelector((state: any) => state.auth.user);
  const { data, isLoading, isError, error } = useAMLData();
  const err: any = error;
  const amlDataHistory: any[] = (data && JSON.parse(data?.message)) || [];

  const total = amlDataHistory.length;
  const limit = 5;

  const isAmlDataHistoryEmpty = amlDataHistory.length === 0;
  const [endIndex, setEndIndex] = useState(limit);

  if (isLoading) {
    return (
      <HistoryWrapper>
        <CustomLoader prompt="Loading file upload history..." />
      </HistoryWrapper>
    );
  }

  if (isError) {
    return (
      <HistoryWrapper>
        <CustomError message={err?.message} />
      </HistoryWrapper>
    );
  }

  const handleShowMore = () => {
    setEndIndex((prevEndIndex) => Math.min(prevEndIndex + limit, total));
  };

  const handleShowLess = () => {
    setEndIndex((prevEndIndex) => Math.max(prevEndIndex - limit, limit));
  };

  const getDBupdateStatusColor = (status: string) => {
    if (status === "success") {
      return "green";
    } else if (status === "pending") {
      return "gold";
    } else if (status === "failed") {
      return "red";
    } else {
      return "";
    }
  };

  return (
    <HistoryWrapper>
      {isAmlDataHistoryEmpty && (
        <div className="_wrap">
          <IndicatorWrapper $isUser={false} />
          <span>No file upload history</span>
        </div>
      )}
      {amlDataHistory
        .reverse()
        .slice(0, endIndex)
        .map((data, index) => (
          <div className="_wrap" key={index + data?.userId}>
            <IndicatorWrapper $isUser={data?.userId === user?.id} />
            <div className="_userdata">
              <p>
                {data?.firstName || "N/A"} {data?.lastName || "N/A"}
              </p>
 
              <span className="_span">
                Uploaded a file at {convertDateString(data?.dateCreated)} -{" "}
                {convertToTimeString(data?.dateCreated)}
              </span>

              <br />
              <span className="_span">
                Database update:{" "}
                <Tag
                  className="_tag"
                  color={getDBupdateStatusColor(data?.status)}
                > 
                  {Boolean(data?.status) ? data?.status : "N/A"}
                </Tag>
              </span>
            </div>
          </div>
        ))}

      {!isAmlDataHistoryEmpty && (
        <div className="_wrap">
          <IndicatorWrapper $isUser={false} />
          <Space>
            <Button onClick={handleShowLess} disabled={endIndex === limit}>
              Show less
            </Button>
            <Button onClick={handleShowMore} disabled={endIndex >= total}>
              Show more
            </Button>
          </Space>
        </div>
      )}
    </HistoryWrapper>
  );
};

const AmlListWrapper = styled.div`
  margin-top: 40px;
`;

const UploadEMLListWrapper = styled.div`
  border: 1px solid rgba(144, 152, 178, 0.3);
  height: 300px;
  width: 780px;
  border-radius: 8px;
  padding: 24px 32px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 32px;

  h2 {
    font-size: 20px;
    font-weight: 500;
    color: #424242;
    margin: 0;
  }

  ._upload_action {
    .note {
      display: flex;
      align-items: center;
      gap: 8px;

      margin-top: -24px;
      color: #707070;
      font-size: 14px;
    }
  }
`;

const HistoryWrapper = styled.div`
  margin-left: 35px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  border-left: 1px solid rgba(144, 152, 178, 0.3);

  Button {
    width: min-content;
  }

  ._wrap {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-left: -6px;

    ._userdata {
      p {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        color: #424242;
      }
      ._span {
        font-size: 14px;
        color: #707070;
      }
      ._tag {
        text-transform: capitalize;
      }
    }
  }
`;

const IndicatorWrapper = styled.div<{ $isUser: boolean }>`
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  flex-shrink: 0;

  border-radius: 100px;

  background-color: ${(props) => (props.$isUser ? "#007B5D" : "#424242")};
  outline: 3px solid rgba(112, 112, 112, 0.3);
`;
