import { createUserAccount, getUsers } from "../../../redux/actions/actions";

import {
  Button,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { queryClient } from "src/App";
import { Countries, getFlagURL } from "src/util/utility";

interface NewUserModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const { Option } = Select;

const initialValues: any = {
  firstName: "",
  lastName: "",
  location_country: "CA",
  username: "",
  password: "",
  mobile: "",
  dob: "",
  referral: "",
  phoneCode: "",
};

function NewUserModal({ open, setOpen }: NewUserModalProps) {
  const { queryKey } = useSelector((state: any) => state.users);
  const [form] = Form.useForm();
  const [step, setStep] = React.useState(1);
  const [formData, setFormData] = useState({});
  const [submittable, setSubmittable] = React.useState(false);
  const [dob, setDob] = React.useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  // Disable dates before 18 years ago from today
  const disabledDate = (current: dayjs.Dayjs | null): boolean => {
    const minDate = dayjs().subtract(18, "year");
    return current ? current.isAfter(minDate, "year") : false;
  };

  const prefixSelector = (
    <Form.Item name="location_country" noStyle style={{ padding: 0 }}>
      <Select style={{ width: 70 }} bordered={false}>
        {Countries.map((flag, index) => (
          <Option value={flag.countryCode} key={index + flag.name}>
            <Tooltip title={`(${flag.dialCode}) ${flag.name}`}>
              <img
                src={getFlagURL(flag.countryCode)}
                alt={flag.name}
                style={{
                  width: "24px",
                  height: "18px",
                  marginTop: "5px",
                }}
              />
            </Tooltip>
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const handleCustomButton = () => {
    form.validateFields().then((values) => {
      if (step === 1) {
        setFormData(values);
        setStep(2); // Move to Step 2 if currently in Step 1
      } else {
        const combinedFormData = { ...formData, ...values };

        // update dob
        combinedFormData.dob = dob;

        combinedFormData.phoneCode = Countries.find(
          (country) => country.countryCode === combinedFormData.location_country
        )?.dialCode;

        setIsSubmitting(true);
        createUserAccount(
          combinedFormData,
          () => {
            setOpen(false);
            form.resetFields();
            setFormData({}); // Clear formData for the next use
            setStep(1); // Reset step to 1 to allow users to create a new user
            setDob("");
            setIsSubmitting(false);

            queryClient.invalidateQueries(queryKey);
          },
          () => {
            setIsSubmitting(false);
          }
        );
      }
    });
  };

  const onDatePickerChange: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    //console.log(dateString);
    setDob(dateString);
  };

  return (
    <Modal
      open={open}
      title="Create an account"
      okText="Create"
      onCancel={() => setOpen(false)}
      footer={null}
      width={550}
      style={{ top: "15%" }}
    >
      <Divider style={{ marginTop: "12px" }} />
      <div className="step_container">
        <div className="step">
          <span
            style={{
              color: "#424242",
              fontSize: "13px",
              fontWeight: 400,
            }}
          >
            Step {step} of 2
          </span>
        </div>
        <div
          className="step_indicator"
          style={{
            width: "200px",
            height: "4px",
            background: "#EDEDED",
            borderRadius: "50px",
            marginTop: "8px",
          }}
        >
          <div
            className="indicator"
            style={{
              width: step === 1 ? "120px" : "200px",
              height: "4px",
              background: "#3E947F",
              borderRadius: "50px",
            }}
          ></div>
        </div>
      </div>
      <div style={{ marginTop: "32px" }}>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initialValues}
        >
          {step === 1 ? (
            <div>
              <Form.Item
                name="mobile"
                label="Phone number"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input
                  size="large"
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                  placeholder="+44"
                />
              </Form.Item>
              <Form.Item
                name="username"
                label="Email address"
                extra="We will use this email to send a verification code to the customer’s email"
                rules={[
                  {
                    required: true,
                    message: "Please input a valid email address!",
                  },
                ]}
              >
                <Input placeholder="E.g example@email.com" size="large" />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                extra="Must be at least 8 characters"
                rules={[
                  {
                    required: true,
                    message: "Please input a password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  size="large"
                  type="textarea"
                />
              </Form.Item>

              <Form.Item
                name="referral"
                label="Referral code (optional)"
                extra="Referred by someone? Use their referral code here."
              >
                <Input placeholder="Enter referral code" size="large" />
              </Form.Item>
              <Form.Item style={{ marginTop: "42px" }}>
                <Button
                  style={{
                    width: "100%",
                    color: "#424242",
                    background: "#FCD20F",
                  }}
                  size="large"
                  type="primary"
                  // loading={true}
                  onClick={() => {
                    setStep(2);
                    handleCustomButton();
                  }}
                >
                  Continue
                </Button>
              </Form.Item>
            </div>
          ) : (
            <div>
              <Form.Item
                name="firstName"
                label="First name"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
              >
                <Input placeholder="E.g John" size="large" />
              </Form.Item>

              <Form.Item
                name="lastName"
                label="Last name"
                extra="Please enter customer’s legal name. This information will be used to verify their account."
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                ]}
              >
                <Input placeholder="E.g Desmond" size="large" />
              </Form.Item>

              <Form.Item
                name="dob"
                label="Enter date of birth"
                rules={[
                  {
                    required: true,
                    message: "Please input DOB!",
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  format="DD-MM-YYYY"
                  style={{ width: "100%" }}
                  onChange={onDatePickerChange}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item style={{ marginTop: "42px" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Button
                    size="large"
                    style={{
                      width: "20%",
                      color: "#424242",
                    }}
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    htmlType="submit"
                    disabled={!submittable}
                    style={{
                      width: "100%",
                      color: "#424242",
                      background: !submittable ? "" : "#FCD20F",
                    }}
                    size="large"
                    type="primary"
                    onClick={handleCustomButton}
                    loading={isSubmitting}
                  >
                    Create User
                  </Button>
                </div>
              </Form.Item>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
}

export default NewUserModal;
