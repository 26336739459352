import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CONFIRM } from "../../../redux/actionTypes";

import { Alert, Button, Divider, Form, Input, Modal } from "antd";
import { identifyAdmin } from "src/util/utility";

import { LockOutlined } from "@ant-design/icons";

export const ConfirmDialog = () => {
  const [form] = Form.useForm();
  const user = useSelector((state: any) => state.auth.user);
  const confirmState = useSelector((state: any) => state.confirmDialog);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [accessControl, setAccessControl] = useState(false);
  const [userRole, setUserRole] = useState("");
  const dispatch = useDispatch();
  const closeDialog = () => {
    dispatch({ type: CONFIRM, payload: { ...confirmState, open: false } });
  };

  React.useEffect(() => {
    let isMounted = true;
    const role = identifyAdmin(user?.roles);

    if (role === "Technical Admin") {
      if (isMounted) {
        setAccessControl(true);
        setUserRole(role);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [confirmState]);

  function handleCancel() {
    form.resetFields();
    closeDialog();
  }

  function handleOk() {
    confirmState.callback(form.getFieldValue("password"));
    closeDialog();
  }

  const confirmInput = () => {
    if (
      form.getFieldValue("password") !== undefined ||
      form.getFieldValue("password") !== " "
    ) {
      setDisabledBtn(false);
    }
  };

  const passwordFiled = confirmState.message === "Password" ? true : false;

  return (
    <Modal
      open={confirmState.open}
      title={accessControl ? "Access Control" : confirmState.title}
      onCancel={() => {
        form.resetFields();
        closeDialog();
      }}
      footer={[
        <Button key="cancel" size="large" onClick={handleCancel}>
          Cancel
        </Button>,
        !accessControl && (
          <Button
            key="ok"
            type="primary"
            size="large"
            onClick={handleOk}
            disabled={passwordFiled ? disabledBtn : false}
          >
            Confirm
          </Button>
        ),
      ]}
      width={485}
      style={{ top: "25%" }}
    >
      <Divider style={{ marginTop: "12px" }} />
      <div style={{ marginTop: "32px" }}>
        {confirmState.field ? (
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ modifier: "public" }}
            onChange={confirmInput}
          >
            <Form.Item
              name="password"
              label={confirmState.field.title}
              rules={[
                {
                  required: true,
                  message: `Please input your ${confirmState.field.title}!`,
                },
              ]}
            >
              {confirmState.field.title === "Password:" ? (
                <Input.Password
                  size="large"
                  style={{ width: "100%" }}
                  placeholder={confirmState.field.placeholder}
                />
              ) : (
                <Input
                  size="large"
                  style={{ width: "100%" }}
                  placeholder={confirmState.field.placeholder}
                />
              )}
            </Form.Item>
          </Form>
        ) : (
          <div>
            {accessControl ? (
              <Alert
                message="Locked"
                icon={<LockOutlined />}
                description={`${userRole} does not have access to this feature!`}
                type="warning"
                showIcon
              />
            ) : (
              <Alert
                message="Warning"
                description={confirmState.message}
                type="warning"
                showIcon
              />
            )}
          </div>
        )}
      </div>
      <Divider />
    </Modal>
  );
};
