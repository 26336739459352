import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getUsers } from "../../../redux/actions/actions";
import { paths } from "../../../util/paths";
import { getImage } from "../../../util/utility";
import TSearch from "../t-search/TSearch";

const Container = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 20px #0000004d;
  border-radius: 15px;
  width: 440px;
  height: 476px;
  position: absolute;
  right: 0;
  margin-right: 24px;
  z-index: 5;
  margin-top: 50px;
  margin-left: -100px;
  padding: 24px 0px;
  .heading {
    font: normal normal normal 24px/29px Montserrat;
    color: #424242;
    padding: 0px 24px;
  }
  .sub {
    font: normal normal normal 16px/24px Montserrat;
    color: #424242b3;
    width: 290px;
    margin: 0px 24px;
    margin-top: 7px;
  }

  ul.users {
    margin: 32px 0px 0px;
    padding: 0px;
    list-style-type: none;
    height: 280px;
    overflow-y: scroll;

    button.new-user-exchange {
      margin: 0px 25px 10px;
      padding: 5px 10px;
      border: 1px solid lightgray;
      cursor: pointer;
      &:hover {
        box-shadow: 1px 1px 2px lightgrey;
      }
    }

    ::-webkit-scrollbar {
      width: 4px;
      background: #b8b8b8 0% 0% no-repeat padding-box;
      border-radius: 10px;
    }
    li.user {
      padding: 8px 24px;
      display: grid;
      grid-template-columns: 0fr 1fr;
      grid-gap: 15px;
      cursor: pointer;
      &:hover {
        background: #f4f4f4 0% 0% no-repeat padding-box;
      }
      .img {
        img {
          width: 46px;
          height: 46px;
          border-radius: 50%;
        }
      }
      .detail {
        .name {
          font: normal normal normal 20px/24px Montserrat;
          color: #424242;
        }
        .location {
          font: normal normal normal 16px/24px Montserrat;
          color: #424242b3;
        }
      }
    }
  }

  .footer {
    padding: 0px 24px;
    .search-container {
      background: #f5f7f7 0% 0% no-repeat padding-box;
      border: 1px solid #b8b8b8;
      border-radius: 10px;
      display: grid;
      grid-template-columns: 0fr 1fr;
      max-width: 432px;
      img {
        width: 25px;
        height: 25px;
        margin: 8px 30px 8px 20px;
      }
      input {
        background: transparent;
        border: none;
        height: 100%;
        font: normal normal normal 20px/24px Montserrat;
        letter-spacing: 0px;
        color: #424242;
        outline: none;
      }
    }
  }
`;

const UserSelectorForTransaction = ({
  closeSelector,
  searchValue,
  setSearchValue,
  onUserSelect,
}: any) => {
  const users = useSelector((state: any) => state.users.users);
  const [usersFormatted, setUsersFormatted] = useState(users);
  const history = useHistory();

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (!searchValue) return setUsersFormatted(users);
    filterUsers(searchValue);
  }, [searchValue, users]);

  const filterUsers = (searchValue: string) => {
    const searchText = searchValue.trim().toLowerCase();

    const filtered = users.filter((user: any) => {
      return (
        (
          user?.profile?.firstName?.toLowerCase() +
          " " +
          user?.profile?.lastName?.toLowerCase()
        )?.indexOf(searchText) !== -1 ||
        (
          user?.profile?.lastName?.toLowerCase() +
          " " +
          user?.profile?.firstName?.toLowerCase()
        )?.indexOf(searchText) !== -1
      );
    });

    setUsersFormatted(filtered);
  };

  return (
    <Fragment>
      <div onClick={() => closeSelector()} className="invisible-overlay"></div>
      <Container>
        <div className="heading">Create exchange for:</div>
        <div className="sub">
          Search here for a user you want to create an exchange for
        </div>
        <ul className="users">
          <button
            className="new-user-exchange"
            onClick={() => onUserSelect(null)}
          >
            {" "}
            New user{" "}
          </button>
          {usersFormatted.map((user: any) => (
            <li className="user" onClick={() => onUserSelect(user.id)}>
              <div className="img">
                <img src={getImage("noimage.png", "images")} alt="user" />
              </div>
              <div className="detail">
                <div className="name">
                  {user.profile?.firstName} {user.profile?.lastName}
                </div>
                <div className="location">
                  {" "}
                  {user.profile.country || user.profile.city || "-"}{" "}
                </div>
              </div>
            </li>
          ))}
        </ul>

        <div className="footer">
          <TSearch
            placeholder="Search for a user"
            setSearchValue={(value: string) => setSearchValue(value)}
          />
        </div>
      </Container>
    </Fragment>
  );
};

export default UserSelectorForTransaction;
