import { useMutation, useQuery } from "react-query";
import { queryClient } from "src/App";
import { MAINTENANCE } from "src/redux/actionTypes";
import {
  confirmDialog,
  confirmUserPassword,
  toastAction,
} from "src/redux/actions/actions";

import store from "src/redux/store";
import endpoints from "src/util/endpoints";
import { getRequest, putRequest } from "src/util/http";
import { parseEndpointParameters } from "src/util/util";

export const useGetMaintainaceData = () => {
  const maintenance = store.getState().maintenance;
  const endpoint = endpoints.HEALTH_CHECK;
  return useQuery(
    endpoint,
    () =>
      getRequest(
        parseEndpointParameters(endpoint),
        "Failed to fetch maintenance data"
      ),
    {
      onSuccess: (data) => {
        store.dispatch({
          type: MAINTENANCE,
          payload: { ...maintenance, ...data?.meta },
        });
      },
    }
  );
};

export const useUpdateMaintainaceData = () => {
  const endpoint = endpoints.HEALTH_CHECK;
  return useMutation(
    (values) =>
      putRequest(
        values,
        parseEndpointParameters(endpoint),
        "Failed to update maintenance data, Pls try again"
      ),
    {
      onSuccess: () => {
        toastAction({
          show: true,
          type: "success",
          timeout: 10000,
          message: "Maintenance data updated",
        });
        queryClient.invalidateQueries(endpoint);
      },
    }
  );
};
